import { useEffect, useRef } from "react";

/**
 * @param {number} progressPercent
 * @param {*} textContent - can be string or Element
 * @param {boolean} animate
 * @returns A radial progress bar with percentage displayed in the center
 */
const RadialProgress = ({ progressPercent, textContent, animate = true }) => {
  const valueRef = useRef();
  const meterRef = useRef();

  /**
   * Update the progress meter
   * @param {Path} path - path element
   * @param {Number} progress - percentage of progress
   */
  const updateProgress = (path, progress) => {
    if (path) {
      // Get the length of the path
      const length = path.getTotalLength(); //Circumfrence of circle. Radius is 45 units, so this is 282.74 units.

      // Calculate the percentage of the total length
      const drawTo = length * ((100 - progress) / 100);
      // Trigger Layout in Safari
      path.getBoundingClientRect();
      // Set the Offset
      path.style.strokeDashoffset = Math.max(0, drawTo);
    }
  };

  /**
   * Animate the percentage number counting up
   * @param {Element} valueElement - DOM element
   * @param {Integer} endValue - end percentage
   * @param {Integer} duration - duration in milliseconds
   */
  const animateValue = (valueElement, endValue, duration) => {
    if (valueElement) {
      const startValue = parseInt(valueRef.current.innerText.replace("%", ""));
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) {
          startTimestamp = timestamp;
        }
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        valueElement.innerHTML =
          Math.floor(progress * (endValue - startValue) + startValue) + "%";
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    }
  };

  useEffect(() => {
    updateProgress(meterRef.current, progressPercent);
    if (animate) {
      animateValue(valueRef.current, progressPercent, 1000);
    } else {
      valueRef.current.innerText = Math.floor(progressPercent) + "%";
    }
  }, [progressPercent]);

  return (
    <div className="radial-progress">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        viewBox="0 0 100 100"
        //TODO: FIND OUT CORRECT PROPERTY FOR SVG DATA VALUE
        datavalue={progressPercent}
      >
        <path
          className="bg"
          d="
          M 100, 100
          m -95, -50
          a 45,45 0 0,1 90,0
          a 45,45 0 0,1 -90,0
          "
          fill="none"
        />
        <path
          className={`meter${animate ? " animate" : ""}`}
          ref={meterRef}
          d="
          M 100, 100
          m -95, -50
          a 45,45 0 0,1 90,0
          a 45,45 0 0,1 -90,0
          "
          fill="none"
          strokeDasharray="282.74"
          strokeDashoffset="282.74"
        />
      </svg>
      <div className="flex flex-col text-center">
        <div className="h2 fontRegular removeMargin" ref={valueRef}>
          0%
        </div>
        <div className="small">{textContent}</div>
      </div>
    </div>
  );
};

export default RadialProgress;
