import { useAppContext } from "@context/state";
import { Collapsible, DropdownBtn } from "@components/index";
import { useState, useEffect, useRef } from "react";
import AdvancedSchoolCard from "./AdvancedSchoolCard";
import { CAPAdvancedSchoolSearch } from "@api/schools";
import { Checkmark } from "@icons/index";
import { checkClickOutside } from "@utils/clickOutside";
import { useRouter } from "next/router";

const AdvancedSchoolList = ({ sortingBy, setSortingBy, setPageNumber }) => {
  const router = useRouter();

  const {
    scenario,
    advancedFilter,
    advancedSearchList,
    setAdvancedSearchList,
    handleApiError,
    initialExpanded,
    setInitialExpanded,
  } = useAppContext();

  const [expanded, setExpanded] = useState(initialExpanded);
  const sortContainerRef = useRef();

  const renderSchools = () => {
    return advancedSearchList?.map((school) => (
      <AdvancedSchoolCard
        key={school.school_id || school.college_id}
        schoolData={school}
      />
    ));
  };

  /**
   * Function for sorting advancedSearchList. Uses the backend so we aren't stuck with just the 20 results that came through in the initial filter.
   * @param {string} sortBy - pass in a string to add onto the filter query. Used like so: &order={sortBy}
   */
  const sortOptionSelected = async (sortBy) => {
    //set the state of sorting by to the selected option
    setSortingBy(sortBy);
    setExpanded(false);
    const sortedFilter = advancedFilter + "&offset=0" + "&order=" + sortBy;
    try {
      const res = await CAPAdvancedSchoolSearch(
        scenario?.case_id,
        sortedFilter
      );
      if (res?.data?.results) {
        router.push(
          `/advanced_search_result/${scenario?.case_id}/${sortedFilter}`
        );
        //reset page number back to 1
        setPageNumber(1);
        setAdvancedSearchList([]);
        setAdvancedSearchList(res.data.results);
      }
      // setExpanded(false);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleClick = (event) => {
    checkClickOutside(event, sortContainerRef);
    const isOutside = checkClickOutside(event, sortContainerRef);
    if (isOutside) {
      setExpanded(false);
    }
  };

  //When expanded state changes to true, add event listener to check for clicking outside search element
  useEffect(() => {
    if (sortContainerRef.current) {
      if (expanded) {
        window.addEventListener("click", handleClick);
      } else {
        window.removeEventListener("click", handleClick);
      }
      return () => {
        window.removeEventListener("click", handleClick);
      };
    }
  }, [expanded]);

  useEffect(() => {
    //reset initial state of sort container. Since sorting triggers a re-render from SSR, context is used to check need for auto-expanding. This resets initial state back to false.
    if (sortContainerRef.current && initialExpanded) {
      setInitialExpanded(false);
    }
  }, [sortContainerRef.current]);

  //option for each value that can be used to sort the search list.
  const sortingOptions = [
    {
      value: "net-cost",
      label: "Your Year 1 Net Cost",
    },
    {
      value: "four-year-cost",
      label: "Your 4 Year Net Cost",
    },
    {
      value: "need",
      label: "Financial Aid %",
    },
    {
      value: "merit-scholarship",
      label: "Scholarship Money",
    },
    //For sticker price sort, see commit fa4ded4d971074661e803cd2d4a7909eed816041
    //use gap to sort by affordability, since funding gap is a more specific metric for affordability that can be sorted more accurately
    {
      value: "gap",
      label: "Funding Gap",
    },
    {
      value: "enrollment",
      label: "Size",
    },
    {
      value: "distance",
      label: "Distance",
    },
    {
      value: "ranking",
      label: "Forbes Ranking",
    },
  ];

  return (
    <div className="advancedList flex flex-col items-start">
      <div className="results-container relative w-full flex justify-start">
        <div
          className="sort-container absolute w-full z-50"
          ref={sortContainerRef}
        >
          <Collapsible expanded={expanded}>
            <div className="sort-options">
              {sortingOptions.map((option) => (
                <div
                  key={option.value}
                  onClick={() => sortOptionSelected(option.value)}
                  className="sort-option flex flex-row items-center w-full"
                >
                  <div className="checkmark">
                    {/* TODO: Add cliploader to show backend is working before showing checkmark */}
                    {sortingBy === option.value && <Checkmark />}
                  </div>
                  <div
                    className={`${
                      sortingBy === option.value ? "font-demi-bold" : ""
                    }`}
                  >
                    {option.label}
                  </div>
                </div>
              ))}
            </div>
          </Collapsible>
        </div>
      </div>
      <div className="curatedGallery flex flex-col flex-nowrap w-full">
        {renderSchools()}
      </div>
    </div>
  );
};

export default AdvancedSchoolList;
