import { Section } from "@components/index";
import Link from "next/link";
import Image from "next/legacy/image";
import {
  DiagonalRightArrow,
  Chart,
  LoanIconMoneyHand,
  LoanIconMoneyPile,
  LoanIconMoneySplit,
  LoanIconWriting,
} from "@icons/index";
import { useAppContext } from "@context/state";

const HowToBorrow = () => {
  const { setPdfViewerContent } = useAppContext();
  const lenders = [
    {
      richText: [
        "Student Loans: ",
        <span className="font-bold" key="rich-text">
          What You Need to Know
        </span>,
      ],
      name: "Student Loans: What You Need to Know",
      svgLogo: <LoanIconMoneyHand />,
      link: "https://collegeaidpro.com/student-loans/",
    },
    {
      name: "Comparing All Student Loan Programs",
      svgLogo: <LoanIconMoneySplit />,
      link: "https://collegeaidpro.com/comparing-student-loan-programs/",
    },
    {
      name: "Compare Private Student Loan Rates",
      svgLogo: <Chart />,
      link: "https://pages.collegeaidpro.com/2024-private-student-loan-fact-sheet-ppc",
    },
    {
      name: "Understanding Federal Loan Program Options",
      svgLogo: <LoanIconMoneyPile />,
      link: "https://collegeaidpro.com/understanding-federal-student-loan-options/",
    },
    {
      name: "Entrance Counseling and Master Promissory Note",
      svgLogo: <LoanIconWriting />,
      link: "https://collegeaidpro.com/student-loan-entrance-counseling/",
    },
  ];

  const HowToBorrowCard = ({ lender }) => {
    return (
      <div
        className="card"
        onClick={() => {
          if (lender.pdf) {
            setPdfViewerContent(lender);
          }
        }}
      >
        <div className="flex items-center justify-center mr-2">
          <div className="lenderLogo">
            {lender.svgLogo ? (
              <>{lender.svgLogo}</>
            ) : (
              <>
                <Image
                  src={lender.logo}
                  layout="fill"
                  objectFit="contain"
                  alt={lender.name}
                />
              </>
            )}
          </div>
          <small className="lenderName font-demi-bold">
            {lender.richText || lender.name}
          </small>
        </div>
        <div className="diagonalArrow">
          <DiagonalRightArrow />
        </div>
      </div>
    );
  };

  const Card = ({ lender }) => {
    return (
      <div className="cardContainer">
        {lender.link ? (
          <Link href={lender.link} rel="noopener noreferrer" target="_blank">
            <HowToBorrowCard lender={lender} />
          </Link>
        ) : (
          <HowToBorrowCard lender={lender} />
        )}
      </div>
    );
  };

  return (
    <div className="apply-for-aid-container how-to-borrow">
      <Section title="How to Borrow" galleryName={"borrow-gallery"}>
        {lenders.map((lender, index) => (
          <Card key={index} lender={lender} />
        ))}
      </Section>
    </div>
  );
};

export default HowToBorrow;
