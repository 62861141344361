const TranslationReadyModal = ({ setTranslationStatus }) => {
  return (
    <div className="card relative flex flex-col items-center text-center">
      <div className="h1 mb-4">Success!</div>
      <div className="mb-4 h3">
        We were able to read and auto-translate your award letter.
      </div>
      <div className="mb-0 h5">
        Please review your results and email us at{" "}
        <a href="mailto:support@collegeaidpro.com">support@collegeaidpro.com</a>{" "}
        if you have any questions. If you believe an amount was translated
        incorrectly, you can manually change the amount by entering a new number
        in the appropriate field.
      </div>
      <button
        type="button"
        className="tertiary hideInfoBtn"
        onClick={() => setTranslationStatus(null)}
      >
        Close
      </button>
    </div>
  );
};

export default TranslationReadyModal;
