/**
 * get access token from cookies
 * @param {string} cookies
 */
export const getAccessTokenFromCookies = (cookies) => {
  const cookieValue = cookies
    .split("; ")
    .find((row) => row.startsWith("access_token="));

  if (cookieValue) {
    return cookieValue.split("=")[1];
  } else {
    return "";
  }
};

/**
 * get access token from document
 * @param - none
 */
export const getAccessTokenFromDocument = () => {
  return getAccessTokenFromCookies(document.cookie);
};

/**
 * get access token from request. Used for getServerSideProps
 * @param {object} request
 */
export const getAccessTokenFromRequest = (request) => {
  const cookies = request.headers.cookie;
  return getAccessTokenFromCookies(cookies);
};

/**
 * get token from url
 * used when an admin impersonates a user and the redirect url that leads to MyCap will hold a token
 * @param - none
 */
export const getAdminAccessTokenFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("token");
};

/**
 * get token from url
 * used when SSO'ing in from the "auth" site
 * @param - none
 */
export const getSSOAccessTokenFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("sso");
};

/**
 * get admin access token from cookies
 * @param {string} cookies
 */
export const getAdminAccessTokenFromCookies = (cookies) => {
  if (cookies) {
    const cookieValue = cookies
      .split("; ")
      .find((row) => row.startsWith("admin_token="));

    if (cookieValue) {
      return cookieValue.split("=")[1];
    } else {
      return "";
    }
  }
};

//Set and Remove cookies for tokens. These functions are not consolidated by passing in the cookie name for the sake of being explicit.

/**
 * set the JWT token to cookies
 * @param {string} token - JWT token
 */
export const setAccessToken = (token) => {
  document.cookie = `access_token=${token}; path=/`;
};

/**
 * set the JWT token to cookies
 * @param {string} token - JWT token
 */
export const setAdminAccessToken = (token) => {
  document.cookie = `admin_token=${token}; path=/`;
};

/**
 * remove access token
 */
export const removeAccessToken = () => {
  document.cookie =
    "access_token=; path=/; expires=" + new Date(0).toGMTString();
};

/**
 * remove admin access token
 */
export const removeAdminAccessToken = () => {
  document.cookie =
    "admin_token=; path=/; expires=" + new Date(0).toGMTString();
};
