import { useRef } from "react";
import _ from "lodash";
import HowToPaySummary from "./HowToPaySummary";
import HowToPayBreakdown from "./HowToPayBreakdown";
import { useForm, FormProvider } from "react-hook-form";
import { useAppContext } from "@context/state";
import { updateTuitionBudget, createTuitionBudget } from "@api/offers";

/**
 * @param {object} offerData
 * @returns Detailed view of a Tuition Budget, with fillable fields
 */
const HowToPayBreakdownCard = ({ offerData }) => {
  if (!offerData?.tuition_budget) {
    return null;
  }
  const cardRef = useRef();

  const {
    scenario,
    handleApiError,
    updateOffersState,
    setHowToPaySchool,
    setScrollToCard,
  } = useAppContext();
  const { school, tuition_budget, cap_projections, tuition_budget_sandbox } =
    offerData;
  const {
    id,
    fillable_fields = {
      coa_1: 0,
      room_and_board_1: 0,
      books_and_supplies_1: 0,
      travel_and_misc_1: 0,
      other_fees_1: 0,
      merit_scholarship_1: 0,
      fed_state_grants_1: 0,
      private_scholarships_1: 0,
      //Pre-Approvals
      funds_in_529_1: 0,
      parent_pledged_assets_1: 0,
      parent_pledged_monthly_1: 0,
      aotc_1: 0,
      student_pledged_assets_1: 0,
      student_pledged_monthly_1: 0,
      other_help_1: 0,
      //Loan Total
      direct_student_loan_1: 0,
      unsubsidized_student_loan_1: 0,
      federal_direct_student_loan_1: 0,
      private_loan_1: 0,
      perkins_loan_1: 0,
      parent_plus_loan_1: 0,
      other_loan_1: 0,
    },
    cost_of_attendance_1 = 0,
    net_cost_1 = 0,
    preapproval_amount_1 = 0,
    funding_gap_1 = 0,
    loan_total_1 = 0,
    remaining_funding_gap_1 = 0,
  } = tuition_budget_sandbox ?? {};

  /**
   * Using watch's default value is not sufficient to handle empty strings, so we use || 0
   * @param {string} inputId
   * @returns integer that updates when form values change
   */
  const watchInt = (inputId) => parseInt(watch(inputId) || 0);

  const formData = {
    tuition_budget: fillable_fields,
    cost_of_attendance_1,
    net_cost_1,
    preapproval_amount_1,
    funding_gap_1,
    loan_total_1,
    remaining_funding_gap_1,
  };

  const methods = useForm({
    mode: "all",
    defaultValues: formData, //useMemo necessary?
    criteriaMode: "all", //allows multiple errors for one field
  });

  const {
    handleSubmit,
    watch,
    formState: { dirtyFields },
  } = methods;

  /* -------- Form Submission -------- */

  /**
   * Get only the values from form which have been changed by the user
   * @param {string} parentKey
   * @param {object} formValues
   * @returns object with only dirty fields and their values
   */
  const getChangedValues = (parentKey, formValues) => {
    const changedValues = {};
    Object.keys(dirtyFields[parentKey]).map((key) => {
      const fieldValue = _.get(formValues, `${parentKey}.${key}`);
      // if value is non-valid, skip this iteration
      if (fieldValue === "" || fieldValue === undefined) {
        return false;
      }
      //add changed key/ value pair to final object
      changedValues[key] = fieldValue;
    });
    return changedValues;
  };

  const upsertTuitionBudget = async (scenarioId, values) => {
    if (id) {
      return await updateTuitionBudget(scenarioId, id, values);
    } else {
      return await createTuitionBudget(scenarioId, values);
    }
  };

  const onSubmit = async (values) => {
    try {
      // Get changed values for tuition_budget. This will update the how_to_pay table
      if (dirtyFields["tuition_budget"]) {
        const changedTuitionBudgetValues = getChangedValues(
          "tuition_budget",
          values
        );
        changedTuitionBudgetValues["college_id"] = school?.school_id;
        //now submit update to tuition budget if id, otherwise create tuition budget
        const res = await upsertTuitionBudget(
          scenario?.case_id,
          changedTuitionBudgetValues
        );
        if (res.data) {
          const updatedOffer = _.get(res.data.result, "offer");
          //update state of offers with new object
          updateOffersState(updatedOffer);
          //set index of card to scroll to
          const cardGalleryIndex = [
            ...cardRef.current.parentNode.children,
          ].indexOf(cardRef.current);
          //return to view of smaller cards
          setHowToPaySchool({});
          //scroll to matching school card
          setScrollToCard(cardGalleryIndex);
        }
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <div className="cardContainer" ref={cardRef}>
      <FormProvider {...methods}>
        <form
          className="how-to-pay-card"
          action="#"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <HowToPaySummary offerData={offerData} watchInt={watchInt} />
          <HowToPayBreakdown
            offerCardData={tuition_budget}
            capProjectedData={cap_projections}
            budgetData={tuition_budget_sandbox}
            watchInt={watchInt}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default HowToPayBreakdownCard;
