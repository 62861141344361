import {
  hasSpouse,
  hasExSpouse,
  isFilingJointly,
  isFilingSeparately,
} from "@utils/maritalStatusCheck";

/**
 * NOTE: commented fields are currently not included in calculations but are present
 * to represent all fields used by D2C for ease of changes in future.
 * Alternatively comment back in but set weight to 0.
 */

/**
 * Convert to percentage, round to 2 decimal places and strip extra zeros.
 * @param {float} ratio
 * @returns {string} formatted percentage
 */
const formatPercentage = (ratio) => {
  return Number.parseFloat((ratio * 100).toFixed(0));
};

/**
 *
 * @param {object} weightedFields
 * @returns {object} total weight, completed weight, and percentage of fields complete
 */
const calcWeightPercentage = (weightedFields) => {
  if (weightedFields) {
    let totalWeight = 0;
    let weightComplete = 0;

    Object.entries(weightedFields).forEach(([key, field]) => {
      const { value, weight } = field;
      totalWeight += weight;
      if (value !== null) {
        weightComplete += weight;
      }
    });
    const percentComplete = formatPercentage(weightComplete / totalWeight);
    return {
      totalWeight,
      weightComplete,
      percentComplete,
      colorClass: getColorClass(percentComplete),
    };
  } else {
    return {
      totalWeight: 0,
      weightComplete: 0,
      percentComplete: 0,
      colorClass: "red",
    };
  }
};

/**
 * Get percentage and weight of student fields
 * @param {object} onboarding - student
 * @returns {object} total weight, completed weight, and percentage of fields complete
 */
export const getStudentWeightPercentage = (onboarding) => {
  let weightedFields;
  if (onboarding) {
    const { student } = onboarding;
    const {
      student_name, //2
      zip, //5
      country, //0.5
      starting_college_year, //2.5
      household_size, //2.5
      school_name, //2.5
      high_school_class_rank, //2
      high_school_class_size, //2
      num_siblings, //2.5
      scores,
      major_id, //2.5
      ethnicity_id, //2
      religion_id, //2
      student_investments, //5
      student_earnings, //0.5
      student_agi,
      student_agi_user_provided,
      // student_agi,
      //student_income_tax_paid field desired?
      //student_ira_deductions field desired?
      //student_agi field desired?
      national_merit_award, //0.5
    } = student;

    const {
      gpa, //10
      weighted_gpa, //2 conditional
      sat, //2 conditional
      act, //2 conditional
    } = scores;

    weightedFields = {
      student_name: {
        value: student_name,
        weight: 2,
      },
      zip: {
        value: zip,
        weight: 5,
      },
      country: {
        value: country,
        weight: 0.5,
      },
      starting_college_year: {
        value: starting_college_year,
        weight: 2.5,
      },
      household_size: {
        value: household_size,
        weight: 2.5,
      },
      school_name: {
        value: school_name,
        weight: 2.5,
      },
      high_school_class_rank: {
        value: high_school_class_rank,
        weight: high_school_class_rank ? 2 : 0,
      },
      high_school_class_size: {
        value: high_school_class_size,
        weight: high_school_class_size ? 2 : 0,
      },
      num_siblings: {
        value: num_siblings,
        weight: 2.5,
      },
      gpa: {
        value: gpa,
        weight: 10,
      },
      weighted_gpa: {
        value: weighted_gpa,
        weight: weighted_gpa ? 2 : 0,
      },
      sat: {
        value: sat,
        weight: sat ? 2 : 0,
      },
      act: {
        value: act,
        weight: act ? 2 : 0,
      },
      major_id: {
        value: major_id,
        weight: 2.5,
      },
      // TODO figure out how to make an attribute do this is done for optional fields
      // define something upstream for this file and PersonalInfo.js ideally
      ethnicity_id: {
        value: ethnicity_id,
        weight: ethnicity_id ? 2 : 0,
      },
      religion_id: {
        value: religion_id,
        weight: religion_id ? 2 : 0,
      },
      student_investments: {
        value: student_investments,
        weight: 5,
      },
      student_earnings: {
        value: student_earnings,
        weight: !student_agi_user_provided ? 0.5 : 0,
      },
      student_agi: {
        value: student_agi_user_provided ? student_agi_user_provided : null,
        weight: student_agi_user_provided ? 0.5 : 0,
      },
      national_merit_award: {
        value: national_merit_award,
        weight: 0.5,
      },
    };
  }
  // console.log("student weightedFields", weightedFields);
  return calcWeightPercentage(weightedFields);
};

/**
 * Get percentage and weight of parent fields
 * @param {object} onboarding - parent_guardian, user_type
 * @returns {object} total weight, completed weight, and percentage of fields complete
 */
export const getParentGuardianWeightPercentage = (onboarding) => {
  let weightedFields;
  if (onboarding) {
    const { parent_guardian } = onboarding;
    const {
      marital_status, //3
      filing_status, //3
      parent_earnings, //7.5
      parent_earnings_2, //7.5 conditional
      parent_earnings_3, //2 conditional *confirm weight
      self_employed, //3
      business_equity, //2
      self_employed_2, //3
      business_equity_2, //2
      self_employed_3, //3
      business_equity_3, //2
      is_homeowner, //5
      home_equity, //5 conditional
      is_ex_spouse_homeowner, //2 conditional *confirm weight
      home_equity_3, //2 conditional *confirm weight
      investments, //10
      investments_2, //2 conditional
      investments_3, //2 conditional
      agi_user_provided,
      agi_user_provided_2,
      agi_user_provided_3,
      retirement_payments, //1
      retirement_payments_2, //1
      retirement_payments_3, //1
      birth_date, //1
      birth_date_2, //1
      birth_date_3, //1
      income_tax_paid, //1
      income_tax_paid_2, //1
      income_tax_paid_3, //1
      child_support,
      child_support_2,
      child_support_3,
    } = parent_guardian;

    let weigh_parent_earnings = true;
    let weigh_parent_earnings_2 = false;
    let agi_2_weight = 0;

    const hasSpouseResult = hasSpouse(marital_status);
    const hasExSpouseResult = hasExSpouse(marital_status);
    const isFilingSeparatelyResult = isFilingSeparately(filing_status);
    const isFilingJointlyResult = isFilingJointly(
      filing_status,
      marital_status
    );

    if (hasSpouseResult) {
      if (isFilingSeparatelyResult) {
        weigh_parent_earnings = !agi_user_provided;
        if (agi_user_provided_2) {
          agi_2_weight = 7.5;
        }
      } else {
        weigh_parent_earnings = !agi_user_provided_2;
        if (agi_user_provided_2) {
          agi_2_weight = 15;
        }
      }
      weigh_parent_earnings_2 = !agi_user_provided_2;
    } else {
      weigh_parent_earnings = !agi_user_provided;
    }

    let weigh_parent_earnings_3 = false;
    if (hasExSpouseResult) {
      weigh_parent_earnings_3 = !agi_user_provided_3;
    }

    weightedFields = {
      marital_status: {
        value: marital_status,
        weight: 3,
      },
      filing_status: {
        value: filing_status,
        weight: 3,
      },
      parent_earnings: {
        value: parent_earnings,
        weight: weigh_parent_earnings ? 7.5 : 0,
      },
      parent_earnings_2: {
        value: parent_earnings_2,
        weight: weigh_parent_earnings_2 ? 7.5 : 0,
      },
      parent_earnings_3: {
        value: parent_earnings_3,
        weight: weigh_parent_earnings_3 ? 2 : 0,
      },
      self_employed: {
        value: self_employed,
        weight: 3,
      },
      business_equity: {
        value: business_equity,
        weight: self_employed === "Yes" ? 2 : 0,
      },
      self_employed_2: {
        value: self_employed_2,
        weight: hasSpouseResult ? 3 : 0,
      },
      business_equity_2: {
        value: business_equity_2,
        weight: hasSpouseResult && self_employed_2 === "Yes" ? 2 : 0,
      },
      self_employed_3: {
        value: self_employed_3,
        weight: hasExSpouseResult ? 3 : 0,
      },
      business_equity_3: {
        value: business_equity_3,
        weight: hasExSpouseResult && self_employed_3 === "Yes" ? 2 : 0,
      },
      home_equity: {
        value: home_equity,
        weight: is_homeowner === "Yes" ? 10 : 0,
      },
      home_equity_3: {
        value: home_equity_3,
        weight: is_ex_spouse_homeowner === "Yes" ? 2 : 0,
      },
      investments: {
        value: investments,
        weight: 10,
      },
      investments_2: {
        value: investments_2,
        // TODO check into this logic, seems different from the other _2's
        weight: isFilingSeparatelyResult ? 2 : 0,
      },
      investments_3: {
        value: investments_3,
        weight: hasExSpouseResult ? 2 : 0,
      },
      birth_date: {
        value: birth_date,
        weight: birth_date ? 2 : 0,
      },
      birth_date_2: {
        value: birth_date_2,
        weight: hasSpouseResult && birth_date_2 ? 2 : 0,
      },
      birth_date_3: {
        value: birth_date_3,
        weight: hasExSpouseResult && birth_date_3 ? 2 : 0,
      },
      income_tax_paid: {
        value: income_tax_paid,
        weight: !isFilingJointlyResult && income_tax_paid ? 2 : 0,
      },
      income_tax_paid_2: {
        value: income_tax_paid_2,
        weight: hasSpouseResult && income_tax_paid_2 ? 2 : 0,
      },
      income_tax_paid_3: {
        value: income_tax_paid_3,
        weight: hasExSpouseResult && income_tax_paid_3 ? 2 : 0,
      },
      child_support: {
        value: child_support,
        weight: !isFilingJointlyResult && child_support ? 2 : 0,
      },
      child_support_2: {
        value: child_support_2,
        weight: hasSpouseResult && child_support_2 ? 2 : 0,
      },
      child_support_3: {
        value: child_support_3,
        weight: hasExSpouseResult && child_support_3 ? 2 : 0,
      },
      agi: {
        value: agi_user_provided ? agi_user_provided : null,
        weight: agi_user_provided ? 7.5 : 0,
      },
      agi_2: {
        value: agi_user_provided_2 ? agi_user_provided_2 : null,
        weight: agi_user_provided_2 ? agi_2_weight : 0,
      },
      agi_3: {
        value: agi_user_provided_3 ? agi_user_provided_3 : null,
        weight: agi_user_provided_3 ? 2 : 0,
      },
      retirement_payments: {
        value: retirement_payments,
        weight: isFilingJointlyResult ? 0 : 1,
      },
      retirement_payments_2: {
        value: retirement_payments_2,
        weight: hasSpouseResult ? 1 : 0,
      },
      retirement_payments_3: {
        value: retirement_payments_3,
        weight: hasExSpouseResult ? 1 : 0,
      },
    };
  }
  // console.log("parent weightedFields", weightedFields);
  return calcWeightPercentage(weightedFields);
};

/**
 * Get percentage and weight of all onboarding fields
 * @param {object} onboarding
 * @returns {object} total weight, completed weight, and percentage of fields complete
 */
export const getTotalPersonalInfoWeightPercentage = (onboarding) => {
  if (onboarding) {
    const studentWeight = getStudentWeightPercentage(onboarding);
    const parentWeight = getParentGuardianWeightPercentage(onboarding);
    const totalWeight = studentWeight.totalWeight + parentWeight.totalWeight;
    const weightComplete =
      studentWeight.weightComplete + parentWeight.weightComplete;
    const percentComplete = formatPercentage(weightComplete / totalWeight);
    return {
      totalWeight,
      weightComplete,
      percentComplete,
      colorClass: getColorClass(percentComplete),
    };
  } else {
    return {
      totalWeight: 0,
      weightComplete: 0,
      percentComplete: 0,
      colorClass: "red",
    };
  }
};

/**
 *
 * @param {float} percentage
 */
const getColorClass = (percentage) => {
  if (percentage > 84) {
    return "green";
  } else if (percentage > 50) {
    return "yellow";
  } else {
    return "red";
  }
};
