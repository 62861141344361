import _ from "lodash";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { SchoolSearchInput } from "@components/index";

/**
 * SchoolSearchFormInput
 * @param {string} inputMapId - main input id, represents a school.
 On the submitted values, it will be an object with college_id and school_name.
 * @param {object} firstTargetSchool - object with initial values for school search input.
 * @returns Input for searching schools when it's part of a form. The selected school will be displayed in the input.
 */
const SchoolSearchFormInput = ({ inputMapId, firstTargetSchool }) => {
  const methods = useFormContext();
  const { register, setValue } = methods;
  const [targetSchool, setTargetSchool] = useState(
    firstTargetSchool
      ? {
          college_id: _.get(firstTargetSchool, "college_id"),
          name: _.get(firstTargetSchool, "school_name"),
        }
      : {}
  );

  /**
   * When a school is clicked on, also set the expanded state back to false to close search list
   * @param {*} school
   */
  const setChosenSchool = (school) => {
    setTargetSchool(school);
    setValue(`${inputMapId}.school_name`, school.name, {
      shouldValidate: true,
    });
  };

  /**
   * Set input values for target school
   */
  useEffect(() => {
    setValue(`${inputMapId}.college_id`, targetSchool.college_id, {
      shouldValidate: true,
    });
    setValue(`${inputMapId}.school_name`, targetSchool.name, {
      shouldValidate: true,
    });
  }, [targetSchool]);

  return (
    <>
      <input
        id={`${inputMapId}.college_id`}
        className="hidden"
        type="text"
        {...register(`${inputMapId}.college_id`, {
          required: {
            value: true,
            message: "Please enter a school",
          },
          validate: {
            isInteger: (value) => value === parseInt(value),
          },
        })}
      />
      <SchoolSearchInput
        customStyle="onboarding-school-search"
        inputId={`${inputMapId}.school_name`}
        chooseSchool={setChosenSchool}
        methods={methods}
      ></SchoolSearchInput>
    </>
  );
};

export default SchoolSearchFormInput;
