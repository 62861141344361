import _ from "lodash";
import { useEffect } from "react";

const Step9 = ({ formData, formStep, setModalContent }) => {
  if (formStep !== 11) {
    // Don't render unless step matches
    return null;
  }

  useEffect(() => {
    //Set content for help modal
    setModalContent([]);
  }, []);

  return (
    <div className="guardianInfoContainer onboardingScreen flex flex-col items-center justify-start md:justify-center text-center">
      <div className="guardianStart flex flex-col w-full text-left md:text-center">
        <h1>
          Lastly, we need some basic financial information about{" "}
          {_.get(formData, "parent_guardian.parent_name") || "Parent"}
        </h1>
      </div>
    </div>
  );
};

export default Step9;
