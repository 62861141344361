import { useAppContext } from "@context/state";
import {
  AppealCard,
  EmptyAppealSection,
  AppealLimitCard,
} from "../EvaluateAppeal";
import { Section } from "@components/index";
import { useEffect, useState } from "react";
import { getTotalPersonalInfoWeightPercentage } from "@utils/calcEnhancedProfile";
import { checkIsUpgraded } from "@utils/checkIsUpgraded";

/**
 * Show concise Appeal Cards in gallery
 * @returns Evaluate & Appeal section on dashboard
 */
const AppealsGallery = ({ title, showMoreDetails }) => {
  const { userOffers, scenario } = useAppContext();

  const { onboarding, subscription_info } = scenario;

  const [hasAppeals, setHasAppeals] = useState();

  const renderAppeals = () => {
    //map offers to cards
    const offers = [];
    userOffers.forEach((offer) => {
      if (offer?.score_result && offer?.school?.visible) {
        offers.push(
          <AppealCard
            key={offer.id}
            offerData={offer}
            showMoreDetails={showMoreDetails}
          />
        );
      }
    });
    if (!checkIsUpgraded(subscription_info?.price_id)) {
      offers.push(
        <AppealLimitCard
          key="appeal_limit"
          remainingAppeals={3 - offers?.length >= 0 ? 3 - offers?.length : 0}
        />
      );
    }
    return offers;
  };

  useEffect(() => {
    setHasAppeals(
      getTotalPersonalInfoWeightPercentage(onboarding).percentComplete ===
        100 &&
        userOffers.some((offer) => !!offer.score_result) &&
        userOffers.some((offer) => offer.school.visible === true)
    );
  }, [onboarding, userOffers]);

  return (
    <>
      <Section title={title} galleryName={"appeal-gallery"} empty={!hasAppeals}>
        {!hasAppeals ? <EmptyAppealSection /> : <>{renderAppeals()}</>}
      </Section>
    </>
  );
};

export default AppealsGallery;
