const Upload = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <circle id="path-1" cx="24" cy="24" r="24"></circle>
          <filter
            x="-79.2%"
            y="-45.8%"
            width="258.3%"
            height="258.3%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feMorphology
              radius="2"
              operator="erode"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="16"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="12"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.03 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
        </defs>
        <g
          id="Icons/Upload-Offer"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Oval">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-2)"
              xlinkHref="#path-1"
            ></use>
            <circle
              stroke="currentColor"
              strokeWidth="1"
              strokeLinejoin="square"
              fillRule="evenodd"
              cx="24"
              cy="24"
              r="23.5"
            ></circle>
          </g>
          <path
            d="M15.6,25.8 L24,17.4 L32.4,25.8 M24,17.4 L24,37.2 L24,17.4 Z M12,12 L36,12 L12,12 Z"
            id="Icon"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Upload;
