import { useAppContext } from "@context/state";

//NOTE: only difference compared to Modal component is top level class name. Is it worth passing that in as a prop to combine the components?
const FormModal = ({ children }) => {
  const { displayFormModal, setDisplayFormModal } = useAppContext();

  const clickOutside = (event) => {
    if (event.target.classList.contains("form-modal-container")) {
      setDisplayFormModal((cur) => !cur);
    }
  };

  //All hooks need to come before early termination
  if (!displayFormModal) {
    return null;
  }

  return (
    <div
      className="form-modal-container fixed top-0 w-full h-full flex justify-center"
      onClick={clickOutside}
    >
      <div className="modal">
        <div className="w-full h-full">{children}</div>
      </div>
    </div>
  );
};

export default FormModal;
