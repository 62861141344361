/**
 * Method for formatting days to an ordinal number
 * @param {integer} num - integer, typically representing a day from a date.
 * @returns: ordinal number string.
 */
export default function ordinalNumber(num) {
  if (num > 20 || num < 10) {
    switch (num % 10) {
      case 1:
        return num + "st";
      case 2:
        return num + "nd";
      case 3:
        return num + "rd";
    }
  }
  return num + "th";
}
