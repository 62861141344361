import { useAppContext } from "@context/state";

import {
  HowToPay,
  HowToPayBreakdownView,
  TalkToAdvisor,
} from "@components/index";

import { LongLeftArrow } from "@icons/index";

const HowToPayLayout = () => {
  const { howToPaySchool, setHowToPaySchool } = useAppContext();

  return (
    <div className="how-to-pay-container sectionsContainer flex flex-col w-full">
      {Object.keys(howToPaySchool).length !== 0 && (
        <button
          className="back-btn dark-btn"
          onClick={() => {
            //reset view of cards
            setHowToPaySchool({});
          }}
        >
          <LongLeftArrow />
        </button>
      )}
      <div className="background" />
      <h2 className="desktop-header hidden lg:block">How to Pay</h2>
      <div className="header-text text-center">
        We&apos;ll help you strategically pay your bill
      </div>
      {howToPaySchool?.name ? <HowToPayBreakdownView /> : <HowToPay />}
      <TalkToAdvisor
        title="Show Me the Best Way to Pay My Bill"
        description1="There's a right & wrong way to pay."
        description2="Learn more!"
      />
    </div>
  );
};

export default HowToPayLayout;
