import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAppContext } from "@context/state";
import { updatePersonalInfo } from "@api/user";
import Step00 from "./UpgradePlan/Step00";
import _ from "lodash";
import {
  OnboardingNav,
  Lockup,
  Entry1,
  Entry2,
  Step01,
  Step02,
  Step03,
  Step04,
  Step05,
  Step06,
  Step07,
  Step08,
  Step09,
  Step10,
  Step11,
  Step12,
  Step13,
  Step14,
} from "./index";
import router from "next/router";
import { mapSiblingData } from "@utils/mappingHelpers";

const Onboarding = () => {
  const [formData, setFormData] = useState({});
  const [formStep, setFormStep] = useState(0);
  //capture the fields only on currently rendered step
  const [watchFields, setWatchFields] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    scenario,
    setScenario,
    modalContent,
    setModalContent,
    populateRecalculatedAddedSchools,
    handleApiError,
    openWalkthroughVideo,
    setIsOnboarding,
    checkForNeededDashboardInfo,
  } = useAppContext();

  const methods = useForm({
    mode: "all",
    defaultValues: formData, //useMemo necessary?
    criteriaMode: "all", //allows multiple errors for one field
  });

  const {
    watch,
    register,
    formState: { isSubmitting, isValid },
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    trigger,
    reset,
  } = methods;

  useEffect(() => {
    reset(formData);
  }, [formData]);

  //Lockup Next button will trigger this function. Uses the watchFields state to
  //cherrypick data just from the current rendered step, and updates formData with that data.
  //formData is simultaneously used to hold the data that will be submitted when registering
  //the user, and setting the default values of inputs so that a user can go back to an earlier
  //step and see the values that they already chose.
  //Finally, increment formStep to render the next step.
  const completeFormStep = () => {
    //Pseudo-Submit, update formData with partially submitted data. Triggered by "Next" button.
    if (watchFields) {
      const newFields = {};
      watchFields.forEach((field) => {
        newFields[field] = watch()[field]; //TODO: Inquire about best practices here. Might as well replace the whole formData with watch object?
      });
      setFormData({ ...formData, ...newFields });
    }
    setFormStep((cur) => cur + 1);
  };

  const updateUser = async (personal_info) => {
    personal_info.student = mapSiblingData(personal_info);
    const res = await updatePersonalInfo(scenario?.case_id, personal_info);
    if (res?.data?.result) {
      const onboarding = _.get(res.data.result, "onboarding");
      const preapprovals = _.get(res.data.result, "preapprovals");
      const newScenario = {
        ...scenario,
        onboarding,
        preapprovals,
      };
      setScenario(newScenario);
      checkForNeededDashboardInfo(onboarding);
      populateRecalculatedAddedSchools(scenario.case_id, newScenario);
    }
  };

  //Save & Exit button will NOT trigger the form to submit, but will instead go through this pass through function to decide what to submit first..
  const saveAndExit = () => {
    //If currently set values on screen are valid, include them, else only use valid screens saved (formData)
    //TODO: allow valid fields to be submitted and ignore invalid fields. Currently will only submit current screens fields if all are valid.
    setLoading(true);
    let values;
    if (!isValid) {
      values = formData;
    } else {
      values = getValues();
    }
    //Now that we have the correct values, submit them
    onSubmit(values);
  };

  //Done button will trigger the form to submit.
  const onSubmit = async (values) => {
    //Ok to use values instead of formData because formData informs all values not seen on screen.
    //Don't hit api if no fields were filled out
    if (Object.keys(values).length !== 0) {
      try {
        await updateUser(values);
        setLoading(false);
        router.push("/");
        setIsOnboarding(false);
        openWalkthroughVideo();
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    } else {
      setLoading(false);
      router.push("/");
      openWalkthroughVideo();
    }
  };

  useEffect(() => {
    setIsOnboarding(true);
  }, []);

  //Multi step form contains all components, and depending on the state of formStep,
  //conditionally render one at a time. The pseudo nav bar and lockup will always be rendered.
  //Any buttons inside form will trigger submit by default, so to prevent that, give buttons a type="button"
  //Pass in formData to set the default values,
  //Pass in formstep to handle conditional renders
  //Pass in needed functions from react hook forms
  return (
    <div className="onboardingContainer w-full h-full">
      <FormProvider {...methods}>
        <form
          className="w-full h-full"
          action="/"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <OnboardingNav
            formStep={formStep}
            setFormStep={setFormStep}
            clearErrors={clearErrors}
            reset={reset}
            isValid={isValid}
            saveAndExit={saveAndExit}
            loading={loading}
          />
          <Step00
            formStep={formStep}
            setFormStep={setFormStep}
            setModalContent={setModalContent}
          />
          <Entry1
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
          />
          <Entry2
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
          />
          <Step01
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
          />
          <Step02
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
          />
          <Step03
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
            setValue={setValue}
          />
          <Step04
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
            setValue={setValue}
          />
          <Step05
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
          />
          <Step06
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
            watch={watch}
            getValues={getValues}
          />
          <Step07
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            trigger={trigger}
          />
          <Step08
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
            setValue={setValue}
            getValues={getValues}
          />
          <Step09
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
          />
          <Step10
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
          />
          <Step11
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
            getValues={getValues}
            setValue={setValue}
          />
          <Step12
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
            setValue={setValue}
            getValues={getValues}
          />
          <Step13
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
          />
          <Step14
            formData={formData}
            formStep={formStep}
            setModalContent={setModalContent}
            setWatchFields={setWatchFields}
            register={register}
            trigger={trigger}
            setValue={setValue}
            getValues={getValues}
          />
          <Lockup
            formData={formData}
            formStep={formStep}
            setFormStep={setFormStep}
            completeFormStep={completeFormStep}
            clearErrors={clearErrors}
            isValid={isValid}
            reset={reset}
            isSubmitting={isSubmitting}
            modalContent={modalContent}
            onSubmit={onSubmit}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default Onboarding;
