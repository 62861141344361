import _ from "lodash";
import { MagnifyingGlass } from "@icons/index";
import { useEffect, useState, useRef } from "react";
import { useAppContext } from "@context/state";
import { getSearchList } from "@api/schools";
import { SearchItem } from "@components/index";
import { checkClickOutside } from "@utils/clickOutside";
import { SEARCH_ACCEPTABLE_CHARS_REGEXP } from "@utils/constants";
import ClipLoader from "react-spinners/ClipLoader";

/**
 * SchoolSearchInput
 * @param {string} customStyle - pass in a class name to override default styles for the input container
 * @param {boolean} primaryStyle - if primary style is true, extra flourishes such as the magnifying glass and cancel button show.
 * @param {string} inputId - change the inputId to match the form the input is being used for.
 * @param {function} chooseSchool - pass the function for what to do when a user clicks on a school from the search list
 * @param {react-hook-form methods} methods - not every instance uses form context, so pass the methods from the parent.
 * @returns input for searching schools with a dropdown list.
 */
const SchoolSearchInput = ({
  primaryStyle,
  inputId,
  chooseSchool,
  methods,
}) => {
  const { handleApiError } = useAppContext();
  const { register, setValue, getValues } = methods;

  //search list should not be in global state
  const [loading, setLoading] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const schoolSearchRef = useRef();

  //Search function
  const searchSchools = async (asyncSearchTerm) => {
    //Trim leading and trailing spaces before checking length of query
    const trimmedSearchTerm = asyncSearchTerm.trim();
    //API call requires at least 3 characters or it will throw an error
    if (trimmedSearchTerm?.length > 2) {
      setLoading(true);
      try {
        const res = await getSearchList(trimmedSearchTerm);
        //Only update the search list if it matches the most recent (current) search term
        //This logic needs to be here so that the value of the synchronous search term can be checked against
        if (res.data) {
          if (getValues(inputId) === asyncSearchTerm) {
            setSearchList(res.data);
            setLoading(false);
          }
        }
      } catch (error) {
        handleApiError(error);
      }
    } else {
      //Synchronously set search results to 0
      setSearchList([]);
    }
  };

  //When a school is clicked on, also set the expanded state back to false to close search list
  const setChosenSchool = (school) => {
    // setAdvancedSearchList([]);
    chooseSchool(school);
    //When input value is set to full school name, update searchList accordingly.
    if (getValues(inputId) === school.name) {
      searchSchools(getValues(inputId));
    }
    setSearchExpanded(false);
  };

  //Create search list from search term. When a user clicks on one of the listed schools, it will be added to the list of displayed school cards
  const renderSearchList = () => {
    return searchList.map((school) => (
      <SearchItem
        key={school.college_id}
        schoolData={school}
        schoolSearchTerm={getValues(inputId)}
        setChosenSchool={setChosenSchool}
      />
    ));
  };

  const handleKeyPress = (event) => {
    //Only allow alphanumeric, dash and space characters
    if (!event.key.match(SEARCH_ACCEPTABLE_CHARS_REGEXP)) {
      event.preventDefault();
    }
    //Prevent enter button from triggering
    if (event.key === "Enter") {
      event.preventDefault();
      //if there's something to choose, choose the first school in the list
      if (searchList.length > 0) {
        setChosenSchool(searchList[0]);
      }
    }
  };

  const debounceSearch = _.debounce((event) => {
    //Since we're overriding the default onChange fn, maintain react hook form changing value to allow react hook form getValues function
    setValue(inputId, event.target.value, {
      shouldValidate: true,
    });
    searchSchools(event.target.value);
  }, 200);

  const handlePointerDown = (event) => {
    const isOutside = checkClickOutside(event, schoolSearchRef);
    if (isOutside) {
      setSearchExpanded(false);
    }
  };

  //When expanded state changes to true, add event listener to check for clicking outside search element
  //use pointerdown instead of click because otherwise event can trigger while input still retains focus (clickdown inside input and clickup outside input will not lose focus)
  useEffect(() => {
    if (schoolSearchRef.current) {
      if (searchExpanded) {
        window.addEventListener("pointerdown", handlePointerDown);
      } else {
        window.removeEventListener("pointerdown", handlePointerDown);
      }
      return () => {
        window.removeEventListener("pointerdown", handlePointerDown);
      };
    }
  }, [searchExpanded]);

  return (
    <div className={` school-search w-full`} ref={schoolSearchRef}>
      <div
        className={`search-bar flex justify-center ${searchList.length > 0 ? "searching" : ""
          } ${searchExpanded && "focused"}`}
        onFocus={() => {
          //Only set expanded to true if it's false. This prevents it from interfering with the cancel button
          if (!searchExpanded) {
            setSearchExpanded(true);
          }
        }}
      >
        <label
          htmlFor={inputId}
          className="textLabel school-search-input flex justify-center items-center"
        >
          {primaryStyle && (
            <div className="search-icon">
              <MagnifyingGlass />
            </div>
          )}
          {getValues(inputId)?.trim().length > 2 && (
            <small
              className={`results-number ${!primaryStyle ? "mr-1.5" : ""}`}
            >
              Results:&nbsp;
              {loading ? (
                <span className="flex items-center">
                  <ClipLoader
                    size={12}
                    css={{
                      position: "absolute;",
                      borderColor: "var(--primary-color)",
                      borderBottomColor: "transparent",
                    }}
                  />
                  <span className="opacity-0">{searchList.length}</span>
                </span>
              ) : (
                <span>{searchList.length}</span>
              )}
            </small>
          )}
          <input
            id={inputId}
            type="text"
            placeholder="Search for Schools"
            {...register(inputId)} //not required because the only important thing is the college_id, whereas this input just allows the user to interact with the hidden id input.
            onFocus={(event) => {
              //Adding focus to the input will render a different placeholder text
              event.target.placeholder = "Enter a School Name";
            }}
            onBlur={(event) => {
              //Removing focus from the input will render the default placeholder text
              event.target.placeholder = "Search for Schools";
            }}
            onKeyPress={handleKeyPress}
            onChange={debounceSearch}
          />
        </label>
        {primaryStyle && (
          <button
            type="button"
            className="cancel-btn"
            onClick={() => {
              //Clicking the cancel button will remove focus from the input and close the expanded search list
              document.activeElement.blur();
              //reset search states
              setValue(inputId, "");
              setSearchExpanded(false);
              setSearchList([]);
            }}
          >
            <small>Cancel</small>
          </button>
        )}
      </div>
      <div className={`search-list ${searchExpanded ? "expanded" : ""}`}>
        {renderSearchList()}
      </div>
    </div>
  );
};

export default SchoolSearchInput;
