import Link from "next/link";
import { CapLogo, Hamburger, People } from "@icons/index";
import { useAppContext } from "@context/state";
import { ssoRedirect } from "@api/ssoApi";
import _ from "lodash";
import {
  PREMIUM_VIEW,
  YOUTUBE_CHANNEL,
  FB_GROUP,
  EXPERT_BUTTON_TEXT,
} from "@utils/constants";
import Image from "next/legacy/image";
import { checkIsUpgraded } from "@utils/checkIsUpgraded";
import { getAdminAccessTokenFromCookies } from "@utils/getAccessToken";
import { parseJwt } from "@utils/parseJwt";
import { logo, settings } from "@whitelabel/whitelabel.preval.js";
import { openExternalLink } from "@utils/externalLink";
import { getTotalPersonalInfoWeightPercentage } from "@utils/calcEnhancedProfile";

/**
 * NOTE: consider using SSR to perform button rendering logic
 * @returns The primary nav component that appears along the top of the page
 */
const PrimaryNav = () => {
  const {
    loggedIn,
    scenario,
    upgraded,
    setModalView,
    setDisplayModal,
    setDisplaySidebar,
    myCapLogout,
    handleAdminToken,
    isIecAndImpersonating,
    routeToExpertMeeting,
  } = useAppContext();

  if (!loggedIn) {
    return null;
  }

  const { subscription_info, onboarding } = scenario;

  //
  const navArray =
    (process.env.NEXT_PUBLIC_WHITELABEL && !upgraded) === "true"
      ? _.get(settings, "navArray")
      : [
        {
          label: "CAP Academy",
          redirect: false,
          path: YOUTUBE_CHANNEL,
          premium: true,
        },
        {
          label: "Ask CAP Community Group",
          redirect: false,
          path: FB_GROUP,
          premium: false,
        },
      ];

  /**
   * @param {object} link - {string} label, {boolean} redirect, {boolean} premium, {string} path
   */
  const onLinkClicked = (link) => {
    if (_.get(link, "premium") && !upgraded) {
      setModalView(PREMIUM_VIEW);
      setDisplayModal(true);
    } else {
      if (_.get(link, "redirect")) {
        ssoRedirect(link.path);
      } else {
        openExternalLink(link.path);
      }
    }
  };

  return (
    <div className="navContainer primaryNav">
      <div className="lg:hidden flex justify-between w-full">
        <div className="hamburger">
          <button
            type="button"
            className="leftBtn"
            onClick={() => {
              setDisplaySidebar((cur) => !cur);
            }}
          >
            <Hamburger />
          </button>
        </div>
        {!checkIsUpgraded(subscription_info?.price_id) && (
          (<Link href="/upgrade_plan">

            <div className="upgrade small cursor-pointer">Upgrade</div>

          </Link>)
        )}
      </div>
      <div className="hidden lg:block w-full">
        <div className="flex flex-row justify-center items-center w-full">
          <div className="desktop-logo flex-1 justify-start">
            <Link href="/">

              <div className="relative w-full h-full">
                {process.env.NEXT_PUBLIC_WHITELABEL === "true" ? (
                  <div className="whitelabel-logo">
                    <Image
                      src={logo}
                      alt={settings?.name}
                      layout="fill"
                      objectFit="contain"
                    />
                  </div>
                ) : (
                  <Image
                    src="/images/cap_main_logo.png"
                    alt="CollegeAidPro"
                    layout="fill"
                    objectFit="contain"
                  />
                )}
              </div>

            </Link>
          </div>
          <ul className="flex justify-center">
            {navArray.map((link, key) => (
              <li key={key}>
                <button
                  onClick={() => {
                    onLinkClicked(link);
                  }}
                >
                  <small>{link.label}</small>
                </button>
              </li>
            ))}
          </ul>
          <div className="flex flex-1 justify-end items-center">
            {handleAdminToken(
              getAdminAccessTokenFromCookies(document.cookie)
            ) && (
                <button
                  className="admin-button flex flex-col items-center py-0.5 px-1 mr-4"
                  onClick={() => {
                    myCapLogout();
                  }}
                >
                  <small className="user-info">Logged in as:</small>
                  <small className="logout absolute">Log out from:</small>
                  <small>
                    {_.get(
                      parseJwt(getAdminAccessTokenFromCookies(document.cookie)),
                      "uid"
                    )}
                  </small>
                </button>
              )}
            {checkIsUpgraded(subscription_info?.price_id) ? (
              <button
                className={`highlight flex flex-col justify-center items-center h-4 px-2 mr-4`}
                onClick={routeToExpertMeeting}
              >
                <div className="flex flex-row items-center">
                  <div className="w-2 h-2 mr-1">
                    <People />
                  </div>
                  <small className="whitespace-nowrap">
                    {settings?.expertButtonText || EXPERT_BUTTON_TEXT}
                  </small>
                </div>
              </button>
            ) : (
              <Link href="/upgrade_plan" legacyBehavior>
                <button className={`highlight upgrade-button`}>
                  <small>Upgrade</small>
                </button>
              </Link>
            )}
            {!isIecAndImpersonating && (
              (<Link href="/profile" className="flex flex-col">

                <small>Profile</small>
                {onboarding && (
                  <>
                    {getTotalPersonalInfoWeightPercentage(onboarding)
                      .percentComplete !== 100 && (
                        <span className="percent-complete micro whitespace-nowrap">
                          {getTotalPersonalInfoWeightPercentage(onboarding)
                            .percentComplete + "%"}{" "}
                          Complete
                        </span>
                      )}
                  </>
                )}

              </Link>)
            )}
          </div>
        </div>
      </div>
      <Link href="/" className="logo flex lg:hidden">

        <button className="logo text">
          <CapLogo />
        </button>

      </Link>
    </div>
  );
};

export default PrimaryNav;
