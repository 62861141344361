import { useAppContext } from "@context/state";
import { EXPERT_BUTTON_TEXT } from "@utils/constants";
import { settings } from "@whitelabel/whitelabel.preval.js";

/**
 *
 * @param {string} title
 * @param {string} description1
 * @param {string} description2
 * @returns Talk To Advisor panel with link to /book_consultation page
 */
const TalkToAdvisor = ({ title, description1, description2 }) => {
  const { routeToExpertMeeting } = useAppContext();

  return (
    <div className="talkToAdvisor flex flex-row items-center text-center flex-wrape md:justify-between">
      <div className="textContent flex flex-col items-center text-center md:text-left md:items-start">
        <h3>{title}</h3>
        <span>
          {description1}
          {description2 && <br className="md:hidden" />}
          &nbsp;&nbsp;
          {description2}
        </span>
      </div>
      <div className="flex items-center">
        <button
          className="tertiary-dark highlight"
          onClick={routeToExpertMeeting}
        >
          <h5 className="removeMargin">
            {settings?.expertButtonText || EXPERT_BUTTON_TEXT}
          </h5>
        </button>
      </div>
    </div>
  );
};

export default TalkToAdvisor;
