import { useAppContext } from "@context/state";
import { OFFER_EDIT } from "@utils/constants";
import { Card, EmptySection } from "@components/index";
import { useRouter } from "next/router";
import { getTotalPersonalInfoWeightPercentage } from "@utils/calcEnhancedProfile";
import { DiagonalRightArrow } from "@icons/index";
import { checkIsUpgraded } from "@utils/checkIsUpgraded";

const EmptyAppealSection = () => {
  const {
    scenario,
    userOffers,
    setFormModalView,
    setFormModalContent,
    setDisplayFormModal,
  } = useAppContext();

  const { onboarding, subscription_info } = scenario;

  const router = useRouter();

  return (
    <Card type="typeEmpty w-full">
      <div className="emptyCard flex flex-col items-center text-center md:flex-row md:justify-between">
        {getTotalPersonalInfoWeightPercentage(onboarding).percentComplete <
          100 &&
        getTotalPersonalInfoWeightPercentage(onboarding).percentComplete !==
          0 ? (
          <EmptySection
            title="We need more information"
            description="To evaluate awards for appeal potential, your profile needs to
                be 100% completed."
            buttonFn={() => router.push("/profile")}
            buttonContent={
              <>
                <span className="px-1 whitespace-nowrap">Go to Profile</span>
                <div className="w-4 p-1 -ml-1">
                  <DiagonalRightArrow />
                </div>
              </>
            }
          />
        ) : (
          <>
            {userOffers?.length &&
            !checkIsUpgraded(subscription_info?.price_id) ? (
              <EmptySection
                title="No Appeals Available"
                description={
                  <span>
                    As a free account, you will see results for your three
                    selected schools. <br />
                    Upgrade to view unlimited appeals evaluations!
                  </span>
                }
                buttonFn={() => {
                  router.push("/upgrade_plan");
                }}
                buttonContent="Upgrade"
              />
            ) : (
              <EmptySection
                title="No Offers Added Yet"
                description="Add an offer to calculate expected costs"
                buttonFn={() => {
                  if (router.pathname === "/evaluate_appeal") {
                    router.push("/translate_compare");
                  }
                  setFormModalContent({}); //When offer data is present, set here
                  setFormModalView(OFFER_EDIT);
                  setDisplayFormModal(true);
                }}
                buttonContent="Add Offer"
              />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default EmptyAppealSection;
