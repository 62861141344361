import { EFC_LABEL } from "@utils/constants";

const retirementPaymentsText = (
  <span>
    Retirement contributions are a variable used to determine need-based aid
    eligibility at some schools. <br />
    <br />
    In this field, please include payments to tax-deferred pension and
    retirement savings plans (paid directly or withheld from earnings). These
    include the amounts reported on the W-2 forms in Boxes 12a through 12d,
    codes D, E, F, G, H and S. <br />
    <br />
    Do NOT include amounts reported in code DD (employer contributions toward
    employee benefits). These include 401(k), 403(b), and 457 plans.
  </span>
);

const businessEquityText = (
  <span>
    Some colleges look at the value of a personal business in their financial
    aid calculations. <br />
    <br />
    If you have a formal valuation for your business, list that amount.
    Otherwise, most business owners will report their liquid assets minus
    liabilities or simply put $0.
  </span>
);

/**
 */
const parentEarningsText = (
  <span>
    Income is one of the main inputs used to calculate need-based aid
    eligibility. Please provide total earnings from wages, salary, interest,
    dividends, rental income, and any other sources you are aware of.
  </span>
);
const agiText = (
  <span>
    Adjusted Gross Income (AGI) will be required in the financial aid forms. AGI
    is determined by subtracting certain adjustments from gross income.
    <br />
    <br />
    The easiest place to find your AGI is your tax filing. For most filers, AGI
    can be found on Line 11 of tax Form 1040.
    <br />
    <br />
    If you do not know your AGI right now, that's also ok. We can proceed with
    just the gross earnings figure for now and for many filers, AGI is the same
    as gross earnings.
  </span>
);

const incomeTaxPaidText = (
  <span>
    Enter your total tax paid. This information can be found on line 22 on your
    tax return.
    <br />
    <br />
    If you do not have this number handy, that's ok. You can leave blank for now
    and we'll make an estimate based on the income amounts entered
  </span>
);

export const infoTips = {
  scenario: {
    onboarding: {
      user_type:
        "Let us know if you are the student applying to college or their proud parent/guardian.",
      parent_guardian: {
        parent_pledged_assets: null,
        filing_status:
          "Tax filings are often used to verify financial aid applications, so knowing how you filed last year helps us understand how colleges may look at your application.",
        parent_name: "First name of the parent or guardian.",
        marital_status: (
          <span>
            Colleges may look at an applicant{`'`}s family{`'`}s financial
            profile (income and assets) differently depending on the marital
            status and the methodology the school uses. <br />
            <br />
            How you answer this question will help us determine what other
            factors we may (or may not) need to consider.{" "}
          </span>
        ),
        self_employed: (
          <span>
            Is the parent self-employed or do they own their own business?{" "}
            <br />
            <br />
            Similar to filing taxes, being self-employed adds a layer of
            complexity and consideration that is important in determining
            need-based aid eligibility at many colleges. <br />
            <br />
            How you answer this question will help us determine what other
            factors we may (or may not) need to consider.
          </span>
        ),
        business_equity: businessEquityText,
        self_employed_2: null,
        business_equity_2: businessEquityText,
        self_employed_3: null,
        business_equity_3: null,
        is_homeowner: "Does the parent/guardian own a home?",
        home_equity: (
          <span>
            Home equity is an important input used to determine need-based aid
            eligbility at some colleges. <br />
            <br />
            Please enter the difference between the market value of your primary
            home less any amount owed on a mortgage (including 2nd mortgage
            and/or HELOC).
          </span>
        ),
        is_spouse_homeowner: null,
        home_equity_2: null,
        is_ex_spouse_homeowner: null,
        home_equity_3: null,
        investments: (
          <span>
            The parent{`'`}s non-retirement savings/investments is an important
            input used to calculate need-based aid eligibility for the student.{" "}
            <br />
            <br />
            As of today, what is Parent 1{`'`}s total current balance of cash,
            savings, checking accounts, and investments? <br />
            <br />
            Do NOT include: primary residence, UTMA/UGMA, 401(k), IRAs,
            pensions, or any retirement accounts. <br />
            <br />
            DO Include: 529s for all accounts that you are the owner, mutual
            funds, stocks, bonds and {`"`}other{`"`} (NOT primary residence)
            real estate.
            <br />
            <br />
            Include the difference between the property value and total amount
            owed on any mortgage(s).{" "}
          </span>
        ),
        investments_2: null,
        investments_3: null,
        parent_earnings: parentEarningsText,
        parent_earnings_2: parentEarningsText,
        parent_earnings_3: parentEarningsText,
        agi: agiText,
        agi_2: agiText,
        agi_3: agiText,
        income_tax_paid: incomeTaxPaidText,
        income_tax_paid_2: incomeTaxPaidText,
        income_tax_paid_3: incomeTaxPaidText,
        retirement_payments: retirementPaymentsText,
        retirement_payments_2: retirementPaymentsText,
        // this seems intentional, but might good to confirm feel free to comment if you know
        retirement_payments_3: null,
      },
      student: {
        student_name: "First name of the college-bound student.",
        zip: "We use the student's zip code to help determine eligibility for in-state tuition.",
        country:
          "This is the student's country of citizenship. We use this to determine eligibility for federally funded, need-based aid.",
        starting_college_year: (
          <span>
            This is the year the student expects to graduate from high school.{" "}
            <br />
            <br /> We use graduation year to help determine tuition and other
            cost of attendance (COA) amounts.
          </span>
        ),
        high_school_class_rank: (
          <span>
            If you do not know your class rank or your school does not track
            this, please leave this blank.
          </span>
        ),
        high_school_class_size: "An applicant's class rank as a % of the class size is sometimes considered as criteria for merit scholarships. Providing the class size (along with the student's rank) will allow us to project some additional merit scholarship opportunities. AAA-JP",
        household_size: (
          <span>
            This is the total number of people (including the student) living in
            the student{`'`}s household.
            <br />
            <br /> Household size is a variable used to calculate need-based aid
            eligibility.
          </span>
        ),
        school_name: "What high school does the student currently attend?",
        num_siblings: (
          <span>
            {`${EFC_LABEL}`} calculations at some schools can be significantly
            impacted if you have siblings attending college at the same time.{" "}
            <br />
            <br />
            How you answer this question will help us determine what other
            factors we may (or may not) need to consider.{" "}
          </span>
        ),
        siblings: null,
        scores: {
          gpa: "Unweighted GPA (on a 4.0 scale) is one of the most important factors for determining eligibility for most merit-based scholarship opportunities.",
          weighted_gpa:
            "Weighted GPA (on a 5.0 scale) is used in addition to (and sometimes in place of) traditional unweighted GPA by some colleges in determining eligibility for merit-based scholarships.",
          sat: (
            <span>
              SAT score is a major factor in determining eligibility for some
              merit-based scholarships. <br />
              <br />
              Please enter the student{`'`}s composite {`"`}super{`"`} SAT score
              (combining best sectional scores, even if they come from different
              tests) to help us find all the merit aid available to you. <br />
              <br />
              If the student has not taken the SAT or will not be submitting
              scores, leave as N/A.
            </span>
          ),
          act: (
            <span>
              ACT score is a major factor in determining eligibility for some
              merit-based scholarships. <br />
              <br />
              Please enter the student{`'`}s best ACT score to help us find all
              the merit aid available to you. <br />
              <br />
              If the student has not taken the ACT or will not be submitting
              scores, leave as N/A.
            </span>
          ),
        },
        target_schools: null,
        ethnicity_id: "Some scholarships include criteria related to the applicant's ethnicity. Providing this information will allow us to project some additional scholarship opportunities.",

        religion_id: "Some scholarships include criteria related to the applicant's religious affiliation. Providing this information will allow us to project some additional scholarship opportunities.",
        major_id: (
          <span>
            What does the student plan on studying in college? <br /> <br />
            Intended major helps us identify specific scholarships for
            particular fields of study.
          </span>
        ),
        student_investments: (
          <span>
            As of today, what is the student{`'`}s total current balance of
            cash, savings, and checking and investment accounts? <br />
            <br />
            Do NOT include: 529{`'`}s, IRAs, ROTH IRAs or any retirement
            accounts. <br />
            <br />
            Do Include: UTMAs/UGMAs, stocks, bonds, mutual funds, CDs, etc.
            <br />
            <br />
            Student savings/investments are variables used to calculate
            need-based aid eligibility.
          </span>
        ),
        student_earnings: (
          <span>
            How much did the student earn from working last year? <br />
            <br />
            Student income is a variable used to calculate need-based aid
            eligibility.
          </span>
        ),
        student_agi: agiText,
        national_merit_award: (
          <span>
            Is the student recognized by the National Merit program?
            <br />
            <br />
            Being recognized by National Merit program can qualify the student
            for merit-based awards at some schools.
            <br />
            <br />
            There are different levels of recognition, including: National
            Recognition, Commended, Semi-Finalist, Finalist, and Scholar
          </span>
        ),
      },
    },
  },
};
