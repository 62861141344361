import React from "react";
import { useRouter } from "next/router";

import { useAppContext } from "@context/state.js";
import { Notice } from "@icons/index";
import { getAdminAccessTokenFromCookies } from "@utils/getAccessToken";
import { cancelSubscription } from "@api/auth";

const CancellationConfirmationModal = () => {
  const router = useRouter();

  const { user, handleAdminToken, setDisplayModal } = useAppContext();

  const onCancel = async () => {
    const isImpersonating = handleAdminToken(
      getAdminAccessTokenFromCookies(document.cookie)
    );
    const res = await cancelSubscription(user.email, isImpersonating);
    if (res) {
      setDisplayModal(false);
      router.push("/");
    }
  };

  return (
    <div className="modal compact">
      <div className="modal-scroll">
        <div className="modal-content">
          <div className="notice mb-2 mt-4 md:mt-0">
            <div className="icon">
              <Notice />
            </div>
          </div>
          <div className="title mb-6 h3">
            This will cancel your subscription!
          </div>
          <div className="infoText h5 mb-6 flex flex-col items-start">
            By clicking on Cancel My Subscription, you will be downgraded to the
            free plan with reduced functionality at the end of your billing
            cycle..
            <br />
            <br />
            <div className="flex flex-col self-center">
              <button
                type="button"
                className="noThanksBtn flex items-center self-center w-full"
                onClick={onCancel}
              >
                Cancel My Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancellationConfirmationModal;
