import axiosInstance from "@api/axiosInstance";

//******************************************//
//**** --- BOOK VIDEO CONSULTATION --- *****//
//******************************************//

/**
 * Endpoint for creating an instance of an appointment. This will be done after paying for an appointment.
 * @param {string} user_id - Id of a user (email)
 * @param {object} appointmentData - at least booleans for is_paid and is_booked
 * @returns - appointment object
 */
export const createAppointment = async (user_id, appointmentData) => {
  const res = await axiosInstance.post(
    `/user/${user_id}/appointment`,
    appointmentData
  );
  return res;
};

/**
 * Endpoint for checking if a paid for, but inbooked appointment exists
 * @param {string} user_id - Id of a user (email)
 * @param {string} access_token - cookie value provided from serverSideProps
 * @returns - appointment object or empty object if none exist
 */
export const checkForPaidUnbookedAppointment = async (
  user_id,
  access_token
) => {
  const res = await axiosInstance.get(`/user/${user_id}/appointment`, {
    headers: access_token ? { Authorization: `Bearer ${access_token}` } : {},
  });
  return res;
};

/**
 * Endpoint for adding an offer to a scenario.
 * @param {string} user_id - Id of a user (email)
 * @param {number} appointment_id - Id of the appointment to update
 * @param {object} appointmentData - at least booleans for is_paid and is_booked
 */
export const updateAppointment = async (
  user_id,
  appointment_id,
  appointmentData
) => {
  const res = await axiosInstance.put(
    `/user/${user_id}/appointment/${appointment_id}`,
    appointmentData
  );
  return res;
};

/**
 * Endpoint for getting the list of all experts
 * @param {string} access_token - cookie value provided from serverSideProps
 * @returns list of experts with their meeting links
 */
export const getExperts = async (access_token) => {
  const res = await axiosInstance.get("/experts", {
    headers: access_token ? { Authorization: `Bearer ${access_token}` } : {},
  });
  return res;
};
