import { useAppContext } from "@context/state";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { sendResetPassword } from "@api/auth";

const ChangePasswordModal = () => {
  const { user, setDisplayFormModal, handleApiError, errorResponse } =
    useAppContext();

  //sentEmail state controls whether to render reset password input or success page with a call to action to check your email
  const [sentEmail, setSentEmail] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async () => {
    const email = getValues("email");
    try {
      const res = await sendResetPassword(email);
      if (res) {
        //After submitting successfully, proceed to check email message page
        setSentEmail(true);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <form
      className="w-full h-full flex flex-col items-center text-left"
      action="#"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="modal-header">
        <button
          type="button"
          className="imitateLink border-0 text-left small"
          onClick={() => setDisplayFormModal(false)}
        >
          Cancel
        </button>
        <h6 className="mb-0 text-center capitalize">Account Info</h6>
        <button
          type="submit"
          className="border-0 text-right small save pointer-events-none opacity-0"
        >
          Save
        </button>
      </div>
      <div className="modal-scroll flex justify-center">
        <div className="modal-content form-content flex justify-center">
          <div id="account-edit-modal" className="flex flex-col items-center">
            {!sentEmail ? (
              <>
                <div className="header mb-4">
                  <h3>Change Password</h3>
                  <div className="divider"></div>
                  <div>
                    Click the button below to send an email to yourself with
                    instructions to change your password.
                  </div>
                </div>
                <div className="inputs flex flex-col text-left">
                  <label htmlFor="email" className="textLabel small">
                    <span className="labelFocus">Email</span>
                    <input
                      id="email"
                      type="email"
                      value={user?.email}
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errorResponse?.email && (
                      <small className="errorMessage text-left">
                        {errorResponse.email}
                      </small>
                    )}
                  </label>
                </div>
                <button
                  type="submit"
                  name="resetPassword"
                  className="primary"
                  disabled={isSubmitting}
                >
                  Send Instructions
                </button>
              </>
            ) : (
              <div className="flex flex-col items-center w-full">
                <div className="header mb-4 w-full">
                  <h3>Check Your Email</h3>
                  <div className="divider"></div>
                  <div>
                    We have sent password recovery instructions to your email.
                  </div>
                </div>
                <div className="tryAgain">
                  Did not receive the email? Check your spam filter, or{" "}
                  <a onClick={() => setSentEmail(false)}>
                    Try another email address.
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordModal;
