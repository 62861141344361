import { useAppContext } from "@context/state";
import { SchoolSearchFormInput } from "@components/index";
import { constructTopSchool } from "@utils/constructTopSchool";

/**
 * Seach a school from getSearchList Api call,
 * @param {object} input - Values for input field. For more reference
 * input field. Values are received from user onboarding object.
 */
const SchoolSearchField = ({ input }) => {
  const { userSchools } = useAppContext();

  const firstTargetSchool = constructTopSchool(userSchools[0]);

  return (
    <SchoolSearchFormInput
      inputMapId={input.mapId}
      firstTargetSchool={firstTargetSchool}
    />
  );
};

export default SchoolSearchField;
