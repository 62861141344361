import _ from "lodash";
import { useEffect, useState } from "react";
import ProgressBar from "../ProgressBar";
import { getMajors } from "@api/misc";
import { useAppContext } from "@context/state";
import { DownArrow } from "@icons/index";
import { infoTips } from "@utils/infoTips";

const Step04 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
  setValue,
}) => {
  if (formStep !== 6) {
    // Don't render unless step matches
    return null;
  }

  const years = [
    "-",
    2016,
    2017,
    2018,
    2019,
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
  ];

  const { handleApiError } = useAppContext();

  //start list of majors as undefined. Will map once majors are defined
  const [majors, setMajors] = useState(undefined);

  const getListOfMajors = async () => {
    try {
      const res = await getMajors();
      const majorsList = res?.data ? res.data : undefined;
      setMajors(majorsList);
    } catch (error) {
      //This is a public route, so 401 or 403 errors are not expected.
      handleApiError(error);
    }
  };

  useEffect(() => {
    const majorId = _.get(formData, "student.major_id");
    if (majorId) {
      setValue("student.major_id", majorId);
    } else {
      setValue("student.major_id", 0);
    }
  }, [majors]);

  useEffect(() => {
    //Get list of majors and set to state
    getListOfMajors();
    trigger([
      "student.school_name",
      "student.starting_college_year",
      // "student.major_id"
    ]);
    setWatchFields(["student"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "High School Name",
        description: _.get(infoTips, "scenario.onboarding.student.school_name"),
      },
      {
        id: 1,
        title: "Graduation Year",
        description: _.get(
          infoTips,
          "scenario.onboarding.student.starting_college_year"
        ),
      },
      {
        id: 2,
        title: "Intended Major",
        description: _.get(infoTips, "scenario.onboarding.student.major_id"),
      },
    ]);
  }, []);

  return (
    <div className="studentInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep} />
      <div className="studentInfoText">
        <h5>
          Where does {_.get(formData, "student.student_name") || "Student"} go
          to school and what year will he/she graduate?{" "}
        </h5>
      </div>
      <div className="inputs flex flex-col text-left">
        <label htmlFor="school_name" className="textLabel small">
          <span className="labelFocus">High School Name</span>
          <input
            id="school_name"
            type="text"
            {...register("student.school_name")}
          />
        </label>
        <label htmlFor="starting_college_year" className="selectLabel small">
          <span className="labelFocus">Graduation Year</span>
          <select
            id="starting_college_year"
            {...register("student.starting_college_year", {
              required: {
                value: true,
                message: "Please select the student's graduation year",
              },
              validate: {
                notDefault: (value) => value !== "-",
              },
            })}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <div className="select-icon">
            <DownArrow />
          </div>
        </label>
        <label htmlFor="major_id" className="selectLabel small">
          <span className="labelFocus">Intended Major</span>
          <select
            id="major_id"
            {...register("student.major_id", {
              required: {
                value: true,
                message: "Please enter the student's intended major",
              },
            })}
          >
            {majors?.map((major) => (
              <option key={major.id} value={major.id}>
                {major.name}
              </option>
            ))}
          </select>
          <div className="select-icon">
            <DownArrow />
          </div>
        </label>
      </div>
    </div>
  );
};

export default Step04;
