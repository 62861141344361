import { useAppContext } from "@context/state";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import {
  getAdminAccessTokenFromCookies,
  setAccessToken,
} from "@utils/getAccessToken";

import { CANCELLATION_CONFIRMATION_VIEW } from "@utils/constants";

import { data } from "autoprefixer";

const CancelSubscriptionModal = () => {
  const {
    handleApiError,
    setDisplayFormModal,
    setModalView,
    setDisplayModal,
    errorResponse,
    setErrorResponse,
    handleAdminToken,
  } = useAppContext();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async () => {
    const { current_email, password } = getValues();
    try {
      if (process.env.NEXT_PUBLIC_WHITELABEL === "true") {
        throw {
          status: 405,
          data: {
            status: 405,
            message: "Changing email not allowed for this user",
          },
        };
      }
      const isImpersonating = handleAdminToken(
        getAdminAccessTokenFromCookies(document.cookie)
      );
      // const res = await cancelSubscription(
      //   current_email,
      //   password,
      //   isImpersonating
      // );
      const res = "true";
      if (res) {
        //After submitting successfully, proceed to change email success page
        //Route provides new token
        // localStorage.setItem("refresh_token", res.data.jwt?.refresh_token);
        // setAccessToken(res.data.jwt?.access_token);
        // reset({
        //   current_email: "",
        //   password: "",
        // });
        setDisplayFormModal(false);
        setModalView(CANCELLATION_CONFIRMATION_VIEW);
        setDisplayModal(true);
      }
    } catch (error) {
      //Only use error modal for unexpected errors (handleApiError)
      if (error?.response?.status === 403) {
        //Unauthorized
        setErrorResponse(error.response.data);
      } else {
        handleApiError(error);
      }
    }
  };

  return (
    <form
      className="w-full h-full flex flex-col items-center"
      action="#"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="modal-header">
        <button
          type="button"
          className="imitateLink border-0 text-left small"
          onClick={() => setDisplayFormModal(false)}
        >
          Cancel
        </button>
      </div>
      <div className="modal-scroll flex justify-center">
        <div className="modal-content form-content flex justify-center">
          <div id="account-edit-modal">
            <div className="header mb-4 text-left">
              <h3>Cancel My Subscription</h3>
              <div className="divider"></div>
              <div>
                Upon cancelling your subsription you will no longer be able to
                access the premium features of College Aid Pro.
              </div>
            </div>
            <div className="inputs flex flex-col text-left pb-10">
              <label htmlFor="current_email" className="textLabel small">
                <span className="labelFocus">
                  Enter your email and password for confirmation
                </span>
                <input
                  id="current_email"
                  type="email"
                  {...register("current_email", {
                    required: {
                      value: true,
                      message: "Invalid email address",
                    },
                    validate: {
                      correctFormat: (value) => /\S+@\S+/g.test(value),
                    },
                  })}
                />
              </label>
              <label htmlFor="password" className="textLabel small">
                <span className="labelFocus">Password</span>
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  className={`${errorResponse?.result ===
                      "The provided password is incorrect."
                      ? "inputErrorState"
                      : ""
                    }`}
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Please enter your password",
                    },
                  })}
                />
                {/* TODO: error should return an object with password as a key and message as a value, not just a string */}
                {errorResponse?.result ===
                  "The provided password is incorrect." && (
                    <small className="errorMessage">{errorResponse.result}</small>
                  )}
              </label>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`${isSubmitting ? "pl-1 pt-1" : ""}
            schoolSearchBtn primary`}
              >
                {isSubmitting ? (
                  <ClipLoader
                    size={20}
                    css={{
                      borderColor: "var(--primary-color)",
                      borderBottomColor: "transparent",
                    }}
                  />
                ) : (
                  "Cancel Subscription"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CancelSubscriptionModal;
