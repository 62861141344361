import { useAppContext } from "@context/state";
import { LongLeftArrow, CapLogo } from "@icons/index";
import { useEffect } from "react";
import { loginUser } from "@api/auth";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import Link from "next/link";
import { setAccessToken } from "@utils/getAccessToken";
import GoogleLoginButton from "./GoogleLoginButton";
import {
  getReferralCodeFromSession,
  removeReferralCode,
} from "@utils/getReferralCode";
import _ from "lodash";
import { gtmPushLogin } from "@utils/gtm";
import { EMAIL } from "@utils/constants";
import { updateProfileProgress } from "@api/profileProgress";
import { getTotalPersonalInfoWeightPercentage } from "@utils/calcEnhancedProfile";

const Login = () => {
  const {
    setLoggedIn,
    scenario,
    setScenario,
    handleApiError,
    checkForNeededDashboardInfo,
  } = useAppContext();

  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm();

  const login = async (emailPassword) => {
    try {
      const res = await loginUser(emailPassword);
      if (res.data?.jwt) {
        //push event to Google Tag Manager
        gtmPushLogin(EMAIL);
        //set tokens
        localStorage.setItem("refresh_token", res.data.jwt?.refresh_token);
        setAccessToken(res.data.jwt?.access_token);
        router.push("/");
        setLoggedIn(true);
        //remove referral code if present
        if (getReferralCodeFromSession()) {
          removeReferralCode();
        }
        //set scenario for instant visual on name and efcs
        setScenario({
          ...scenario,
          case_id: res.data.case_id,
          onboarding: res.data.onboarding,
          efcs: res.data.efcs,
          preapprovals: res.data.preapprovals,
        });

        const onboarding = res.data.onboarding;
        checkForNeededDashboardInfo(onboarding);
        const profileProgress =
          getTotalPersonalInfoWeightPercentage(onboarding).percentComplete;
        const res2 = await updateProfileProgress(
          res.data.case_id,
          profileProgress
        );
      }
    } catch (error) {
      //Only use error modal for unexpected errors
      if (
        _.get(error?.response, "data.result.message") ===
        "Credentials are invalid."
      ) {
        //error.response.data.result is "Credentials are invalid". Use setError for custom message:
        //Unauthorized
        setError("email", {
          type: "manual",
          message: "Incorrect email or password",
        });
      } else {
        handleApiError(error);
      }
    }
  };

  const onSubmit = async (values) => {
    await login(values);
  };

  useEffect(() => {
    clearErrors();
  }, watch(["email", "password"]));

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    if (email) {
      setValue("email", email);
    }
  }, []);

  return (
    <div className="entryPage flex flex-col justify-between h-full w-full">
      <div className="welcomeContainer flex flex-col items-center justify-start text-center h-full w-full">
        <button
          className="simple back-btn flex items-center"
          onClick={() => {
            router.push("/");
          }}
        >
          <LongLeftArrow />
        </button>
        <form
          className="w-full h-full"
          action="#"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col items-center w-full">
            <div className="bgLogo">
              <CapLogo />
            </div>
            <div className="welcomeText loginText flex flex-col w-full">
              <h1>Welcome back</h1>
              <h5 className="entryText self-center">Log-in to your account</h5>
            </div>
            <div className={`divider`} />
            {/* add to divider ${(errors.email || errors.password) && "removeDividerBottom"} */}
            {/* When backend active, use those errors, not react hook form errors here */}
            {/* {(errors.email || errors.password) && <small className="errorMessage loginError text-left">Incorrect email or password</small>} */}
            <div className="inputs flex flex-col text-left">
              {errors.email && (
                <small className="loginError">{errors.email.message}</small>
              )}
              <label htmlFor="email" className="textLabel small">
                <span className="labelFocus">Email</span>
                <input
                  id="email"
                  type="email"
                  autoComplete="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Invalid email address",
                    },
                  })}
                />
              </label>
              <label htmlFor="password" className="textLabel small">
                <span className="labelFocus">Password</span>
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Please enter a password",
                    },
                  })}
                />
              </label>
            </div>
            {isSubmitting ? (
              <div className="spinnerContainer">
                <ClipLoader
                  size={25}
                  css={{
                    borderColor: "var(--primary-color)",
                    borderBottomColor: "transparent",
                  }}
                />
              </div>
            ) : (
              <button
                type="submit"
                name="login"
                className="startBtn loginBtn primary"
              >
                Log in
              </button>
            )}
            <Link href="/reset_password" legacyBehavior>
              <button
                type="button"
                disabled={isSubmitting}
                className="resetFont imitateLink"
              >
                <b>Forgot Your Password?</b>
              </button>
            </Link>
            <GoogleLoginButton className="my-4" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
