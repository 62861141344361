import { CapLogo } from "@icons/index";
import Link from "next/link";
import { RenewalPlan } from "@components/index";

const PricingPlanLayout = () => {
  return (
    <div className="entryPage flex flex-col justify-between h-full w-full">
      <section className="upgradePlanContainer flex flex-col items-center">
        <div className="lg:hidden headerSection ">
          <Link href="/" className="logo">

            <button className="logo">
              <CapLogo />
            </button>

          </Link>
        </div>
        <RenewalPlan />
      </section>
    </div>
  );
};

export default PricingPlanLayout;
