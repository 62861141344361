import { useAppContext } from "@context/state";
import { OFFER_EDIT } from "@utils/constants";
import { Card } from "@components/index";
import { useRouter } from "next/router";

const EmptyOfferSection = ({ descriptionText }) => {
  const { setFormModalView, setFormModalContent, setDisplayFormModal } =
    useAppContext();

  const router = useRouter();

  return (
    <Card type="typeEmpty w-full">
      <div className="emptyCard flex flex-col items-center text-center md:flex-row md:justify-between">
        <div className="flex flex-col items-center md:items-start md:text-left">
          <h4>No Offers Added Yet</h4>
          <span className="description font-regular">{descriptionText}</span>
        </div>
        <button
          className="tertiary small flex justify-center"
          onClick={() => {
            if (router.pathname === "/how_to_pay") {
              router.push("/translate_compare");
            }
            setFormModalContent({}); //When offer data is present, set here
            setFormModalView(OFFER_EDIT);
            setDisplayFormModal(true);
          }}
        >
          Add Offer
        </button>
      </div>
    </Card>
  );
};

export default EmptyOfferSection;
