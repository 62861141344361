import axiosInstance from "@api/axiosInstance";
import { axiosInstanceWithToken } from "./axiosInstanceWithToken";

//******************************************//
//************ ----- AUTH ----- ************//
//******************************************//

/**
 * Login or Register with social account (Google, etc.)
 * @param {string} access_token - access token obtained from logging in via social account
 * @param {string} referral_code - string which will be present if user was referred
 * @returns case_id, jwt token, user, efcs, preapprovals, and onboarding. TODO: Include efcs, preapprovals, plan_info in returned data for registering
 */
export const socialLogin = async (access_token, referral_code) => {
  //Post a new user
  const res = await axiosInstanceWithToken(access_token).post(
    `/social-login${referral_code ? "?referral_code=" + referral_code : ""}`
  );
  return res;
};

/**
 * Register
 * Endpoint for registering a new user to the database
 * @param {string} userOnboardingData - user's email address and password, and onboarding answers
 * @returns case_id, jwt token, user, and onboarding. TODO: Include efcs, preapprovals, plan_info in returned data
 */
export const registerUser = async (userOnboardingData) => {
  //Post a new user
  const res = await axiosInstance.post("/registration", userOnboardingData);
  return res;
};

/**
 * WakeMe! Registration
 * Endpoint for registering a new user to the database
 * @param {string} userOnboardingData - user's registration answers
 * @returns case_id
 *
 */
export const registerWakeMeUser = async (userOnboardingData) => {
  //Post a new user
  const res = await axiosInstance.post("/intake", userOnboardingData);
  return res;
};

/**
 * Login
 * Endpoint for logging in an existing user
 * @param {string} userLoginData - user's email address and password
 * @returns case_id, jwt token, user, efcs, preapprovals, and onboarding.
 */
export const loginUser = async (userLoginData) => {
  //login existing user
  const res = await axiosInstance.post("/login", userLoginData);
  return res;
};

/**
 * Password reset
 * Endpoint for resetting password. Instructions will be sent to the email provided to reset password. That process is done entirely separately from the main site.
 * @param {string} userEmail - user's email address
 * @returns empty array
 */
export const sendResetPassword = async (userEmail) => {
  const res = await axiosInstance.post("/me/send-reset-password", {
    email: userEmail,
  });
  return res;
};

/**
 * Change Email
 * Endpoint for resetting password. Instructions will be sent to the email provided to reset password. That process is done entirely separately from the main site.
 * @param {string} currentEmail - user's email address currently associated with account
 * @param {string} newEmail - user's email address to replace old email
 * @param {string} userPassword - user's password associated with the account
 * @param {boolean} isImpersonating - send true if admin is impersonating to get shorter token duration
 * @returns empty array
 */
export const changeEmail = async (
  currentEmail,
  newEmail,
  userPassword,
  isImpersonating
) => {
  const res = await axiosInstance.patch("/me/update-email", {
    current_email: currentEmail,
    new_email: newEmail,
    password: userPassword,
    is_impersonating: isImpersonating,
  });
  return res;
};

/**
 * Cancel Subscription
 * Endpoint for cancelling subscriptions.  The api cancels the subscription in Stripe and Chargebee, and then updates the user's subscription status in the database.
 * @param {string} userEmail - user's email address associated with account
 * @param {string} userPassword - user's password associated with the account
 * @param {boolean} isImpersonating - send true if admin is impersonating to get shorter token duration
 * @returns empty array
 */
export const cancelSubscription = async (userEmail, isImpersonating) => {
  const res = await axiosInstance.post(`/subscriptions/cancel/${userEmail}`, {
    email: userEmail,
    is_impersonating: isImpersonating,
  });
  return res;
};

/**
 * refresh token
 * Endpoint for getting a new token
 * @param {boolean} isImpersonating - send true if admin is impersonating to get shorter token duration
 * @param {string} refresh_token - requires a valid jwt token present in localStorage
 * @returns jwt token
 */
export const refreshToken = async (isImpersonating) => {
  const res = await axiosInstance.post("/me/refresh-token", {
    refresh_token: localStorage.getItem("refresh_token"),
    is_impersonating: isImpersonating,
  });
  return res;
};

/**
 * refresh token
 * Endpoint for getting a new token
 * @param {boolean} isImpersonating - send true if admin is impersonating to get shorter token duration
 * @param {string} refresh_token - requires a valid jwt token present in localStorage
 * @returns jwt token
 */
export const adminRefreshToken = async (isImpersonating) => {
  const res = await axiosInstance.post("/me/refresh-token", {
    refresh_token: localStorage.getItem("refresh_token"),
    is_impersonating: isImpersonating,
  });
  return res;
};
