import { UpgradePlan } from "@components/index";
import { useEffect } from "react";

/**
 * Step naming convention is 2 digits for ordering
 * @param {integer} formStep - used to handle step in wizard form
 * @param {function} setFormStep
 * @param {function} setModalContent
 * @returns Upgrade plan page before first step of onboarding
 */
const Step00 = ({ formStep, setFormStep, setModalContent }) => {
  if (formStep !== 0) {
    // Don't render unless step matches
    return null;
  }

  useEffect(() => {
    //Set content for help modal
    setModalContent([]);
  }, []);

  return (
    <div className="onboardingUpgradePlan flex flex-col items-center justify-start md:justify-center pt-2 lg:pt-0">
      <section className="upgradePlanContainer flex flex-col items-center">
        <UpgradePlan setFormStep={setFormStep} />
      </section>
    </div>
  );
};

export default Step00;
