const CapAdvisors = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 36 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Cap-Advisors"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M5.2,34 L6.38708822,26.5026007 C3.57104699,23.9070555 2,20.3016522 2,16.3157895 C2,8.40939727 8.18160445,2 18,2 C27.8183956,2 34,8.40939727 34,16.3157895 C34,24.2221817 27.8183956,30.6315789 18,30.6315789 C15.7994537,30.6315789 13.7815905,30.3096225 11.9750604,29.7210145 L5.2,34 Z M11.2952381,16.3157897 L11.312,16.3157897 L11.2952381,16.3157897 Z M18,16.3157897 L18.0167619,16.3157897 L18,16.3157897 Z M24.7047619,16.3157897 L24.7215238,16.3157897 L24.7047619,16.3157897 Z"
            id="Icon"
            stroke="currentColor"
            strokeWidth="2"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default CapAdvisors;
