import { useState } from "react";
import { Card } from "@components/index";
import { DiagonalRightArrow, RocketIcon, Cross } from "@icons/index";
import { useRouter } from "next/router";
import { getTotalPersonalInfoWeightPercentage } from "@utils/calcEnhancedProfile";
import { useAppContext } from "@context/state";

const LowInfoCTA = () => {
  const router = useRouter();
  const [visible, setVisible] = useState(true);

  const { scenario } = useAppContext();
  const { onboarding } = scenario;
  return (
    <Card type={`typeDark relative ${!visible && "hidden"} mt-4 mx-2`}>
      <div className="rocket flex flex-col items-center text-center md:flex-row md:justify-between px-3">
        <div className="flex flex-col items-center md:flex-row">
          <div className="icon">
            <RocketIcon />
          </div>
          <div className="flex flex-col items-center md:items-start md:text-left md:mx-6">
            <h3 className="header">We Can Do Even Better!</h3>
            <span className="description">
              {getTotalPersonalInfoWeightPercentage(onboarding)
                .percentComplete < 100 &&
                `Your profile is ${
                  getTotalPersonalInfoWeightPercentage(onboarding)
                    .percentComplete
                }% finished.`}{" "}
              Go complete your profile to make sure we are anticipating all of
              the potential need- and merit-based aid opportunities available to
              you.
            </span>
          </div>
        </div>
        <div className="learnMoreBtn">
          <button
            className="tertiary-dark highlight h-full ml-3 flex flex-row items-center flex-nowrap"
            onClick={() => router.push("/profile")}
          >
            <span className="px-1 whitespace-nowrap">Go to Profile</span>
            <div className="w-4 p-1 -ml-1">
              <DiagonalRightArrow />
            </div>
          </button>
        </div>
        <button
          className={`close-card ${
            !visible && "spin-shrink"
          } tertiary rounded-full flex items-center justify-center`}
          onClick={() => {
            setVisible(false);
            sessionStorage.setItem("hide_cta", true);
          }}
        >
          <Cross />
        </button>
      </div>
    </Card>
  );
};

export default LowInfoCTA;
