import { useEffect, useState } from "react";
import { TextField } from "@components/index";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import { requiredValidation } from "@utils/formValidations";
import { HelpButton } from "@components/index";
import { useAppContext } from "@context/state";
import { HELP_VIEW } from "@utils/constants";
import { renderCapOrResetIcon } from "@utils/renderHelpers";

/**
 * Returns an input based on the type of type of data
 * @param {object} input - Values for input field. For more reference
 * @param {string} label
 * @param {string} mapId
 * @param {number} tuitionBudgetValue
 * @param {number} capProjectionValue
 * @param {number} decidedDocumentJobValue
 * @param {string} placeholder
 * @param {string} prefix
 * @param {*} helpText - Can be a string or Element
 * look for info objects in personalData.js file.
 */
const OfferFormField = ({
  label,
  mapId,
  tuitionBudgetValue,
  capProjectionValue,
  decidedDocumentJobValue,
  isAward,
  placeholder,
  prefix,
  helpText,
}) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [valueType, setValueType] = useState("Translated");
  const { setDisplayModal, setModalView, setModalContent } = useAppContext();

  /**
   * Reset input to an automatic value, preferring translated values
   */
  const resetToDefaultValue = () => {
    if (valueType === "Reset") {
      if (
        Number.isInteger(decidedDocumentJobValue) &&
        decidedDocumentJobValue >= 0
      ) {
        setValue(mapId, decidedDocumentJobValue, { shouldDirty: true });
        setValueType("Translated");
      } else if (
        Number.isInteger(capProjectionValue) &&
        capProjectionValue >= 0
      ) {
        setValue(mapId, capProjectionValue, { shouldDirty: true });
        if (isAward) {
          setValueType("Translated");
        } else {
          setValueType("CAP Value");
        }
      }
    } else if (valueType === "CAP Value") {
      setModalContent([
        {
          id: 0,
          title: "CAP Value",
          description: (
            <span>
              These are the expected or default results produced by the CAP
              platform based on your profile and selections.
            </span>
          ),
        },
      ]);
      setModalView(HELP_VIEW);
      setDisplayModal(true);
    }
  };

  /**
   * After render initialize default values for every
   * input field. Values are received from user preapprovals object.
   */
  useEffect(() => {
    //if tuition budget value is equal to job value, set "Translated value" and render field normally
    //else if tuition budget value is equal to cap value, set "CAP Value" and render with "CAP Value" inside input aligned right
    //else set "manual value" and render with "Reset" button inside input aligned right. Logic for reset submits update with value of job or if that isn't present, update with value of cap projection.
    if (!isNaN(tuitionBudgetValue)) {
      if (tuitionBudgetValue === decidedDocumentJobValue) {
        //Job Value
        setValueType("Translated");
      } else if (tuitionBudgetValue === capProjectionValue && !isAward) {
        //CAP Value
        setValueType("CAP Value");
      } else {
        //Manual Value -> Reset
        if (isAward && !Number.isInteger(decidedDocumentJobValue)) {
          setValueType("Translated");
        } else {
          setValueType("Reset");
        }
      }
      setValue(mapId, tuitionBudgetValue);
    } else if (!isNaN(capProjectionValue)) {
      if (isAward) {
        setValueType("Translated");
      } else {
        setValueType("CAP Value");
      }
      setValue(mapId, capProjectionValue);
    }
  }, []);

  /**
   * Dynamically change valueType when input is changed
   */
  useEffect(() => {
    const inputValue = parseInt(watch(mapId));
    //if tuition budget value is equal to job value, set "Translated value" and render field normally
    //else if tuition budget value is equal to cap value, set "CAP Value" and render with "CAP Value" inside input aligned right
    //else set "manual value" and render with "Reset" button inside input aligned right. Logic for reset submits update with value of job or if that isn't present, update with value of cap projection.
    if (inputValue !== undefined) {
      if (inputValue === decidedDocumentJobValue) {
        //Job Value
        setValueType("Translated");
      } else if (inputValue === capProjectionValue && !isAward) {
        //CAP Value
        setValueType("CAP Value");
      } else {
        //Manual Value -> Reset
        if (isAward && !Number.isInteger(decidedDocumentJobValue)) {
          setValueType("Translated");
        } else {
          setValueType("Reset");
        }
      }
    }
  }, [watch(mapId)]);

  return (
    <div className="mb-3">
      <div
        className={`inputItem ${
          prefix ? "currencyLabel" : ""
        } relative flex flex-col`}
      >
        <div className="labelWrapper flex items-end justify-between">
          <label htmlFor={mapId} className="small">
            {label}
          </label>
          {helpText && (
            <HelpButton
              helpContent={[
                {
                  id: 0,
                  title: label,
                  description: helpText,
                },
              ]}
              className="small mb-0.5 absolute"
            />
          )}
        </div>
        <TextField
          input={{
            mapId,
            type: "text",
            amount: true,
            placeholder,
            validation: requiredValidation,
          }}
        />
        {valueType !== "Translated" && (
          <button
            className="value-type simple"
            type="button"
            onClick={resetToDefaultValue}
          >
            <small>{renderCapOrResetIcon(valueType)}</small>
          </button>
        )}
        <div className="currency resetFont">{prefix}</div>
      </div>
      {_.get(errors, mapId) && (
        <small className="errorMessage text-left">
          {_.get(errors, mapId).message}
        </small>
      )}
    </div>
  );
};

export default OfferFormField;
