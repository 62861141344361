import { useAppContext } from "@context/state";
import {
  Section,
  HowToPaySchoolCard,
  EmptyOfferSection,
} from "@components/index";

const HowToPay = () => {
  const { userOffers } = useAppContext();

  const renderSchools = () => {
    //map budgets to cards
    const budgets = userOffers.map((offer) => (
      <HowToPaySchoolCard
        key={offer.id}
        offerData={offer}
        showMoreDetails={true}
      />
    ));
    return budgets;
  };

  return (
    <>
      <Section
        galleryName={"how-to-pay-gallery"}
        empty={userOffers.length === 0}
      >
        {userOffers.length === 0 ? (
          <EmptyOfferSection
            descriptionText={"Add an offer to calculate expected costs"}
          />
        ) : (
          <>{renderSchools()}</>
        )}
      </Section>
    </>
  );
};

export default HowToPay;
