import Link from "next/link";
import { useAppContext } from "@context/state";

import { Card } from "@components/index.js";

const AddSchoolCard = () => {
  const { scenario } = useAppContext();

  // Set the search url to the school search page with the case id and default search params
  // state is xx because we aren't in the advanced search yet.
  const case_id = scenario?.case_id;
  const Url = `/school_search/${case_id}/state=xx&limit=10&offset=0&order=net-cost`;

  return (
    <div className="cardContainer">
      <Card type="typeTall">
        <div className="w-full h-full flex flex-col justify-center items-center text-center">
          <h4>Shop For Another School Here</h4>
          <Link href={Url}>

            <button className="tertiary addSchoolBtn">Add School</button>

          </Link>
        </div>
      </Card>
    </div>
  );
};

export default AddSchoolCard;
