import ClipLoader from "react-spinners/ClipLoader";
import { HelpButton } from "@components/index";

// Component conditionaly renders button for the onboarding flow
// depending on which step the user currently is while onboarding.
const Lockup = ({
  formData,
  formStep,
  setFormStep,
  completeFormStep,
  clearErrors,
  isValid,
  reset,
  isSubmitting,
  modalContent,
  onSubmit,
}) => {
  if (formStep === 16) {
    // Don't render unless step matches account creation step
    return (
      <div className="lockupContainer flex flex-row items-center justify-between text-center">
        <HelpButton helpContent={modalContent} />
        {isSubmitting ? (
          <div className="createAccountBtn flex items-center justify-center">
            <ClipLoader
              size={25}
              css={{
                borderColor: "var(--primary-color)",
                borderBottomColor: "transparent",
              }}
            />
          </div>
        ) : (
          <button
            type="submit"
            className="createAccountBtn secondary"
            disabled={!isValid || isSubmitting}
          >
            Done
          </button>
        )}
        <button
          type="button"
          className="imitateLink skip h6 removeMargin"
          onClick={() => onSubmit(formData)}
        >
          Skip
        </button>
      </div>
    );
  }

  return (
    <div className="lockupContainer flex flex-row items-center justify-between text-center">
      <HelpButton helpContent={modalContent} />
      <button
        type="button"
        className="nextBtn secondary"
        disabled={!isValid}
        onClick={() => {
          isValid && completeFormStep();
        }}
      >
        Next
      </button>
      <button
        type="button"
        className="imitateLink skip h6 removeMargin"
        onClick={() => {
          reset({ ...formData }); //Populate form with saved data only
          clearErrors(); //Clean slate for next step BUG: button theme flashes on new step due to time between _.isEmpty(errors) and trigger
          //make async and happen after errors clear? or make _.isEmpty(errors) wait for trigger first
          setFormStep((cur) => cur + 1);
        }}
      >
        Skip
      </button>
    </div>
  );
};

export default Lockup;
