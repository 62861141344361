import _ from "lodash";
import { useEffect, useState } from "react";
import ProgressBar from "../ProgressBar";
import { MinusIcon, PlusIcon } from "@icons/index";
import { infoTips } from "@utils/infoTips";

const Step03 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
  setValue,
}) => {
  if (formStep !== 5) {
    // Don't render unless step matches
    return null;
  }

  const [quantity, setQuantity] = useState(
    formData.student?.household_size ? formData.student.household_size : 1
  );

  useEffect(() => {
    trigger("student.household_size");
    setWatchFields(["student"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "Number of People in Student's Household",
        description: _.get(
          infoTips,
          "scenario.onboarding.student.household_size"
        ),
      },
    ]);
  }, []);

  useEffect(() => {
    setValue("student.household_size", quantity); //necessary due to hidden nature of input
  }, [quantity]);

  const decrement = () => {
    if (quantity > 1) {
      setQuantity(parseInt(quantity) - 1);
    }
  };

  const increment = () => {
    setQuantity(parseInt(quantity) + 1);
  };

  return (
    <div className="studentInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep} />
      <div className="studentInfoText">
        <h5>
          How many people live in{" "}
          {_.get(formData, "student.student_name") || "Student"}&apos;s
          household?
        </h5>
      </div>
      <div className="inputs flex justify-center">
        <label htmlFor="household_size" className="numberLabel small">
          <button type="button" className="incButton" onClick={decrement}>
            <div className="icon">
              <MinusIcon />
            </div>
          </button>
          <input
            id="household_size"
            type="number"
            className="hidden"
            {...register("student.household_size", {
              required: {
                value: true,
                message: "Please enter a quantity of at least 1",
              },
            })}
          />
          <div className="numDisplay h2">{quantity}</div>
          <button type="button" className="incButton" onClick={increment}>
            <div className="icon">
              <PlusIcon />
            </div>
          </button>
        </label>
      </div>
    </div>
  );
};

export default Step03;
