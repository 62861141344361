import _ from "lodash";
import { useAppContext } from "@context/state";
import { calculateNetCostForGivenSchools } from "@api/calculateScenario";
import { SchoolSearchInput } from "@components/index";
import { useForm } from "react-hook-form";

const MapSearch = ({ curatedSearchList, setCuratedSearchList }) => {
  const methods = useForm();

  const { scenario, handleApiError } = useAppContext();

  //Search Results
  const addSchoolToCuratedList = async (school) => {
    const { college_id } = school;
    const alreadyHasSchool = curatedSearchList.some((school) => {
      //college_id in school details data is a string
      return school.school_id === `${college_id}`;
    });
    //Only get school details and add to curated list if it's not already in the list
    if (!alreadyHasSchool) {
      try {
        //use calculate to get school details to ensure data is up to date
        const res = await calculateNetCostForGivenSchools(scenario?.case_id, [
          college_id,
        ]);
        //add school to list
        const schoolData = _.get(res, `data.result.schools[${college_id}]`);
        if (schoolData) {
          // setCuratedSearchList([]);
          setCuratedSearchList((cur) => [...cur, schoolData]);
        }
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  return (
    <div className="mapBar">
      <div className="mapSearchContainer flex justify-center w-full">
        <SchoolSearchInput
          customStyle="transform -translate-y-4"
          primaryStyle={true}
          inputId={"school_search"}
          chooseSchool={addSchoolToCuratedList}
          methods={methods}
        />
      </div>
    </div>
  );
};

export default MapSearch;
