const RocketIcon = () => {
  return (
    <div className="flex justify-center w-full h-full">
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 441.948 441.948"
      >
        <g>
          <path
            d="M55.237,336.353c3.905-3.905,3.905-10.237,0-14.143c-3.905-3.904-10.237-3.904-14.143,0L2.929,360.377
		c-3.905,3.905-3.905,10.237,0,14.143c1.952,1.952,4.512,2.929,7.071,2.929s5.119-0.977,7.071-2.929L55.237,336.353z"
            fill="currentColor"
          />
          <path
            d="M306.916,68.629c-8.868,8.869-13.752,20.66-13.752,33.201c0,12.542,4.884,24.333,13.752,33.201
		c9.153,9.153,21.177,13.729,33.201,13.729s24.047-4.576,33.2-13.729c8.869-8.868,13.752-20.659,13.752-33.201
		c0-12.541-4.884-24.332-13.752-33.201C355.011,50.323,325.224,50.323,306.916,68.629z M359.175,120.889
		c-10.509,10.511-27.607,10.51-38.117,0c-5.091-5.091-7.894-11.858-7.894-19.059c0-7.199,2.804-13.968,7.894-19.059
		c5.255-5.255,12.156-7.882,19.059-7.882c6.901,0,13.805,2.628,19.058,7.882c5.091,5.091,7.895,11.859,7.895,19.059
		C367.07,109.031,364.266,115.798,359.175,120.889z"
            fill="currentColor"
          />
          <path
            d="M86.775,353.748c-3.905-3.904-10.237-3.904-14.142,0l-53.536,53.537c-3.905,3.905-3.905,10.237,0,14.143
		c1.953,1.952,4.512,2.929,7.071,2.929s5.119-0.977,7.071-2.929l53.536-53.537C90.68,363.985,90.68,357.653,86.775,353.748z"
            fill="currentColor"
          />
          <path
            d="M435.581,13.329c-0.938-3.382-3.581-6.023-6.961-6.962c-36.445-10.115-75.921-8.147-114.161,5.688
		c-29.631,10.721-58.449,28.693-79.065,49.31l-31.096,31.096L91.644,110.058c-2.094,0.327-4.029,1.311-5.528,2.809l-72.051,72.051
		c-2.806,2.807-3.688,7.006-2.247,10.703c1.441,3.697,4.933,6.192,8.897,6.359l71.073,2.989l-17.813,17.813
		c-3.905,3.905-3.905,10.237,0,14.143l10.397,10.396L53.319,294.92c-2.584,3.961-2.041,9.19,1.304,12.535l79.871,79.87
		c3.345,3.344,8.574,3.89,12.536,1.304l47.597-31.054l10.397,10.397c3.905,3.904,10.237,3.904,14.143,0l17.813-17.813l2.989,71.071
		c0.167,3.965,2.662,7.456,6.359,8.897c1.18,0.46,2.41,0.683,3.63,0.683c2.604,0,5.162-1.019,7.073-2.929l72.051-72.051
		c1.499-1.499,2.482-3.435,2.81-5.528l17.598-112.653l31.095-31.096c20.616-20.616,38.589-49.434,49.31-79.064
		C443.728,89.25,445.695,49.773,435.581,13.329z M142.898,367.444L74.504,299.05l24.321-37.278l33.604,33.604l-3.952,3.952
		c-3.906,3.905-3.906,10.237,0,14.143c3.905,3.904,10.237,3.904,14.143,0l3.952-3.952l33.604,33.604L142.898,367.444z
		 M366.441,192.412l-33.401,33.402c-0.004,0.004-0.008,0.008-0.012,0.012l-34.754,34.754c-3.905,3.905-3.905,10.237,0,14.143
		c3.905,3.904,10.237,3.904,14.143,0l13.082-13.082l-12.865,82.354l-53.64,53.641l-2.804-66.686l27.943-27.943
		c3.905-3.905,3.905-10.237,0-14.143c-3.905-3.904-10.237-3.904-14.143,0l-31.021,31.021c-0.017,0.017-0.033,0.033-0.05,0.05
		l-26.824,26.823l-8.917-8.917c-0.102-0.105-0.204-0.208-0.31-0.309l-42.155-42.156l120.945-120.944
		c3.905-3.905,3.906-10.237,0-14.143c-3.904-3.903-10.236-3.904-14.142,0L146.571,281.235l-42.157-42.157
		c-0.1-0.104-0.202-0.206-0.307-0.307l-8.917-8.918l26.824-26.824c0.017-0.016,0.033-0.032,0.049-0.049l31.021-31.021
		c3.906-3.905,3.906-10.237,0-14.143c-3.904-3.904-10.237-3.904-14.142,0l-27.943,27.943l-66.686-2.805l53.64-53.64l82.354-12.864
		l-13.082,13.081l14.142,14.143l34.649-34.649c0.075-0.073,0.15-0.147,0.223-0.223l33.296-33.296
		c27.828-27.827,93.904-69.058,168.203-51.297C435.499,98.509,394.268,164.586,366.441,192.412z"
            fill="currentColor"
          />
          <path
            d="M105.595,386.711l-38.166,38.166c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929
		s5.119-0.977,7.071-2.929l38.166-38.166c3.905-3.905,3.905-10.237,0-14.143C115.833,382.806,109.501,382.806,105.595,386.711z"
            fill="currentColor"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </div>
  );
};

export default RocketIcon;
