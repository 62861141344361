import { useState } from "react";
import { Collapsible, DropdownBtn } from "@components/index";

const Drawer = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="drawer flex flex-col">
      <div
        className="dropdown drawerRow"
        onClick={() => setExpanded((cur) => !cur)}
      >
        <b>{title}</b>
        <DropdownBtn className="justify-end" expanded={expanded} />
      </div>
      <Collapsible expanded={expanded}>
        <div className="accordion">{children}</div>
      </Collapsible>
    </div>
  );
};

export default Drawer;
