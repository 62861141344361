import Link from "next/link";
import { useRouter } from "next/router";
import { CapLogo, LongLeftArrow } from "@icons/index";

/**
 * Nav header for book consultations
 * Shows back button always and home button only on mobile/ tablet
 */
const PseudoNav = () => {
  const router = useRouter();
  return (
    <div className="headerSection">
      <button
        className="simple back-btn flex items-center"
        onClick={() => {
          router.back();
        }}
      >
        <LongLeftArrow />
      </button>
      <div className="lg:hidden">
        <Link href="/" className="logo">

          <button className="logo">
            <CapLogo />
          </button>

        </Link>
      </div>
    </div>
  );
};

export default PseudoNav;
