import { useAppContext } from "@context/state";
import Link from "next/link";
import renderLogo from "@utils/renderLogo";
import { processUSD } from "@utils/costEstimates";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { Collapsible, DropdownBtn, RadialProgress } from "@components/index";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { getFundingGap } from "@utils/calcHowToPay";
import { getMonthlyRepayment, getTotalRepayment } from "@utils/costEstimates";

const HowToPaySummary = ({ offerData, watchInt }) => {
  const [expanded, setExpanded] = useState(false);

  const { scenario } = useAppContext();
  const { school, tuition_budget_sandbox } = offerData;
  const {
    school_id,
    name,
    logo,
    average_monthly_take_home,
    annual_interest_rate,
  } = school;
  const { percent_funded = 0 } = tuition_budget_sandbox ?? {};

  const major = _.get(scenario, "onboarding.student.intended_major_object");

  const {
    formState: { isSubmitting, dirtyFields },
  } = useFormContext();

  return (
    <div className="how-to-pay-summary flex flex-col items-center md:flex-row md:justify-between">
      <div className="school-summary relative flex flex-col xl:flex-row justify-between">
        <div className="school-heading m-3 sm:mb-1 xl:mb-3 xl:w-4/12">
          <div className="flex flex-col justify-start items-start">
            <div className="flex flex-row justify-between items-start pr-3">
              {renderLogo(logo, name)}
            </div>
            <div className="h4">
              <Link
                href={`/case/${scenario.case_id}/school/${[school_id]}`}
                className="underline inherit">
                {name}
              </Link>
            </div>
          </div>
          <div className="estimates flex flex-row items-center w-full mt-1 sm:hidden">
            <div className="flex flex-col w-1/2">
              <RadialProgress
                progressPercent={percent_funded}
                textContent="Funded"
              />
            </div>
            <div className="right-side flex flex-row w-1/2 pl-2">
              <div
                className="flex flex-row cursor-pointer"
                onClick={() => setExpanded((cur) => !cur)}
              >
                <div className="flex flex-col">
                  <small className="year-one-loans font-demi-bold">
                    Year 1 Loans
                  </small>
                  <div>
                    {processUSD(
                      getFundingGap(watchInt) < 0 ? 0 : getFundingGap(watchInt)
                    )}
                  </div>
                </div>
                <DropdownBtn
                  className="justify-end p-1.5 -mr-1.5"
                  expanded={expanded}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="vertical-divider hidden xl:block" />
        <div className="flex flex-col sm:hidden">
          <Collapsible expanded={expanded}>
            <div className="accordion">
              <div className="estimates flex flex-col p-3 pt-0">
                <small className="title font-demi-bold mb-1">
                  Total Repayment for 10-year plan
                </small>
                <div className="flex flex-row w-full mb-2">
                  <div className="flex flex-col w-1/2">
                    <small className="mb-0.5">Full Amount</small>
                    <div>
                      {processUSD(
                        getTotalRepayment(watchInt, annual_interest_rate, 10)
                      )}
                    </div>
                  </div>
                  <div className="right-side flex flex-col w-1/2 pl-2">
                    <small className="mb-0.5">Per Month</small>
                    <div>
                      {processUSD(
                        getMonthlyRepayment(watchInt, annual_interest_rate, 10),
                        true
                      )}
                    </div>
                  </div>
                </div>
                <small className="title font-demi-bold mb-1">
                  Total Repayment for 25-year plan
                </small>
                <div className="flex flex-row w-full mb-2">
                  <div className="flex flex-col w-1/2">
                    <small className="mb-0.5">Full Amount</small>
                    <div>
                      {processUSD(
                        getTotalRepayment(watchInt, annual_interest_rate, 25)
                      )}
                    </div>
                  </div>
                  <div className="right-side flex flex-col w-1/2 pl-2">
                    <small className="mb-0.5">Per Month</small>
                    <div>
                      {processUSD(
                        getMonthlyRepayment(watchInt, annual_interest_rate, 25),
                        true
                      )}
                    </div>
                  </div>
                </div>
                <small className="title font-demi-bold mb-1">
                  Estimated Take-Home Pay
                </small>
                <div className="flex flex-row w-full">
                  <div className="flex flex-col w-1/2">
                    <small className="mb-0.5">Major</small>
                    <div>{major?.name}</div>
                  </div>
                  <div className="right-side flex flex-col w-1/2 pl-2">
                    <small className="mb-0.5">Per Month</small>
                    <div>{processUSD(average_monthly_take_home || 0)}</div>
                  </div>
                </div>
              </div>
            </div>
          </Collapsible>
        </div>
        <div className="summary-panel hidden sm:flex flex-col justify-end p-3 pt-0 xl:pt-3 w-full">
          <div className="school-values flex flex-col sm:flex-row justify-between items-start md:h-2/3">
            <div className="flex flex-col w-1/4 mr-2">
              <div className="value-header removeMargin">
                {processUSD(
                  getFundingGap(watchInt) < 0 ? 0 : getFundingGap(watchInt)
                )}
              </div>
              <small className="micro">
                <span className="font-demi-bold color-red">Year 1 Loans</span>{" "}
                at {name}
              </small>
            </div>
            <div className="flex flex-col w-1/4 mr-2">
              <div className="value-header removeMargin">
                {processUSD(
                  getMonthlyRepayment(watchInt, annual_interest_rate, 10),
                  true
                )}
                /mo
              </div>
              <small className="micro">Total Repayment for 10-year plan</small>
              <small className="micro font-demi-bold">
                {processUSD(
                  getTotalRepayment(watchInt, annual_interest_rate, 10)
                )}
              </small>
            </div>
            <div className="flex flex-col w-1/4 mr-2">
              <div className="value-header removeMargin">
                {processUSD(
                  getMonthlyRepayment(watchInt, annual_interest_rate, 25),
                  true
                )}
                /mo
              </div>
              <small className="micro">Total Repayment for 25-year plan</small>
              <small className="micro font-demi-bold">
                {processUSD(
                  getTotalRepayment(watchInt, annual_interest_rate, 25)
                )}
              </small>
            </div>
            <div className="flex flex-col w-1/4">
              <div className="value-header removeMargin">
                {processUSD(average_monthly_take_home || 0)}/mo
              </div>
              <small className="micro">
                Estimated Monthly Take-Home Pay for{" "}
                <span className="micro font-demi-bold">{major?.name}</span>
              </small>
            </div>
          </div>
        </div>
        <div className="control-panel flex justify-end p-3">
          {/* Only render the actual save button if the form is dirty, else clicking the button will just return to compact view of budget cards */}
          <button
            type="submit"
            disabled={
              isSubmitting ||
              (!dirtyFields["tuition_budget"] && !dirtyFields["preapprovals"])
            }
            className={"border-0 text-right small save absolute font-demi-bold"}
          >
            {isSubmitting ? (
              <ClipLoader
                size={20}
                css={{
                  borderColor: "var(--primary-color)",
                  borderBottomColor: "transparent",
                }}
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowToPaySummary;
