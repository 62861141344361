import { OUTCOMES_VIEW } from "@utils/constants";
import { useEffect, useState } from "react";
import { useAppContext } from "@context/state";
import { Drawer, DrawerRow } from "@components/index";
import { formatDollarAmount } from "@utils/costFormatter";
import { processLoanTotal } from "@utils/costEstimates";

const OutcomesDrawer = ({ outcomesData }) => {
  const {
    name,
    average_salary,
    average_salary_5,
    average_salary_10,
    affordability,
    salary_year_percentile,
    breakdowns,
  } = outcomesData;

  const { scenario, setDisplayModal, setModalView, setModalContent } =
    useAppContext();
  //NOTE: intended_major_object rather than intended_major, since we wanted to return more info related to the chosen major without breaking things that previously relied on intended_major being an integer.
  const major = scenario?.onboarding?.student?.intended_major_object;

  const openModal = () => {
    if (salary_year_percentile && name) {
      const averageSalaries = {
        average_salary,
        average_salary_5,
        average_salary_10,
      };
      const salaryData = { ...salary_year_percentile, ...averageSalaries };
      setModalContent([salaryData, name]);
      setModalView(OUTCOMES_VIEW);
      setDisplayModal(true);
    }
  };

  const [majorDrawer, setMajorDrawer] = useState(undefined);

  useEffect(() => {
    //only populate once scenario is present
    if (scenario.case_id && major) {
      setMajorDrawer(
        <div className="link" onClick={openModal}>
          {major.name}
        </div>
      );
    }
  }, [major]);

  return (
    <Drawer title="Outcomes">
      <DrawerRow
        title="Major Group"
        value={majorDrawer || "Major not chosen"}
      />
      <DrawerRow
        title="Year 1 Salary"
        value={average_salary ? formatDollarAmount(average_salary) : undefined}
      />
      <DrawerRow
        title="Year 5 Salary*"
        value={
          average_salary_5 ? formatDollarAmount(average_salary_5) : undefined
        }
      />
      <DrawerRow
        title="Year 10 Salary"
        value={
          average_salary_10 ? formatDollarAmount(average_salary_10) : undefined
        }
      />
      <DrawerRow
        title="Funding Gap (Loans)"
        // supposed to be 4 years or 1 year? Currently 4 years
        value={
          breakdowns
            ? processLoanTotal(breakdowns, scenario?.preapprovals)
            : undefined
        }
      />
      <DrawerRow title="Affordability Profile" value={affordability} />
      <small className="footnote">* Out-of-state tuition</small>
    </Drawer>
  );
};

export default OutcomesDrawer;
