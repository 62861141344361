import { processUSD } from "@utils/costEstimates";
import OfferCardField from "./OfferCardField";

/**
 * @param {object} tuitionBudget
 * @param {object} decidedDocumentJob
 * @returns Award fields on offer card
 */
const OfferCardAwards = ({ tuitionBudget, decidedDocumentJob }) => {
  /**
   * calculate value from decided document job
   * @param {array} aidAmounts
   * @param {function} filterFn
   * @returns {Number}
   */
  const calculateTotalAward = (aidAmounts, filterFn) =>
    aidAmounts?.filter(filterFn).reduce((total, cost) => total + cost.value, 0);

  //total values will likely need to be summed on FE due to potential for differing sources of values
  return (
    <>
      <div className="flex flex-col w-full mb-2">
        <div className="flex flex-col w-full mb-1">
          <h6 className="mb-0.5">Grants & Scholarships</h6>
          <h5 className="removeMargin">
            {processUSD(
              [
                tuitionBudget?.fillable_fields?.merit_scholarship_1,
                tuitionBudget?.fillable_fields?.fed_state_grants_1,
              ].reduce((acc, val) => {
                if (val !== null) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0)
            )}
          </h5>
        </div>
        <OfferCardField
          title={"Need-Based Grants"}
          tuitionBudgetValue={
            tuitionBudget?.fillable_fields?.fed_state_grants_1
          }
          capProjectionValue={0}
          decidedDocumentJobValue={calculateTotalAward(
            decidedDocumentJob?.aid_amounts,
            (aid) => aid.category === "GRANT"
          )}
        />
        <OfferCardField
          title={"Merit Scholarships"}
          tuitionBudgetValue={
            tuitionBudget?.fillable_fields?.merit_scholarship_1
          }
          capProjectionValue={0}
          decidedDocumentJobValue={calculateTotalAward(
            decidedDocumentJob?.aid_amounts,
            (aid) => aid.category === "SCHOLARSHIP"
          )}
        />
      </div>
      <div className="flex flex-col w-full mb-2">
        <div className="flex flex-col w-full mb-1">
          <h6 className="mb-0.5">Federal Loans</h6>
          <h5 className="removeMargin">
            {processUSD(
              [
                tuitionBudget?.fillable_fields?.federal_direct_student_loan_1,
                tuitionBudget?.fillable_fields?.unsubsidized_student_loan_1,
              ].reduce((acc, val) => {
                if (val !== null) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0)
            )}
          </h5>
        </div>
        <OfferCardField
          title={"Subsidized Loans"}
          tuitionBudgetValue={
            tuitionBudget?.fillable_fields?.federal_direct_student_loan_1
          }
          capProjectionValue={0}
          decidedDocumentJobValue={calculateTotalAward(
            decidedDocumentJob?.aid_amounts,
            (aid) =>
              aid.category === "LOAN" &&
              aid.sub_category === "SUBSIDIZED" &&
              aid.source === "FEDERAL"
          )}
        />
        <OfferCardField
          title={"Unsubsidized Loans"}
          tuitionBudgetValue={
            tuitionBudget?.fillable_fields?.unsubsidized_student_loan_1
          }
          capProjectionValue={0}
          decidedDocumentJobValue={calculateTotalAward(
            decidedDocumentJob?.aid_amounts,
            (aid) =>
              aid.category === "LOAN" &&
              aid.sub_category === "UNSUBSIDIZED" &&
              aid.source === "FEDERAL"
          )}
        />
      </div>
    </>
  );
};

export default OfferCardAwards;
