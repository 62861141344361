import { useAppContext } from "@context/state";
import { useEffect, useRef } from "react";

const PdfViewer = () => {
  const viewerRef = useRef();

  const { pdfViewerContent } = useAppContext();

  /**
   * This function comes from Adobe's Embed API
   * https://developer.adobe.com/document-services/docs/overview/pdf-embed-api/
   * This solution is preferred over using html embed, iframe or object tags which have poor browser support with embedding pdfs, especially on mobile
   * Users have better UX with this solution as well. Zooming, fitting the page to the screen are all easier. If desired, this also gives us the ability to track how users interact with the pdfs.
   * Since it requires an API key with a unique site address, the pdfs will not work on vercel preview links, similar to google login. Credentials exist for localhost, dev, staging, and prod addresses
   */
  const previewPdf = () => {
    const AdobeDC = window.AdobeDC;
    const adobeDCView = new AdobeDC.View({
      clientId: process.env.NEXT_PUBLIC_ADOBE_EMBED_API_KEY,
      divId: "adobe-dc-view",
    });
    const previewConfig = {
      showAnnotationTools: false,
      enableFormFilling: false,
      showDownloadPDF: true,
      embedMode: "LIGHT_BOX", //Overlays over whole window
    };
    adobeDCView.previewFile(
      {
        content: {
          location: {
            url: pdfViewerContent.pdf,
          },
        },
        metaData: { fileName: pdfViewerContent.name },
      },
      previewConfig
    );
  };

  useEffect(() => {
    //When pdf viewer state is changed, pdf viewer will activate
    if (viewerRef.current && pdfViewerContent) {
      //Check that SDK is ready
      if (window.AdobeDC) {
        previewPdf();
      }
    }
  }, [viewerRef.current, pdfViewerContent]);

  return (
    <>
      <div ref={viewerRef} id="adobe-dc-view"></div>
    </>
  );
};

export default PdfViewer;
