import { useEffect } from "react";

const Entry1 = ({
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
}) => {
  if (formStep !== 1) {
    // Don't render unless step matches
    return null;
  }

  useEffect(() => {
    trigger("user_type"); //Start with initial validation check to render proper button UI. QUESTION: Best Practice???
    setWatchFields(["user_type"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "Dependent Student",
        description: "< 24 years old and live with parent/guardian",
      },
      {
        id: 0,
        title: "Independent Student",
        description:
          "> 24 years old or are considered independent by court/state",
      },
    ]);
  }, []);

  return (
    <div className="welcomeContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <div className="welcomeText welcome flex flex-col w-full">
        <h1>Finish Creating Your Account</h1>
        <h5>
          Select the option that applies to your form situation, then press next
          to continue
        </h5>
      </div>
      <div className="inputs">
        <input
          type="radio"
          value="parent/guardian"
          id="parent/guardian"
          {...register("user_type", {
            required: {
              value: true,
              message: "Please specify your situation",
            },
          })}
        />
        <label
          htmlFor="parent/guardian"
          className="radioOption situationOption"
        >
          <span className="radioControl"></span>
          <span className="radioLabel">I am a parent/guardian</span>
        </label>
        <input
          type="radio"
          value="dependent student"
          id="dependent_student"
          {...register("user_type", {
            required: {
              value: true,
              message: "Please specify your situation",
            },
          })}
        />
        <label
          htmlFor="dependent_student"
          className="radioOption situationOption"
        >
          <span className="radioControl"></span>
          <span className="radioLabel">I am a dependent student</span>
        </label>
        <input
          type="radio"
          value="independent student"
          id="independent_student"
          {...register("user_type", {
            required: {
              value: true,
              message: "Please specify your situation",
            },
          })}
        />
        <label
          htmlFor="independent_student"
          className="radioOption situationOption"
        >
          <span className="radioControl"></span>
          <span className="radioLabel">I am an independent student</span>
        </label>
      </div>
    </div>
  );
};

export default Entry1;
