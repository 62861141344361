import { useEffect } from "react";
import _ from "lodash";
import { useAppContext } from "@context/state.js";
import { useRouter } from "next/router";
import { calculateNetCostForAllSchools } from "@api/calculateScenario";
import { LongLeftArrow } from "@icons/index";
import CAPFilter from "./CAPFilter";
import { CALCULATE_JOB_ADDED_TO_QUEUE } from "@utils/constants";

const CAPAdvancedSearch = () => {
  const router = useRouter();

  const {
    scenario,
    initiatePollingCalculations,
    setPolling,
    hasRecalculatedAllSchools,
    handleApiError,
  } = useAppContext();

  const recalculateNetCostsForAllSchools = async () => {
    try {
      const res = await calculateNetCostForAllSchools(scenario?.case_id);
      if (res?.data?.result) {
        const result = res.data.result;
        if (_.get(result, "message") === CALCULATE_JOB_ADDED_TO_QUEUE) {
          localStorage.setItem(
            "scenario_updated_at",
            _.get(result, "last_updated_at")
          );
          initiatePollingCalculations();
        }
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    if (scenario?.case_id) {
      //Only recalculate if scenario has been updated since the last time recalculate all has been run or on a new session.
      if (!hasRecalculatedAllSchools) {
        //set polling state for UI to stop form being submitted until calculations are up to date
        setPolling(true);
        //recalculate net costs
        recalculateNetCostsForAllSchools();
      }
    }
  }, [scenario.case_id]);

  return (
    <div className="CAPSearchContainer flex flex-col items-center w-full">
      <div className="nav w-full flex flex-row items-center justify-between">
        <button
          className="w-3"
          onClick={() => {
            router.push("/school_search");
          }}
        >
          <LongLeftArrow />
        </button>
        <b>CAP Advanced Search</b>
        <div className="emptySpace" />
      </div>
      <CAPFilter />
    </div>
  );
};

export default CAPAdvancedSearch;
