import axiosInstance from "@api/axiosInstance";

//******************************************//
//********** ----- SSO ----- ***********//
//******************************************//

/**
 * Method to get full url to link to
 * @param {string} path - path to redirect to such as for private scholarships
 * @returns: full url
 */
const getSSOToken = async (path) => {
  const res = await axiosInstance.get(path);
  return res.data;
};

/**
 * Method to redirect to an SSO path
 * @param {string} path - path to redirect to such as for private scholarships
 */
const ssoRedirect = async (path) => {
  /**
   * To avoid popup blockers on certain browsers for async
   * click events, we need to create an instance of window before a
   * async call and then assign a path to it on successful response.
   */
  const windowRef = window.open();
  try {
    const link = await getSSOToken(path);
    windowRef.location.href = link;
  } catch (error) {
    windowRef.close();
    console.warn(error.response);
  }
};

export { getSSOToken, ssoRedirect };
