import { useFormContext } from "react-hook-form";
import { Checkmark } from "@icons/index";
import _ from "lodash";

const CheckboxField = ({ input }) => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label className="checkbox">
        <input
          defaultChecked={input.value}
          type="checkbox"
          id={input.mapId}
          // ref={checkboxRef}
          {...register(input.mapId, {
            ...input.validation,
          })}
          disabled={input.disabled}
        />
        <span className="checkmark">
          <Checkmark />
        </span>
      </label>
    </>
  );
};

export default CheckboxField;
