import { Section } from "@components/index";
import SsoCard from "./SsoCard";
import { FAFSA_VIDEO, CSS_VIDEO } from "@utils/constants";

/**
 * Section for holding fafsa and css cards. Found on the dashboard.
 * //TODO: Replace with svgs or pngs with transparency to work with background colors other than white
 */
const FafsaCss = () => {
  return (
    <div className="apply-for-aid-container">
      <Section title={"FAFSA & CSS Profile"} galleryName={"fafsa-gallery"}>
        <SsoCard
          logo={"/images/fafsa_logo.jpeg"}
          description={
            "Line by Line FAFSA Tutorial with a CAP financial aid expert"
          }
          url={FAFSA_VIDEO}
          logoWidth={305}
          logoHeight={165}
        />
        <SsoCard
          logo={"/images/css_profile.png"}
          description={
            "Line by Line CSS Profile Tutorial with a CAP financial aid expert"
          }
          url={CSS_VIDEO}
          logoWidth={1589}
          logoHeight={469}
        />
      </Section>
    </div>
  );
};

export default FafsaCss;
