import _ from "lodash";
import { YES, NO } from "@utils/constants";
import { formatDollarAmount } from "./costFormatter";
import {
  maritalStatuses,
  marriedOptions,
  unmarriedOptions,
  hasSpouse,
  hasExSpouse,
  isFilingJointly,
  isFilingSeparately,
} from "@utils/maritalStatusCheck";
import { getKeyValueFromObject } from "@utils/getValueForDisplay";
import { infoTips } from "./infoTips";
import { getYears } from "./dateFormatter";
import moment from "moment";

export const initializeGetDirtyOrOriginalField =
  ({ dirtyFieldValues, onboarding } = {}) =>
  (path) => {
    const dirtyField = _.get(dirtyFieldValues, path);
    if (dirtyField === null || dirtyField === undefined) {
      return _.get(onboarding, path);
    }
    return dirtyField;
  };

/**
 * Method that returns parent/guardian information from the onboarding object.
 * Information contains all parent/guardian related information like
 * gross_earning, spouse, ex_spouse, is_homeowner.
 * @param {object} onboarding - object received from user scenario that provides
 * all the onboarding information for a user (student object and parent/guardian object).
 */
/**
 * Returned array contains student information that can be used
 * to display or edit parent/guardian related data.
 * @param {string} label - Label that describes what the value is about.
 * @param {string} mapId - Id against which we search student info in
 * an object. Also use this key value to register a field in react hook
 * form if we want to edit this value.
 * @param {string} type - Defines which type of input field to render.
 * @param {object} validation - Defines a validation, if we need to pass it to
 * react hook form for that field.
 * @param {array} selectOptions - If input is a type of dropdown than this key
 * returns all the options that can be selected for that dropdown.
 * @param {string} dependentMapId - MapId for a field to which this field is
 * dependent upon.
 * @param {function} getSiblingsDataFields - Validates if siblings exists and
 * return fields for each sibling
 * @param {boolean} validateRenderCondition - Condition that tells if a field should
 * be rendered or not in UI.
 */
// TODO rename to getParentGuardianInfo
export const getParentGuardianInfo = (onboarding = {}, dirtyFieldValues) => {
  const getDirtyOrOriginalField = initializeGetDirtyOrOriginalField({
    dirtyFieldValues,
    onboarding,
  });
  const hasSpouseResult = hasSpouse(
    getDirtyOrOriginalField("parent_guardian.marital_status")
  );

  const hasExSpouseResult = hasExSpouse(
    getDirtyOrOriginalField("parent_guardian.marital_status")
  );

  const isFilingJointlyResult = isFilingJointly(
    getDirtyOrOriginalField("parent_guardian.filing_status"),
    getDirtyOrOriginalField("parent_guardian.marital_status")
  );

  const isFilingSeparatelyResult = isFilingSeparately(
    getDirtyOrOriginalField("parent_guardian.filing_status")
  );

  const filingStatusOptions = hasSpouseResult
    ? marriedOptions
    : unmarriedOptions;

  const isSelfEmployed =
    getDirtyOrOriginalField("parent_guardian.self_employed") === YES;

  const parentInformationArray = [
    {
      label: "Marital Status",
      mapId: "parent_guardian.marital_status",
      type: "dropdown",
      selectOptions: maritalStatuses
        .filter((value) => value !== "-")
        .map(optionToBothLabelAndValue),
    },
    {
      label: "Parent Birth Year",
      mapId: "parent_guardian.birth_date",
      type: "dropdown",
      selectOptions: getYears(
        moment().subtract(120, "years").get("year"),
        moment().subtract(25, "years").get("year")
      ).map((year) => {
        return { label: `${year}`, value: year };
      }),
    },
  ];

  if (hasSpouseResult) {
    parentInformationArray.push({
      label: "Spouse Birth Year",
      mapId: "parent_guardian.birth_date_2",
      type: "dropdown",
      selectOptions: getYears(
        moment().subtract(120, "years").get("year"),
        moment().subtract(25, "years").get("year")
      ).map((year) => {
        return { label: `${year}`, value: year };
      }),
    });
  }

  if (hasExSpouseResult) {
    parentInformationArray.push({
      label: "Ex-Spouse Birth Year",
      mapId: "parent_guardian.birth_date_3",
      type: "dropdown",
      selectOptions: getYears(
        moment().subtract(120, "years").get("year"),
        moment().subtract(25, "years").get("year")
      ).map((year) => {
        return { label: `${year}`, value: year };
      }),
    });
  }

  parentInformationArray.push({
    label: "Last Year's Tax Return Filing Status",
    mapId: "parent_guardian.filing_status",
    type: "dropdown",
    selectOptions: filingStatusOptions
      .filter((val) => val !== "-")
      .map(optionToBothLabelAndValue),
  });

  parentInformationArray.push(
    yesNoField({
      label: "Parent/Guardian Self-Employed",
      path: "parent_guardian.self_employed",
    })
  );

  if (isSelfEmployed) {
    parentInformationArray.push(
      dollarField({
        label: "Parent/Guardian's Estimated Business Value",
        path: "parent_guardian.business_equity",
        onboarding,
      })
    );
  }

  if (hasSpouseResult) {
    parentInformationArray.push(
      yesNoField({
        label: "Spouse Self-Employed",
        path: "parent_guardian.self_employed_2",
      })
    );

    // maybe put in above conditional
    // is spouse self employed
    if (getDirtyOrOriginalField("parent_guardian.self_employed_2") === YES) {
      parentInformationArray.push(
        dollarField({
          label: "Spouse's Estimated Business Value",
          path: "parent_guardian.business_equity_2",
          onboarding,
        })
      );
    }
  }

  if (hasExSpouseResult) {
    parentInformationArray.push(
      yesNoField({
        label: "Ex-Spouse Self-Employed",
        path: "parent_guardian.self_employed_3",
      })
    );

    // is ex spouse self employed
    if (getDirtyOrOriginalField("parent_guardian.self_employed_3") === YES) {
      parentInformationArray.push(
        dollarField({
          label: "Ex-Spouse's Estimated Business Value",
          path: "parent_guardian.business_equity_3",
          onboarding,
        })
      );
    }
  }

  parentInformationArray.push(
    grossEarningsField({
      onboarding,
      label: "Parent/Guardian Gross Earnings",
      subPath: "parent_guardian.parent_earnings",
    })
  );

  if (!isFilingJointlyResult) {
    parentInformationArray.push(
      adjustedGrossIncomeField({
        onboarding,
        agi_user_provided: onboarding?.parent_guardian?.agi_user_provided,
        labelPrefix: "Parent/Guardian",
        onboarding_agi_path: "parent_guardian.agi",
      })
    );
  }

  if (hasSpouseResult) {
    parentInformationArray.push(
      grossEarningsField({
        onboarding,
        label: "Spouse Gross Earnings",
        subPath: "parent_guardian.parent_earnings_2",
      })
    );
  }

  //Need render condition based on filing status, use agi_2 if married filing jointly
  if (isFilingJointlyResult) {
    parentInformationArray.push(
      adjustedGrossIncomeField({
        onboarding,
        agi_user_provided: onboarding?.parent_guardian?.agi_user_provided_2,
        labelPrefix: "Parent/Guardian",
        onboarding_agi_path: "parent_guardian.agi_2",
      })
    );
  }

  //Need render condition based on filing status, use agi_2 if married filing jointly
  if (isFilingSeparatelyResult) {
    parentInformationArray.push(
      adjustedGrossIncomeField({
        onboarding,
        agi_user_provided: onboarding?.parent_guardian?.agi_user_provided_2,
        labelPrefix: "Spouse",
        onboarding_agi_path: "parent_guardian.agi_2",
      })
    );
  }

  if (hasExSpouseResult) {
    parentInformationArray.push(
      grossEarningsField({
        onboarding,
        label: "Ex-Spouse Gross Earnings",
        subPath: "parent_guardian.parent_earnings_3",
      })
    );

    parentInformationArray.push(
      adjustedGrossIncomeField({
        onboarding,
        agi_user_provided: onboarding?.parent_guardian?.agi_user_provided_3,
        labelPrefix: "Ex-Spouse",
        onboarding_agi_path: "parent_guardian.agi_3",
      })
    );
  }

  if (!isFilingJointlyResult) {
    parentInformationArray.push(
      dollarField({
        label: "Parent/Guardian's Household Income Taxes Paid",
        path: "parent_guardian.income_tax_paid",
        onboarding,
      })
    );
  }

  if (isFilingJointlyResult) {
    parentInformationArray.push(
      dollarField({
        label: "Parent/Guardian's Household Income Taxes Paid",
        path: "parent_guardian.income_tax_paid_2",
        onboarding,
      })
    );
  }

  //Need render condition based on filing status, use agi_2 if married filing jointly
  if (isFilingSeparatelyResult) {
    parentInformationArray.push(
      dollarField({
        label: "Spouse's Household Income Taxes Paid",
        path: "parent_guardian.income_tax_paid_2",
        onboarding,
      })
    );
  }

  if (hasExSpouseResult) {
    parentInformationArray.push(
      dollarField({
        label: "Ex-Spouse's Household Income Taxes Paid",
        path: "parent_guardian.income_tax_paid_3",
        onboarding,
      })
    );
  }

  parentInformationArray.push(
    dollarField({
      label: "Parent/Guardian's Non-Retirement Savings/Investments",
      path: "parent_guardian.investments",
      onboarding,
    })
  );

  if (isFilingSeparatelyResult) {
    parentInformationArray.push(
      dollarField({
        label: "Spouse's Non-Retirement Savings/Investments",
        path: "parent_guardian.investments_2",
        onboarding,
      })
    );
  }

  if (hasExSpouseResult) {
    parentInformationArray.push(
      dollarField({
        label: "Ex-Spouse's Non-Retirement Savings/Investments",
        path: "parent_guardian.investments_3",
        onboarding,
      })
    );
  }

  parentInformationArray.push(
    yesNoField({
      label: "Parent/Guardian Own a Home?",
      path: "parent_guardian.is_homeowner",
      onboarding,
      displayValue: true,
    })
  );

  if (getDirtyOrOriginalField("parent_guardian.is_homeowner") === YES) {
    parentInformationArray.push(
      dollarField({
        label: "Home Equity",
        path: "parent_guardian.home_equity",
        onboarding,
      })
    );
  }

  if (hasExSpouseResult) {
    parentInformationArray.push(
      yesNoField({
        label: "Ex-Spouse Own a Home?",
        path: "parent_guardian.is_ex_spouse_homeowner",
        onboarding,
        displayValue: true,
      })
    );
  }

  if (
    getDirtyOrOriginalField("parent_guardian.is_ex_spouse_homeowner") === YES
  ) {
    parentInformationArray.push(
      dollarField({
        label: "Ex-Spouse's Home Equity",
        path: "parent_guardian.home_equity_3",
        onboarding,
      })
    );
  }

  if (!isFilingJointlyResult) {
    parentInformationArray.push(
      dollarField({
        label: "Parent/Guardian's Pre-Tax Retirement Contributions",
        path: "parent_guardian.retirement_payments",
        onboarding,
      })
    );
  }

  if (isFilingSeparatelyResult) {
    parentInformationArray.push(
      dollarField({
        label: "Spouse's Pre-Tax Retirement Contributions",
        path: "parent_guardian.retirement_payments_2",
        onboarding,
      })
    );
  }

  if (isFilingJointlyResult) {
    parentInformationArray.push(
      dollarField({
        label: "Parent/Guardian's Pre-Tax Retirement Contributions",
        path: "parent_guardian.retirement_payments_2",
        onboarding,
      })
    );
  }

  if (hasExSpouseResult) {
    parentInformationArray.push(
      dollarField({
        label: "Ex-Spouse's Pre-Tax Retirement Contributions",
        path: "parent_guardian.retirement_payments_3",
        onboarding,
      })
    );
  }

  if (!isFilingJointlyResult) {
    parentInformationArray.push(
      dollarField({
        label: "Parent/Guardian's Child Support Received",
        path: "parent_guardian.child_support",
        onboarding,
      })
    );
  }

  if (isFilingSeparatelyResult) {
    parentInformationArray.push(
      dollarField({
        label: "Spouse's Child Support Received",
        path: "parent_guardian.child_support_2",
        onboarding,
      })
    );
  }

  if (isFilingJointlyResult) {
    parentInformationArray.push(
      dollarField({
        label: "Parent/Guardian's Child Support Received",
        path: "parent_guardian.child_support_2",
        onboarding,
      })
    );
  }

  if (hasExSpouseResult) {
    parentInformationArray.push(
      dollarField({
        label: "Ex-Spouse's Child Support Received",
        path: "parent_guardian.child_support_3",
        onboarding,
      })
    );
  }

  /**
   * Assign value to each object, extracted from onboarding information.
   */
  return parentInformationArray.map((parentInput) => {
    return {
      ...parentInput,
      value: getKeyValueFromObject(onboarding, parentInput.mapId, ""),
      tipsData: _.get(infoTips, `scenario.onboarding.${parentInput.mapId}`),
    };
  });
};

const optionToBothLabelAndValue = (option) => ({
  label: option,
  value: option,
});

const yesNoField = ({ label, path, onboarding, displayValue } = {}) => ({
  label,
  mapId: path,
  type: "dropdown",
  selectOptions: [YES, NO].map(optionToBothLabelAndValue),
  displayValue: displayValue
    ? getKeyValueFromObject(onboarding, path)
    : undefined,
});

const dollarField = ({ label, path, onboarding } = {}) => ({
  label,
  mapId: path,
  type: "text",
  displayValue: formatDollarAmount(
    Number(getKeyValueFromObject(onboarding, path))
  ),
  amount: true,
});

export const adjustedGrossIncomeField = ({
  onboarding,
  agi_user_provided,
  labelPrefix,
  onboarding_agi_path,
} = {}) => {
  const displayValue = agi_user_provided
    ? formatDollarAmount(
        Number(getKeyValueFromObject(onboarding, onboarding_agi_path))
      )
    : "--";

  return {
    label: `${labelPrefix}'s Adjusted Gross Income`,
    editLabel: `${labelPrefix}'s Adjusted Gross Income`,
    mapId: onboarding_agi_path,
    type: "text",
    dontRenderValue: !agi_user_provided,
    displayValue,
    amount: true,
  };
};

export const grossEarningsField = ({ onboarding, label, subPath }) => {
  return {
    label,
    mapId: subPath,
    type: "text",
    displayValue: formatDollarAmount(
      Number(getKeyValueFromObject(onboarding, subPath))
    ),
    amount: true,
  };
};
