import { useRef } from "react";

/**
 * @param {number} progressPercent
 * @returns A pill shaped progress graphic with color changing as percent increases
 */
const PillProgress = ({ progressPercent }) => {
  const valueRef = useRef();
  const pillRef = useRef();

  const calcAlphaChannel = (percent, lowerLimit, upperLimit) => {
    const inversePercent = percent - lowerLimit;
    const multiplier = 100 / (upperLimit - lowerLimit);
    const alpha = inversePercent > 0 ? (inversePercent * multiplier) / 100 : 0;
    return alpha;
  };

  return (
    <div
      className="pill-progress flex justify-center items-center micro whitespace-nowrap"
      style={{
        background: `linear-gradient(rgba(176, 224, 171, ${calcAlphaChannel(
          progressPercent,
          80,
          100
        )}), rgba(176, 224, 171, ${calcAlphaChannel(
          progressPercent,
          80,
          100
        )})),
    linear-gradient(rgba(248, 230, 149, ${calcAlphaChannel(
      progressPercent,
      40,
      65
    )}), rgba(248, 230, 149, ${calcAlphaChannel(progressPercent, 40, 65)})),
    linear-gradient(rgba(231, 142, 139, 1), rgba(231, 142, 139, 1))`,
      }}
      ref={pillRef}
      // onClick={() => {
      //   valueRef.current.innerHTML = "0%";
      //   animateValue(valueRef.current, progressPercent, 10000);
      // }}
    >
      <small ref={valueRef}>{progressPercent + "%"}</small>
      <small>&nbsp;Complete</small>
    </div>
  );
};

export default PillProgress;
