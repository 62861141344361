import SchoolCard from "./SchoolCard.js";

const SchoolList = ({ curatedSearchList }) => {
  const renderSchools = () => {
    return curatedSearchList?.map((school) => (
      <SchoolCard
        key={school.school_id || school.college_id}
        schoolData={school}
      />
    ));
  };

  return (
    <div className="schoolList flex flex-col justify-center">
      <div className="results flex flex-row justify-between w-full md:justify-start">
        <small className="md:w-4/12 md:pl-2">
          {curatedSearchList.length} Search Result
          {curatedSearchList.length !== 1 && "s"}
        </small>
        <small className="hidden md:block md:whitespace-nowrap md:pl-3 md:w-2/12">
          Forbes Ranking
        </small>
        <small className="hidden md:block md:whitespace-nowrap md:pl-3 md:w-2/12">
          Sticker Price
        </small>
        <small className="hidden md:block md:whitespace-nowrap md:w-2/12">
          Your Net Cost (Year 1)
        </small>
        <small className="md:text-right md:whitespace-nowrap md:w-2/12">
          Add School&nbsp;&nbsp;
        </small>
      </div>
      <div className="curatedGallery flex flex-col flex-nowrap w-full">
        {renderSchools()}
      </div>
    </div>
  );
};

export default SchoolList;
