import { useFormContext } from "react-hook-form";
import { convertFileToBase64 } from "@utils/convertFileToBase64";
import { DocumentFile, Cross } from "@icons/index";
import { getFormattedDate } from "@utils/dateFormatter";
import { useState } from "react";
import _ from "lodash";

/**
 * @param {string} fileInputId (required) - used for registering the file input
 * @param {string} fileUrlInputId (required) - used for registering the input that holds the base64 string
 * @param {string} fileUrl - provide the default base64 string from the database
 * @param {string} fileNameInputId (required) - used for registering the input that holds the file name
 * @param {string} fileName - provide the default file name from the database
 * @param {string} fileTypeInputId (required) - used for registering the input that holds the file type
 * @param {string} fileType - provide the default file type from the database
 * @param {string} fileSizeInputId (required) - used for registering the input that holds the file size
 * @param {string} fileSize - provide the default file size from the database
 * @param {string} fileDateInputId (required) - used for registering the input that holds the file date
 * @param {string} fileDate - provide the default file date from the database
 * @returns File upload input
 */
const FileUpload = ({
  fileInputId,
  fileUrlInputId,
  fileUrl,
  fileNameInputId,
  fileName,
  fileTypeInputId,
  fileType,
  fileSizeInputId,
  fileSize,
  fileDateInputId,
  fileDate,
  setFileChanged,
  fileExists,
  setFileExists,
}) => {
  const [offerImage, setOfferImage] = useState(fileUrl);
  const {
    register,
    watch,
    setValue,
    getValues,
    setError,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  /**
   * Run validations on file and set errors if not valid
   * Parse the file into separate fields to keep information for base64 converted files.
   * Convert the file to base64 in order to use for displaying thumbnail.
   * @param {array} files - raw files array from file upload input
   */
  const handleFileUpload = async (files) => {
    if (files && files.length) {
      try {
        const uploadedFile = files[0];
        //Run validations on the file
        validateFile(uploadedFile);
        //convert the file to base64 for using as the thumbnail
        const uploadedImageBase64 = await convertFileToBase64(uploadedFile);
        //set data string to input value
        setValue(fileUrlInputId, uploadedImageBase64);
        setOfferImage(uploadedImageBase64);
        //set file name to input value
        setValue(fileNameInputId, uploadedFile.name);
        //set file name to input value
        setValue(fileTypeInputId, uploadedFile.type);
        //set file size to input value
        setValue(fileSizeInputId, uploadedFile.size);
        //set file date to input value
        const date = getFormattedDate(new Date(uploadedFile.lastModified));
        setValue(fileDateInputId, date);
        setFileExists(true);
        setFileChanged(true);
      } catch (error) {
        //clear value from input
        resetFileInputs();
        //set the error thrown using react-hook-form
        setError(fileInputId, {
          type: "manual",
          message: error.message,
        });
      }
    }
  };

  /**
   * Checks the type and size of the file and throws an error if not valid
   * @param {*} file - raw file from file input
   */
  const validateFile = (file) => {
    //check file type and throw error if not valid file type
    const validTypes = ["application/pdf", "image/png", "image/jpeg"];
    if (!validTypes.includes(file.type)) {
      throw { message: `Error: Invalid file type (${file.type}).` };
    }
    //check that file is less than 10MB
    const maxFileSize = 10485760;
    if (file.size > maxFileSize) {
      throw {
        message: `Error: Your file is too large (${getFileSize(
          file.size
        )}). The maximum size allowed is ${getFileSize(maxFileSize)}.`,
      };
    }
  };

  /**
   * Opens the uploaded image in a new tab. Accomodates pdf and image types
   * @param {base64 string} file - base64 encoded file
   * @param {string} name - name of file
   * @param {string} type - type of file
   */
  const openInNewTab = (file, name, type) => {
    const newTab = window.open();
    newTab.document.body.style = "margin: 0;";
    newTab.document.body.innerHTML = `<embed
              width="100%"
              height="100%"
              style="object-fit: contain;"
              type="${type}"
              src="${file}"
              alt="${name}"
            ></embed>`;
  };

  /**
   * Format the file size to be readable by humans
   * @param {integer} fileSize
   * @returns string that shows the size of the user's file
   */
  const getFileSize = (fileSize) => {
    const bytes = fileSize || 0;
    //return size in MB or KB based on size
    return bytes > 1024 * 1024
      ? `${(bytes / (1024 * 1024)).toFixed(1)}MB`
      : `${(bytes / 1024).toFixed(0)}KB`;
  };

  /**
   * Manually reset values for inputs related to file
   */
  const resetFileInputs = () => {
    setValue(fileUrlInputId, "");
    setValue(fileNameInputId, "");
    setValue(fileTypeInputId, "");
    setValue(fileDateInputId, "");
    setValue(fileSizeInputId, "");
    setValue(fileInputId, "");
  };

  return (
    <div className="flex flex-col">
      <input
        type="hidden"
        id={fileUrlInputId}
        value={fileUrl}
        {...register(fileUrlInputId)}
      ></input>
      <input
        type="hidden"
        id={fileNameInputId}
        value={fileName || "No file chosen"}
        {...register(fileNameInputId)}
      ></input>
      <input
        type="hidden"
        id={fileTypeInputId}
        value={fileType}
        {...register(fileTypeInputId)}
      ></input>
      <input
        type="hidden"
        id={fileSizeInputId}
        value={fileSize}
        {...register(fileSizeInputId)}
      ></input>
      <input
        type="hidden"
        id={fileDateInputId}
        value={fileDate}
        {...register(fileDateInputId)}
      ></input>
      <div
        className={`relative flex flex-row justify-center items-center mb-6 mt-2 ${
          !fileExists ? "hidden" : ""
        }`}
      >
        <div className="h-full self-start">
          <button
            type="button"
            className="remove tertiary"
            onClick={() => {
              resetFileInputs();
              setFileExists(false);
              //If there is an initial file, set file changed to true
              if (fileUrl) {
                setFileChanged(true);
              }
            }}
          >
            <Cross />
          </button>
        </div>
        <div
          className="relative flex justify-center w-12 pointer-cursor"
          onClick={() =>
            openInNewTab(
              offerImage,
              getValues(fileNameInputId),
              getValues(fileTypeInputId)
            )
          }
        >
          {offerImage ? (
            <div className="offer-file pointer-events-none">
              <embed
                width="100%"
                height="100%"
                type={getValues(fileTypeInputId)}
                src={offerImage}
                alt={getValues(fileNameInputId)}
                className="pointer-events-none"
              ></embed>
              <div className="absolute w-full h-full top-0" />
            </div>
          ) : (
            <>
              <DocumentFile />
              <div className="absolute top-0 text-center pt-5 w-6">
                Your offer letter
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col p-2">
          <label htmlFor={fileNameInputId} className="pb-1">
            {watch(fileNameInputId)}
          </label>
          <label htmlFor={fileDateInputId} className="pb-0">
            {watch(fileDateInputId)}
          </label>
          <label htmlFor={fileSizeInputId} className="pb-0">
            {getFileSize(watch(fileSizeInputId))}
          </label>
        </div>
      </div>
      <div
        className={`upload-offer flex flex-col items-center mb-1 pb-2 ${
          (_.get(errors, fileInputId) || _.get(errors, fileUrlInputId)) &&
          "upload-error-state"
        } ${fileExists ? "hidden" : ""}`}
        onChange={(event) => {
          //handle file selected
          handleFileUpload(event.target.files);
        }}
        onDrop={(event) => {
          //prevent browser default behavior if drag and dropping
          event.preventDefault();
          //handle files dropped
          handleFileUpload(event.dataTransfer.files);
        }}
        onDragOver={(event) => {
          //prevent browser default behavior if drag and dropping
          event.preventDefault();
        }}
        onClick={() => {
          //reset error state
          clearErrors([
            fileUrlInputId,
            fileNameInputId,
            fileTypeInputId,
            fileDateInputId,
            fileSizeInputId,
            fileInputId,
          ]);
        }}
      >
        <span className="h4 py-3 removeMargin">
          Upload your offer letter here
        </span>
        <input
          type="file"
          accept=".pdf,.png,.jpg,.jpeg"
          id="files"
          {...register(fileInputId)}
          className="hidden"
        ></input>
        <button type="button" className="tertiary py-1 mb-2">
          <label htmlFor="files" className="file-upload-button py-1 px-3">
            Select file
          </label>
        </button>
        <div className="upload-limits flex flex-col">
          <small>Supported file types: pdf, jpg, png</small>
          <small>Max file size: 5MB</small>
        </div>
        {_.get(errors, fileInputId) && (
          <small className="errorMessage px-8">
            {_.get(errors, fileInputId).message}
          </small>
        )}
        {_.get(errors, fileUrlInputId) && (
          <small className="errorMessage px-8">
            {_.get(errors, fileUrlInputId).message}
          </small>
        )}
      </div>
      {!fileExists && (
        <div className="micro flex flex-col text-center mb-5">
          <span className="mb-0.5">Offers can be confusing!</span>
          <span>
            Upload your offer so{" "}
            {process.env.NEXT_PUBLIC_WHITELABEL === "true"
              ? "our team"
              : "a MyCAP expert"}{" "}
            can verify the details are correct.
          </span>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
