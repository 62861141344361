import _ from "lodash";
import { useEffect } from "react";
import ProgressBar from "../ProgressBar.js";
import NumberFormat from "react-number-format";

const Step14 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
  setValue,
  getValues,
}) => {
  if (formStep !== 16) {
    // Don't render unless step matches
    return null;
  }

  useEffect(() => {
    trigger("parent_guardian.parent_pledged_assets");
    setWatchFields(["parent_guardian"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "",
        description: (
          <span>
            Take your best guess. In addition to your savings, include monthly
            cash-flow that can be paid directly towards college, contributions
            from friends, relatives and the student. <b>DO NOT</b> include what
            you or the student plan to borrow.
          </span>
        ),
      },
    ]);
  }, []);

  return (
    <div className="guardianInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep - 1} />
      <div className="guardianInfoText">
        <h5>
          How much can your family realistically afford to pay per year for
          college for {_.get(formData, "student.student_name") || "Student"}?
        </h5>
      </div>
      <div className="inputs flex flex-col text-left">
        <label
          htmlFor="parent_guardian.parent_pledged_assets"
          className="textLabel currencyLabel small"
        >
          <span className="labelFocus">Enter amount</span>
          <NumberFormat
            value={getValues("parent_guardian.parent_pledged_assets")}
            {...register("parent_guardian.parent_pledged_assets", {
              required: {
                value: true,
                message: "Please enter your college budget",
              },
            })}
            onValueChange={({ value }) => {
              setValue("parent_guardian.parent_pledged_assets", value);
              trigger("parent_guardian.parent_pledged_assets");
            }}
            thousandSeparator={true}
          />
          <div className="currency resetFont">$</div>
        </label>
      </div>
    </div>
  );
};

export default Step14;
