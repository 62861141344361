import axiosInstance from "@api/axiosInstance";

//******************************************//
//************ ----- USER ----- ************//
//******************************************//

/**
 * get user data, including case_id, Onboarding data, EFC data, Preapprovals data
 * Endpoint for getting user
 * @params - none, but does require a valid jwt token present in localStorage
 * returns: user user, onboarding, efcs, preapprovals
 */
export const getUser = async () => {
  const res = await axiosInstance.get(`/me`);
  return res;
};

/**
 * update first_name, last_name, walkthrough_video_played
 * Endpoint for updating user
 * @params {string} userId
 * @params {object} params
 * returns: user
 */
export const updateUser = async (userId, params) => {
  const res = await axiosInstance.put(`/user/${userId}`, params);
  return res;
};

/**
 * Subscribe to a payment plan
 * Endpoint subscribing to a new payment plan. Only allows upgrading, not downgrading.
 * @param {string} hostedPageId-
    comes from success callback on chargebee checkout
 */
export const setSubscriptionInfoWithHostedPageId = async (hostedPageId) => {
  const res = await axiosInstance.post(`/me/subscribe-hosted-page`, {
    hosted_page_id: hostedPageId,
  });
  return res;
};

/**
 * Create a chargebee portal session
 * @param {string} userId
 */
export const createChargebeePortalSession = async (userId) => {
  const res = await axiosInstance.post(`/user/${userId}/chargebee-portal`);
  return res;
};

/**
 * Schedule a renewal for end of term
 * @param {string} userId
 */
export const scheduleRenewal = async (userId) => {
  const res = await axiosInstance.post(`/user/${userId}/subscriptions/renewal`);
  return res;
};

/**
 * Update personal information for a user (onbaording data)
 * @param {number} case_id - Id of a user
 * @param {object} personal_info - personal information object containg data values
 *  student and parent e.g. student_name, gross_income, siblings, is_homeowner e.t.c.
 */
export const updatePersonalInfo = async (case_id, personal_info) => {
  const res = await axiosInstance.put(`/case/${case_id}`, {
    onboarding: {
      ...personal_info,
    },
  });
  return res;
};

/**
 * Update personal information for a user (preapproval data)
 * @param {number} case_id - Id of a user
 * @param {object} preapproval_data - preapprovals object containg data values
 * funds_in_529, monthly_529_funds, monthly_529_months, parent_pledged_assets, parent_pledged_monthly, student_pledged_monthly, other_help,
 */
export const updatePreApprovals = async (case_id, preapproval_data) => {
  const res = await axiosInstance.put(`/case/${case_id}/update-preapprovals`, {
    preapprovals: {
      ...preapproval_data,
    },
  });
  return res;
};
