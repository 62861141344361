import _ from "lodash";
import { MagnifyingGlass } from "@icons/index";
import { useEffect, useState, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { SearchItem } from "@components/index";
import { checkClickOutside } from "@utils/clickOutside";
import { getHighSchools } from "@api/misc";
import { useAppContext } from "@context/state.js";
import { SEARCH_ACCEPTABLE_CHARS_REGEXP } from "@utils/constants";
import ClipLoader from "react-spinners/ClipLoader";

/**
 * HighSchoolSearchInput
 * @param {boolean} primaryStyle - if primary style is true, extra flourishes such as the magnifying glass and cancel button show.
 * @param {object} input -
 * @param {function} chooseSchool - pass the function for what to do when a user clicks on a school from the search list
 * @returns input for searching schools with a dropdown list.
 */
const HighSchoolSearchInput = ({ primaryStyle, input }) => {
  const {
    register,
    setValue,
    getValues,
    clearErrors,
    setError,
    setFocus,
    formState: { errors },
  } = useFormContext();

  const { scenario } = useAppContext();
  const { onboarding } = scenario;
  const lookUpName = input.mapId + "_lookup";
  const schoolId = "student.school_id"; //This is the hs school id for the student.  school_id1 -5 are for siblings

  //search list should not be in global state
  const [loading, setLoading] = useState(false);
  const [hsSearchList, setHSSearchList] = useState([]);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const schoolSearchRef = useRef();

  //Search function
  const searchSchools = async (asyncSearchTerm) => {
    const myZip = document.getElementById("student.zip");

    //Trim leading and trailing spaces before checking length of query
    const trimmedSearchTerm = asyncSearchTerm.trim();
    //API call requires at least 3 characters or it will throw an error
    if (
      trimmedSearchTerm?.length > 2 &&
      (onboarding.student.zip !== null || myZip.value !== "")
    ) {
      setLoading(true);
      clearErrors();
      const notFound = {
        school_id: 1,
        name: "School not found",
        lowercase_name: "school not found",
        city: "Not Found",
        state: "NF",
      };

      const homeSchool = {
        school_id: 2,
        name: "Home Schooled",
        lowercase_name: "home schooled",
        city: "Home",
        state: "HS",
      };

      const studentZip = onboarding.student.zip
        ? onboarding.student.zip
        : myZip.value;
      const hsSchools = await getHighSchools(studentZip);
      const searchTerm = trimmedSearchTerm.toLowerCase();
      const hsList = hsSchools.filter((school) => {
        if (school.lowercase_name.includes(searchTerm)) {
          return school;
        }
      });

      hsList.push(notFound);
      hsList.push(homeSchool);

      setHSSearchList(hsList);
      setLoading(false);
    } else if (
      trimmedSearchTerm?.length > 2 &&
      onboarding.student.zip === null &&
      myZip.value === ""
    ) {
      //Zip code is required to do the high school search.  Use setError() to display error message
      //Unauthorized
      setError("student.zip", {
        message: "Zip code is required to search for high schools.",
        shouldFocus: true,
      });
      setFocus("student.zip");
    } else {
      //Synchronously set search results to 0
      setHSSearchList([]);
    }
  };

  //When a school is clicked on, also set the expanded state back to false to close search list
  const setChosenSchool = (school) => {
    // chooseSchool(school);
    //When input value is set to full school name, update hsSearchList accordingly.
    // if (getValues(lookUpName) === school.name) {
    //   searchSchools(getValues(lookUpName));
    setValue(lookUpName, school.name);
    setValue(input.mapId, school.name, {
      shouldDirty: true,
    });
    setValue(schoolId, school.school_id, {
      shouldDirty: true,
    });
    // }
    setSearchExpanded(false);
  };

  //Create search list from search term. When a user clicks on one of the listed schools, it will be added to the list of displayed school cards
  const renderSearchList = () => {
    return hsSearchList.map((school) => (
      <SearchItem
        key={school.school_id}
        schoolData={school}
        schoolSearchTerm={getValues(lookUpName)}
        setChosenSchool={setChosenSchool}
      />
    ));
  };

  const handleKeyPress = (event) => {
    //Only allow alphanumeric, dash and space characters
    if (!event.key.match(SEARCH_ACCEPTABLE_CHARS_REGEXP)) {
      event.preventDefault();
    }
    //Prevent enter button from triggering
    if (event.key === "Enter") {
      event.preventDefault();
      //if there's something to choose, choose the first school in the list
      if (hsSearchList.length > 0) {
        setChosenSchool(hsSearchList[0]);
      }
    }
  };

  const debounceSearch = _.debounce((event) => {
    //Since we're overriding the default onChange fn, maintain react hook form changing value to allow react hook form getValues function
    setValue(lookUpName, event.target.value, {
      shouldValidate: true,
    });
    searchSchools(event.target.value);
  }, 200);

  const handlePointerDown = (event) => {
    const isOutside = checkClickOutside(event, schoolSearchRef);
    if (isOutside) {
      setSearchExpanded(false);
    }
  };

  //When expanded state changes to true, add event listener to check for clicking outside search element
  //use pointerdown instead of click because otherwise event can trigger while input still retains focus (clickdown inside input and clickup outside input will not lose focus)
  useEffect(() => {
    if (schoolSearchRef.current) {
      if (searchExpanded) {
        window.addEventListener("pointerdown", handlePointerDown);
      } else {
        window.removeEventListener("pointerdown", handlePointerDown);
      }
      return () => {
        window.removeEventListener("pointerdown", handlePointerDown);
      };
    }
  }, [searchExpanded]);

  return (
    <div className={` hs-school-search w-full`} ref={schoolSearchRef}>
      <div
        className={`search-bar flex justify-center ${hsSearchList.length > 0 ? "searching" : ""
          } ${searchExpanded && "focused"}`}
        onFocus={() => {
          //Only set expanded to true if it's false. This prevents it from interfering with the cancel button
          if (!searchExpanded) {
            setSearchExpanded(true);
          }
        }}
      >
        <label
          htmlFor={lookUpName}
          className="textLabel school-search-input flex justify-center items-center"
          {...register(lookUpName)}
        >
          {primaryStyle && (
            <div className="search-icon">
              <MagnifyingGlass />
            </div>
          )}
          {getValues(lookUpName)?.trim().length > 2 && (
            <small
              className={`results-number ${!primaryStyle ? "mr-1.5" : ""}`}
            >
              Results:&nbsp;
              {loading ? (
                <span className="flex items-center">
                  <ClipLoader
                    size={12}
                    css={{
                      position: "absolute;",
                      borderColor: "var(--primary-color)",
                      borderBottomColor: "transparent",
                    }}
                  />
                  <span className="opacity-0">{hsSearchList.length}</span>
                </span>
              ) : (
                <span>{hsSearchList.length}</span>
              )}
            </small>
          )}
          <input
            id={lookUpName}
            type="text"
            className={`${_.get(errors, input.mapId) ? "inputErrorState" : ""}`}
            style={{ ...(input.forceWhite ? { background: "white" } : {}) }}
            placeholder="Search for Schools"
            onFocus={(event) => {
              //Adding focus to the input will render a different placeholder text
              event.target.placeholder = "Enter a School Name";
            }}
            onBlur={(event) => {
              //Removing focus from the input will render the default placeholder text
              event.target.placeholder = "Search for Schools";
            }}
            onKeyPress={handleKeyPress}
            onChange={debounceSearch}
          />
        </label>
        {primaryStyle && (
          <button
            type="button"
            className="cancel-btn"
            onClick={() => {
              //Clicking the cancel button will remove focus from the input and close the expanded search list
              document.activeElement.blur();
              //reset search states
              setValue(lookUpName, "");
              setSearchExpanded(false);
              setHSSearchList([]);
            }}
          >
            <small>Cancel</small>
          </button>
        )}
        <input
          id={input.mapId}
          type="hidden"
          {...register(input.mapId, {
            ...input.validation,
          })}
        />
        <input id={schoolId} type="hidden" {...register(schoolId)} />
      </div>
      <div className={`search-list ${searchExpanded ? "expanded" : ""}`}>
        {renderSearchList()}
      </div>
    </div>
  );
};

export default HighSchoolSearchInput;
