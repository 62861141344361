export const getYears = (parentDOB=false) => {
  const currentYear = new Date().getFullYear();
  let startYear = currentYear - 4;
  if (parentDOB) {
    startYear = currentYear - 80;
  }
  let endYear = currentYear + 10;
  if (parentDOB) {
    endYear = currentYear - 18;
  }

  const years = [];
  while (startYear <= endYear) {
    years.push(startYear++);
  }
  return years;
};

export const getDaysFromCount = (count = "31") => {
  const days = [];
  for (let i = 1; i <= count; i++) {
    days.push(i);
  }
  return days;
};

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const getFormattedDate = (date) => {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
};

const msInADay = 86400000;

export const convertMsToDays = (ms) => {
  return ms / msInADay;
};

export const convertDaysToMs = (days) => {
  return days * msInADay;
};
