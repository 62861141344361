import { UpArrow, DownArrow } from "@icons/index";

/**
 * The toggleable arrow at the end of a drawer or other collapsible component
 * @param {boolean} expanded - whether dropdown is expanded or not
 * @param {function} onClick - optional, what to do when clicked
 * @param {string} className - optional, modifying class names
 * @returns
 */
const DropdownBtn = ({ expanded, onClick, className }) => {
  return (
    <button
      type="button"
      className={`dropdownBtn flex items-center${
        className ? " " + className : ""
      }`}
      onClick={onClick}
    >
      <div className="icon">{expanded ? <UpArrow /> : <DownArrow />}</div>
    </button>
  );
};

export default DropdownBtn;
