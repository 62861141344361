import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useAppContext } from "@context/state";
import { useRouter } from "next/router";
import { PREMIUM_VIEW } from "@utils/constants";
import { CAPAdvancedSchoolSearch } from "@api/schools";

import React from "react";

import CAPSelect from "./CAPSelect";

import ClipLoader from "react-spinners/ClipLoader";
import { getMajors } from "@api/misc";
import _ from "lodash";


const CAPFilter = ({
  sortingBy,
  setSortingBy,
  setPageNumber,
  advancedSearchList,
  setAdvancedSearchList,
  curatedSearchList,
  setCuratedSearchList,
  show
}) => {
  const router = useRouter();
  const filterContainerRef = useRef(null);
  const { upgraded, setDisplayModal, setModalView } = useAppContext();
  const {
    scenario,
    CAPFilterDefaults,
    setCAPFilterDefaults,
    setAdvancedFilter,
    setInitialExpanded,
    polling,
    handleApiError,
  } = useAppContext();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues: CAPFilterDefaults, //useMemo necessary?
    criteriaMode: "all", //allows multiple errors for one field
  });

  const [majors, setMajors] = useState([]);

  useEffect(() => {
    CAPFilterDefaults.show = true;
  });

  //For sticker price filter, see commit fa4ded4d971074661e803cd2d4a7909eed816041
  const createFilters = (values) => {
    let filter = "";
    if (!fileIsEmpty(values.distance)) {
      filter = filter.concat(`distance=${values.distance}&`);
    }
    if (!fileIsEmpty(values.state)) {
      filter = filter.concat(`state=${values.state}&`);
    }
    if (!fileIsEmpty(values.enrollment)) {
      filter = filter.concat(`size=${values.enrollment}&`);
    }
    if (!fileIsEmpty(values.type)) {
      filter = filter.concat(`type=${values.type}&`);
    }
    if (!fileIsEmpty(values.major)) {
      filter = filter.concat(`major=${values.major}&`);
    }
    if (!fileIsEmpty(values.forbes_ranking)) {
      filter = filter.concat(`ranking=${values.forbes_ranking}&`);
    }
    if (!fileIsEmpty(values.funding_gap)) {
      filter = filter.concat(`gap=${values.funding_gap}&`);
    }
    if (!fileIsEmpty(values.net_cost)) {
      filter = filter.concat(`net_cost=${values.net_cost}&`);
    }
    if (!fileIsEmpty(values.scholarship)) {
      filter = filter.concat(`scholarship=${values.scholarship}&`);
    }
    filter = filter.concat(`limit=10`);
    //set filter to state to use for sorting
    setAdvancedFilter(filter);
    return filter + "&offset=0" + "&order=net-cost";
  };

  const fileIsEmpty = (field) => {
    return field === "null" || field === "0" || _.isEmpty(field);
  };

  const onSubmit = async (formValues) => {
    const myFilters = createFilters(formValues);
    setInitialExpanded(false);
    setCAPFilterDefaults(formValues);
    const tmpArray = [1, 2, 3];
    setCuratedSearchList(tmpArray);
    const bb = curatedSearchList.length;
    setCuratedSearchList([]);
    try {
      if (upgraded) {
        const res = await CAPAdvancedSchoolSearch(
          scenario?.case_id,
          myFilters
        );
        if (res?.data?.results) {
          router.push(
            `/school_search/${scenario?.case_id}/${myFilters}`
          );
          //   //reset page number back to 1
          setPageNumber(1);
          setAdvancedSearchList([]);
          setAdvancedSearchList(res.data.results);
        }
      } else {
        setModalView(PREMIUM_VIEW);
        setDisplayModal(true);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getMajors();
        if (res.data) {
          res.data[0].name = "Any Major";
          setMajors(
            res.data.map((major) => {
              return { label: major.name, value: major.id };
            })
          );
        }
      } catch (error) {
        //This is a public route, so 401 or 403 errors are not expected.
        handleApiError(error);
      }
    }
    fetchData();
  }, []);

  const distances = [
    { label: "Any Distance", value: "null" },
    { label: "=< 100 Miles", value: "100" },
    { label: "=< 200 Miles", value: "200" },
    { label: "=< 500 Miles", value: "500" },
    { label: "=< 1,000 Miles", value: "1000" },
  ];

  const states = [
    { label: "Any State", value: "null" },
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "American Samoa", value: "AS" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District of Columbia", value: "DC" },
    { label: "Federated States of Micronesia", value: "FSM" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Guam", value: "GU" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Palua", value: "PW" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virgin Islands", value: "VI" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];

  const enrollments = [
    { label: "Any Enrollment Size", value: "null" },
    { label: "< 5,000", value: "small" },
    { label: "> 5,000 =< 15,000", value: "medium" },
    { label: "> 15,000", value: "large" },
  ];

  //Institution type filter
  const institutionTypes = [
    { label: "Any Type", value: "null" },
    { label: "Two-Year Institution", value: "two-year" },
    { label: "Four-Year Institution", value: "four-year" },
  ];

  //Forbes Ranking filter. Forbes rankings go up to 600
  const forbesRankings = [
    { label: "Any Ranking", value: "null" },
    { label: "Top 10", value: "10" },
    { label: "Top 50", value: "50" },
    { label: "Top 100", value: "100" },
    { label: "Just Ranked Schools", value: "600" },
  ];

  //For sticker price filter, see commit fa4ded4d971074661e803cd2d4a7909eed816041

  //affordability is actually filtered based on funding gap, so a numeric value is needed to filter results
  const fundingGaps = [
    { label: "Any Amount", value: "null" },
    { label: "=< $10,000", value: "10000" },
    { label: "=< $27,000", value: "27000" },
    {
      label: "=< $55,000", value: "55000",
    },
  ];

  const netCosts = [
    { label: "Any Net Cost", value: "null" },
    { label: "=< $10,000", value: "10000" },
    { label: "=< $25,000", value: "25000" },
    { label: "=< $40,000", value: "40000" },
    { label: "=< $55,000", value: "55000" },
  ];

  const meritScholarships = [
    { label: "No Filter", value: "null" },
    { label: "Any Scholarship", value: "*" },
    { label: "Automatic Only", value: "A" },
  ];

  let myStyle = { display: "none" };
  if (show) {
    myStyle = { display: "flex" };
  }

  return (
    <div className="formContainer flex flex-col flew-wrap" ref={filterContainerRef} style={myStyle}>
      <form className="filterForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col flex-1 flex-wrap w-full">
          <div className="filters felx flex-col flex-1 md:justify-between flex-wrap w-full">
            <div className="selectContainer flex flex-col w-full">
              <CAPSelect
                register={register}
                title="Miles From Home"
                field="distance"
                options={distances}
              />
              <CAPSelect
                register={register}
                title="State"
                field="state"
                options={states}
              />
              <CAPSelect
                register={register}
                title="Enrollment"
                field="enrollment"
                options={enrollments}
              />
              <CAPSelect
                register={register}
                title="Institution Type"
                field="type"
                options={institutionTypes}
              />
              <CAPSelect
                register={register}
                title="Major"
                field="major"
                options={majors}
              />
              <CAPSelect
                register={register}
                title="Forbes Ranking"
                field="forbes_ranking"
                options={forbesRankings}
              />
              <CAPSelect
                register={register}
                title="Funding Gap"
                field="funding_gap"
                options={fundingGaps}
              />
              <CAPSelect
                register={register}
                title="Your Net Cost"
                field="net_cost"
                options={netCosts}
              />
              <CAPSelect
                register={register}
                title="Merit Scholarship"
                field="scholarship"
                options={meritScholarships}
              />
              <div className="lockupContainer1 flex flex-row place-items-end text-center">
                {isSubmitting || polling ? (
                  <div className="m-auto">
                    <ClipLoader
                      size={25}
                      css={{
                        borderColor: "var(--primary-color)",
                        borderBottomColor: "transparent",
                      }}
                      speedMultiplier={0.8}
                    />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className={"secondary"}
                    disabled={isSubmitting || polling}
                  >
                    Search
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CAPFilter;
