import _ from "lodash";
import { useEffect } from "react";
import ProgressBar from "../ProgressBar.js";
import { infoTips } from "@utils/infoTips";

const Step02 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
}) => {
  if (formStep !== 4) {
    // Don't render unless step matches
    return null;
  }

  useEffect(() => {
    trigger("student.zip");
    setWatchFields(["student"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "Student's Zip Code",
        description: _.get(infoTips, "scenario.onboarding.student.zip"),
      },
    ]);
  }, []);

  //TODO: The Zip Code entry field should only accept numerical values and the user should only see a numerical keyboard when entering information in this field.

  return (
    <div className="studentInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep} />
      <div className="studentInfoText">
        <h5>
          In what Zip Code is{" "}
          {_.get(formData, "student.student_name") || "Student"}&apos;s primary
          residence located?
        </h5>
      </div>
      <div className="inputs flex flex-col text-left">
        <label htmlFor="zip" className="textLabel small">
          <span className="labelFocus">Zip Code</span>
          <input
            id="zip"
            type="number"
            {...register("student.zip", {
              required: {
                value: true,
                message: "Please enter a zipcode",
              },
              minLength: 5,
              // TODO: add regex zipcode validation?
            })}
          />
        </label>
      </div>
    </div>
  );
};

export default Step02;
