/**
 * Function for closing component when clicking outside mapsearch
 * @param {event} event - pass in an onclick or other event
 * @param {ref} ref - ref for the element that we want to check if we clicked outside of
 * This function is specifically for cases where the website is still interactable and we need to check if clicking outside.
 * Requires setting an event listener to use
 */
const checkClickOutside = (event, ref) => {
  if (ref.current) {
    const isClickInside = ref.current.contains(event.target);

    if (!isClickInside) {
      //Click was outside, so return true
      return true;
    }
  }
  //Click was not found to be outside, so return false
  return false;
};

export { checkClickOutside };
