const Checkmark = () => {
  return (
    <div className="flex justify-center items-center h-full w-full">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Checkmark/Active"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M17.3502243,7.29289322 C17.7407486,6.90236893 18.3739136,6.90236893 18.7644379,7.29289322 C19.1249218,7.65337718 19.1526514,8.22060824 18.8476265,8.61289944 L18.7644379,8.70710678 L9.97631836,17.4952263 L5.29289322,12.8118011 C4.90236893,12.4212769 4.90236893,11.7881119 5.29289322,11.3975876 C5.65337718,11.0371036 6.22060824,11.0093741 6.61289944,11.314399 L6.70710678,11.3975876 L9.976,14.666 L17.3502243,7.29289322 Z"
            id="Checkmark"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Checkmark;
