import renderLogo from "@utils/renderLogo";

const SchoolHeader = ({ name, logo }) => {
  return (
    <div className="school-header flex flex-row justify-center items-center">
      {renderLogo(logo, name)}
      <h4 className="university-name">{name || "University Name Goes Here"}</h4>
    </div>
  );
};

export default SchoolHeader;
