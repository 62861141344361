import { useAppContext } from "@context/state";
import { formatDollarAmount } from "@utils/costFormatter";

const OutcomesModal = () => {
  const { setDisplayModal, modalContent, scenario } = useAppContext();

  const salaryOutcomes = modalContent[0];
  const universityName = modalContent[1];

  const getProjectedSalary = (year, percentile) => {
    return formatDollarAmount(salaryOutcomes[year][percentile]);
  };

  return (
    <div className="modal">
      <div className="modal-scroll">
        <div className="modal-content outcomesModal">
          <h3>Outcomes</h3>
          <div className="table small flex flex-col">
            <div>
              <div></div>
              <div>Year 1</div>
              <div>Year 5</div>
              <div>Year 10</div>
            </div>
            <div>
              <div>25th</div>
              <div>{getProjectedSalary(1, 25)}</div>
              <div>{getProjectedSalary(5, 25)}</div>
              <div>{getProjectedSalary(10, 25)}</div>
            </div>
            <div>
              <div>50th</div>
              <div>{getProjectedSalary(1, 50)}</div>
              <div>{getProjectedSalary(5, 50)}</div>
              <div>{getProjectedSalary(10, 50)}</div>
            </div>
            <div>
              <div>75th</div>
              <div>{getProjectedSalary(1, 75)}</div>
              <div>{getProjectedSalary(5, 75)}</div>
              <div>{getProjectedSalary(10, 75)}</div>
            </div>
            <div>
              <div>Avg.</div>
              <div>{formatDollarAmount(salaryOutcomes["average_salary"])}</div>
              <div>
                {formatDollarAmount(salaryOutcomes["average_salary_5"])}
              </div>
              <div>
                {formatDollarAmount(salaryOutcomes["average_salary_10"])}
              </div>
            </div>
          </div>
          <div className="description">
            Percentiles and averages based on surveys from {universityName}{" "}
            graduates who earned their{" "}
            {scenario?.onboarding?.student?.intended_major_object?.name} degree
            1, 5 and 10 years ago.
          </div>
          <button
            type="button"
            className="tertiary hideInfoBtn hideOutcomesBtn"
            onClick={() => setDisplayModal((cur) => !cur)}
          >
            Got it
          </button>
        </div>
      </div>
    </div>
  );
};

export default OutcomesModal;
