import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import {
  getAdminAccessTokenFromUrl,
  getAdminAccessTokenFromCookies,
  removeAccessToken,
} from "@utils/getAccessToken";

// env variable is inherently different for production and development,
//no need for logic to check which url to use
const BASE_URL = process.env.NEXT_PUBLIC_API_URL;

const axiosInstanceAdmin = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
});

//Only run interceptor if window is not undefined (when not using serverSideProps)
if (typeof window !== "undefined") {
  axiosInstanceAdmin.interceptors.request.use(
    (config) => {
      const access_token = getAdminAccessTokenFromCookies(document.cookie)
        ? getAdminAccessTokenFromCookies(document.cookie)
        : getAdminAccessTokenFromUrl(); //QUESTION: May be able to consolidate with axiosInstance by checking for a document.referrer to trigger where the access token is taken from. Is this a good idea, or should they stay separate files like this?
      if (access_token && access_token.length > 10) {
        config.headers.Authorization = `Bearer ${access_token}`;
      }
      return config;
    },
    (error) => {
      localStorage.removeItem("refresh_token");
      removeAccessToken();
      return Promise.reject(error);
    }
  );
}

axiosInstanceAdmin.interceptors.response.use(
  async (response) => response,
  (error) => {
    Sentry.captureException(error);
    throw error;
  }
);

export default axiosInstanceAdmin;
