import _ from "lodash";

//For now, get the top school from the list of user's schools. This doesn't change the result compared to before, but this way allows login to be much faster.
//This means getting the top school is being "faked". TODO: create relationship to student's top school and implement feature for real.
/**
 *
 * @param {Object} school - school object from userSchools
 * @returns transformed object with expected key names
 */
export const constructTopSchool = (school) => {
  const topSchool = {};
  if (school) {
    topSchool.college_id = parseInt(_.get(school, "school_id"));
    topSchool.school_name = _.get(school, "name");
  } else {
    //Allow forms to be submitted without adding a school by having a default when user has no added schools
    topSchool.college_id = 0;
  }
  return topSchool;
};
