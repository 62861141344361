import { useEffect, useRef } from "react";
import { throttle } from "lodash";
import { useAppContext } from "@context/state";
import {
  PrimaryNav,
  SecondaryNav,
  Footer,
  Sidebar,
  ErrorModal,
  Modal,
  VideoModal,
  HelpModal,
  OnboardingModal,
  OutcomesModal,
  SchoolCardEditModal,
  ChangeEmailModal,
  ChangePasswordModal,
  FormModal,
  PremiumModal,
  ExpirationModal,
  SchoolMoreInfoModal,
  PersonalInfoEditModal,
  CollegePreApprovalEditModal,
  OfferEditModal,
  ComingSoonModal,
  SubscriptionRenewalModal,
  CancelSubscriptionModal,
  CancellationConfirmationModal,
  PdfViewer,
} from "@components/index";
import { useRouter } from "next/router";
import {
  HELP_VIEW,
  ONBOARDING_VIEW,
  OUTCOMES_VIEW,
  PREMIUM_VIEW,
  EXPIRATION_VIEW,
  SUBSCRIPTION_RENEWAL,
  SCHOOL_CARD_EDIT,
  SCHOOL_MORE_INFO,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  PERSONAL_INFO_EDIT,
  COLLEGE_PRE_APPROVAL_EDIT,
  OFFER_EDIT,
  COMING_SOON_VIEW,
  CANCEL_SUBSCRIPTION,
  CANCELLATION_CONFIRMATION_VIEW,
} from "@utils/constants";

const Layout = ({
  children,
  showPrimaryNav,
  showSecondaryNav,
  showMobileNav,
  showFooter,
  showMobileFooter,
}) => {
  const {
    loggedIn,
    modalView,
    setModalView,
    setDisplayModal,
    formModalView,
    siteInteract,
    setSiteInteract,
    displaySidebar,
    setDisplaySidebar,
    barLoading,
    setBarLoading,
    percentLoaded,
    eligibleForRenewal,
  } = useAppContext();

  const router = useRouter();

  const layoutRef = useRef();

  const siteInteractRef = useRef();

  useEffect(() => {
    siteInteractRef.current = siteInteract;
  }, [siteInteract]);

  useEffect(() => {
    window.addEventListener("scroll", interactOnScroll);
    return () => {
      window.removeEventListener("scroll", interactOnScroll);
    };
  }, []);

  const interactOnScroll = throttle(() => {
    if (!siteInteractRef.current) {
      setSiteInteract(true);
    }
  }, 200);

  useEffect(() => {
    // The HubSpot script loader (script loaded in CustomHead file)
    // creates and object that helps us use some external API methods.
    // That object allows to customize chatbot widget behavior.
    // Check if the HubSpot Conversations API is ready
    // after the script is laoded
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.refresh();
    }
    //Scroll to top when navigating
    if (layoutRef.current) {
      layoutRef.current.scrollTo(0, 0);
    }
    //Close sidebar if it's open
    if (displaySidebar) {
      setDisplaySidebar(false);
    }
  }, [router.pathname]);

  useEffect(() => {
    if (
      eligibleForRenewal &&
      sessionStorage.getItem("notified_subscription_ending") !== "true"
    ) {
      setModalView(EXPIRATION_VIEW);
      setDisplayModal(true);
      sessionStorage.setItem("notified_subscription_ending", true);
    }
  }, [eligibleForRenewal]);

  //Variable "loggedIn" below handles cases for the welcome page, which is the logged out render for pathname "/"
  return (
    <div
      ref={layoutRef}
      className={`layout flex flex-col`}
      onClick={() => !siteInteract && setSiteInteract(true)}
    >
      <nav aria-label="primary">
        <div
          className={`${!showMobileNav || !loggedIn ? "hidden lg:block" : ""}
          ${!showPrimaryNav ? "lg:hidden" : ""}`}
        >
          <PrimaryNav />
        </div>
      </nav>
      <div className="flex flex-1 flex-col lg:flex-row">
        <nav aria-label="secondary" className="flex">
          {loggedIn && showSecondaryNav && <SecondaryNav />}
        </nav>
        {loggedIn && (
          <div
            className="loading-bar"
            style={{
              maxHeight: `${!barLoading ? "0rem" : "0.4rem"}`,
            }}
          >
            <div
              className="progress"
              style={{
                width: `${percentLoaded}%`,
              }}
              onTransitionEnd={() => {
                if (percentLoaded === 100) {
                  setBarLoading(false);
                }
              }}
            />
          </div>
        )}
        <main
          className={`h-full flex flex-col${showSecondaryNav && loggedIn ? " less-width" : ""
            }`}
        >
          {children}
        </main>
      </div>
      <div
        className={`${!showMobileFooter || !loggedIn ? "hidden lg:block" : ""}
        ${!showFooter ? "lg:hidden" : ""}`}
      >
        <Footer />
      </div>

      <div className="lg:hidden">
        <Sidebar />
      </div>

      <FormModal>
        {formModalView === SCHOOL_CARD_EDIT && <SchoolCardEditModal />}
        {formModalView === SCHOOL_MORE_INFO && <SchoolMoreInfoModal />}
        {formModalView === CHANGE_EMAIL && <ChangeEmailModal />}
        {formModalView === CHANGE_PASSWORD && <ChangePasswordModal />}
        {formModalView === CANCEL_SUBSCRIPTION && <CancelSubscriptionModal />}
        {formModalView === PERSONAL_INFO_EDIT && <PersonalInfoEditModal />}
        {formModalView === COLLEGE_PRE_APPROVAL_EDIT && (
          <CollegePreApprovalEditModal />
        )}
        {formModalView === OFFER_EDIT && <OfferEditModal />}
      </FormModal>

      <Modal>
        {modalView === HELP_VIEW && <HelpModal />}
        {modalView === ONBOARDING_VIEW && <OnboardingModal />}
        {modalView === OUTCOMES_VIEW && <OutcomesModal />}
        {modalView === PREMIUM_VIEW && <PremiumModal />}
        {modalView === COMING_SOON_VIEW && <ComingSoonModal />}
        {modalView === EXPIRATION_VIEW && <ExpirationModal />}
        {modalView === CANCELLATION_CONFIRMATION_VIEW && (
          <CancellationConfirmationModal />
        )}
        {modalView === SUBSCRIPTION_RENEWAL && <SubscriptionRenewalModal />}
      </Modal>

      <PdfViewer />

      <VideoModal />

      <ErrorModal />
    </div>
  );
};

export default Layout;
