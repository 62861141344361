import _ from "lodash";

/**
 * Method removes all the values from the
 * personal_info object that
 * are not required and are empty.
 * @param {object} filterObject
 */
export const removeNullValues = (filterObject) => {
  Object.keys(filterObject).forEach((key) => {
    if (
      filterObject[key] === undefined ||
      filterObject[key] === "" ||
      filterObject[key] === null
    ) {
      delete filterObject[key];
    }
  });
};

/**
 * Transform sibling data to work with database
 * @param {*} onboardingFormValues - profile data
 * @returns
 */
export const mapSiblingData = (onboardingFormValues) => {
  const siblingData = {};
  const siblings = _.get(onboardingFormValues, "student.siblings");
  if (siblings) {
    siblings.forEach((sibling, key) => {
      siblingData[`starting_college_year_${key + 1}`] =
        sibling.starting_college_year;
    });
  }
  const numSiblings = _.get(onboardingFormValues, "student.num_siblings");
  if (numSiblings) {
    for (let i = 5; i > numSiblings; i--) {
      siblingData[`starting_college_year_${i}`] = null;
    }
  }
  return {
    ...onboardingFormValues.student,
    ...siblingData,
  };
};
