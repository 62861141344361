import { useAppContext } from "@context/state";

const ErrorModal = () => {
  const { displayErrorModal, setDisplayErrorModal, errorResponse } =
    useAppContext();

  const clickOutside = (event) => {
    if (event.target.classList.contains("errorModalContainer")) {
      setDisplayErrorModal((cur) => !cur);
    }
  };

  // All hooks need to come before early termination
  if (!displayErrorModal) {
    return null;
  }

  const { name, status, result, message, email, password, statusText } =
    errorResponse;

  /**
   * result is an object with unknown keys. Therefore return all values in an array.
   * @param {object or string} resultObject
   * @returns
   */
  const parseResultFromError = (resultObject) => {
    //handle specific case of B2B users with message that includes a link
    if (resultObject.message === "Invalid MyCAP User") {
      return (
        <>
          <span>
            Advisors and families working with them must use the Advising Portal
            at this time.
          </span>{" "}
          <br />
          <br />
          <span>
            Visit{" "}
            <a
              href="https://app.collegeaidpro.com/index.html"
              target="_blank"
              rel="noreferrer"
            >
              app.collegeaidpro.com
            </a>{" "}
            to log into your account or use a different email to signup for
            MyCap.
          </span>
        </>
      );
    } else {
      const parsedResult = [];
      for (const property in resultObject) {
        parsedResult.push(resultObject[property]);
      }
      return parsedResult;
    }
  };

  //Until error messages are standardized, this more complex function is needed to return a readable error message
  const getErrorMessageFromResponse = () => {
    switch (true) {
      case !!result:
        return parseResultFromError(result);
      case !!message:
        return message;
      case !!email:
        return email;
      case !!password:
        return password;
      default:
        return (
          <div>
            <span>
              Oops! Something went wrong. <br />
              Please try again or{" "}
              <a href="mailto:info@collegeaidpro.com">contact us</a> if the
              problem persists.
            </span>
          </div>
        );
    }
  };

  const getStatusFromResponse = () => {
    if (statusText) {
      return `${status}: ${statusText}`;
    } else {
      return `Error ${status || name}`;
    }
  };

  return (
    <div
      className="errorModalContainer fixed top-0 w-full h-full"
      onClick={clickOutside}
    >
      <div className="modal">
        <div className="modal-scroll">
          <div className="flex flex-col items-center">
            <div className="h4">{getErrorMessageFromResponse()}</div>
            <div className="divider mb-3"></div>
            <small>{getStatusFromResponse()}</small>
            <button
              type="button"
              className="tertiary hideErrorBtn mt-3"
              onClick={() => setDisplayErrorModal((cur) => !cur)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
