import Link from "next/link";
import { useAppContext } from "@context/state";
import { CapLogo, PlayButton } from "@icons/index";
import Image from "next/legacy/image";
import { settings } from "@whitelabel/whitelabel.preval.js";

const Footer = () => {
  const { openWalkthroughVideo } = useAppContext();

  return (
    <footer className="footer w-full flex justify-center md:justify-between items-center">
      <div className="logo">
        <Link href="/">

          <button className="logo">
            {process.env.NEXT_PUBLIC_WHITELABEL === "true" ? (
              <div className="flex justify-center items-center h-full w-full relative">
                <CapLogo />
                <div className="powered-by-cap">
                  <Image
                    src={"/images/powered_by_cap.png"}
                    alt={settings?.name}
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
              </div>
            ) : (
              <CapLogo />
            )}
          </button>

        </Link>
      </div>
      <div className="links flex flex-row">
        <a onClick={openWalkthroughVideo}>
          <small className="hidden md:block">
            Welcome to{" "}
            {process.env.NEXT_PUBLIC_WHITELABEL === "true"
              ? settings?.name
              : "MyCAP"}
          </small>
          <div className="w-3 -ml-1 md:hidden">
            <PlayButton />
          </div>
        </a>
        {process.env.NEXT_PUBLIC_WHITELABEL === "true" &&
          settings?.contactUrl ? (
          <a href={settings?.contactUrl} target="_blank" rel="noreferrer">
            <small>Contact</small>
          </a>
        ) : (
          <a href="mailto:info@collegeaidpro.com">
            <small>Contact</small>
          </a>
        )}
        {process.env.NEXT_PUBLIC_WHITELABEL === "true" &&
          settings?.privacyPolicyUrl ? (
          <a href={settings?.privacyPolicyUrl} target="_blank" rel="noreferrer">
            <small>Privacy Policy</small>
          </a>
        ) : (
          (<Link href="/Privacy Policy.pdf" target="_blank">

            <small>Privacy Policy</small>

          </Link>)
        )}
        <Link href="https://app.collegeaidpro.com/disclosures.html" target="_blank">

          <small>Disclosures</small>

        </Link>
      </div>
    </footer>
  );
};

export default Footer;
