import { useAppContext } from "@context/state";
import { WALKTHROUGH_VIDEO } from "@utils/constants";

/**
 * Video Modal component
 * The main value of this component is a responsive modal that doesn't change aspect ratio.
 * QUESTION: For design, do we need an x somewhere to close the video, or is clicking outside enough?
 */
const VideoModal = () => {
  const { displayVideoModal, setDisplayVideoModal, videoModalContent } =
    useAppContext();
  //Dimensions of walkthrough video and efc videos differ so use this check to add a class for fitting the odal to the dimensions of an efc video (1.77 ratio vs 1.6)
  const isEfcVideo = videoModalContent === WALKTHROUGH_VIDEO ? false : true;

  const clickOutside = (event) => {
    if (event.target.classList.contains("modal-container")) {
      setDisplayVideoModal((cur) => !cur);
    }
  };

  //All hooks need to come before early termination
  if (!displayVideoModal) {
    return null;
  }

  return (
    <div
      className="modal-container fixed top-0 w-full h-full flex justify-center items-center"
      onClick={clickOutside}
    >
      <div className={`video-modal-content ${isEfcVideo ? "efc-video" : ""}`}>
        <div className="video-container">
          <iframe
            src={videoModalContent}
            width="1152"
            height="720"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="MyCAP tour"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
