import _ from "lodash";
import { useEffect } from "react";
import ProgressBar from "../ProgressBar.js";
import { infoTips } from "@utils/infoTips";

const Step06 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
  watch,
  getValues,
}) => {
  if (formStep !== 8) {
    // Don't render unless step matches
    return null;
  }

  /**
   * Handle the logic for displaying the input value.
   * @param {string} mapId - registered reference for the input
   * @param {string} min - minimum value of input as a string
   * @returns properly formatted number or N/A
   */
  const displayInput = (mapId, min) => {
    const barWatch = watch(mapId);
    if (`${parseFloat(barWatch)}` === `${min}` || barWatch === undefined) {
      return "N/A";
    } else {
      if (
        mapId === "student.scores.gpa" ||
        mapId === "student.scores.weighted_gpa"
      ) {
        return parseFloat(barWatch).toFixed(1);
      } else {
        return barWatch;
      }
    }
  };

  useEffect(() => {
    trigger([
      "student.scores.gpa",
      "student.scores.weighted_gpa",
      "student.scores.sat",
      "student.scores.act",
      //  "student.applying_test_optional"
    ]);
    setWatchFields(["student"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "GPA",
        description: _.get(infoTips, "scenario.onboarding.student.scores.gpa"),
      },
      {
        id: 1,
        title: "Weighted GPA",
        description: _.get(
          infoTips,
          "scenario.onboarding.student.scores.weighted_gpa"
        ),
      },
      {
        id: 2,
        title: "SAT",
        description: _.get(infoTips, "scenario.onboarding.student.scores.sat"),
      },
      {
        id: 3,
        title: "ACT",
        description: _.get(infoTips, "scenario.onboarding.student.scores.act"),
      },
      {
        id: 4,
        title: "",
        description: "If student took ACT and SAT, enter both.",
      },
    ]);
  }, []);

  return (
    <div className="studentInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep} />
      <div className="inputs flex flex-col text-left">
        <label htmlFor="scores.gpa" className="sliderLabel">
          <div className="sliderText">
            <h5>
              {_.get(formData, "student.student_name") || "Student"}&apos;s GPA{" "}
            </h5>
            <div className="h2">{displayInput("student.scores.gpa", "0")}</div>
          </div>
          <div className="sliderProgressContainer">
            <div className="sliderProgressBar">
              <div
                className="sliderProgress"
                style={{
                  width: `${
                    ((parseFloat(getValues("student.scores.gpa") || 0) + 0.1) /
                      4.1) *
                    100
                  }%`,
                }}
              />
            </div>
          </div>
          <input
            id="scores.gpa"
            type="range"
            min="0"
            max="4.0"
            step="0.1"
            {...register("student.scores.gpa", {
              required: {
                value: true,
                message: "Please select a GPA",
              },
              validate: {
                min: (value) => value >= 0,
              },
            })}
            defaultValue="0"
          />
          <div className="minMax flex justify-between">
            <span>N/A</span>
            <span>4.0</span>
          </div>
        </label>
        <label htmlFor="scores.weighted_gpa" className="sliderLabel">
          <div className="sliderText">
            <h5>
              {_.get(formData, "student.student_name") || "Student"}&apos;s
              Weighted GPA{" "}
            </h5>
            <div className="h2">
              {displayInput("student.scores.weighted_gpa", "0")}
            </div>
          </div>
          <div className="sliderProgressContainer">
            <div className="sliderProgressBar">
              <div
                className="sliderProgress"
                style={{
                  width: `${
                    ((parseFloat(
                      getValues("student.scores.weighted_gpa") || 0
                    ) +
                      0.1) /
                      5.1) *
                    100
                  }%`,
                }}
              />
            </div>
          </div>
          <input
            id="scores.weighted_gpa"
            type="range"
            min="0"
            max="5.0"
            step="0.1"
            {...register("student.scores.weighted_gpa", {
              required: {
                value: true,
                message: "Please select a GPA",
              },
              validate: {
                min: (value) => value >= 0,
              },
            })}
            defaultValue="0"
          />
          <div className="minMax flex justify-between">
            <span>N/A</span>
            <span>5.0</span>
          </div>
        </label>
        {/* <label
          htmlFor="applying_test_optional"
          className="selectLabel testOptional small"
        >
          <span className="labelFocus">Do you plan on applying Test Optional?</span>
          <select
            id="applying_test_optional"
            {...register("student.applying_test_optional", {
              required: {
                value: true,
                message:
                  "Please select whether the student will be applying Test Optional",
              },
            })}
          >
            <option key="-" value="-">
              -
            </option>
            <option key="yes" value="Yes">
              Yes
            </option>
            <option key="no" value="No">
              No
            </option>
            <option key="unsure" value="Unsure">
              Unsure
            </option>
          </select>
          <div className="select-icon">
            <DownArrow />
          </div>
        </label> */}
        <label htmlFor="scores.sat" className="sliderLabel">
          <div className="sliderText">
            <h5>
              {_.get(formData, "student.student_name") || "Student"}&apos;s SAT{" "}
            </h5>
            <div className="h2">
              {displayInput("student.scores.sat", "390")}
            </div>
          </div>
          <div className="sliderProgressContainer">
            <div className="sliderProgressBar">
              <div
                className="sliderProgress"
                style={{
                  width: `${
                    ((parseInt(getValues("student.scores.sat") || 390) - 390) /
                      1210) *
                    100
                  }%`,
                }}
              />
            </div>
          </div>
          <input
            id="scores.sat"
            type="range"
            min="390"
            max="1600"
            step="10"
            {...register("student.scores.sat", {
              required: {
                value: true,
                message: "Please select an SAT score",
              },
            })}
            defaultValue="390"
          />
          <div className="minMax flex justify-between">
            <span>N/A</span>
            <span>1600</span>
          </div>
        </label>
        <label htmlFor="scores.act" className="sliderLabel">
          <div className="sliderText">
            <h5>
              {_.get(formData, "student.student_name") || "Student"}&apos;s ACT{" "}
            </h5>
            <div className="h2">{displayInput("student.scores.act", "0")}</div>
          </div>
          <div className="sliderProgressContainer">
            <div className="sliderProgressBar">
              <div
                className="sliderProgress"
                style={{
                  width: `${
                    ((parseInt(getValues("student.scores.act") || 0) + 1) /
                      37) *
                    100
                  }%`,
                }}
              />
            </div>
          </div>
          <input
            id="scores.act"
            type="range"
            min="0"
            max="36"
            step="1"
            {...register("student.scores.act", {
              required: {
                value: true,
                message: "Please select a GPA",
              },
            })}
            defaultValue="0"
          />
          <div className="minMax flex justify-between">
            <span>N/A</span>
            <span>36</span>
          </div>
        </label>
      </div>
    </div>
  );
};

export default Step06;
