import { CapLogo, ResetIcon } from "@icons/index";

/**
 * Render an icon based on value of string
 * Used for how to pay and offer form fields
 * @param {string} valueType
 * @returns
 */
export const renderCapOrResetIcon = (valueType) => {
  switch (valueType) {
    case "CAP Value":
      return (
        <div className="logo">
          <CapLogo />
        </div>
      );
    case "Reset":
      return (
        <div className="logo">
          <ResetIcon />
        </div>
      );
    default:
    //
  }
};
