import axiosInstanceAdmin from "@api/axiosInstanceAdmin";
import { axiosInstanceWithToken } from "./axiosInstanceWithToken";

//******************************************//
//************ ----- AUTH ----- ************//
//******************************************//

/**
 * Impersonate
 * Endpoint for an admin logging in as a user
 * Send the admin token as a bearer with this POST request
 * Returns the JWT token response same as a normal login request would get
 */
export const loginAsUser = async () => {
  //Post a new user
  const res = await axiosInstanceAdmin.post("/login-as-user");
  return res;
};

/**
 * SSO login via ?sso= from "auth" site
 * Call the backend (via loginWithAuthorizedToken() in that code) to make sure the access token is valid
 * Returns the JWT token response as a normal login request would get
 * @param {string} JWT access token
 */
export const ssoLoginAsUser = async (access_token) => {
  const res = await axiosInstanceWithToken(access_token).post("/sso-as-user");
  return res;
};

/**
 * Log out from user
 * Endpoint for an admin logging out from a user
 * Send the admin token as a bearer with this POST request
 * Returns the JWT token response same as a normal login request would get
 */
export const logoutFromUser = async () => {
  //logout from user and redirect back to admin dashboard
  const res = await axiosInstanceAdmin.post("/redirect-admin-url");
  return res;
};
