const ProgressBar = ({ formStep }) => {
  return (
    <div className="progressInfo flex flex-col w-full">
      <div className="flex justify-between">
        <h4>{`${formStep > 10 ? "Parent/Guardian" : "Student"}'s Info`}</h4>
        <small className="infoStep">{formStep - 3}/12</small>
      </div>
      <div className="progress-bar rounded-full w-full">
        <div
          className="progress rounded-full"
          style={{ width: `${((formStep - 3) / 12) * 100}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
