/**
 *
 * @param {string} title
 * @param {string} description
 * @param {string} buttonFn
 * @param {*} buttonContent
 * @returns
 */
const EmptySection = ({ title, description, buttonFn, buttonContent }) => {
  return (
    <>
      <div className="flex flex-col items-center md:items-start md:text-left">
        <h4>{title}</h4>
        <span className="description font-regular">{description}</span>
      </div>
      <button
        className="tertiary h-full ml-3 flex flex-row items-center flex-nowrap"
        onClick={buttonFn}
      >
        {buttonContent}
      </button>
    </>
  );
};

export default EmptySection;
