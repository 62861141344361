const LoanIconMoneyPile = () => {
  return (
    <div className="flex justify-center">
      <svg
        enableBackground="new 0 0 489.175 489.175"
        width="100%"
        height="100%"
        viewBox="0 0 489.175 489.175"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <path d="m402.438 79.025-67.7-67.7c-15.1-15.1-39.6-15.1-54.7 0l-67.7 67.7z" />
        <path d="m240.438 15.625h-97.1c-21.4 0-38.8 17.4-38.8 38.7v24.7h72.4z" />
        <path d="m249.738 475.525h93.9c20.6 0 37.4-16.8 37.4-37.4v-25.2h-68.7z" />
        <path d="m85.838 412.825 65.4 65.4c14.6 14.6 38.3 14.6 52.9 0l65.4-65.4z" />
        <path d="m247.238 161.125c-46.8 0-84.8 38-84.8 84.8s38 84.8 84.8 84.8 84.8-38 84.8-84.8-38-84.8-84.8-84.8zm24.3 118.3c-3.7 4.5-8.5 7.5-14.1 9.1-2.5.7-3.5 2-3.4 4.5s0 5 0 7.5c0 2.2-1.1 3.4-3.3 3.5-2.7.1-5.4.1-8.1 0-2.4 0-3.5-1.4-3.5-3.7 0-1.8 0-3.7-.1-5.5 0-4-.2-4.2-4.1-4.8-5-.8-9.8-1.9-14.4-4.1-3.6-1.7-3.9-2.6-2.9-6.4.8-2.8 1.5-5.6 2.4-8.3 1-3.2 1.9-3.6 4.8-2.1 5 2.6 10.4 4.1 16 4.8 3.6.5 7.1.1 10.5-1.4 6.2-2.7 7.2-10 1.9-14.3-1.8-1.5-3.8-2.6-6-3.5-5.5-2.4-11.2-4.2-16.3-7.3-8.4-5-13.7-11.9-13.1-22.1.7-11.5 7.2-18.7 17.8-22.6 4.4-1.6 4.4-1.5 4.4-6.1 0-1.5 0-3.1 0-4.6.1-3.4.7-4 4.1-4.1h3.2c7.3 0 7.3 0 7.3 7.3 0 5.2 0 5.2 5.2 6 3.9.6 7.7 1.8 11.3 3.4 2 .9 2.8 2.3 2.1 4.4-.9 3.1-1.8 6.3-2.8 9.4-1 2.9-1.9 3.4-4.7 2-5.7-2.8-11.7-3.9-17.9-3.6-1.7.1-3.3.3-4.8 1-5.4 2.4-6.3 8.4-1.7 12.1 2.3 1.9 5 3.2 7.8 4.4 4.8 2 9.6 3.9 14.2 6.4 14.499 8 18.499 26.2 8.2 38.7z" />
        <circle cx="370.538" cy="250.225" r="23.5" />
        <circle cx="123.838" cy="250.225" r="23.5" />
        <path d="m429.138 107.625h-369.2c-21.3 0-38.7 17.4-38.7 38.7v199.2c0 21.3 17.4 38.7 38.7 38.7h369.3c21.3 0 38.7-17.4 38.7-38.7v-199.2c-.101-21.3-17.4-38.7-38.8-38.7zm5.699 200.8c-3.1-.8-6.3-1.2-9.7-1.2-21.6 0-39.2 17.5-39.2 39.2 0 1.6.1 3.3.3 4.9h-286.6c.4-2.3.7-4.7.7-7.2 0-21.6-17.5-39.2-39.2-39.2-2.4 0-4.6.2-6.9.6v-119c2.2.4 4.5.6 6.9.6 21.6 0 39.2-17.5 39.2-39.2 0-2.5-.2-4.9-.7-7.2h287.5c-.8 3.1-1.2 6.3-1.2 9.6 0 21.6 17.5 39.2 39.2 39.2 3.3 0 6.6-.4 9.7-1.2z" />
      </svg>
    </div>
  );
};

export default LoanIconMoneyPile;
