import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import { removeAccessToken } from "@utils/getAccessToken";

/**
 * Pass a custom token to axiosInstance
 * TODO: Can replace other axiosInstances that are made just to use a different token. All axiosInstanceAdmin can be replaced with this.
 * @param {*} access_token
 * @returns axios instance
 */
export const axiosInstanceWithToken = (access_token = null) => {
  // env variable is inherently different for production and development,
  //no need for logic to check which url to use
  const BASE_URL = process.env.NEXT_PUBLIC_API_URL;

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  });

  //Only run interceptor if window is not undefined (when not using serverSideProps)
  if (typeof window !== "undefined") {
    axiosInstance.interceptors.request.use(
      (config) => {
        if (access_token && access_token.length > 10) {
          config.headers.Authorization = `Bearer ${access_token}`;
        }
        return config;
      },
      (error) => {
        localStorage.removeItem("refresh_token");
        removeAccessToken();
        return Promise.reject(error);
      }
    );
  }

  axiosInstance.interceptors.response.use(
    async (response) => response,
    (error) => {
      Sentry.captureException(error);
      throw error;
    }
  );

  return axiosInstance;
};
