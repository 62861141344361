import { LongLeftArrow } from "@icons/index";
import { useRouter } from "next/router";
import { useAppContext } from "@context/state";
import { COLLEGE_PRE_APPROVAL_EDIT } from "@utils/constants";
import { getPreApprovalInfo } from "@utils/budgetData";
import { processPreApprovals } from "@utils/costEstimates";
import { formatDollarAmount } from "@utils/costFormatter";
import { TalkToAdvisor } from "..";

const CollegePreApproval = () => {
  const router = useRouter();
  const { scenario, setFormModalView, setDisplayFormModal } = useAppContext();

  const { preapprovals } = scenario;

  //itemData comes from profileData, like personalInfo, but the expected structure of the object is different,
  //so they cannot be consolidated into a common component.
  const ListItem = ({ itemData }) => {
    //itemData.prefix is either "$" or nonexistent. TODO: refactor so it represents a boolean that indicates a dollar amount or not, and rename it to "itemData.isUSD"
    //Also TODO: Re examine default values for null or undefined. Sometimes it's "-", sometimes it's "XXX,XXX" and sometimes it's "Not Reported". We may want more consistency, but keep nuances in mind.
    const value =
      itemData.prefix &&
      itemData.amount &&
      itemData.value !== null &&
      itemData.value !== undefined
        ? formatDollarAmount(itemData.value)
        : itemData.value || "--";
    //Because we're mapping profileData as components,
    //and there's a need for differing types of components,
    //a property "basis" has been added to items whose value depends on another field
    //TODO: Don't map profileData, just write out a list of components (see CollegePreApprovalEditModal for reference)
    if (itemData.basis) {
      return <SumListItem itemData={itemData} value={value} />;
    } else {
      return <StandardListItem itemData={itemData} value={value} />;
    }
  };

  const StandardListItem = ({ itemData, value }) => {
    return (
      <div
        className={`contentItem flex flex-col md:flex-row md:justify-between py-1.5 md:py-3 ${
          itemData.label === "Total 529 Savings Plan" &&
          "py-4 md:py-4 md:flex-col"
        }`}
      >
        <small className="mb-0.5">{itemData.label}</small>
        {itemData.label === "Total 529 Savings Plan" ? (
          <h2 className="fontRegular">{value}</h2>
        ) : (
          <span>{value}</span>
        )}
      </div>
    );
  };

  const SumListItem = ({ itemData, value }) => {
    return (
      <div className="contentItem flex flex-col py-1.5 md:py-3">
        <small className="mb-0.5">{itemData.label}</small>
        <span>
          <span className="sum-list-item micro">
            {itemData.basis} x 48 = &nbsp;&nbsp;
          </span>
          <span>{value}</span>
        </span>
      </div>
    );
  };

  return (
    <div className="entryPage flex flex-col justify-between h-full w-full">
      <div className="profileContainer flex flex-col items-center justify-start h-full w-full">
        <div className="pseudo-nav constrained flex flex-row items-center justify-center w-full">
          <button
            className="simple w-7 h-7 p-2 -ml-2 flex items-center absolute left-0"
            onClick={() => {
              router.back();
            }}
          >
            <LongLeftArrow />
          </button>
          <div className="font-demi-bold text-center">My College Budget</div>
          <button
            className="small imitateLink p-2 -mr-2 absolute right-0"
            onClick={() => {
              setFormModalView(COLLEGE_PRE_APPROVAL_EDIT);
              setDisplayFormModal(true);
            }}
          >
            Edit
          </button>
        </div>
        <div className="profileDirectory flex flex-col items-center w-full">
          <div className="preApprovalInfoBlock borderBottom paddingBottom">
            <p className="text-center mb-0 introText">
              This exercise will help determine your college budget. Take 1
              minute to learn how much your family can afford for college before
              borrowing.
            </p>
          </div>
        </div>
        <div className="profileDirectory flex flex-col items-center w-full">
          <div className="preApprovalInfoBlock mt-4">
            <small className="mb-1">4-Year College Budget</small>
            <h2>{processPreApprovals(preapprovals, false)}</h2>
          </div>
        </div>
        <div className="profileDirectory flex flex-col items-center w-full mb-6 md:mb-10">
          <div className="preApprovalInfoBlock mt-4">
            <h3 className="borderBottom removeMargin">College Contributions</h3>
            {getPreApprovalInfo(preapprovals).map((basicItem, index) => (
              <ListItem key={index} itemData={basicItem} />
            ))}
          </div>
        </div>
      </div>
      <div className="sm:hidden">
        <TalkToAdvisor
          title="Increase Your Budget"
          description1="Want to learn how to increase your"
          description2="college budget?"
        />
      </div>
    </div>
  );
};

export default CollegePreApproval;
