import { useAppContext } from "@context/state";

import {
  EmptyCard,
  Section,
  ApplyForAidSchoolCard,
  TalkToAdvisor,
} from "@components/index";
import _ from "lodash";

import { NO } from "@utils/constants";

import Terms from "./Terms";

const ApplyForAid = () => {
  const { userSchools } = useAppContext();

  /**
   * Don't show school card for Manage Application page
   * if applying is set to NO.
   * Show it for all other values that are "Early Action",
   * "Early Decision","Regular Decision" and "Rolling".
   */
  const filteredSchools = _.filter(userSchools, (school) => {
    return school.applying !== NO;
  });

  const renderSchools = () => {
    //map schools to cards
    const schools = filteredSchools.map((school) => (
      <ApplyForAidSchoolCard
        key={school.school_id}
        schoolData={school}
        showMoreDetails={true}
      />
    ));
    return schools;
  };

  return (
    <div className="apply-for-aid-container sectionsContainer flex flex-col w-full">
      <div className="background" />
      <h2 className="desktop-header hidden lg:block">Manage Applications</h2>
      <div className="header-text text-center">{`Make sure you don't miss deadlines or applications!`}</div>
      <Section galleryName={"aid-gallery"} empty={filteredSchools.length === 0}>
        {filteredSchools.length === 0 ? <EmptyCard /> : <>{renderSchools()}</>}
      </Section>
      <TalkToAdvisor
        title="Help Me With Financial Aid Applications"
        description1="Mistakes can cost thousands."
        description2="Talk to us first!"
      />
      <Terms />
    </div>
  );
};

export default ApplyForAid;
