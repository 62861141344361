import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { HelpButton } from "@components/index";

/**
 * Returns a list item component that displays a sum based on the value
 * @param {string} label - Displayed name of item
 * @param {string} mapId - id that is used for identifying item with react hook forms
 * @param {string} value - value is given by watching another input
 * @param {string} helpText - text used for help modal
 */
const CollegePreApprovalSum = ({ label, mapId, value, helpText }) => {
  const { setValue } = useFormContext();

  /**
   * After render initialize default values for every
   * field. Values are received directly as props.
   */
  useEffect(() => {
    if (value !== undefined) {
      setValue(mapId, value);
    }
  }, []);

  return (
    <div className="inputItem relative">
      <div className="labelWrapper flex items-end justify-between">
        <label htmlFor={mapId} className="small">
          {label}
        </label>
        {helpText && (
          <HelpButton
            helpContent={[
              {
                id: 0,
                title: label,
                description: helpText,
              },
            ]}
            className="small mb-0.5 absolute"
          />
        )}
      </div>
      <span>
        <span className="sum-list-item micro">
          Monthly Cash-Flow x 48 = &nbsp;&nbsp;
        </span>
        <span>{value}</span>
      </span>
    </div>
  );
};

export default CollegePreApprovalSum;
