const Awards = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 36 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Awards"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M8.85714286,15.109472 L5.04761905,15.109472 C3.36446552,15.109472 2,13.7450064 2,12.0618529 L2,6.03927275 L8.85714286,6.03927275 L8.85714286,15.109472 Z M13.4285714,33.2380952 L22.5714286,33.2380952 L13.4285714,33.2380952 Z M18,26.3809524 L18,33.0857143 L18,26.3809524 Z M27.1428571,2 L27.1428571,15.109472 C27.1428571,20.6664799 27.1428571,26.3809524 18,26.3809524 C8.85714286,26.3809524 8.85714286,20.6664799 8.85714286,15.109472 L8.85714286,2 L27.1428571,2 Z M27.1428571,15.109472 L27.1428571,6.03927275 L34,6.03927275 L34,12.0618529 C34,13.7450064 32.6355345,15.109472 30.952381,15.109472 L27.1428571,15.109472 Z"
            id="Icon"
            stroke="currentColor"
            strokeWidth="2"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Awards;
