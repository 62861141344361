import _ from "lodash";
import { useEffect } from "react";

const Step01 = ({ formData, formStep, setModalContent }) => {
  if (formStep !== 3) {
    // Don't render unless step matches
    return null;
  }

  useEffect(() => {
    //Set content for help modal
    setModalContent([]);
  }, []);

  return (
    <div className="studentInfoContainer onboardingScreen flex flex-col items-center justify-start md:justify-center text-center">
      <div className="studentStart flex flex-col w-full text-left md:text-center">
        <h1>
          Let&apos;s begin with some information about{" "}
          {_.get(formData, "student.student_name") || "Student"}
        </h1>
      </div>
    </div>
  );
};

export default Step01;
