import _ from "lodash";
import { useEffect } from "react";
import ProgressBar from "../ProgressBar.js";
import NumberFormat from "react-number-format";
import { infoTips } from "@utils/infoTips";

const Step08 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
  setValue,
  getValues,
}) => {
  if (formStep !== 10) {
    // Don't render unless step matches
    return null;
  }

  useEffect(() => {
    trigger("student.student_investments");
    setWatchFields(["student"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "Student’s Non-Retirement Savings/Investments",
        description: _.get(
          infoTips,
          "scenario.onboarding.student.student_investments"
        ),
      },
    ]);
  }, []);

  return (
    <div className="studentInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep} />
      <div className="studentInfoText">
        <h5>
          How much does {_.get(formData, "student.student_name") || "Student"}{" "}
          have in Non-Retirement Savings/&#8203;Investments?
        </h5>
      </div>
      <div className="inputs flex flex-col text-left">
        <label
          htmlFor="student_investments"
          className="textLabel currencyLabel small"
        >
          <span className="labelFocus">Total amount</span>
          <NumberFormat
            value={getValues("student.student_investments")}
            {...register("student.student_investments", {
              required: {
                value: true,
                message: "Please enter nonretirement savings/investments total",
              },
            })}
            onValueChange={({ value }) => {
              setValue("student.student_investments", value);
              trigger("student.student_investments");
            }}
            thousandSeparator={true}
          />
          <div className="currency resetFont">$</div>
        </label>
      </div>
    </div>
  );
};

export default Step08;
