const DiagonalRightArrow = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 15 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>Icons/Diagonal Arrow/Right/Blue</title>
        <g
          id="Icons/Diagonal-Arrow/Right/Blue"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M14,0 C14.5128358,0 14.9355072,0.38604019 14.9932723,0.883378875 L15,1 L15,12 C15,12.5522847 14.5522847,13 14,13 C13.4871642,13 13.0644928,12.6139598 13.0067277,12.1166211 L13,12 L13,2 L3,2 C2.48716416,2 2.06449284,1.61395981 2.00672773,1.11662113 L2,1 C2,0.487164161 2.38604019,0.0644928393 2.88337887,0.00672773133 L3,0 L14,0 Z"
            id="Rectangle"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
          <path
            d="M15.6276943,6.91353111 C16.2835325,6.91353111 16.8151943,7.36124636 16.8151943,7.91353111 C16.8151943,8.42636695 16.3567716,8.84903827 15.7661819,8.90680338 L15.6276943,8.91353111 L-0.997305679,8.91353111 C-1.65314382,8.91353111 -2.18480568,8.46581586 -2.18480568,7.91353111 C-2.18480568,7.40069527 -1.72638295,6.97802395 -1.13579327,6.92025884 L-0.997305679,6.91353111 L15.6276943,6.91353111 Z"
            id="Path"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(7.315194, 7.913531) rotate(-45.000000) translate(-7.315194, -7.913531) "
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default DiagonalRightArrow;
