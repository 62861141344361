const Upload = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <circle id="path-1" cx="24" cy="24" r="24"></circle>
          <filter
            x="-79.2%"
            y="-45.8%"
            width="258.3%"
            height="258.3%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feMorphology
              radius="2"
              operator="erode"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="16"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="12"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.03 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
        </defs>
        <g
          id="Icons/Take-a-Photo"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Oval">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-2)"
              xlinkHref="#path-1"
            ></use>
            <circle
              stroke="currentColor"
              strokeWidth="1"
              strokeLinejoin="square"
              fillRule="evenodd"
              cx="24"
              cy="24"
              r="23.5"
            ></circle>
          </g>
          <path
            d="M27.1651687,13.25 L29.1243524,16.1071429 L33.7142857,16.1071429 C34.2073971,16.1071429 34.6538257,16.3070157 34.9769764,16.6301665 C35.2642215,16.9174116 35.4540619,17.3020614 35.4927024,17.7303207 L35.5,17.8928571 L35.5,33.3214286 C35.5,33.81454 35.3001271,34.2609685 34.9769764,34.5841193 C34.6897313,34.8713643 34.3050815,35.0612047 33.8768221,35.0998452 L33.7142857,35.1071429 L14.2857143,35.1071429 C13.7926029,35.1071429 13.3461743,34.90727 13.0230236,34.5841193 C12.7357785,34.2968742 12.5459381,33.9122244 12.5072976,33.483965 L12.5,33.3214286 L12.5,17.8928571 C12.5,17.3997458 12.6998729,16.9533172 13.0230236,16.6301665 C13.3102687,16.3429214 13.6949185,16.153081 14.1231779,16.1144405 L14.2857143,16.1071429 L18.9024062,16.1071429 L20.5350592,13.25 L27.1651687,13.25 Z M24,19.6785714 C22.4482143,19.6785714 21.0371816,20.3128545 20.0143038,21.3357323 C18.991426,22.3586101 18.3571429,23.7696429 18.3571429,25.3214286 C18.3571429,26.8732143 18.991426,28.284247 20.0143038,29.3071248 C21.0371816,30.3300026 22.4482143,30.9642857 24,30.9642857 C25.5517857,30.9642857 26.9628184,30.3300026 27.9856962,29.3071248 C29.008574,28.284247 29.6428571,26.8732143 29.6428571,25.3214286 C29.6428571,23.7696429 29.008574,22.3586101 27.9856962,21.3357323 C26.9628184,20.3128545 25.5517857,19.6785714 24,19.6785714 Z"
            id="Icon"
            stroke="currentColor"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Upload;
