import { useState, useRef, useEffect } from "react";
import { Checkmark } from "@icons/index";
import { Card, Collapsible, DropdownBtn } from "@components/index";
import { YES } from "@utils/constants";
import moment from "moment";
import { formatDollarAmount } from "@utils/costFormatter";

//Used on SchoolDetail page for showing the scholarships
const ScholarshipCard = ({
  scholarshipData,
  awardsTotal,
  setAwardsTotal,
  addedScholarshipIds,
  setAddedScholarshipIds,
  updateAwards,
}) => {
  //data from scholarshipData to be used
  let {
    selected,
    scholarship_id,
    // min_amount,
    // max_amount,
    scholarship_amount,
    name,
    min_sat,
    min_act,
    min_gpa,
    scholarship_type,
    notes,
    deadline,
    application_url,
    requirements,
  } = scholarshipData;
  //Convert deadline date to moment object
  deadline = moment(deadline || null);

  //reference the checkbox element
  const checkboxRef = useRef();
  //This useState is needed to control the collapsible component
  // If the scholarship is checked, expand it
  // Just like "defaultChecked" code below
  const [expanded, setExpanded] = useState(selected === YES && scholarship_amount !== undefined)

  //Update awards total value that gets displayed above scholarship cards
  //Triggered by onClick event from checkbox. The amount associated with that card gets added or subtracted from the total.
  //Importantly, also update the array of scholarship ids for updating the backend.
  //Currently backend requires an array of all added scholarship ids just to update one scholarship's status.
  const changeAwardsTotal = (event) => {
    if (scholarship_amount) {
      let newScholarshipsArray;
      if (event.target.checked) {
        setAwardsTotal((cur) => cur + parseInt(scholarship_amount));
        //add to array of added scholarship ids
        newScholarshipsArray = [...addedScholarshipIds, scholarship_id];
      } else {
        if (awardsTotal !== 0) {
          setAwardsTotal((cur) => cur - parseInt(scholarship_amount));
          //remove from array of added scholarship ids
          const index = addedScholarshipIds.indexOf(scholarship_id);
          if (index > -1) {
            newScholarshipsArray = addedScholarshipIds;
            newScholarshipsArray.splice(index, 1);
          }
        }
      }
      setAddedScholarshipIds(newScholarshipsArray);
      //Update the backend
      updateAwards(newScholarshipsArray);
    }
  };

  useEffect(() => {
    if (checkboxRef) {
      checkboxRef.current.checked = selected === YES;
    }
  }, [selected, scholarshipData]);
  //TODO: Room for refactoring. Don't think it's possible to use the same xml for elements that move from inside collapsible to outside, though.
  return (
    <Card type="typeShort">
      <div className="scholarshipCard awardsColumns flex flex-col md:flex-row md:items-center">
        {/* Desktop Layout */}
        <b className="hidden md:block">
          {application_url ? (
            <a href={application_url} target="_blank" rel="noreferrer">
              {name}
            </a>
          ) : (
            <span>{name}</span>
          )}
        </b>
        <div className="h2 font-regular removeMargin hidden md:block">
          {/* NOTE: scholarship_amount must be a number. In the DB, the "value" column has a type of VARCHAR(40), so the value must be mapped to scholarship_amount only after confirming it is a number. Otherwise, scholarship_amount should be 0. */}
          {scholarship_amount ? formatDollarAmount(scholarship_amount) : ""}
        </div>
        <div className="requirements flex flex-row hidden md:flex">
          <div className="scores flex flex-row">
            <div className="flex flex-col">
              <small>SAT</small>
              {min_sat || "-"}
            </div>
            <div className="flex flex-col">
              <small>ACT</small>
              {min_act || "-"}
            </div>
            <div className="flex flex-col">
              <small>GPA</small>
              {min_gpa || "-"}
            </div>
          </div>
        </div>
        <div className="flex justify-between hidden md:flex">
          <label className="checkbox">
            <input
              defaultChecked={
                selected === YES && scholarship_amount !== undefined
              }
              type="checkbox"
              ref={checkboxRef}
              disabled={scholarship_amount === undefined}
              onChange={changeAwardsTotal}
            />
            <span className="checkmark">
              <Checkmark />
            </span>
          </label>
          <DropdownBtn
            className="p-1 -m-1"
            onClick={() => setExpanded((cur) => !cur)}
            expanded={expanded}
          />
        </div>
        {/* Mobile Layout */}
        <div className="topHeading mb-1 md:hidden">
          <b>{name}</b>
          <label className="checkbox">
            <input
              defaultChecked={
                selected === YES && scholarship_amount !== undefined
              }
              type="checkbox"
              ref={checkboxRef}
              disabled={scholarship_amount === undefined}
              onChange={changeAwardsTotal}
            />
            <span className="checkmark">
              <Checkmark />
            </span>
          </label>
        </div>
        <small className="mb-0.5 md:hidden">Amount</small>
        <div className="flex flex-row justify-between items-end w-full md:hidden">
          <div className="h2 font-regular removeMargin">
            {scholarship_amount ? formatDollarAmount(scholarship_amount) : ""}
          </div>
          <DropdownBtn
            className="p-1 -m-1"
            onClick={() => setExpanded((cur) => !cur)}
            expanded={expanded}
          />
        </div>
      </div>
      <Collapsible expanded={expanded}>
        <div className="divider"></div>
        <div className="flex flex-col md:flex-row md:pt-1">
          <small className="title md:hidden">
            <b>Requirements</b>
          </small>
          <div className="requirements flex flex-col md:mr-6">
            <div className="flex flex-row">
              <div className="scores flex flex-row md:hidden">
                <small>SAT</small>
                <small>ACT</small>
                <small>GPA</small>
              </div>
              <small>Type</small>
            </div>
            <div className="flex flex-row">
              <div className="scores flex flex-row md:hidden">
                <div>{min_sat || "-"}</div>
                <div>{min_act || "-"}</div>
                <div>{min_gpa || "-"}</div>
              </div>
              <div>{scholarship_type || "-"}</div>
            </div>
          </div>

          {/* Add some margin to the right  */}
          <div className="flex flex-col md:mr-6">
            {deadline.isValid() && (
              <small className="title">
                <b>Deadline is {deadline.format("MMMM Do")}</b>
              </small>
            )}
            <div className="description">
              <div>{notes}</div>
            </div>
          </div>

          {/* New area to list the scholarship requirements  */}
          <div className="flex flex-col">
            <small className="title">
              <b>Requirements</b>
            </small>
            <div className="description">
              <div>{requirements.map((requirement) => (<>{requirement}<br /></>))}</div>
            </div>
          </div>

        </div>
      </Collapsible>
    </Card>
  );
};

export default ScholarshipCard;
