/**
 * get token from url
 * used when an admin impersonates a user and the redirect url that leads to MyCap will hold a token
 * @param - none
 */
export const getFirstPromoterReferralCodeFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("fpr");
};

/**
 * TODO: Will be deprecated and should be removed after enough time passes where users are no longer using it. This is here for legacy to maintain compatibility.
 * get token from url
 * used when an admin impersonates a user and the redirect url that leads to MyCap will hold a token
 * @param - none
 */
export const getReferralCodeFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("refer");
};

/**
 * get admin access token from cookies
 */
export const getReferralCodeFromSession = () => {
  return sessionStorage.getItem("referral_code");
};

/**
 * set the referral code to session storage
 * @param {string} referralCode - string of 12 random characters
 */
export const setReferralCode = (referralCode) => {
  sessionStorage.setItem("referral_code", referralCode);
};

/**
 * remove referral code
 */
export const removeReferralCode = () => {
  sessionStorage.removeItem("referral_code");
};
