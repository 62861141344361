import axiosInstance from "@api/axiosInstance";

//******************************************//
//********* ----- CALCULATE ----- **********//
//******************************************//

/**
 * Calculate EFCs, scholarships, and net costs
 * @param {number} case_id
 * @returns efcs, added schools
 */
export const calculateNetCostForAddedSchools = async (case_id) => {
  const params = {
    efcs: true,
    scope: {
      schools: "selected",
      scholarships: "not_dirty",
    },
    net_costs: true,
    return: "efcs,schools",
  };
  const res = await axiosInstance.post(`/case/${case_id}/calculate`, params);
  return res;
};

/**
 * Calculate scholarships and net cost for one school
 * @param {number} case_id
 * @param {array} college_id_array
 * @returns school
 */
export const calculateNetCostForGivenSchools = async (
  case_id,
  college_id_array,
  access_token
) => {
  const params = {
    efcs: false,
    scope: {
      schools: college_id_array.toString(),
      scholarships: "not_dirty",
    },
    net_costs: true,
    return: "schools",
  };
  const res = await axiosInstance.post(`/case/${case_id}/calculate`, params, {
    headers: access_token ? { Authorization: `Bearer ${access_token}` } : {},
  });
  return res;
};

/**
 * Calculate scholarships and net costs for all schools
 * @param {number} case_id
 * @returns nothing, goes to queue
 */
export const calculateNetCostForAllSchools = async (case_id) => {
  const params = {
    efcs: false,
    scope: {
      schools: "all",
      scholarships: "not_dirty",
    },
    net_costs: true,
    async: true,
  };
  const res = await axiosInstance.post(`/case/${case_id}/calculate`, params);
  return res;
};
