const Download = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 17 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Download"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline
            id="Path"
            stroke="currentColor"
            strokeWidth="2"
            points="14 8 8.5 13.3333333 3 8"
          ></polyline>
          <path
            d="M8.5,12.571429 L8.5,1 L8.5,12.571429 Z"
            id="Path"
            stroke="currentColor"
            strokeWidth="2"
          ></path>
          <path
            d="M1,17 L16,17 L1,17 Z"
            id="Path"
            stroke="currentColor"
            strokeWidth="2"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Download;
