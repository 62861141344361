const MinusIcon = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 13 2"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Minus"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <polygon
            id="MinusIcon"
            fill="currentColor"
            fillRule="nonzero"
            points="7.2675 1.53 13 1.53 13 6.8e-16 7.2675 6.8e-16 5.7375 6.8e-16 0 6.8e-16 0 1.53 5.7375 1.53"
          ></polygon>
        </g>
      </svg>
    </div>
  );
};

export default MinusIcon;
