import { useAppContext } from "@context/state.js";
import { useRouter } from "next/router";
import { useState, useEffect, useRef } from "react";

import MapSearch from "./MapSearch.js";
import SchoolList from "./SchoolList.js";
import CAPFilter from "./CAPFilter";
import { CAPAdvancedSchoolSearch } from "@api/schools";
import { checkClickOutside } from "@utils/clickOutside";
import AdvancedSchoolList from "./AdvancedSchoolList";

import { LongLeftArrow } from "@icons/index";
import { Collapsible, DropdownBtn } from "@components/index";
import { Checkmark } from "@icons/index";
import ClipLoader from "react-spinners/ClipLoader";
import { getUserSchools, addSchoolToUser } from "@api/schools";
import { calculateNetCostForAllSchools } from "@api/calculateScenario";
import { CALCULATE_JOB_ADDED_TO_QUEUE } from "@utils/constants";


const SchoolSearch = ({ searchList, filters }) => {

  //Use local state so list will unmount when leaving page
  const [curatedSearchList, setCuratedSearchList] = useState([]);

  const router = useRouter();

  const {
    scenario,
    advancedFilter,
    advancedSearchList,
    setAdvancedSearchList,
    advancedSearchListChecked,
    setAdvancedSearchListChecked,
    setAdvancedFilter,
    setUserSchools,
    handleApiError,
    populateOffers,
    initiatePollingCalculations,
    setPolling,
    hasRecalculatedAllSchools,
  } = useAppContext();

  //Sorting by need_met_percentage is the default sorting method
  const [sortingBy, setSortingBy] = useState("need");
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [moreSchoolLoading, setMoreSchoolLoading] = useState(false);

  const sortContainerRef = useRef();

  const recalculateNetCostsForAllSchools = async () => {
    try {
      const res = await calculateNetCostForAllSchools(scenario?.case_id);
      if (res?.data?.result) {
        const result = res.data.result;
        if (_.get(result, "message") === CALCULATE_JOB_ADDED_TO_QUEUE) {
          localStorage.setItem(
            "scenario_updated_at",
            _.get(result, "last_updated_at")
          );
          initiatePollingCalculations();
        }
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  // reset the advancedsearchchecked list when the advancedsearchlist is updated
  useEffect(() => {
    setAdvancedSearchListChecked([]);
  }, [advancedSearchList]);

  useEffect(() => {
    if (scenario?.case_id) {
      //Only recalculate if scenario has been updated since the last time recalculate all has been run or on a new session.
      if (!hasRecalculatedAllSchools) {
        //set polling state for UI to stop form being submitted until calculations are up to date
        setPolling(true);
        //recalculate net costs
        recalculateNetCostsForAllSchools();
      }
    }
  }, [scenario.case_id]);

  //option for each value that can be used to sort the search list.
  const sortingOptions = [
    {
      value: "net-cost",
      label: "Your Year 1 Net Cost",
    },
    {
      value: "four-year-cost",
      label: "Your 4 Year Net Cost",
    },
    {
      value: "need",
      label: "Financial Aid %",
    },
    {
      value: "merit-scholarship",
      label: "Scholarship Money",
    },
    //For sticker price sort, see commit fa4ded4d971074661e803cd2d4a7909eed816041
    //use gap to sort by affordability, since funding gap is a more specific metric for affordability that can be sorted more accurately
    {
      value: "gap",
      label: "Funding Gap",
    },
    {
      value: "enrollment",
      label: "Size",
    },
    {
      value: "distance",
      label: "Distance",
    },
    {
      value: "ranking",
      label: "Forbes Ranking",
    },
  ];

  const addSchools = async () => {
    if (advancedSearchListChecked.length) {
      setLoading(true);
      try {
        await Promise.all(
          advancedSearchListChecked.map((college_id) => {
            return addSchoolToUser(scenario?.case_id, college_id);
          })
        );
        //Updated school list, now get the updated list
        const res = await getUserSchools(scenario.case_id);
        if (res?.data?.results) {
          setUserSchools(res?.data?.results);
          setAdvancedSearchList(searchList);
          populateOffers(scenario?.case_id);
        }
        setLoading(false);
        // For now redirect to dashboard when successfully added.
        // Need to discuss if to show any modal when school added.
        router.push("/");
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  // const handleClick = (event) => {
  //   // Is the click outside of the sort container?
  //   checkClickOutside(event, sortContainerRef);
  //   const isOutside = checkClickOutside(event, sortContainerRef);
  //   if (isOutside) {
  //     setExpanded(false);
  //   }
  // };

  // //When expanded state changes to true, add event listener to check for clicking outside search element
  // useEffect(() => {
  //   if (sortContainerRef.current) {
  //     if (expanded) {
  //       window.addEventListener("click", handleClick);
  //     } else {
  //       window.removeEventListener("click", handleClick);
  //     }
  //     return () => {
  //       window.removeEventListener("click", handleClick);
  //     };
  //   }
  // }, [expanded]);

  /**
   * Function for sorting advancedSearchList. Uses the backend so we aren't stuck with just the 20 results that came through in the initial filter.
   * @param {string} sortBy - pass in a string to add onto the filter query. Used like so: &order={sortBy}
   */
  const sortOptionSelected = async (sortBy) => {
    //set the state of sorting by to the selected option
    setSortingBy(sortBy);
    setExpanded(false);

    if (advancedSearchList?.length > 0) {
      const sortedFilter = advancedFilter + "&offset=0" + "&order=" + sortBy;
      try {
        const res = await CAPAdvancedSchoolSearch(
          scenario?.case_id,
          sortedFilter
        );
        if (res?.data?.results) {
          router.push(
            `/advanced_search_result/${scenario?.case_id}/${sortedFilter}`
          );
          //   //reset page number back to 1
          setPageNumber(1);
          setAdvancedSearchList([]);
          setAdvancedSearchList(res.data.results);
        }
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * Load more schools for advance school search
   */
  const getMoreResults = async () => {
    try {
      setMoreSchoolLoading(true);
      const resultsNumber = 10;
      const offset = pageNumber * resultsNumber;
      const res = await CAPAdvancedSchoolSearch(
        scenario?.case_id,
        advancedFilter + `&offset=${offset}` + `&order=${sortingBy}`
      );
      if (_.get(res, "data.results.length") > 0) {
        const combinedSchools = advancedSearchList.concat(res.data.results);
        setPageNumber(pageNumber + 1);
        setAdvancedSearchList(combinedSchools);
      } else {
        setPageNumber(0);
      }
      setMoreSchoolLoading(false);
    } catch (error) {
      setMoreSchoolLoading(false);
      handleApiError(error);
    }
  };

  useEffect(() => {
    if (advancedSearchList.length > 0) {
      const bb = window.document.getElementsByClassName("leftSearchBox");
      const g = 1;
    }
  }, [advancedSearchList]);

  useEffect(() => {
    //On refresh set sort by to be everything after &order=
    const filtersSplitOrder = filters.split("&order=");
    if (filtersSplitOrder[1]) {
      setSortingBy(filtersSplitOrder[1]);
    }
    //On refresh set advanced filter to be everything before &offset=
    const filtersSplitBeforeOffset = filters.split("&offset=");
    if (filtersSplitBeforeOffset[0]) {
      setAdvancedFilter(filtersSplitBeforeOffset[0]);
    }
    setAdvancedSearchList(searchList);
  }, [router.pathname]);

  const expandSortBy = () => {
    setExpanded(true);
  }

  return (
    <div className="searchContainer flex flex-col w-full items-center">
      <div className="nav w-full flex flex-row items-center justify-between">
        <button
          className="w-3"
          onClick={() => {
            router.push("/"); //Should this go directly to filter page instead?
          }}
        >
          <LongLeftArrow />
        </button>
        <div className="emptySpace" />
      </div>
      <div className="searchBar w-full flex flex-col items-center justify-center">
        <MapSearch
          curatedSearchList={curatedSearchList}
          setCuratedSearchList={setCuratedSearchList}
        />
      </div>
      <div className="CAPSearchContainer flex flex-col items-center w-4/5">
        <div className="outerSearchBox flex flex-row items-start w-full">
          <div className="leftSearchBox flex flex-col">
            <div className="nav flex items-center text-center justify-center w-full">
              <div className="subNav">Advanced<br />Search Filters</div>
            </div>
            <CAPFilter
              sortingBy="net-cost"
              setSortingBy={setSortingBy}
              setPageNumber={setPageNumber}
              advancedSearchList={advancedSearchList}
              setAdvancedSearchList={setAdvancedSearchList}
              curatedSearchList={curatedSearchList}
              setCuratedSearchList={setCuratedSearchList}
              show={true}
            />
          </div>
          <div className="rightSearchBox flex flex-col w-full h-full">
            <div className="topSearchBox flex flex-row items-start w-full">
              <div className="results-sort flex flex-row justify-between items-center lg:items-end">
                <div
                  className="flex items-center p-1 -m-1 cursor-pointer"
                  onClick={() => setExpanded((cur) => !cur)}
                >
                  <span>Sort by</span>
                  <DropdownBtn className="p-1" expanded={expanded} />
                </div>
              </div>
              <div className="sort-container absolute z-50" ref={sortContainerRef} >
                <Collapsible expanded={expanded}>
                  <div className="sort-options flex flex-col justify-center">
                    {sortingOptions.map((option) => (
                      <div
                        key={option.value}
                        onClick={() => sortOptionSelected(option.value)}
                        className="sort-option flex flex-row items-center w-full"
                      >
                        <div className="checkmark">
                          {/* TODO: Add cliploader to show backend is working before showing checkmark */}
                          {sortingBy === option.value && <Checkmark />}
                        </div>
                        <div
                          className={`${sortingBy === option.value ? "font-demi-bold" : ""
                            }`}
                        >
                          {option.label}
                        </div>
                      </div>
                    ))}
                  </div>
                </Collapsible>
              </div>
              <div className="addSchools">
                <div className="bttnContainer">
                  <button
                    onClick={() =>
                      addSchools(JSON.stringify(advancedSearchListChecked))
                    }
                    className={"secondary"}
                  >
                    Add Schools
                  </button>
                </div>
              </div>
            </div>
            {curatedSearchList.length !== 0 ? (
              <SchoolList curatedSearchList={curatedSearchList} />
            ) : (<div></div>)}
            <div className="emptyNest flex flex-col items-start w-full h-full">
              {advancedSearchList?.length ? (
                <>
                  <div className="searchResultContainer result overflow-y-scroll">
                    <AdvancedSchoolList
                      sortingBy={sortingBy}
                      setSortingBy={setSortingBy}
                      setPageNumber={setPageNumber}
                    />
                  </div>
                  {pageNumber && (
                    <div className="loadMoreContainer">
                      {moreSchoolLoading ? (
                        <ClipLoader
                          size={25}
                          css={{
                            borderColor: "var(--primary-color)",
                            borderBottomColor: "transparent",
                          }}
                        />
                      ) : (
                        <button
                          className="underline imitateLink small"
                          onClick={getMoreResults}
                        >
                          Load more search results
                        </button>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div className="chooseLabel flex flex-col items-center w-full absolute">
                  <div>Search for Specific Schools Above</div>
                  <div>- or -</div>
                  <div>Use the Advanced Search Filters</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolSearch;
