import { MyCap } from "@icons/index";
import { useAppContext } from "@context/state";
import getTidCookie from "@utils/getTidCookie";
import { useEffect, useState } from "react";
import _ from "lodash";
import { createAppointment } from "@api/bookConsultation";
import PseudoNav from "./PseudoNav";
import { HelpButton } from "@components/index";
import Image from "next/legacy/image";
import { logo, settings } from "@whitelabel/whitelabel.preval.js";
import * as Sentry from "@sentry/nextjs";

/**
 * If a user has not paid for an appointment, they will see this page that allows them to purchase an appointment.
 * @param {object} appointmentResponse - appointment object obtained from the api. NOTE: consider only passing in the boolean "free_appointment_available"
 * @returns The book consultation landing page for users that have not purchased a new appointment
 */
const BookConsultation = ({ appointmentResponse }) => {
  const {
    user,
    setConsultationAppointment,
    setHasPurchasedConsultation,
    handleApiError,
  } = useAppContext();

  const free_appointment_available = _.get(
    appointmentResponse,
    "free_appointment_available"
  );

  const [booking, setBooking] = useState(false);

  /**
   * Upon successful payment, create a paid, unbooked appointment instance
   * @param {Boolean} is_discounted - pass in true for Valedictorian if no other appointments have is_discounted === true
   */
  const createPaidUnbookedAppointment = async (is_discounted) => {
    const paidUnbookedAppointment = {
      is_paid: true,
      is_booked: false,
      is_discounted,
    };
    try {
      const res = await createAppointment(user?.email, paidUnbookedAppointment);
      if (res) {
        //set appointment states
        setConsultationAppointment(res.data.result);
        //setting has purchased state to true will trigger render of success page
        setHasPurchasedConsultation(true);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    // Chargebee instance will be created when DOMContentLoaded
    // event is triggered.Since, you are loading it asynchronously,
    // instance is not getting created. So we can create the instance
    // using Chargebee.init().
    // In Nextjs, it doesnot simply work if we add a script tag to Head.
    // Requires to load a script after the app loads in order to work.
    if (!_.isNull(user)) {
      //Bypass chargebee if user has a free appointment
      if (!free_appointment_available) {
        if (document.getElementById("chargebee-script-element")) {
          document.getElementById("chargebee-script-element").remove();
        }
        //Standard chargebee script
        const chargeBeeScript = document.createElement("script");
        chargeBeeScript.onload = () => {
          window.Chargebee.init({
            site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
          });
          window.Chargebee.registerAgain();
          const cbInstance = window.Chargebee.getInstance();
          cbInstance.setCheckoutCallbacks(() => {
            // Defining a custom callback based on cart object.
            return {
              success: () => {
                // Payment Successful!
                // Hosted page id will be unique token for the checkout that happened
                // You can pass this hosted page id to your backend
                // and then call our retrieve hosted page api to get subscription details
                // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page
                // console.log(hostedPageId);
                //Close modal and create an instance of a paid appointment
                cbInstance.closeAll();
                createPaidUnbookedAppointment(false);
              },
              error: () => {
                Sentry.captureException(
                  `Chargebee book appointment error for user ${user?.email}`
                );
              },
            };
          });

          //Get tracking id, used for chargebee tracking script
          const tid = getTidCookie();
          // Prepopulating some fields on the modal
          if (tid && cbInstance) {
            const cart = cbInstance.getCart();
            cart.setCustomer({
              email: user.email,
              first_name: user.first_name,
              cf_tid: tid,
            });
          } else if (cbInstance) {
            const cart = cbInstance.getCart();
            cart.setCustomer({
              email: user.email,
              first_name: user.first_name,
            });
          }
        };

        chargeBeeScript.src = "https://js.chargebee.com/v2/chargebee.js";
        chargeBeeScript.id = "chargebee-script-element";
        document.head.appendChild(chargeBeeScript);
      }
    }
  }, [booking, user]);

  const chargeBeeAnchorProps = {
    "data-cb-item-0-quantity": "1",
    "data-cb-type": "checkout",
    href: "javascript:void(0)",
  };

  return (
    <div className="book-consultation flex flex-col items-center px-3 mb-8">
      <PseudoNav />
      {process.env.NEXT_PUBLIC_WHITELABEL === "true" ? (
        <div className="whitelabel-mycap mt-8 mb-4 flex justify-center relative">
          <Image
            src={logo}
            alt={settings?.name}
            layout="fill"
            objectFit="contain"
          />
        </div>
      ) : (
        <div className="myCap mt-8 mb-2">
          <MyCap />
        </div>
      )}
      <div className="flex flex-row relative px-5">
        <h3>Schedule a 1 Hour, 1:1 Meeting with a CAP Expert</h3>
        <HelpButton
          helpContent={[
            {
              id: 0,
              title:
                "Book a 60-minute Expert Consultation to learn about how MyCAP enables you to:",
              description: (
                <ul className="ml-1 md:ml-1.5">
                  <li>Identify the “perfect-fit” college for your family</li>
                  <li>Compare the real cost of colleges on your shortlist</li>
                  <li>
                    Identify the 1, 5 and 10-year salaries for graduates of each
                    major
                  </li>
                  <li>Access over 5,000 private scholarships</li>
                </ul>
              ),
            },
          ]}
          className="small mb-2 top-0 absolute"
        />
      </div>
      <div className="flex flex-col items-start mt-1 md:mt-5 mb-5">
        <div className="h6 w-full text-center mb-2">
          <i>What’s Keeping You Up At Night?</i>
        </div>
        <ul className="ml-1 md:ml-1.5">
          <li>Maximize financial aid &amp; scholarships</li>
          <li>School Recommendations</li>
          <li>FAFSA &amp; CSS/PROFILE</li>
          <li>Negotiate with colleges</li>
          <li>How to pay your bill for all 4 years</li>
          <li>What loan/s to utilize for college</li>
          <li>Anything &amp; Everything Else!</li>
        </ul>
      </div>
      <a
        {...chargeBeeAnchorProps}
        data-cb-item-0="Book-1-Hour-Consultation-USD"
      >
        <button
          className="primary h-7 px-4"
          onClick={() => {
            if (free_appointment_available) {
              createPaidUnbookedAppointment(true);
            } else {
              setBooking(true);
            }
          }}
        >
          {free_appointment_available ? "Next" : "Purchase Expert Consultation"}
        </button>
      </a>
    </div>
  );
};

export default BookConsultation;
