import { useAppContext } from "@context/state";
import { LongLeftArrow } from "@icons/index";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { sendResetPassword } from "@api/auth";
import CheckEmail from "./CheckEmail";

const ResetPassword = () => {
  const { handleApiError, errorResponse, setErrorResponse } = useAppContext();

  const router = useRouter();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm();

  //sentEmail state controls whether to render reset password input or success page with a call to action to check your email
  const [sentEmail, setSentEmail] = useState(false);

  const onSubmit = async () => {
    const email = getValues("email");
    try {
      const res = await sendResetPassword(email);
      if (res) {
        //After submitting successfully, proceed to check email message page
        setSentEmail(true);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <div className="entryPage flex flex-col justify-between h-full w-full">
      <div className="welcomeContainer flex flex-col items-center justify-start text-center h-full w-full">
        <form
          className="w-full h-full"
          action="#"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col items-center w-full">
            {!sentEmail ? (
              <>
                <button
                  className="simple back-btn flex items-center"
                  onClick={() => {
                    router.back();
                  }}
                >
                  <LongLeftArrow />
                </button>
                <div className="resetPasswordText flex flex-col w-full">
                  <h2>Reset Password</h2>
                  <span className="self-center">
                    Enter the email associated with your account and we’ll send
                    an email with instructions to reset your password.
                  </span>
                </div>
                <div
                  className="inputs flex flex-col text-left"
                  onChange={() => {
                    setErrorResponse({});
                  }}
                >
                  <label htmlFor="email" className="textLabel small">
                    <span className="labelFocus">Email</span>
                    <input
                      id="email"
                      type="email"
                      className={`${
                        errorResponse?.email ? "inputErrorState" : ""
                      }`}
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Invalid email address",
                        },
                        // validate: {
                        //   correctFormat: (value) => /\S+@\S+/g.test(value),
                        // },
                      })}
                    />
                    {errorResponse?.email && (
                      <small className="errorMessage text-left">
                        {errorResponse.email}
                      </small>
                    )}
                  </label>
                </div>
                <button
                  type="submit"
                  name="resetPassword"
                  className="startBtn loginBtn primary"
                  disabled={isSubmitting}
                >
                  Send Instructions
                </button>
              </>
            ) : (
              <CheckEmail setSentEmail={setSentEmail} />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
