import _ from "lodash";
import { useState, useEffect, useRef } from "react";
import { useAppContext } from "@context/state";
import {
  addSchoolToUser,
  setSchoolApplyingStatus,
  getSchoolScholarships,
  resetSchoolScholarships,
  updateSchoolScholarships,
  getSchoolUserInfo,
} from "@api/schools";
import { PREMIUM_VIEW, YES, NO } from "@utils/constants";
import { useRouter } from "next/router";
import {
  processNetCost,
  processCostOfAttendance,
  processNeedBasedGrants,
  processUSD,
} from "@utils/costEstimates";
import renderLogo from "@utils/renderLogo";

import { LongLeftArrow } from "@icons/index";
import { ScholarshipCard } from "@components/index";
import AdmissionsDrawer from "./AdmissionsDrawer";
import FinancialAidDrawer from "./FinancialAidDrawer";
import OutcomesDrawer from "./OutcomesDrawer";

const SchoolDetail = ({ schoolData }) => {
  const { school_id, name, logo, breakdowns, applying } = schoolData;
  /**
   * data belonging to breakdowns:
   * year, cost_of_attendance, efc_type, efc, need, need_met_percentage, need_based_grants, merit_scholarship, net_cost
   */
  const [netCost, setNetCost] = useState(processNetCost(1, breakdowns));

  const {
    scenario,
    userSchools,
    upgraded,
    setModalView,
    setDisplayModal,
    populateUserSchools,
    handleApiError,
    barLoading,
    loadedSchools,
    populateOffers,
  } = useAppContext();

  const router = useRouter();

  const [awardsTotal, setAwardsTotal] = useState(0);

  const [scholarships, setScholarships] = useState([]);
  //controls the array of ids we pass to update which scholarships are added
  const [addedScholarshipIds, setAddedScholarshipIds] = useState([]);

  //Toggle Switch
  const toggleRef = useRef();

  const setApplying = async (status) => {
    const res = await setSchoolApplyingStatus(
      scenario?.case_id,
      school_id,
      status
    );
    return res;
  };

  const addSchool = async () => {
    if (
      userSchools.filter((school) => school.visible === true).length >= 3 &&
      !upgraded &&
      !_.find(userSchools, ["school_id", school_id])
    ) {
      toggleRef.current.checked = false;
      setModalView(PREMIUM_VIEW);
      setDisplayModal(true);
    } else {
      if (!_.find(userSchools, ["school_id", school_id])) {
        const res = await addSchoolToUser(scenario?.case_id, school_id);
        if (res) {
          await setApplying(YES);
          populateOffers(scenario?.case_id);
        }
      } else {
        await setApplying(YES);
      }
      //update state for user schools
      populateUserSchools(scenario.case_id);
    }
  };

  const removeSchool = async () => {
    const res = await setApplying(NO);
    if (res) {
      //update state for user schools
      populateUserSchools(scenario?.case_id);
    }
  };

  const toggleSwitch = (event) => {
    //post or delete school from user's scenario, will appear in dashboard shop for schools section
    if (barLoading && !loadedSchools) {
      //prevent toggle from operating before loading schools
      toggleRef.current.checked = false;
    } else {
      try {
        if (event.target.checked) {
          addSchool();
        } else {
          removeSchool();
        }
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const updateCostBreakdown = async () => {
    if (scenario?.case_id) {
      try {
        const res = await getSchoolUserInfo(scenario.case_id, school_id);
        //use updated school data
        const school = res?.data?.result || schoolData;
        //update net cost calculation
        setNetCost(processNetCost(1, school.breakdowns));
        //update data sitewide so net cost matches on dashboard
        populateUserSchools(scenario?.case_id);
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const getScholarships = async () => {
    try {
      const res = await getSchoolScholarships(scenario.case_id, school_id);
      let totalAmount = 0;
      const scholarshipsArray = [];
      res.data.results.forEach((award) => {
        if (award.selected === YES && award.scholarship_amount) {
          totalAmount += parseInt(award.scholarship_amount);
          scholarshipsArray.push(award.scholarship_id);
        }

        // Add in the actual requirements
        // Adapted from scholarships_reports() within B2B
        award.requirements = [];
        if (award.min_sat > 0) {
          award.requirements.push("SAT " + award.min_sat);
        }
        if (award.min_act > 0) {
          award.requirements.push("ACT " + award.min_act);
        }
        if (award.min_gpa > 0) {
          award.requirements.push("GPA " + award.min_gpa);
          if (award.gpa_type) {
            award.requirements.push(award.gpa_type + " GPA");
          }
        }
        if (award.national_recognition_scholar > 0) {
          award.requirements.push("Recognition Scholar");
        }
        if (award.national_merit_commended > 0) {
          award.requirements.push("Natl Merit Commended");
        }
        if (award.national_merit_semi_finalist > 0) {
          award.requirements.push("Natl Merit Finalist");
        }
        if (award.national_merit_scholar > 0) {
          award.requirements.push("Natl Merit Scholar");
        }
        if (award.scores_required == "Yes") {
          award.requirements.push("Scores Required");
        }
        if (award.state_residence == "") {
          award.requirements.push("In-State");
          award.requirements.push("Out-of-State");
        } else {
          award.requirements.push(award.state_residence);
        }
        if (award.class_rank != "") {
          award.requirements.push("Class Rank: " + award.class_rank);
        }
        if (award.military_veteran == 1) {
          award.requirements.push("Military/Veteran");
        }
        if (award.international_baccalaureate == 1) {
          award.requirements.push("IB Student");
        }
        if (award.honors_program == 1) {
          award.requirements.push("Honors Program");
        }
        if (award.minority == "Yes") {
          award.requirements.push("Minority Only");
        }
        if (award.essay == "Yes") {
          award.requirements.push("Essay Required");
        }
        if (award.interview == "Yes") {
          award.requirements.push("Interview Required");
        }
        if (award.international_students == "Yes") {
          award.requirements.push("Intl Students");
        }
        if (award.portfolio_required == "Yes") {
          award.requirements.push("Portfolio Required");
        }
        if (award.audition == "Yes") {
          award.requirements.push("Audition Required");
        }
        /*
        // Deadline is already include in the previous column
        if (award.deadline != '0000-00-00') {
          award.requirements.push('Deadline ' + moment(award.deadline, "YYYY-MM-DD").format("MM/DD"));
        }
        */
        if (award.major != "All") {
          award.requirements.push(award.major + " majors only");
        }
        if (award.recipient_notes != "") {
          award.requirements.push(award.recipient_notes);
        }
        if (award.transfer == "Yes") {
          award.requirements.push("Available to Transfer Students");
        }
      });
      setAddedScholarshipIds(scholarshipsArray);
      setAwardsTotal(totalAmount);
      setScholarships([]); //clear data to force re-render on next line
      setScholarships(res.data.results);
    } catch (error) {
      handleApiError(error);
    }
  };

  const resetAwards = async () => {
    try {
      const res = await resetSchoolScholarships(scenario.case_id, school_id);
      if (res.data) {
        //reset checkboxes with correct data. Cannot be handled locally as resetting does not necessarily mean setting to zero.
        getScholarships();
        //Guarantee net cost calculation is correct
        updateCostBreakdown();
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  //Endpoint for adding or removing scholaships requires entire array of the ids of added schools. This is why there's a useState for addedScholarshipIds.
  //required props: scholarshipIdArray (an array of scholarship ids, representing which scholarships are "added")
  const updateAwards = async (scholarshipIdArray) => {
    try {
      await updateSchoolScholarships(
        scenario.case_id,
        school_id,
        scholarshipIdArray
      );
      //Guarantee net cost calculation is correct
      updateCostBreakdown();
    } catch (error) {
      handleApiError(error);
    }
  };

  const renderAwardsCards = () => {
    if (scholarships.length === 0) {
      return (
        <div className="text-center">
          <b>
            Scholarship information is currently unavailable for this school.
          </b>
        </div>
      );
    }
    return scholarships.map((award) => (
      <ScholarshipCard
        key={award.scholarship_id}
        scholarshipData={award}
        awardsTotal={awardsTotal}
        setAwardsTotal={setAwardsTotal}
        addedScholarshipIds={addedScholarshipIds}
        setAddedScholarshipIds={setAddedScholarshipIds}
        resetAwards={resetAwards}
        updateAwards={updateAwards}
      />
    ));
  };

  useEffect(() => {
    //Initialize toggle switch state
    if (toggleRef.current) {
      //school_id is an integer, whereas school_id is a string. Convert to string to match.
      //TODO: figure out a way to asynchronously set checked when school is added/ removed so that clicking between pages rapidly doesn't display false information
      if (_.isEqual(applying, YES)) {
        toggleRef.current.checked = true;
      }
    }
  }, [toggleRef]);

  useEffect(() => {
    if (scenario.case_id) {
      getScholarships();
    }
  }, [scenario.case_id]);

  useEffect(() => {
    //Use awards total to calculate net cost right away. Once backend call finishes, it will set the net cost but this will be instant.
    //Formally, the number being subtracted is known as the subtrahend, while the number it is subtracted from is the minuend. The result is the difference.
    //Subtract the greater number between need based grants and awards total
    const subtrahend =
      awardsTotal > parseInt(_.get(breakdowns, "[0].need_based_grants"))
        ? awardsTotal
        : parseInt(_.get(breakdowns, "[0].need_based_grants"));
    setNetCost(
      processUSD(
        parseInt(_.get(breakdowns, "[0].cost_of_attendance")) - subtrahend
      )
    );
    //Guarantee net cost calculation is correct. Above calculation is not always right so must use backend
    updateCostBreakdown();
  }, [awardsTotal]);

  return (
    <div className="schoolContainer  flex flex-col items-center w-full">
      <div className="topBackground w-full" />

      <div className="nav w-full flex flex-row items-center justify-between p-2 lg:px-6">
        <button
          className="w-3"
          onClick={() => {
            router.back();
          }}
        >
          <LongLeftArrow />
        </button>
        <b>School Detail Page</b>
        <div className="emptySpace" />
      </div>
      <div className="content w-full">
        <div className="schoolName flex flex-row p-2 lg:px-0 lg:mt-4">
          {renderLogo(logo, name)}
          <div className="text flex flex-col justify-center">
            <h3>{name || "University Name"}</h3>
          </div>
        </div>
        <div className="w-full p-2 mb-1 lg:px-0 lg:mt-2 lg:mb-4">
          <div className="financialDetails flex flex-col w-full p-3">
            <div className="flex flex-row w-full justify-between pb-2">
              <b>Cost of Attendance</b>
              <b>{processCostOfAttendance(breakdowns)}</b>
            </div>
            <div
              className={`${
                awardsTotal >
                parseInt(_.get(breakdowns, "[0].need_based_grants"))
                  ? ""
                  : "font-demi-bold"
              } flex flex-row w-full justify-between pb-2`}
            >
              <span>Need Based Grants</span>
              <span>{processNeedBasedGrants(breakdowns)}</span>
            </div>
            <div
              className={`${
                awardsTotal >
                parseInt(_.get(breakdowns, "[0].need_based_grants"))
                  ? "font-demi-bold"
                  : ""
              } flex flex-row w-full justify-between pb-3`}
            >
              <span>Merit Scholarship</span>
              <span>{processUSD(awardsTotal)}</span>
            </div>
            <div className="divider mb-3"></div>
            <div className="flex flex-row w-full justify-between items-center">
              <b>Your Net Cost (Year 1)</b>
              <span className="h2 font-demi-bold removeMargin">{netCost}</span>
            </div>
          </div>
        </div>
        <div className="applyingContainer flex flex-row justify-between items-center w-full p-2 lg:px-3">
          <b>Are you applying to this school?</b>
          {/* Will add school to user's Manage Applications section*/}
          <label className="toggle">
            <input type="checkbox" ref={toggleRef} onChange={toggleSwitch} />
            <span className="slider">
              <div className="yes">Yes</div>
              <div className="no">No</div>
            </span>
          </label>
        </div>
        <div className="schoolInfo">
          <h2 className="px-2 lg:p-0">School Information</h2>
          <div className="drawers">
            <AdmissionsDrawer admissionsData={schoolData} />
            <FinancialAidDrawer financialAidData={schoolData} />
            <OutcomesDrawer outcomesData={schoolData} />
          </div>
        </div>
        <div className="scholarships flex flex-col items-start px-2 lg:px-0">
          <div className="text w-full mb-4 lg:mb-3">
            <h2 className="title removeMargin lg:mb-4">
              Available Merit Scholarships
            </h2>
            <div className="awards flex flex-row justify-between items-end w-full">
              <div className="flex flex-col">
                <small className="awardsTotal">Awards Total</small>
                <h2 className="title removeMargin">
                  {processUSD(awardsTotal)}
                </h2>
              </div>
              <button
                className="tertiary flex justify-center items-center"
                onClick={() => {
                  resetAwards();
                }}
              >
                <small>Reset</small>
              </button>
            </div>
          </div>
        </div>
        <div className="awardsColumns hidden md:block w-full flex flex-row py-3 px-5 lg:p-3">
          <div className="small font-regular w-4/12">Award Name</div>
          <div className="small font-regular">Award Amount</div>
          <div className="small font-regular">Requirements</div>
          <div className="small font-regular">Select</div>
        </div>
        <div className="scholarshipCards px-2 lg:px-0">
          {renderAwardsCards()}
        </div>
      </div>
    </div>
  );
};

export default SchoolDetail;
