import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import _ from "lodash";
import { flatten, unflatten } from "flat";

import { useAppContext } from "@context/state.js";
import { getbasicInfo, getStudentDetail } from "@utils/studentData";
import { getParentGuardianInfo } from "@utils/parentData";
import { getMajors, getEthnicities, getReligions } from "@api/misc";
import { PersonalInfoInput } from "@components/index";
import { updatePersonalInfo } from "@api/user";
import { updateProfileProgress } from "@api/profileProgress";
import { NO } from "@utils/constants";
import { mapSiblingData } from "@utils/mappingHelpers";
import { getTotalPersonalInfoWeightPercentage } from "@utils/calcEnhancedProfile";
import {
  marriedOptions,
  unmarriedOptions,
  hasSpouse,
  isFilingJointly,
} from "@utils/maritalStatusCheck";

/**
 * Component to edit personal information for a user that
 * opens in personal_info page.
 */
const PersonalInfoEditModal = () => {
  const methods = useForm();

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting, dirtyFields },
  } = methods;

  const dirtyFieldValues = Object.entries(dirtyFields).reduce(
    (values, [fieldName, isDirty]) => {
      if (isDirty) {
        values[fieldName] = watch(fieldName);
      }
      return values;
    },
    {}
  );

  const {
    scenario,
    setScenario,
    setDisplayFormModal,
    populateRecalculatedAddedSchools,
    handleApiError,
    checkForNeededDashboardInfo,
  } = useAppContext();

  const { onboarding } = scenario;

  //NOTE: intended_major_object rather than intended_major, since we wanted to return more info related to the chosen major without breaking things that previously relied on intended_major being an integer.
  const major = onboarding?.student?.intended_major_object;
  const ethnicity = onboarding?.student?.ethnicity;
  const religion = onboarding?.student?.religion;
  const [majors, setMajors] = useState([]);
  const [ethnicities, setEthnicities] = useState([]);
  const [religions, setReligions] = useState([]);

  const updateUser = async () => {
    //NOTE: Make sure all fields can be marked as dirty. Fields that rely on setValue must include the flag { shouldDirty: true }.
    const dirtyKeys = Object.keys(flatten(dirtyFields));
    const dirtyObject = dirtyKeys.reduce((result, key) => {
      result[key] = getValues(key) === "" ? null : getValues(key);
      return result;
    }, {});
    const changedValues = unflatten(dirtyObject);

    const { parent_guardian, student } = changedValues;

    if (parent_guardian) {
      //reset fields according to filing status
      const {
        filing_status,
        is_homeowner,
        is_ex_spouse_homeowner,
        self_employed,
        self_employed_2,
        self_employed_3,
        agi,
        agi_2,
        agi_3,
        marital_status,
      } = parent_guardian;
      if (
        filing_status &&
        isFilingJointly(
          filing_status,
          marital_status || onboarding?.parent_guardian?.marital_status
        )
      ) {
        //reset retirement_payments since it is combined into retirement_payments_2 with spouse's contributions
        changedValues.parent_guardian.retirement_payments = null;
      }
      //reset home equity if not homeowner
      if (is_homeowner === NO) {
        changedValues.parent_guardian.home_equity = null;
      }
      if (is_ex_spouse_homeowner === NO) {
        changedValues.parent_guardian.home_equity_3 = null;
      }
      //reset business equity if not self employed
      if (self_employed === NO) {
        changedValues.parent_guardian.business_equity = null;
      }
      if (self_employed_2 === NO) {
        changedValues.parent_guardian.business_equity_2 = null;
      }
      if (self_employed_3 === NO) {
        changedValues.parent_guardian.business_equity_3 = null;
      }
      //if agi was changed and not null, mark as true
      //if agi was changed and null, mark as false
      if (parent_guardian.hasOwnProperty("agi")) {
        if (agi !== null) {
          changedValues.parent_guardian.agi_user_provided = true;
        } else {
          changedValues.parent_guardian.agi_user_provided = false;
        }
      }
      if (parent_guardian.hasOwnProperty("agi_2")) {
        if (agi_2 !== null) {
          changedValues.parent_guardian.agi_user_provided_2 = true;
        } else {
          changedValues.parent_guardian.agi_user_provided_2 = false;
        }
      }
      if (parent_guardian.hasOwnProperty("agi_3")) {
        if (agi_3 !== null) {
          changedValues.parent_guardian.agi_user_provided_3 = true;
        } else {
          changedValues.parent_guardian.agi_user_provided_3 = false;
        }
      }
    }
    if (student) {
      if (student.hasOwnProperty("student_agi")) {
        if (student.student_agi !== null) {
          changedValues.student.student_agi_user_provided = true;
        } else {
          changedValues.student.student_agi_user_provided = false;
        }
      }
    }

    changedValues.student = mapSiblingData(changedValues);

    const res = await updatePersonalInfo(scenario?.case_id, changedValues);
    if (res?.data?.result) {
      const onboarding = _.get(res.data.result, "onboarding");
      const newScenario = {
        ...scenario,
        onboarding,
      };

      const profileProgress =
        getTotalPersonalInfoWeightPercentage(onboarding).percentComplete;
      const res2 = await updateProfileProgress(
        scenario?.case_id,
        profileProgress
      );

      setScenario(newScenario);
      checkForNeededDashboardInfo(onboarding);
      if (
        profileProgress < 100 ||
        !_.get(onboarding, "student.scores.sat") ||
        !_.get(onboarding, "student.scores.weighted_gpa")
      ) {
        sessionStorage.setItem("hide_cta", false);
      }
      populateRecalculatedAddedSchools(scenario.case_id, newScenario);
    }
    setDisplayFormModal(false);
  };

  const onSubmit = async () => {
    try {
      await updateUser();
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [majors, ethnicities, religions] = await Promise.all([
          getMajors(),
          getEthnicities(),
          getReligions(),
        ]);
        const nameToLabelIdToValue = ({ id, name }) => {
          return { label: name, value: id };
        };
        setMajors(majors.data.map(nameToLabelIdToValue));
        setEthnicities(ethnicities.data.map(nameToLabelIdToValue));
        setReligions(religions.data.map(nameToLabelIdToValue));
      } catch (error) {
        //This is a public route, so 401 or 403 errors are not expected.
        handleApiError(error);
      }
    }

    fetchData();
  }, []);

  /**
   * Reset react hook form when component
   * unmounts
   */
  useEffect(() => {
    return () => {
      methods.reset();
    };
  }, []);

  useEffect(() => {
    if (dirtyFields?.parent_guardian?.marital_status) {
      const maritalStatus = watch("parent_guardian.marital_status");
      const getFilingStatuses = (maritalStatus) => {
        if (hasSpouse(maritalStatus)) {
          return marriedOptions
            .filter((val) => val !== "-")
            .map((marriedOption) => {
              return { label: marriedOption, value: marriedOption };
            });
        }
        return unmarriedOptions
          .filter((val) => val !== "-")
          .map((unmarriedOption) => {
            return { label: unmarriedOption, value: unmarriedOption };
          });
      };
      const filingStatuses = getFilingStatuses(maritalStatus);
      //dirty filing status if married status is changed
      setValue("parent_guardian.filing_status", filingStatuses[0].value, {
        shouldDirty: true,
      });
    }
  }, [watch("parent_guardian.marital_status")]);

  //use FormProvider for forms containing heavily nested components, and use useFormContext for those nested components to get the react hook form methods
  return (
    <FormProvider {...methods}>
      <form
        className="w-full h-full flex flex-col items-center text-left"
        action="#"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="modal-header">
          <button
            type="button"
            className="imitateLink border-0 text-left small"
            onClick={() => setDisplayFormModal(false)}
          >
            Cancel
          </button>
          <h6 className="mb-0 text-center capitalize">Personal Info</h6>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`${isSubmitting ? "pt-1" : ""}
          border-0 text-right small save`}
          >
            {isSubmitting ? (
              <ClipLoader
                size={20}
                css={{
                  borderColor: "var(--primary-color)",
                  borderBottomColor: "transparent",
                }}
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
        <div className="modal-scroll flex justify-center">
          <div className="modal-content form-content">
            <div className="profileContainer flex flex-col items-center justify-start h-full w-full mb-5">
              <div className="profileDirectory flex flex-col  items-center w-full">
                <div className="informationEditBlock mt-4 mb-4">
                  <h3>Basic Info</h3>
                  {getbasicInfo(onboarding).map((inputData, index) => (
                    <PersonalInfoInput key={index} input={inputData} />
                  ))}
                </div>
              </div>
              <div className="profileDirectory flex flex-col  items-center w-full">
                <div className="informationEditBlock mb-4">
                  <h3>Student Info</h3>
                  {getStudentDetail({
                    onboarding,
                    major,
                    userMajorEthnicityOrReligionId: true,
                    ethnicity,
                    religion,
                    dirtyFieldValues,
                  }).map((inputData, index) => (
                    <PersonalInfoInput
                      key={index}
                      input={inputData}
                      majors={majors}
                      ethnicities={ethnicities}
                      religions={religions}
                    />
                  ))}
                </div>
              </div>
              <div className="profileDirectory flex flex-col  items-center w-full">
                <div className="informationEditBlock mb-10">
                  <h3>Parent/Guardian Info</h3>
                  {getParentGuardianInfo(onboarding, dirtyFieldValues).map(
                    (inputData) => (
                      <PersonalInfoInput
                        key={inputData.mapId}
                        input={inputData}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
export default PersonalInfoEditModal;
