import { AppealsGallery } from "../EvaluateAppeal";

/**
 * Show concise Appeal Cards in gallery
 * @returns Evaluate & Appeal section on dashboard
 */
const EvaluateAppealSection = () => {
  return (
    <div className="offers-container">
      <AppealsGallery title={"Evaluate & Appeal"} />
    </div>
  );
};

export default EvaluateAppealSection;
