const Community = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 36 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Community"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M18,34 C26.836556,34 34,26.836556 34,18 C34,9.163444 26.836556,2 18,2 C9.163444,2 2,9.163444 2,18 C2,26.836556 9.163444,34 18,34 Z M18,2 C13.9365079,7.33333333 11.9047619,12.6666667 11.9047619,18 C11.9047619,23.3333333 13.9365079,28.6666667 18,34 C22.0634921,28.6666667 24.0952381,23.3333333 24.0952381,18 C24.0952381,12.6666667 22.0634921,7.33333333 18,2 Z M2.5,18.5 L33.2380952,18"
            id="Icon"
            stroke="currentColor"
            strokeWidth="2"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Community;
