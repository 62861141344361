import Link from "next/link";

import validURL from "@utils/validURL";
import protocolCheck from "@utils/protocolCheck";

import { Drawer, DrawerRow } from "@components/index";

const AdmissionsDrawer = ({ admissionsData }) => {
  const {
    freshman_gpa,
    sat_verbal_25,
    sat_verbal_75,
    sat_math_25,
    sat_math_75,
    act_25,
    act_75,
    common_app,
    test_optional,
    forbes_ranking,
    us_news_ranking,
    money_ranking,
    undergraduate_majors, //NOTE: Missing from backend response, but part of design
    undergraduate_enrollment,
    percent_admitted,
    graduation_rate,
  } = admissionsData;

  return (
    <Drawer title="Admissions">
      <DrawerRow title="GPA" value={freshman_gpa} />
      <DrawerRow
        title="SAT Verbal Range"
        value={
          sat_verbal_25 && sat_verbal_75
            ? `${sat_verbal_25} - ${sat_verbal_75}`
            : undefined
        }
      />
      <DrawerRow
        title="SAT Math Range"
        value={
          sat_math_25 && sat_math_75
            ? `${sat_math_25} - ${sat_math_75}`
            : undefined
        }
      />
      <DrawerRow
        title="ACT Range"
        value={act_25 && act_75 ? `${act_25} - ${act_75}` : undefined}
      />
      <DrawerRow title="Common App" value={common_app} />
      <DrawerRow title="Test Optional" value={test_optional} />
      <DrawerRow title="Forbes Ranking" value={forbes_ranking} />
      <DrawerRow title="US News Ranking" value={us_news_ranking} />
      <DrawerRow title="Money Ranking" value={money_ranking} />
      <DrawerRow title="Undergraduate Majors" value={undergraduate_majors} />
      <DrawerRow title="Enrollment" value={undergraduate_enrollment} />
      <DrawerRow title="% Admitted" value={percent_admitted} />
      <DrawerRow title="4 Year Graduation Rate" value={graduation_rate} />
    </Drawer>
  );
};

export default AdmissionsDrawer;
