import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { Card } from "@components/index";
import { useAppContext } from "@context/state";
import renderLogo from "@utils/renderLogo";
import { useRouter } from "next/router";
import AppealFactorsList from "./AppealFactorsList";
import AppealDetails from "./AppealDetails";

/**
 * @param {object} schoolData - the detailed data about the school obtained from the api
 * @param {boolean} showMoreDetails - decide whether or not the card should show all or minimal information
 * @returns card component that appears in the appeals page and appeals section of the dashboard
 */
const AppealCard = ({ offerData, showMoreDetails }) => {
  if (!offerData?.score_result) {
    return null;
  }
  const cardRef = useRef();

  const { school, tuition_budget, score_result } = offerData;
  const { school_id, logo, name, breakdowns } = school ? school : {};

  const { score } = score_result;

  const { category, chances } = score;

  const [hasApplicableCategories, setHasApplicableCategories] = useState(false);

  const { scenario, setScrollToCard } = useAppContext();

  const router = useRouter();

  const [expectedNetCostRating, setExpectedNetCostRating] = useState();

  const handleMoreInfoButton = () => {
    //Set scroll left default for appeals section and navigate to /evaluate_appeal
    //set index of card to scroll to
    const cardGalleryIndex = [...cardRef.current.parentNode.children].indexOf(
      cardRef.current
    );
    setScrollToCard(cardGalleryIndex);
    router.push("/evaluate_appeal");
  };

  useEffect(() => {
    const percentDifference =
      tuition_budget?.net_cost_1 / breakdowns[0]?.net_cost - 1;
    if (percentDifference > 0.05) {
      setExpectedNetCostRating("Lower");
    } else if (percentDifference < -0.05) {
      setExpectedNetCostRating("Higher");
    } else {
      setExpectedNetCostRating("In line");
    }
  }, [tuition_budget?.net_cost_1, breakdowns[0]?.net_cost]);

  useEffect(() => {
    if (
      category?.amount?.applicable ||
      category?.school?.applicable ||
      category?.family?.applicable ||
      category?.unique?.applicable
    ) {
      setHasApplicableCategories(true);
    }
  }, [category]);

  return (
    <div className="cardContainer" ref={cardRef}>
      <Card type="typeTall">
        <div className="flex flex-col justify-start h-full">
          <div className="topHeading">
            <div className="flex flex-row justify-between items-start">
              {renderLogo(logo, name)}
              {!showMoreDetails && (
                <button
                  onClick={handleMoreInfoButton}
                  className="imitateLink flex items-start"
                >
                  <small>
                    <b>More Info</b>
                  </small>
                </button>
              )}
            </div>
            <div className="h4">
              <Link
                href={`/case/${scenario.case_id}/school/${[school_id]}`}
                className="underline inherit">
                {name}
              </Link>
            </div>
            <div className="affordabilityStatus flex flex-row items-center">
              <small className="micro">Appeal Potential</small>
              <small
                className={`micro status ${chances ? chances.replace(/\s+/g, "-").toLowerCase() : "low"
                  } ideal flex justify-center items-center`}
              >
                {chances}
              </small>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="divider" />
            <div className="award-details">
              {showMoreDetails ? (
                <AppealDetails
                  awardNetCost={tuition_budget?.net_cost_1}
                  estimatedNetCost={breakdowns[0]?.net_cost}
                  expectedNetCostRating={expectedNetCostRating}
                  hasApplicableCategories={hasApplicableCategories}
                />
              ) : (
                <>
                  <small className="mb-2">
                    <span className="font-bold">Award Amount:</span>{" "}
                    {expectedNetCostRating === "In line"
                      ? "In line with projections"
                      : `${expectedNetCostRating} than expected`}
                  </small>
                  {hasApplicableCategories && (
                    <small>
                      Factors indicating you should consider appealing
                    </small>
                  )}
                </>
              )}
              <AppealFactorsList
                category={category}
                showMoreDetails={showMoreDetails}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AppealCard;
