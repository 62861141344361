import _ from "lodash";
import { useEffect } from "react";
import ProgressBar from "../ProgressBar.js";
import NumberFormat from "react-number-format";
import { hasExSpouse } from "@utils/maritalStatusCheck";
import { infoTips } from "@utils/infoTips";

const Step13 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
  watch,
  getValues,
  setValue,
}) => {
  if (formStep !== 15) {
    // Don't render unless step matches
    return null;
  }

  const homeowner = watch("is_homeowner");
  const ex_spouse_homeowner = watch("is_ex_spouse_homeowner");
  const maritalStatus = getValues("parent_guardian.marital_status");

  useEffect(() => {
    const homeEquity = getValues("parent_guardian.home_equity");
    //only set starter value if previously filled out and submitted
    if (homeEquity) {
      setValue("is_homeowner", homeEquity === "0" ? "No" : "Yes");
    }
    trigger(["parent_guardian.home_equity"]);
    const exSpousehomeEquity = getValues("parent_guardian.home_equity_3");
    //only set starter value if previously filled out and submitted
    if (exSpousehomeEquity) {
      setValue(
        "is_ex_spouse_homeowner",
        exSpousehomeEquity === "0" ? "No" : "Yes"
      );
    }
    // trigger(["parent_guardian.home_equity_3"]);
    //only home_equity will be saved. is_homeowner will be discarded
    setWatchFields(["parent_guardian"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "Home Equity",
        description: _.get(
          infoTips,
          "scenario.onboarding.parent_guardian.home_equity"
        ),
      },
    ]);
  }, []);

  useEffect(() => {
    if (homeowner === "No") {
      setValue("parent_guardian.home_equity", "0");
    }
  }, [homeowner]);

  useEffect(() => {
    if (ex_spouse_homeowner === "No") {
      setValue("parent_guardian.home_equity_3", "0");
    }
  }, [ex_spouse_homeowner]);

  return (
    <div className="guardianInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep - 1} />
      <div className="guardianInfoText">
        <h5>
          Is {_.get(formData, "parent_guardian.parent_name") || "Parent"} a
          homeowner?
        </h5>
      </div>
      <div className="inputs flex flex-row homeowner">
        <input
          type="radio"
          value="Yes"
          id="Yes"
          {...register("is_homeowner", {
            required: {
              value: true,
              message: "Please specify homeowner status",
            },
          })}
        />
        <label htmlFor="Yes" className="radioOption">
          <span className="radioLabel">Yes</span>
        </label>
        <div className="mx-2" />
        <input
          type="radio"
          value="No"
          id="No"
          {...register("is_homeowner", {
            required: {
              value: true,
              message: "Please specify homeowner status",
            },
          })}
        />
        <label htmlFor="No" className="radioOption">
          <span className="radioLabel">No</span>
        </label>
      </div>
      {homeowner === "Yes" && (
        <div>
          <hr className="divider" />
          <div className="guardianInfoText">
            <h5>How much equity do you have in your home?</h5>
          </div>
          <div className="inputs flex flex-col text-left">
            <label
              htmlFor="parent_guardian.home_equity"
              className="textLabel currencyLabel small"
            >
              <span className="labelFocus">Equity amount</span>
              <NumberFormat
                value={getValues("parent_guardian.home_equity")}
                {...register("parent_guardian.home_equity", {
                  required: {
                    value: true,
                    message: "Please enter your home equity",
                  },
                })}
                onValueChange={({ value }) => {
                  setValue("parent_guardian.home_equity", value);
                  trigger("parent_guardian.home_equity");
                }}
                thousandSeparator={true}
              />
              <div className="currency resetFont">$</div>
            </label>
          </div>
        </div>
      )}
      <hr className="divider" />
      {hasExSpouse(maritalStatus) && (
        <>
          <div className="guardianInfoText">
            <h5>Is Ex-Spouse a homeowner?</h5>
          </div>
          <div className="inputs flex flex-row homeowner">
            <input
              type="radio"
              value="Yes"
              id="Ex_Yes"
              {...register("is_ex_spouse_homeowner", {
                required: {
                  value: true,
                  message: "Please specify homeowner status",
                },
              })}
            />
            <label htmlFor="Ex_Yes" className="radioOption">
              <span className="radioLabel">Yes</span>
            </label>
            <div className="mx-2" />
            <input
              type="radio"
              value="No"
              id="Ex_No"
              {...register("is_ex_spouse_homeowner", {
                required: {
                  value: true,
                  message: "Please specify homeowner status",
                },
              })}
            />
            <label htmlFor="Ex_No" className="radioOption">
              <span className="radioLabel">No</span>
            </label>
          </div>
        </>
      )}
      {ex_spouse_homeowner === "Yes" && (
        <div className="mb-8">
          <hr className="divider" />
          <div className="guardianInfoText">
            <h5>How much equity does your ex-spouse have in their home?</h5>
          </div>
          <div className="inputs flex flex-col text-left">
            <label
              htmlFor="parent_guardian.home_equity_3"
              className="textLabel currencyLabel small"
            >
              <span className="labelFocus">Equity amount</span>
              <NumberFormat
                value={getValues("parent_guardian.home_equity_3")}
                {...register("parent_guardian.home_equity_3", {
                  required: {
                    value: true,
                    message: "Please enter your ex spouse's home equity",
                  },
                })}
                onValueChange={({ value }) => {
                  setValue("parent_guardian.home_equity_3", value);
                  trigger("parent_guardian.home_equity_3");
                }}
                thousandSeparator={true}
              />
              <div className="currency resetFont">$</div>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step13;
