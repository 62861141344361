import { DownArrow } from "@icons/index";

const CAPSelect = ({ register, title, field, options, defaultValue }) => {
  return (
    <label htmlFor={field} className="selectLabel small">
      <div>{title}</div>
      <select
        id={field}
        {...register(field)}
        defaultValue = {defaultValue}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}

      </select>
      <div className="select-icon md:flex">
        <DownArrow />
      </div>
    </label>
  );
};

export default CAPSelect;
