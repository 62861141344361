import _ from "lodash";
import { Checkmark } from "@icons/index";
import { Card } from "@components/index";
import { useEffect, useRef } from "react";
import { useAppContext } from "@context/state";
import { PREMIUM_VIEW } from "@utils/constants";
import renderLogo from "@utils/renderLogo";
import { removeSchoolFromUser } from "@api/schools";
import { processCostOfAttendance, processNetCost } from "@utils/costEstimates";
import { useRouter } from "next/router";

const SchoolCard = (props) => {
  const { schoolData } = props;

  const {
    school_id,
    // Advanded search response contains college_id instead of school_id
    // value is indentical but is an integer, Where as school_id comes as string.
    // Need to address, should contain only one type of ID in both calls.
    name,
    logo,
    forbes_ranking,
    breakdowns,
  } = schoolData;

  const {
    scenario,
    userSchools,
    upgraded,
    setModalView,
    setDisplayModal,
    advancedSearchListChecked,
    setAdvancedSearchListChecked,
    populateUserSchools,
    handleApiError,
    populateOffers,
  } = useAppContext();

  //Checkbox
  const checkboxRef = useRef();
  const router = useRouter();

  const addSchool = async () => {
    const selectedSchoolsCount = advancedSearchListChecked.length;
    const userSchoolsCount = userSchools.filter((school) => school.visible === true).length;
    if (((selectedSchoolsCount + userSchoolsCount) >= 3) && !upgraded) {
      checkboxRef.current.checked = false;
      setModalView(PREMIUM_VIEW);
      setDisplayModal(true);
    } else {
      setAdvancedSearchListChecked((cur) => [...cur, `${school_id}`]);
    }
  };

  const openPremiumModal = () => {
    setModalView(PREMIUM_VIEW);
    setDisplayModal(true);
  };

  const removeSchool = async () => {
    const myArr = advancedSearchListChecked;
    const index = myArr.indexOf(`${school_id}`);
    if (index > -1) {
      myArr.splice(index, 1);
      setAdvancedSearchListChecked(myArr);
    }

    const inUserSchools = userSchools.find((school) => school.school_id === school_id);
    // Only remove from user's scenario if user is upgraded
    if (upgraded && inUserSchools) {
      try {
        const res = await removeSchoolFromUser(scenario?.case_id, school_id);
        if (res) {
          //update state for user schools
          populateUserSchools(scenario.case_id);
          populateOffers(scenario?.case_id);
        }
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const toggleCheckbox = (event) => {
    //post or delete school from user's scenario
    if (event.target.checked) {
      addSchool();
    } else {
      if (upgraded) {
        removeSchool();
      } else {
        setModalView(PREMIUM_VIEW);
        setDisplayModal(true);
      };
    };
  };

  useEffect(() => {
    //Initialize checkbox state
    if (checkboxRef.current) {
      // In advanced search response we get college_id instead of school_id which is
      // identical, but is an integer value, so convert to string to match in userSchools
      //  if exist, for settig up the initial state of checkbox.
      const id = school_id;
      const foundSchool = _.find(userSchools, { school_id: id });
      if (foundSchool && foundSchool?.visible) {
        checkboxRef.current.checked = true;
      }
    }
  }, [checkboxRef]);

  return (
    <div className="cardContainer">
      <Card type="typeShort">
        <div className="basicSchoolInfo flex flex-col md:flex-row md:items-center">
          <div className="topHeading w-10/12 md:w-4/12">
            <div className="flex flex-row items-center md:pl-3 md:py-3">
              {/* The Letter sticker corresponds to a pin in the map that shows up when a school is added to the list. Feature not active */}
              {/* <div className="pinSticker micro rounded-full flex justify-center items-center hidden md:flex">
                A
              </div> */}
              {renderLogo(logo, name)}
              <button onClick={() => {
                upgraded
                  ? router.push(`/case/${scenario.case_id}/school/${school_id}`)
                  : openPremiumModal();
              }}><span className="h6 inherit removeMargin underline">{name}</span></button>
            </div>
          </div>
          <div className="forbesRanking flex flex-row items-center md:w-2/12">
            {/* <div className="pinSticker micro rounded-full flex justify-center items-center md:hidden">
              A
            </div> */}
            <small className="rank md:w-full md:pl-3">
              <span className="md:hidden">Forbes Ranking:</span>{" "}
              {forbes_ranking ? `#${forbes_ranking}` : "Not Reported"}
            </small>
          </div>
          <div className="price flex flex-row justify-between md:w-4/12">
            <div className="quote flex flex-col">
              <small className="mb-0.5 md:hidden">Sticker Price</small>
              <small className="stickerPrice">{processCostOfAttendance(breakdowns)}</small>
            </div>
            <div className="quote flex flex-col">
              <small className="mb-0.5 md:hidden">Your Net Cost (Year 1)</small>
              <small className="netCost" >{processNetCost(1, breakdowns)}</small>
            </div>
          </div>
          <label className="checkbox md:mr-3">
            <input
              type="checkbox"
              ref={checkboxRef}
              onChange={toggleCheckbox}
            />
            <span className="checkmark">
              <Checkmark />
            </span>
          </label>
        </div>
      </Card>
    </div>
  );
};


export default SchoolCard;
