import { useState } from "react";
import { Collapsible, DropdownBtn } from "@components/index";
import BreakdownRow from "./BreakdownRow";
import { Checkmark, RightArrow, ClockIcon } from "@icons/index";

/**
 * @param {*} title - Element
 * @param {*} children
 * @param {number} forcedValue - value that will be enforced. Only for read only inputs
 * @param {number} defaultValue - default value
 * @param {string} inputId - inputId for registering input
 * @param {string} status - status of questionnaire. "Get Started", "In Progress", or "Done"
 * @returns Drawer component with buttons for a questionnaire and collapsible related rows
 */
const BreakdownDrawer = ({
  title,
  children,
  forcedValue,
  defaultValue,
  inputId,
  status,
  helpText,
}) => {
  const [expanded, setExpanded] = useState(false);

  const getStatusClassName = (status) => {
    switch (status) {
      case "Done":
        return "done";
      case "In Progress":
        return "in-progress";
      default:
        //"Get Started"
        return "get-started";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "Done":
        return <Checkmark />;
      case "In Progress":
        return <ClockIcon />;
      default:
        //"Get Started"
        return (
          <div className="double-arrow w-full h-full relative">
            <RightArrow />
            <RightArrow />
          </div>
        );
    }
  };

  return (
    <div className="drawer flex flex-col w-full">
      <BreakdownRow
        title={title}
        readOnly={true}
        progressButton={
          <>
            <button
              type="button"
              className={`questionnaire-btn ${getStatusClassName(
                status
              )} secondary ml-2 hidden md:block`}
              // onClick={() => console.log("OPEN QUESTIONNAIRE")}
            >
              <small className="whitespace-nowrap p-1">{status}</small>
            </button>
            <button
              type="button"
              className={`progress-btn ${getStatusClassName(
                status
              )} secondary rounded-full flex items-center justify-center small md:hidden`}
              // onClick={() => console.log("OPEN QUESTIONNAIRE")}
            >
              {getStatusIcon(status)}
            </button>
          </>
        }
        helpText={helpText}
        inputId={inputId}
        defaultValue={defaultValue}
        forcedValue={forcedValue}
        textClassName="cursor-pointer"
        onClick={() => setExpanded((cur) => !cur)}
        type="Calculated"
      >
        <DropdownBtn className="justify-end ml-1.5" expanded={expanded} />
      </BreakdownRow>
      <Collapsible expanded={expanded}>
        <div className="accordion">{children}</div>
      </Collapsible>
    </div>
  );
};

export default BreakdownDrawer;
