import { useEffect } from "react";
import { useAppContext } from "@context/state";
import { FormProvider, useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { RadioField } from "@components/index";
import { YES, NO } from "@utils/constants";
import {
  CollegePreApprovalInput,
  CollegePreApprovalSum,
} from "@components/index";
import { updatePreApprovals } from "@api/user";
import _ from "lodash";
import { formatDollarAmount } from "@utils/costFormatter";
import { removeNullValues } from "@utils/mappingHelpers";

/**
 * Component to edit Pre-Approval information for a user that
 * opens in College Pre-Approval page.
 */
const CollegePreApprovalEditModal = () => {
  const methods = useForm();

  const {
    watch,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const savingsExist = watch("savings_exist");

  const {
    scenario,
    setScenario,
    setDisplayFormModal,
    handleApiError,
    populateRecalculatedAddedSchools,
    populateOffers,
  } = useAppContext();
  const { preapprovals } = scenario;

  //These will be new preapproval keys, needed for providing the default values for this section.
  const {
    funds_in_529,
    monthly_529_funds,
    monthly_529_months,
    parent_pledged_assets,
    parent_pledged_monthly,
    student_pledged_monthly,
    other_help,
  } = preapprovals;

  const onSubmit = async (values) => {
    removeNullValues(values?.preapprovals);
    try {
      //By registering needed values to preapprovals in the form, we can bypass submitting unusable values such as "savings_exist"
      const res = await updatePreApprovals(
        scenario?.case_id,
        values?.preapprovals
      );
      if (res.data) {
        const newScenario = {
          ...scenario,
          preapprovals: res.data.preapprovals,
        };
        setScenario(newScenario);
        setDisplayFormModal(false);
        //update EFCs and preapprovals
        populateRecalculatedAddedSchools(scenario.case_id, newScenario);
        populateOffers(scenario.case_id);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * Reset react hook form when component
   * unmounts
   */
  useEffect(() => {
    setValue(
      "savings_exist",
      funds_in_529 || (monthly_529_funds && monthly_529_months) ? YES : NO
    );

    return () => {
      methods.reset();
    };
  }, []);

  /**
   * When selecting 'No' for 'Do you have a 529 savings plan?', reset 529 values to zero except number of months.
   */
  useEffect(() => {
    const savingsExist = getValues("savings_exist");
    if (savingsExist === NO) {
      setValue("preapprovals.funds_in_529", 0, { shouldDirty: true });
      setValue("preapprovals.monthly_529_funds", 0, { shouldDirty: true });
      setValue("preapprovals.monthly_529_months", 0, { shouldDirty: true });
    }
  }, [watch("savings_exist")]);

  return (
    <FormProvider {...methods}>
      <form
        className="preapproval-form w-full h-full flex flex-col items-center text-left"
        action="#"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="modal-header">
          <button
            type="button"
            className="imitateLink border-0 text-left small"
            onClick={() => setDisplayFormModal(false)}
          >
            Cancel
          </button>
          <h6 className="mb-0 text-center capitalize">My College Budget</h6>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`${isSubmitting ? "pt-1" : ""}
          border-0 text-right small save`}
          >
            {isSubmitting ? (
              <ClipLoader
                size={20}
                css={{
                  borderColor: "var(--primary-color)",
                  borderBottomColor: "transparent",
                }}
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
        <div className="modal-scroll flex justify-center">
          <div className="modal-content form-content">
            <div className="flex flex-col items-center justify-start h-full w-full">
              <div className="flex flex-col  items-center w-full px-3 md:p-0">
                <div className="preApprovalInfoEditBlock">
                  <h3>College Contributions</h3>
                  <div className="flex flex-col mb-4">
                    <div className="flex mb-3 md:justify-center">
                      <label htmlFor="savings_exist">
                        Do you have a 529 Savings Plan?
                      </label>
                    </div>
                    <RadioField
                      input={{
                        mapId: "savings_exist",
                        type: "radio",
                        optionsType: "radio",
                        radioOptions: [YES, NO].map((option) => {
                          return { label: option, value: option };
                        }),
                      }}
                    />
                  </div>
                  {savingsExist === "Yes" && (
                    <div className="w-full flex flex-col">
                      <CollegePreApprovalInput
                        label="Current 529 Savings Plan Balance"
                        mapId="preapprovals.funds_in_529"
                        value={funds_in_529}
                        prefix="$"
                        helpText={
                          <span>
                            Current 529 balance for{" "}
                            {_.get(
                              scenario,
                              "onboarding.student.student_name"
                            ) || "THIS STUDENT"}{" "}
                            at this moment.
                            <br />
                            <br />
                            <b>Do NOT include</b> contributions you expect to
                            make in the future OR 529 balances intended for use
                            for siblings or other beneficiaries.
                          </span>
                        }
                      />
                      <CollegePreApprovalInput
                        label="Monthly Contributions to 529 Account"
                        mapId="preapprovals.monthly_529_funds"
                        value={monthly_529_funds}
                        prefix="$"
                        helpText="How much money will be added to the 529 account each month."
                      />
                      <CollegePreApprovalInput
                        label="Number of Months Until College Starts"
                        mapId="preapprovals.monthly_529_months"
                        value={monthly_529_months}
                        prefix=""
                      />
                      <div className="flex flex-col mb-1 pt-1">
                        <div className="labelWrapper flex items-end justify-between">
                          <label
                            htmlFor="total_529_plan"
                            className="small mb-0.5"
                          >
                            Total 529 Savings Plan
                          </label>
                        </div>
                        <div className="fontRegular h2">
                          {formatDollarAmount(
                            Number(
                              parseInt(
                                watch("preapprovals.funds_in_529") || 0
                              ) +
                                parseInt(
                                  (watch("preapprovals.monthly_529_funds") ||
                                    0) *
                                    (watch("preapprovals.monthly_529_months") ||
                                      0)
                                )
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <CollegePreApprovalInput
                    label="Parent Pledged Assets"
                    mapId="preapprovals.parent_pledged_assets"
                    value={parent_pledged_assets}
                    prefix="$"
                    helpText={
                      <span>
                        Are there other assets like mutual funds or stocks that
                        you have earmarked for college or are willing to pledge
                        towards college? This is a number you can pledge for the
                        4 years of college.
                        <br />
                        <br />
                        For example, if parent&apos;s think they can pledge
                        $10,000/yr, you&apos;ll put $40,000 in this space to
                        reflect the four year total.
                      </span>
                    }
                  />
                  <CollegePreApprovalInput
                    label="Monthly Cash-Flow"
                    mapId="preapprovals.parent_pledged_monthly"
                    value={parent_pledged_monthly}
                    prefix="$"
                    helpText="Think of this as “pay as you go”. Kids are not free while
                  they live at home. A meal plan is typically around $2,400 for the year,
                  so $200/month may be a good starting point. What do you spend on
                  extracurricular activities on average per month or per year that will go
                  away once the student is in college? Club lacrosse? Band dues?"
                  />
                  <CollegePreApprovalSum
                    label="4-Year Monthly Cash-Flow"
                    mapId="four_year_cash_flow"
                    value={formatDollarAmount(
                      Number(watch("preapprovals.parent_pledged_monthly") * 48)
                    )}
                  />
                  <CollegePreApprovalInput
                    label="Student Pledged Monthly Cash-Flow"
                    mapId="preapprovals.student_pledged_monthly"
                    value={student_pledged_monthly}
                    prefix="$"
                    helpText="Kids working in college and/or breaks can pledge a monthly amount towards paying for college."
                  />
                  <CollegePreApprovalSum
                    label="4-Year Student Pledged Monthly Cash-Flow"
                    mapId="four_year_student_cash_flow"
                    value={formatDollarAmount(
                      Number(watch("preapprovals.student_pledged_monthly") * 48)
                    )}
                  />
                  <CollegePreApprovalInput
                    label="Grandparent and Other Financial Help"
                    mapId="preapprovals.other_help"
                    value={other_help}
                    prefix="$"
                    helpText="Anticipate help from grandparents or relatives/friends? Now is the time to
                  quantify exactly what that is and what types of accounts it is coming from. If you are
                  eligible for need-based aid, grandparent funds used improperly can penalize you in future years."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
export default CollegePreApprovalEditModal;
