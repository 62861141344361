import _ from "lodash";

/**
 * Returns key value from an object passed
 * Otherwise return default value
 * @param {object} onboarding - Object in which to search value
 * @param {string} key - Key for which to find value.
 * @param {string} defaultValue - Returns this value if value in the object not found. Fields that are not present display as "-"
 */
export const getKeyValueFromObject = (
  onboarding = {},
  key,
  defaultValue = "-"
) => {
  const value = _.get(onboarding, key);
  return value !== undefined ? value : defaultValue;
};
