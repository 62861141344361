import Link from "next/link";
import { Card } from "@components/index";

/**
 * Empty Card component
 * The main value of this component is the styling of a card used in sections when no schools or other content are added.
 * @param {string} title - default value of "No Schools Added Yet"
 * @param {string} description - no default value, if not passed in then it won't be rendered
 * @param {boolean} disabled - used for unfinished sections that have a button. NOTE: May be deprecated
 * @param {boolean} comingSoon - used for controlling which render is shown for the content. Passing in as true renders the "coming soon" variant of content.
 * @param {string} comingSoonText - default value of "Coming Soon!"
 * @param {string} buttonText - default value of "Search for a school"
 */
const EmptyCard = ({
  title,
  description,
  disabled,
  comingSoon,
  comingSoonText,
  buttonText,
  route,
}) => {

  return (
    <Card type="typeEmpty w-full">
      <div className="emptyCard flex flex-col items-center text-center md:flex-row md:justify-between">
        {comingSoon ? (
          <h3 className="opacity-50 mb-0">{comingSoonText}</h3>
        ) : (
          <>
            <div className="flex flex-col items-center md:items-start md:text-left">
              <h4>{title}</h4>
              {description && (
                <span className="description font-regular">{description}</span>
              )}
            </div>
            <Link href={route}>

              <button
                className="tertiary small flex justify-center"
                disabled={disabled}
              >
                {buttonText}
              </button>

            </Link>
          </>
        )}
      </div>
    </Card>
  );
};

EmptyCard.defaultProps = {
  title: "No Schools Added Yet",
  comingSoonText: "Coming Soon!",
  buttonText: "Search for a school",
  route: "/school_search",
};

export default EmptyCard;
