import HelpButton from "@components/Common/HelpButton";

/**
 * @param {object} category - factor category
 * @param {string} title
 * @param {boolean} showMoreDetails
 * @returns List item with help button for explanation popup
 */
const FactorListItem = ({ category, title, showMoreDetails }) => {
  if (!category?.applicable) {
    return null;
  }
  return (
    <li>
      <div className="flex flex-row items-center justify-between">
        {title}
        {showMoreDetails && (
          <HelpButton
            helpContent={[
              {
                id: 0,
                title: category.title,
                description: category.explanation,
              },
            ]}
            className="small"
          />
        )}
      </div>
    </li>
  );
};

export default FactorListItem;
