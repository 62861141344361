import {
  EFC_VIDEO_1,
  EFC_VIDEO_2,
  EFC_VIDEO_3,
  EFC_VIDEO_4,
  EFC_VIDEO_5,
  EFC_VIDEO_6,
  EFC_VIDEO_7,
  EFC_VIDEO_8,
  EFC_VIDEO_9,
  EFC_VIDEO_10,
} from "./constants";
/**
 * Evaluate which text and video to return based on the efcs
 * @param {string} studentName - Student's name
 * @param {integer} efcVideoNumber - comes from backend logic choosing an option from 0-9
 */
const getEfcEval = (studentName, efcVideoNumber) => {
  //EFC text constants, not used or needed anywhere outside of this function, thus not in constants.js
  const SCHOLARSHIP_TEXT_1 = `${
    studentName || "Student"
  } will also be eligible for merit-based scholarships at some colleges!`;
  const SCHOLARSHIP_TEXT_2 = `${
    studentName || "Student"
  } will be eligible for merit-based scholarships at some colleges.`;

  // let text1, text2, video;
  let text1 = `${
    studentName || "Student"
  } will likely be eligible for need-based grants at most public and private colleges.`;
  let text2 = SCHOLARSHIP_TEXT_1;
  let video = EFC_VIDEO_1;

  const textVideoDictionary = {
    0: {
      video: EFC_VIDEO_1,
    },
    1: {
      text1: `${
        studentName || "Student"
      } will likely be eligible for need-based grants at most public colleges and private colleges that use the FM or CM, but NOT at private colleges that use the IM.`,
      video: EFC_VIDEO_2,
    },
    2: {
      text1: `${
        studentName || "Student"
      } will likely be eligible for need-based grants at most public colleges and private colleges that use the FM, but only some private colleges that use the IM or CM.`,
      video: EFC_VIDEO_3,
    },
    3: {
      text1: `${
        studentName || "Student"
      } will likely be eligible for need-based grants at most public colleges and private colleges that use the FM and CM, but not private colleges that use the IM.`,
      video: EFC_VIDEO_4,
    },
    4: {
      text1: `${
        studentName || "Student"
      } will likely be eligible for need-based grants at most public colleges and private colleges that use the FM, but not private colleges that use the IM or CM.`,
      video: EFC_VIDEO_5,
    },
    5: {
      text1: `${
        studentName || "Student"
      } will not be eligible for need-based grants at most public colleges, but will be eligible at most private colleges.`,
      video: EFC_VIDEO_6,
    },
    6: {
      text1: `${
        studentName || "Student"
      } will not be eligible for need-based grants at most public colleges, but will be at some private colleges.`,
      video: EFC_VIDEO_7,
    },
    7: {
      text1: `${
        studentName || "Student"
      } will not be eligible for need-based grants at most public colleges, but will be at some private colleges.`,
      video: EFC_VIDEO_8,
    },
    8: {
      text1: `${
        studentName || "Student"
      } will likely be eligible for need-based grants at private colleges that use the FM, but not at public colleges or private colleges that use the IM or CM.`,
      video: EFC_VIDEO_9,
    },
    9: {
      text1: `It's unlikely ${
        studentName || "Student"
      } will be eligible for need-based grants at most colleges this year, but that's OK!`,
      text2: SCHOLARSHIP_TEXT_2,
      video: EFC_VIDEO_10,
    },
  };

  const textVideoEntry = textVideoDictionary[efcVideoNumber];

  text1 = textVideoEntry.text1 ? textVideoEntry.text1 : text1;
  text2 = textVideoEntry.text2 ? textVideoEntry.text2 : text2;
  video = textVideoEntry.video;

  return { text1, text2, video };
};

export { getEfcEval };
