import _ from "lodash";
import { useEffect, useState } from "react";
import ProgressBar from "../ProgressBar";
import { DownArrow } from "@icons/index";
import {
  maritalStatuses,
  marriedOptions,
  unmarriedOptions,
  hasSpouse,
} from "@utils/maritalStatusCheck";
import { infoTips } from "@utils/infoTips";

const Step10 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
  watch,
  setValue,
}) => {
  if (formStep !== 12) {
    // Don't render unless step matches
    return null;
  }

  const maritalStatus = watch("parent_guardian.marital_status");

  const [taxOptions, setTaxOptions] = useState(
    hasSpouse(maritalStatus) ? marriedOptions : unmarriedOptions
  );

  useEffect(() => {
    trigger([
      "parent_guardian.marital_status",
      "parent_guardian.filing_status",
      "parent_guardian.self_employed",
    ]);
    setWatchFields(["parent_guardian"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "Marital Status",
        description: _.get(
          infoTips,
          "scenario.onboarding.parent_guardian.marital_status"
        ),
      },
      {
        id: 1,
        title: "Last Year's Tax Return Filing Status",
        description: _.get(
          infoTips,
          "scenario.onboarding.parent_guardian.filing_status"
        ),
      },
    ]);
  }, []);

  useEffect(() => {
    if (hasSpouse(maritalStatus)) {
      if (taxOptions !== marriedOptions) {
        setTaxOptions(marriedOptions);
        //reset tax filing status
        setValue("parent_guardian.filing_status", "-");
      }
    } else {
      if (taxOptions !== unmarriedOptions) {
        setTaxOptions(unmarriedOptions);
        //reset tax filing status
        setValue("parent_guardian.filing_status", "-");
        //Clear spouse earnings value when user changes marital status to a single option
        if (formData.parent_guardian?.parent_earnings_2) {
          setValue("parent_guardian.parent_earnings_2", ""); //only clears value if submitted
        }
      }
    }
  }, [maritalStatus]);

  return (
    <div className="guardianInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep - 1} />
      <div className="guardianInfoText">
        <h5>
          What is {_.get(formData, "parent_guardian.parent_name") || "Parent"}
          &apos;s marital and tax filing statuses?
        </h5>
      </div>
      <div className="inputs flex flex-col text-left">
        <label
          htmlFor="parent_guardian.marital_status"
          className="selectLabel small"
        >
          <span className="labelFocus">Marital Status</span>
          <select
            id="parent_guardian.marital_status"
            {...register("parent_guardian.marital_status", {
              required: {
                value: true,
                message: "Please select the parent/guardian's marital status",
              },
              validate: {
                notDefault: (value) => value !== "-",
              },
            })}
          >
            {maritalStatuses.map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          </select>
          <div className="select-icon">
            <DownArrow />
          </div>
        </label>
        <label
          htmlFor="parent_guardian.filing_status"
          className="selectLabel small"
        >
          <span className="labelFocus">
            Last Year&apos;s Tax Return Filing Status
          </span>
          <select
            id="parent_guardian.filing_status"
            {...register("parent_guardian.filing_status", {
              required: {
                value: true,
                message:
                  "Please select the parent/guardian's tax return filing status",
              },
              validate: {
                notDefault: (value) => value !== "-",
              },
            })}
          >
            {taxOptions.map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          </select>
          <div className="select-icon">
            <DownArrow />
          </div>
        </label>
        <div className="guardianInfoText topSpacer text-center">
          <h5>Is either parent self-employed?</h5>
        </div>
        <div className="flex flex-row">
          <input
            type="radio"
            value="Yes"
            id="Yes"
            {...register("parent_guardian.self_employed", {
              required: {
                value: true,
                message: "Please specify self-employment status",
              },
            })}
          />
          <label htmlFor="Yes" className="radioOption">
            <span className="radioLabel">Yes</span>
          </label>
          <div className="mx-2" />
          <input
            type="radio"
            value="No"
            id="No"
            {...register("parent_guardian.self_employed", {
              required: {
                value: true,
                message: "Please specify self-employment status",
              },
            })}
          />
          <label htmlFor="No" className="radioOption">
            <span className="radioLabel">No</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Step10;
