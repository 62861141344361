import _ from "lodash";
import { useEffect } from "react";
import ProgressBar from "../ProgressBar";
import { SchoolSearchFormInput } from "@components/index";

const Step07 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  trigger,
}) => {
  if (formStep !== 9) {
    // Don't render unless step matches
    return null;
  }

  //local state
  const firstTargetSchool = _.get(formData, "student.target_schools[0]");

  useEffect(() => {
    trigger("student.target_schools[0]");
    setWatchFields(["student"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "",
        description: "If you are not sure, enter the name of any college.",
      },
    ]);
  }, []);

  return (
    <div className="studentInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep} />
      <div className="studentInfoText">
        <h5>
          What is {_.get(formData, "student.student_name") || "Student"}&apos;s
          top school?
        </h5>
      </div>
      <div className="inputs flex flex-col text-left">
        <label htmlFor="target_schools[0].college_id" className="textLabel">
          <span className="small labelFocus">School 1</span>
          <SchoolSearchFormInput
            inputMapId="student.target_schools[0]"
            firstTargetSchool={firstTargetSchool}
          />
        </label>
      </div>
    </div>
  );
};

export default Step07;
