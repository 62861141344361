import _ from "lodash";

const SearchItem = (props) => {
  //These are passed as props so this component can be used in multiple places
  const { schoolData, schoolSearchTerm, setChosenSchool } = props;

  const { school_id, name, address, city, state, zip } = schoolData;

  const boldSearchTerm = (searchTerm) => {
    //TODO: Highlighting the matching term does not support fuzzy search. Change when search becomes fuzzy.
    //This regex and logic is used to highlight the search term in the results without changing casing
    const regex = new RegExp(searchTerm, "i");
    const matchedTerm = name.match(regex);
    if (_.get(matchedTerm, "[0]")) {
      const array = name.split(matchedTerm[0]);
      const richTextOutput = (
        <span key={school_id}>
          {array[0]}
          <b>{matchedTerm[0]}</b>
          {array[1]}
        </span>
      );
      return richTextOutput;
    } else {
      return name;
    }
  };

  return (
    <>
      {!!boldSearchTerm(schoolSearchTerm) && (
        <div
          className="search-item flex flex-col"
          onClick={() => {
            setChosenSchool(schoolData);
          }}
        >
          {boldSearchTerm(schoolSearchTerm)}
          <small>{`${city}, ${state}`}</small>
        </div>
      )}
    </>
  );
};

export default SearchItem;
