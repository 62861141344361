const SidebarOption = ({ Icon, title }) => {
  return (
    <a className="sidebarOption">
      <div className="icon">
        <Icon />
      </div>
      <small>{title}</small>
    </a>
  );
};

export default SidebarOption;
