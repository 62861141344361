/**
 * parse a jwt token to make it readable by a human
 * Currently does not provide exp, which will give the expiry time
 * @param {string} token - jwt token
 */
export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  if (!base64Url) {
    return null;
  }
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (char) {
        return "%" + ("00" + char.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

/**
 * @param {string} token
 * @returns {float} percentage of time remaining
 */
export const getTokenPercentExpiry = (token) => {
  let percentRemaining;
  const jwtObject = parseJwt(token);
  //First check for valid jwt format, or else logout
  if (jwtObject) {
    const originalDuration = jwtObject.exp - jwtObject.iat;
    //Expiry is in seconds. Convert Date.now() to seconds.
    const secondsRemaining = jwtObject.exp - Date.now() / 1000;
    percentRemaining = (secondsRemaining / originalDuration) * 100;
  }
  return percentRemaining;
};
