const LoanIconWriting = () => {
  return (
    <div className="flex justify-center">
      <svg
        enableBackground="new 0 0 489 489"
        width="100%"
        height="100%"
        viewBox="0 0 489 489"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M120,248.103c0.1,3.2,0,6.3,0,9.5c0,2.8-1.4,4.3-4.2,4.4c-3.4,0.1-6.8,0.1-10.2,0c-3-0.1-4.4-1.8-4.4-4.7
		c0-2.3,0-4.6-0.1-7c-0.1-5.1-0.2-5.3-5.1-6.1c-6.3-1-12.5-2.4-18.2-5.2c-4.5-2.2-5-3.3-3.7-8.1c1-3.5,1.9-7.1,3-10.6
		c1.3-4.1,2.4-4.6,6.1-2.6c6.4,3.3,13.1,5.2,20.2,6.1c4.6,0.6,9,0.1,13.3-1.7c7.9-3.4,9.1-12.6,2.5-18.1c-2.3-1.9-4.8-3.2-7.6-4.4
		c-6.9-3-14.1-5.4-20.7-9.3c-10.6-6.4-17.3-15.1-16.6-28c0.9-14.6,9.1-23.7,22.5-28.6c5.5-2,5.5-1.9,5.6-7.7c0-2,0-3.9,0-5.9
		c0.1-4.4,0.9-5.1,5.2-5.2c1.3,0,2.7,0,4,0c9.2,0,9.2,0,9.3,9.2c0,6.5,0,6.6,6.5,7.6c5,0.8,9.7,2.2,14.3,4.3
		c2.5,1.1,3.5,2.9,2.7,5.6c-1.2,4-2.2,8-3.5,11.9c-1.2,3.7-2.4,4.3-6,2.5c-7.2-3.5-14.8-4.9-22.7-4.5c-2.1,0.1-4.1,0.4-6.1,1.2
		c-6.9,3-8,10.6-2.1,15.3c3,2.4,6.3,4.1,9.9,5.5c6.1,2.5,12.2,4.9,18,8.1c18.3,10.5,23.3,33.6,10.3,49.3
		c-4.7,5.7-10.8,9.5-17.9,11.5C121.2,243.303,119.8,244.903,120,248.103z M187.9,139.603h196.7c2.6,0,4.7-2.1,4.7-4.7v-15.2
		c0-2.6-2.1-4.7-4.7-4.7H187.9c-2.6,0-4.7,2.1-4.7,4.7v15.2C183.2,137.503,185.3,139.603,187.9,139.603z M183.2,175.703
		c0,2.6,2.1,4.7,4.7,4.7h138.8c2.6,0,4.7-2.1,4.7-4.7v-15.2c0-2.6-2.1-4.7-4.7-4.7H187.9c-2.6,0-4.7,2.1-4.7,4.7V175.703z
		 M389.3,216.603v-15.2c0-2.6-2.1-4.7-4.7-4.7H187.9c-2.6,0-4.7,2.1-4.7,4.7v15.2c0,2.6,2.1,4.7,4.7,4.7h196.7
		C387.2,221.303,389.3,219.203,389.3,216.603z M38.3,327.003h233c-1.5-3.6-2.5-7.4-2.8-11.3c-0.5-8.2-1-16.4-1.5-24.7H38.3
		c-1.3,0-2.3-1-2.3-2.3v-205.8h403.8v205.8c0,1.3-1,2.3-2.3,2.3h-23.3c8.8,11.9,17.7,23.9,26.5,35.8c19.6-1.6,35.1-18.1,35.1-38.1
		v-248.2c0-21.1-17.2-38.3-38.3-38.3H38.3c-21.1,0-38.3,17.2-38.3,38.3v248.2C0,309.803,17.2,327.003,38.3,327.003z M236.9,237.503
		h-20.1h-28.9c-2.6,0-4.7,2.1-4.7,4.7v15.2c0,2.6,2.1,4.7,4.7,4.7h28.9h20.1h28.4c-0.1-1.5-0.2-2.9-0.3-4.4l-0.7-11.5
		c-0.2-3,0.1-5.9,0.7-8.7L236.9,237.503L236.9,237.503z M367.4,266.803c35.1,47.5,79.3,104.1,114.4,151.6
		c10.9,14.7,7.8,35.5-6.9,46.4l-20,14.8c-14.7,10.9-35.5,7.8-46.4-7c-22.5-30.5-54.2-70.1-76.7-100.6
		c-12.7-17.1-25.4-34.2-37.9-51.4c-1.4-1.9-2.2-4.1-2.3-6.4c-1.5-23.1-2.9-46.2-4.2-69.2c-0.3-5.8,3.8-8.8,9.2-6.8
		c21.5,8,43,16.1,64.6,24.1C363.7,263.003,365.9,264.703,367.4,266.803z M350,272.003c-8.4-3.1-16.7-6.3-25-9.3
		c-3.4,9.4-12.3,15.8-22.3,16v0.1c0.5,9.5,1.1,18.9,1.7,28.6c4.1,1,7.8,0.4,11.4-0.7c12.8-4.1,23.1-11.7,31.1-22.4
		c2-2.7,3.1-6.2,4.6-9.3C352.2,273.503,351.7,272.603,350,272.003z"
            fill="currentColor"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </div>
  );
};

export default LoanIconWriting;
