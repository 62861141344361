import { useAppContext } from "@context/state";

import {
  Section,
  HowToPaySchoolCard,
  EmptyOfferSection,
} from "@components/index";

/**
 * @returns How to Pay section of dashboard
 */
const HowToPay = () => {
  const { userOffers } = useAppContext();

  const renderSchools = () => {
    //map budgets to cards
    const budgets = userOffers.map((offer) => (
      <HowToPaySchoolCard
        key={offer.id}
        offerData={offer}
        showMoreDetails={true}
      />
    ));
    return budgets;
  };

  return (
    <>
      <div className="affordability-container">
        <Section
          title={"How to Pay"}
          galleryName={"how-to-pay-gallery"}
          empty={userOffers.length === 0}
        >
          {userOffers.length === 0 ? (
            <EmptyOfferSection
              descriptionText={"Add an offer to calculate expected costs"}
            />
          ) : (
            <>{renderSchools()}</>
          )}
        </Section>
      </div>
    </>
  );
};

export default HowToPay;
