import { Cross } from "@icons/index";
import { Notice } from "@icons/index";

const TranslationFailureModal = ({ setTranslationStatus }) => {
  return (
    <>
      <div className="card relative flex flex-col items-center text-center mb-6">
        <div className="notice flex justify-center items-center mb-2 mt-4 md:mt-0">
          <div className="icon">
            <Notice />
          </div>
        </div>
        <div className="h3 mb-3">
          Hmmmm... something did not go as expected and we need to take a closer
          look at this one.
        </div>
        <div className="h5 mb-3">
          Our team has been notified that this award requires a manual review
          and we will be in touch soon.
        </div>
        <div className="h5 mb-3">
          You may also manually input all relevant amounts yourself in the
          appropriate fields on the previous page.
        </div>
        <div className="details flex flex-col text-left p-3 md:p-5">
          <div className="h5 mb-3">
            <b>NOTE:</b> While our team is looking this over, please double
            check that the file:
          </div>
          <ul className="checklist">
            <li>Is a .jpeg, .png, or .pdf</li>
            <li>Is less than 5MB in size</li>
            <li>Is easily readable and clear</li>
          </ul>
          <div className="mb-3 h5">
            Please also triple check that the uploaded document is{" "}
            <i>
              <b>NOT:</b>
            </i>
          </div>
          <ul className="checklist">
            <li className="not">An acceptance letter</li>
            <li className="not">
              An individual grant or scholarship notification letter
            </li>
            <li className="not">Another admissions-related letter</li>
          </ul>
          <div className="mb-0 h5 text-center">
            We will be in touch as soon as we can with your results
          </div>
        </div>
        <button
          type="button"
          className="tertiary hideInfoBtn mb-2 md:mb-0"
          onClick={() => setTranslationStatus(null)}
        >
          Close
        </button>
        <button
          className="close-popup"
          onClick={() => setTranslationStatus(null)}
        >
          <Cross />
        </button>
      </div>
    </>
  );
};

export default TranslationFailureModal;
