import { PlanCard } from "@components/index";
import { useEffect, useState, useRef } from "react";
import { useAppContext } from "@context/state";
import _ from "lodash";
import {
  FREEMIUM_PRICE_ID,
  MYCAP_PREMIUM_PLUS_PRICE_ID,
  MYCAP_PREMIUM_PRICE_ID_MONTHLY,
  MYCAP_PREMIUM_PRICE_ID_YEARLY,
} from "@utils/constants";
import { useRouter } from "next/router";
import { setSubscriptionInfoWithHostedPageId } from "@api/user";
import delay from "@utils/delay";
import getTidCookie from "@utils/getTidCookie";
import { checkIsUpgraded } from "@utils/checkIsUpgraded";
import { plans } from "@utils/plans";
import * as Sentry from "@sentry/nextjs";
import { gtmPushAccountUpgrade } from "@utils/gtm";

const UpgradePlan = ({ setFormStep }) => {
  const router = useRouter();
  const {
    user,
    scenario,
    setScenario,
    setUpgraded,
    handleApiError,
    freePlanSelected,
    setFreePlanSelected,
    routeToExpertMeeting,
  } = useAppContext();

  const { student, scholar, valedictorian } = plans;
  const { subscription_info } = scenario;
  //This state need to be held in a ref in order for the chargebee instance to use them.
  const newPriceIdRef = useRef();
  const [newPriceId, setNewPriceId] = useState(null);
  useEffect(() => {
    newPriceIdRef.current = newPriceId;
  }, [newPriceId]);

  /**
   * Function to call when free button is clicked
   */
  const freePlanFunction = () => {
    setFormStep((cur) => cur + 1);
  };

  /**
   *
   * @param {string} hostedPageId - the string returned by the chargebee success callback. Will be used by the backend to set subscription info for the user.
   */
  const upgradePlanWithHostedPageId = async (hostedPageId) => {
    try {
      const res = await setSubscriptionInfoWithHostedPageId(hostedPageId);
      //expected res.data: eg. {is_paid: true, price_id: 'Premium-USD-Yearly'}
      setScenario({
        ...scenario,
        subscription_info: res.data,
      });
      if (checkIsUpgraded(res.data.price_id)) {
        //TODO: with subscription_info, upgraded state may be deprecated. Just use the scenario's subscription_info.price_id for logic checks.
        setUpgraded(true);
        setNewPriceId(res.data.price_id);
        //push event to Google Tag Manager
        switch (res.data.price_id) {
          case MYCAP_PREMIUM_PRICE_ID_YEARLY:
            gtmPushAccountUpgrade(
              res.data.price_id,
              scholar?.planPrices[0].value
            );
            break;
          case MYCAP_PREMIUM_PLUS_PRICE_ID:
            gtmPushAccountUpgrade(
              res.data.price_id,
              valedictorian?.planPrices[0].value
            );
            break;
          default:
          //
        }
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * Wait for specified time then close all chargebee modals
   * @param {chargeBeeInstance} chargeBeeInstance
   * @param {integer} ms - time in milliseconds
   */
  const delayModalClose = async (chargeBeeInstance, ms) => {
    await delay(ms);
    chargeBeeInstance.closeAll();
  };

  /**
   * When closing the chargebee modal, redirect if the purchase was successful
   */
  const handleChargebeeClose = () => {
    if (newPriceIdRef.current) {
      // if path is /onboarding, proceed to the next step
      if (router.pathname === "/onboarding") {
        setFormStep((cur) => cur + 1);
      } else {
        // proceed to dashboard or book consultation
        if (newPriceIdRef.current === MYCAP_PREMIUM_PLUS_PRICE_ID) {
          //if valedictorian, redirect to book consultation. Possible we may need to make the first appointment as well.
          //TODO: Add a book consultation page in the onboarding flow for valedictorians
          router.push(`/book_consultation/${encodeURIComponent(user?.email)}`);
        } else {
          // redirect to dashboard
          router.push("/");
        }
      }
    }
  };

  useEffect(() => {
    // Chargebee instance will be created when DOMContentLoaded
    // event is triggered.Since, you are loading it asynchronously,
    // instance is not getting created. So we can create the instance
    // using Chargebee.init().
    // In Nextjs, it doesnot simply work if we add a script tag to Head.
    // Requires to load a script after the app loads in order to work.
    if (!_.isNull(user)) {
      if (document.getElementById("chargebee-script-element")) {
        document.getElementById("chargebee-script-element").remove();
      }
      //Standard chargebee script
      const chargeBeeScript = document.createElement("script");
      chargeBeeScript.onload = () => {
        window.Chargebee.init({
          site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
        });
        window.Chargebee.registerAgain();
        const cbInstance = window.Chargebee.getInstance();
        cbInstance.setCheckoutCallbacks(() => {
          // Defining a custom callback based on cart object.
          return {
            success: (hostedPageId) => {
              // Payment Successful!
              // Hosted page id will be unique token for the checkout that happened
              // You can pass this hosted page id to your backend
              // and then call our retrieve hosted page api to get subscription details
              // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page
              upgradePlanWithHostedPageId(hostedPageId);
              setFreePlanSelected(false);
              //Automatically close modal after 15 seconds
              delayModalClose(cbInstance, 15000);
            },
            close: () => {
              handleChargebeeClose();
            },
            error: () => {
              Sentry.captureException(
                `Chargebee hosted page error for user ${user?.email}`
              );
            },
          };
        });

        //Get tracking id, used for chargebee tracking script
        const tid = getTidCookie();
        // Prepopulating some fields on the modal
        if (tid && cbInstance) {
          const cart = cbInstance.getCart();
          cart.setCustomer({
            email: user.email,
            first_name: user.first_name,
            cf_tid: tid,
          });
        } else if (cbInstance) {
          const cart = cbInstance.getCart();
          cart.setCustomer({
            email: user.email,
            first_name: user.first_name,
          });
        }
      };

      chargeBeeScript.src = "https://js.chargebee.com/v2/chargebee.js";
      chargeBeeScript.id = "chargebee-script-element";
      document.head.appendChild(chargeBeeScript);
    }
  }, [user]);

  const isPriceId = (toComparePriceId) => {
    return subscription_info?.price_id === toComparePriceId;
  };

  // Set disabled state for the button on each plan card.
  const freemiumActiveState =
    router.pathname === "/onboarding" && isPriceId(FREEMIUM_PRICE_ID)
      ? freePlanSelected
      : isPriceId(FREEMIUM_PRICE_ID); //if price id is freemium and path is /onboarding, use freePlanSelected state
  const premiumActiveState =
    isPriceId(MYCAP_PREMIUM_PRICE_ID_MONTHLY) ||
    isPriceId(MYCAP_PREMIUM_PRICE_ID_YEARLY);
  const premiumPlusActiveState = isPriceId(MYCAP_PREMIUM_PLUS_PRICE_ID);

  return (
    <>
      <div className="flex flex-col items-center text-center mb-1 lg:mb-2">
        <h1>Choose a Plan</h1>
        <h5 className="hidden lg:block">
          Your college planning journey
          <br />
          starts with the right CAP plan.
        </h5>
      </div>
      {!_.isEmpty(scenario) && (
        <div className="gridContainer">
          <div className="cardsContainer">
            <PlanCard
              active={freemiumActiveState}
              plan={student}
              unavailable={
                isPriceId(MYCAP_PREMIUM_PRICE_ID_MONTHLY) ||
                isPriceId(MYCAP_PREMIUM_PRICE_ID_YEARLY) ||
                isPriceId(MYCAP_PREMIUM_PLUS_PRICE_ID)
              }
              freePlanFunction={freePlanFunction}
              freeButtonText="Get Started for FREE"
            />
            <PlanCard
              plan={scholar}
              active={premiumActiveState}
              unavailable={isPriceId(MYCAP_PREMIUM_PLUS_PRICE_ID)}
            />
            <PlanCard
              plan={valedictorian}
              active={premiumPlusActiveState}
              unavailable={
                isPriceId(MYCAP_PREMIUM_PRICE_ID_MONTHLY) ||
                isPriceId(MYCAP_PREMIUM_PRICE_ID_YEARLY)
              }
              recommended
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UpgradePlan;
