import { processUSD } from "@utils/costEstimates";
import OfferFormField from "./OfferFormField";
import {
  getGrantsScholarships,
  getFederalLoanTotal,
} from "@utils/calcHowToPay";

/**
 * @param {object} tuitionBudget
 * @param {object} decidedDocumentJob
 * @returns Award fields on offer card
 */
const OfferAwardFormFields = ({
  tuitionBudget,
  decidedDocumentJob,
  watchInt,
}) => {
  /**
   * calculate value from decided document job
   * @param {array} aidAmounts
   * @param {function} filterFn
   * @returns {Number}
   */
  const calculateTotalAward = (aidAmounts, filterFn) => {
    const filteredArray = aidAmounts?.filter(filterFn);
    if (filteredArray?.length > 0) {
      return filteredArray.reduce((total, cost) => total + cost.value, 0);
    } else {
      return null;
    }
  };

  //total values will likely need to be summed on FE due to potential for differing sources of values
  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full mb-1">
          <h4 className="mb-0.5">Grants & Scholarships</h4>
          <h5 className="removeMargin">
            {processUSD(getGrantsScholarships(watchInt))}
          </h5>
        </div>
        <OfferFormField
          label="Need-Based Grants"
          mapId="tuition_budget.fed_state_grants_1"
          tuitionBudgetValue={
            tuitionBudget?.fillable_fields?.fed_state_grants_1
          }
          capProjectionValue={0}
          decidedDocumentJobValue={calculateTotalAward(
            decidedDocumentJob?.aid_amounts,
            (aid) => aid.category === "GRANT"
          )}
          isAward={true}
          placeholder="0"
          prefix="$"
        />
        <OfferFormField
          label="Merit Scholarships"
          mapId="tuition_budget.merit_scholarship_1"
          tuitionBudgetValue={
            tuitionBudget?.fillable_fields?.merit_scholarship_1
          }
          capProjectionValue={0}
          decidedDocumentJobValue={calculateTotalAward(
            decidedDocumentJob?.aid_amounts,
            (aid) => aid.category === "SCHOLARSHIP"
          )}
          isAward={true}
          placeholder="0"
          prefix="$"
        />
      </div>
      <div className="divider mb-3" />
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full mb-1">
          <h4 className="mb-0.5">Federal Loans</h4>
          <h5 className="removeMargin">
            {processUSD(getFederalLoanTotal(watchInt))}
          </h5>
        </div>
        <OfferFormField
          label="Subsidized Loans"
          mapId="tuition_budget.federal_direct_student_loan_1"
          tuitionBudgetValue={
            tuitionBudget?.fillable_fields?.federal_direct_student_loan_1
          }
          capProjectionValue={0}
          decidedDocumentJobValue={calculateTotalAward(
            decidedDocumentJob?.aid_amounts,
            (aid) =>
              aid.category === "LOAN" &&
              aid.sub_category === "SUBSIDIZED" &&
              aid.source === "FEDERAL"
          )}
          isAward={true}
          placeholder="0"
          prefix="$"
        />
        <OfferFormField
          label="Unsubsidized Loans"
          mapId="tuition_budget.unsubsidized_student_loan_1"
          tuitionBudgetValue={
            tuitionBudget?.fillable_fields?.unsubsidized_student_loan_1
          }
          capProjectionValue={0}
          decidedDocumentJobValue={calculateTotalAward(
            decidedDocumentJob?.aid_amounts,
            (aid) =>
              aid.category === "LOAN" &&
              aid.sub_category === "UNSUBSIDIZED" &&
              aid.source === "FEDERAL"
          )}
          isAward={true}
          placeholder="0"
          prefix="$"
        />
      </div>
    </>
  );
};

export default OfferAwardFormFields;
