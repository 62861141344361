/* -------- Net Cost -------- */

/**
 * Sum of costs
 * @param {function} watchInt
 */
export const getCostOfAttendance = (watchInt) =>
  watchInt("tuition_budget.coa_1") +
  watchInt("tuition_budget.room_and_board_1") +
  watchInt("tuition_budget.books_and_supplies_1") +
  watchInt("tuition_budget.travel_and_misc_1") +
  watchInt("tuition_budget.other_fees_1");

/**
 * Cost of Attendance minus Private Scholarships and either Need Based Grants or Merit Scholarships depending on which is greater
 * @param {function} watchInt
 */
export const getGrantsScholarships = (watchInt) =>
  watchInt("tuition_budget.fed_state_grants_1") +
  watchInt("tuition_budget.merit_scholarship_1");

/**
 * Cost of Attendance minus Private Scholarships and either Need Based Grants or Merit Scholarships depending on which is greater
 * @param {function} watchInt
 */
export const getNetCost = (watchInt) =>
  watchInt("cost_of_attendance_1") -
  watchInt("tuition_budget.private_scholarships_1") -
  watchInt("tuition_budget.fed_state_grants_1") -
  watchInt("tuition_budget.merit_scholarship_1");
/* -------- Pre-Approvals -------- */

/**
 * Pre-Approval calculations
 * additional_529 = monthly_529_funds * monthly_529_months
 * Funds from Parent = funds_in_529 + additional_529 + parent_pledged_assets + (48 * parent_pledged_monthly) + tax_credit_elligible
 * Funds from Student = student_pledged_assets + (48 * student_pledged_monthly)
 * Funds from Others = other_help
 * Pre-Approval Amount = Funds from Parent + Funds from Student + Funds from Others
 * @param {function} watchInt
 * @returns value for 1 year
 */
export const getPreApprovalAmount = (watchInt) =>
  watchInt("tuition_budget.funds_in_529_1") + //QUESTION: Should show in form?
  watchInt("tuition_budget.parent_pledged_assets_1") +
  watchInt("tuition_budget.parent_pledged_monthly_1") +
  // watchInt("tuition_budget.aotc_1") + //currently not shown. QUESTION: Should show in form as read only?
  watchInt("tuition_budget.student_pledged_assets_1") +
  watchInt("tuition_budget.student_pledged_monthly_1") +
  watchInt("tuition_budget.other_help_1");

/* -------- Loan Total -------- */

/**
 * Sum of federal loans
 * @param {function} watchInt
 */
export const getFederalLoanTotal = (watchInt) =>
  watchInt("tuition_budget.federal_direct_student_loan_1") +
  watchInt("tuition_budget.unsubsidized_student_loan_1");

/**
 * Sum of loans
 * @param {function} watchInt
 */
export const getLoanTotal = (watchInt) =>
  watchInt("tuition_budget.federal_direct_student_loan_1") +
  watchInt("tuition_budget.unsubsidized_student_loan_1") +
  watchInt("tuition_budget.private_loan_1") +
  watchInt("tuition_budget.parent_plus_loan_1") +
  watchInt("tuition_budget.other_loan_1");

/* -------- Funding Gap -------- */
/**
 * aotc = American Opportunity Tax Credit - using tax_credit_elligible
 * non_loan_total = funds_in_529 + parent_pledged_assets + parent_pledged_monthly + aotc + student_pledged_assets + student_pledged_monthly + other_help
 */

/**
 * gap_funding_amount = net_cost - non_loan_total
 * @param {function} watchInt
 */
export const getFundingGap = (watchInt) =>
  watchInt("net_cost_1") - watchInt("preapproval_amount_1");

/**
 * remaining_funding_gap = gap_funding_amount - loan_total
 * @param {function} watchInt
 */
export const getRemainingFundingGap = (watchInt) =>
  watchInt("funding_gap_1") - watchInt("loan_total_1");
