import { useRouter } from "next/router";
import { LongLeftArrow } from "@icons/index";
import { useAppContext } from "@context/state";
import AdvancedSchoolList from "./AdvancedSchoolList";
import {
  getUserSchools,
  addSchoolToUser,
  CAPAdvancedSchoolSearch,
} from "@api/schools";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import _ from "lodash";
import useForceUpdate from '@components/Common/useForceUpdate';

/**
 * Advanced Search Result component
 * The main value of this component is to display the search results after a user has applied filters and done an advanced search from /advanced_search
 * @param {array} searchList - array of school  objects returned by search endpoint
 * @param {string} filters - string that comes from url params
 */
const CAPAdvancedSearchResult = ({ searchList, filters }) => {
  const router = useRouter();

  const {
    scenario,
    setAdvancedFilter,
    advancedSearchList,
    setAdvancedSearchList,
    advancedSearchListChecked,
    setAdvancedSearchListChecked,
    setUserSchools,
    handleApiError,
    advancedFilter,
    populateOffers,
  } = useAppContext();

  //Sorting by need_met_percentage is the default sorting method
  const [sortingBy, setSortingBy] = useState("need");

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [moreSchoolLoading, setMoreSchoolLoading] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  const addSchools = async () => {
    if (advancedSearchListChecked.length) {
      setLoading(true);
      try {
        await Promise.all(
          advancedSearchListChecked.map((college_id) => {
            return addSchoolToUser(scenario?.case_id, college_id);
          })
        );
        //Updated school list, now get the updated list
        const res = await getUserSchools(scenario.case_id);
        if (res?.data?.results) {
          setUserSchools(res?.data?.results);
          populateOffers(scenario?.case_id);
        }
        setLoading(false);
        // For now redirect to dashboard when successfully added.
        // Need to discuss if to show any modal when school added.
        router.push("/");
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * Load more schools for advance school search
   */
  const getMoreResults = async () => {
    try {
      setMoreSchoolLoading(true);
      const resultsNumber = 10;
      const offset = pageNumber * resultsNumber;
      const res = await CAPAdvancedSchoolSearch(
        scenario?.case_id,
        advancedFilter + `&offset=${offset}` + `&order=${sortingBy}`
      );
      if (_.get(res, "data.results.length") > 0) {
        const combinedSchools = advancedSearchList.concat(res.data.results);
        setPageNumber(pageNumber + 1);
        setAdvancedSearchList(combinedSchools);
      } else {
        setPageNumber(0);
      }
      setMoreSchoolLoading(false);
    } catch (error) {
      setMoreSchoolLoading(false);
      handleApiError(error);
    }
  };

  useEffect(() => {
    //On refresh set sort by to be everything after &order=
    const filtersSplitOrder = filters.split("&order=");
    if (filtersSplitOrder[1]) {
      setSortingBy(filtersSplitOrder[1]);
    }
    //On refresh set advanced filter to be everything before &offset=
    const filtersSplitBeforeOffset = filters.split("&offset=");
    if (filtersSplitBeforeOffset[0]) {
      setAdvancedFilter(filtersSplitBeforeOffset[0]);
    }
    setAdvancedSearchList(searchList);
  }, [router.pathname]);

  return (
    <div className="CAPSearchContainer flex flex-col w-full h-full">
      <div className="nav w-full flex flex-row items-center justify-between">
        <button
          className="w-3"
          onClick={() => {
            setAdvancedSearchListChecked([]);
            router.push("/school_search"); //Should this go directly to filter page instead?
            setAdvancedSearchList([]);
          }}
        >
          <LongLeftArrow />
        </button>
        <div className="emptySpace" />
      </div>
      {advancedSearchList?.length ? (
        <>
          <div className="searchResultContainer result overflow-y-scroll">
            <AdvancedSchoolList
              sortingBy={sortingBy}
              setSortingBy={setSortingBy}
              setPageNumber={setPageNumber}
            />
            {pageNumber && (
              <div className="loadMoreContainer">
                {moreSchoolLoading ? (
                  <ClipLoader
                    size={25}
                    css={{
                      borderColor: "var(--primary-color)",
                      borderBottomColor: "transparent",
                    }}
                  />
                ) : (
                  <button
                    className="underline imitateLink small"
                    onClick={getMoreResults}
                  >
                    Load more search results
                  </button>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="searchPlaceHolder flex flex-row justify-center items-center w-full h-full text-center">
          <h4 className="mt-64 mx-auto">Search for Specific Schools Above<br></br> -or-<br></br>Use the Advanced Search Filters</h4>
        </div>
      )}
    </div>
  );
};

export default CAPAdvancedSearchResult;
