const DocumentFile = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 14 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="currentColor" fillRule="nonzero">
          <path d="m7.72491909 0h-7.24919093c-.26273741 0-.47572816.21068953-.47572816.47058824v15.05882356c0 .2598987.21299075.4705882.47572816.4705882h13.04854364c.2627374 0 .4757282-.2106895.4757282-.4705882v-10.26737971c0-.13947727-.0625476-.27176849-.1707665-.36117926l-5.79935274-4.79144385c-.08555597-.07068662-.19348894-.10940898-.30496167-.10940898zm-.17654722 1 5.45162813 4.5024v9.4976h-12v-14z" />
          <path d="m7.49328859 0c.24216513 0 .44357336.17423523.48534105.40400311l.00794754.0885342-.00004698 4.52149254 5.5201812.00089552c.2421651 0 .4435734.17423524.4853411.40400311l.0079475.08853421c0 .2417963-.174501.44289779-.4046193.48460187l-.0886693.00793544h-6.01342281c-.24216512 0-.44357336-.17423523-.48534105-.40400311l-.00794754-.0885342v-5.01492538c0-.27202084.22085282-.49253731.49328859-.49253731z" />
        </g>
      </svg>
    </div>
  );
};

export default DocumentFile;
