import { useAppContext } from "@context/state";
import { GoogleLogin } from "react-google-login";
import GoogleButton from "react-google-button";
import { useState } from "react";

/**
 * @param {string} className
 * @returns functional google sign in button
 */
const GoogleLoginButton = ({ className }) => {
  const { responseGoogle } = useAppContext();
  const [error, setError] = useState({});

  /**
   * Error via Google
   * @param {*} response
   */
  const errorResponseGoogle = async (response) => {
    //first check that initialization failed
    if (response.error === "idpiframe_initialization_failed") {
      //set error message. NOTE: error message is not reset anywhere because user will have to refresh to re-initialize the google button anyway by refreshing
      setError({
        message:
          "Please enable third party cookies then refresh the page in order to use Social Login",
      });
    }
  };

  return (
    <div className={`flex flex-col items-center ${className}`}>
      <GoogleLogin
        clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
        render={(renderProps) => (
          <GoogleButton
            className={`google-btn`}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled || !!error.message}
          />
        )}
        onSuccess={responseGoogle}
        onFailure={errorResponseGoogle}
        cookiePolicy={"single_host_origin"}
      />
      {error.message && (
        <span className="micro errorMessage">{error.message}</span>
      )}
    </div>
  );
};

export default GoogleLoginButton;
