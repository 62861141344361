import { Card } from "@components/index";
import { DiagonalRightArrow, Notice } from "@icons/index";
import { useRouter } from "next/router";
import { EFC_LABEL } from "@utils/constants";

const NoInfoCTA = () => {
  const router = useRouter();
  return (
    <Card type="typeDark w-full">
      <div className="notice flex flex-col items-center text-center md:flex-row md:justify-between px-3">
        <div className="flex flex-col items-center md:flex-row">
          <div className="icon">
            <Notice />
          </div>
          <div className="flex flex-col items-center md:items-start md:text-left md:mx-6">
            <h3 className="header">We Need More Information</h3>
            <span className="description">
              {`For basic projections of your ${EFC_LABEL}s and net costs, please provide
              High School Graduation Year, unweighted GPA, Income, and
              Non-Retirement Savings.`}
            </span>
          </div>
        </div>
        <div className="learnMoreBtn">
          <button
            className="tertiary-dark highlight h-full ml-3 flex flex-row items-center flex-nowrap"
            onClick={() => router.push("/profile")}
          >
            <span className="px-1 whitespace-nowrap">Go to Profile</span>
            <div className="w-4 p-1 -ml-1">
              <DiagonalRightArrow />
            </div>
          </button>
        </div>
      </div>
    </Card>
  );
};

export default NoInfoCTA;
