import Link from "next/link";
import { useRef } from "react";
import { Card } from "@components/index";
import { useAppContext } from "@context/state";
import { SCHOOL_MORE_INFO } from "@utils/constants";
import {
  processNetCost,
  processPreApprovals,
  processLoanTotal,
  processUSD,
} from "@utils/costEstimates";
import renderLogo from "@utils/renderLogo";
import { useRouter } from "next/router";

/**
 * @param {object} schoolData - the detailed data about the school obtained from the api
 * @param {boolean} showMoreDetails - decide whether or not the card should show all or minimal information
 * @returns card component that appears in the affordability page and affordability section of the dashboard
 */
const SchoolCard = ({ schoolData, showMoreDetails }) => {
  if (!schoolData || !schoolData?.visible) {
    return null;
  }

  const cardRef = useRef();

  const {
    school_id,
    name,
    logo,
    breakdowns,
    total_10,
    payment_10,
    total_25,
    payment_25,
    average_monthly_take_home,
    affordability,
  } = schoolData;

  const {
    scenario,
    setFormModalView,
    setFormModalContent,
    setDisplayFormModal,
    setScrollToCard,
  } = useAppContext();
  //NOTE: intended_major_object rather than intended_major, since we wanted to return more info related to the chosen major without breaking things that previously relied on intended_major being an integer.
  const major = scenario?.onboarding?.student?.intended_major_object;

  const router = useRouter();

  const handleMoreInfoButton = () => {
    if (router.pathname === "/affordability") {
      //if already on affordability page, open the "more info" modal
      setFormModalContent(schoolData);
      setFormModalView(SCHOOL_MORE_INFO);
      setDisplayFormModal(true);
    } else {
      //Set scroll left default for affordability section and navigate to /affordability
      //set index of card to scroll to
      const cardGalleryIndex = [...cardRef.current.parentNode.children].indexOf(
        cardRef.current
      );
      setScrollToCard(cardGalleryIndex);
      router.push("/affordability");
    }
  };

  return (
    <div className="cardContainer" ref={cardRef}>
      <Card type="typeTall">
        <div className="flex flex-col justify-between h-full">
          <div className="topHeading">
            <div className="flex flex-row justify-between items-start">
              {renderLogo(logo, name)}
              <button
                onClick={handleMoreInfoButton}
                className="imitateLink flex items-start"
              >
                <small>
                  <b>More Info</b>
                </small>
              </button>
            </div>
            <div className="h4">
              <Link
                href={`/case/${scenario.case_id}/school/${[school_id]}`}
                className="underline inherit">
                {name}
              </Link>
            </div>
            <div className="affordabilityStatus flex flex-row items-center">
              <small className="micro">Affordability</small>
              <small
                className={`micro status ${affordability?.toLowerCase()} ideal flex justify-center items-center`}
              >
                {affordability}
              </small>
            </div>
          </div>
          <div className="flex flex-col">
            <div
              className={`divider ${router.pathname === "/affordability" ? "hidden" : ""
                }`}
            />
            <div
              className={`affordability-costs ${router.pathname === "/affordability" ? "mt-3" : ""
                }`}
            >
              <small>Your 4-Year Net Cost</small>
              <h2>{processNetCost(4, breakdowns)}</h2>
              <div className="flex flex-row w-full">
                <div className="flex flex-col w-1/2">
                  <small>Your Budget</small>
                  <div>{processPreApprovals(scenario?.preapprovals)}</div>
                </div>
                <div className="right-side flex flex-col w-1/2">
                  <small>Total Loans</small>
                  <div>
                    {processLoanTotal(breakdowns, scenario?.preapprovals)}
                  </div>
                </div>
              </div>
            </div>
            {showMoreDetails && (
              <>
                <div className="divider" />
                <div className="estimates flex flex-col">
                  <small className="title font-demi-bold">
                    Total Repayment for 10-year plan
                  </small>
                  <div className="flex flex-row w-full">
                    <div className="flex flex-col w-1/2">
                      <small>Full Amount</small>
                      <div>{processUSD(total_10 || 0)}</div>
                    </div>
                    <div className="right-side flex flex-col w-1/2">
                      <small>Per Month</small>
                      <div>{processUSD(payment_10 || 0)}</div>
                    </div>
                  </div>
                  <small className="title font-demi-bold">
                    Total Repayment for 25-year plan
                  </small>
                  <div className="flex flex-row w-full">
                    <div className="flex flex-col w-1/2">
                      <small>Full Amount</small>
                      <div>{processUSD(total_25 || 0)}</div>
                    </div>
                    <div className="right-side flex flex-col w-1/2">
                      <small>Per Month</small>
                      <div>{processUSD(payment_25 || 0)}</div>
                    </div>
                  </div>
                  <small className="title font-demi-bold">
                    Estimated Take-Home Pay
                  </small>
                  <div className="flex flex-row w-full">
                    <div className="flex flex-col w-1/2">
                      <small>Major</small>
                      <div>{major?.name}</div>
                    </div>
                    <div className="right-side flex flex-col w-1/2">
                      <small>Per Month</small>
                      <div>{processUSD(average_monthly_take_home || 0)}</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SchoolCard;
