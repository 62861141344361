// TODO doesn't seem to be used
const Terms = () => {
  return (
    <div className="terms flex flex-col self-center">
      <h3>Terms</h3>
      <div className="flex flex-col">
        <b>Deadlines</b>
        <span>
          Deadlines are extremely important. Best practice is to check directly
          with each school’s financial aid website to confirm the corresponding
          deadline for each financial aid application.
        </span>
      </div>
      <div className="flex flex-col">
        <b>Verification</b>
        <span>
          The process schools use to confirm that the data reported on your
          FAFSA/CSS Profile form is accurate. Some colleges use IDOC to
          facilitate this, while others may automatically require federal tax
          returns and W2’s be sent directly to the college. Lastly, some
          colleges may not require tax information initially but may randomly
          select families to be verified.
        </span>
      </div>
      <div className="flex flex-col">
        <b>IDOC</b>
        <span>
          A service of College Board that some colleges utilize to collect
          family financial documents (federal tax returns, W2’s, etc.) and
          distribute them to participating institutions on behalf of the
          student.
        </span>
      </div>
      <div className="flex flex-col">
        <b>Self-Employed</b>
        <span>
          If either parent is self-employed or owns a business/farm, a private
          college may request information about the business or farm’s income,
          assets, and liabilities by submitting a Business/Farm Supplement.
        </span>
      </div>
    </div>
  );
};

export default Terms;
