import { useAppContext } from "@context/state.js";

const ComingSoonModal = () => {
  const { setDisplayModal } = useAppContext();

  return (
    <div className="modal">
      <div className="modal-scroll">
        <div className="modal-content">
          <div className="title mb-4 md:mb-2">
            <h3>Coming Soon</h3>
          </div>
          <div className="infoText h5 mb-4 md:mb-6">
            This feature will be available soon.
          </div>
          <button
            type="button"
            className="closeBtn"
            onClick={() => setDisplayModal((cur) => !cur)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonModal;
