import _ from "lodash";
import { formatDollarAmount } from "./costFormatter";
import { getFundingGap } from "@utils/calcHowToPay";

/**
 * Calculate net cost
 * @param {integer} numberOfYears - typically 1 - 4
 * @param {array} yearsData - array of years, each year containing a net_cost
 * @returns float
 */
const calcNetCost = (numberOfYears, yearsData) => {
  const net_cost = _.get(yearsData, "[0].net_cost");
  if (net_cost !== undefined && numberOfYears) {
    let total = 0;
    for (let i = 0; i < numberOfYears; i++) {
      if (yearsData[i]) {
        total += yearsData[i].net_cost;
      }
    }
    return total;
  } else {
    return null;
  }
};

/**
 * if props present, tallies up the net cost from each year up to the number of years specified,
 * then returns the formatted total. If props missing, default return of $XXX,XXX
 * @param {integer} numberOfYears - typically 1 - 4
 * @param {array} yearsData - array of years, each year containing a net_cost
 * @returns
 */
const processNetCost = (numberOfYears, yearsData) => {
  //calculate net cost according to the number of years projected to be attended at school
  const total = calcNetCost(numberOfYears, yearsData);
  if (total) {
    return formatDollarAmount(total);
  } else {
    return "$XXX,XXX";
  }
};

/**
 * if props present, tallies up the net cost from each year up to the number of years specified,
 * then returns the formatted total. If props missing, default return of $XXX,XXX
 * @param {object} preapprovalsData - object with preapproval amounts, numbered according to year
 * @param {boolean} estimate
 * @returns four year pre approval total
 */
const processPreApprovals = (preapprovalsData, estimate = true) => {
  //calculate net cost according to the number of years projected to be attended at school
  if (preapprovalsData) {
    if (estimate) {
      const fourYearEstimate =
        0 +
        parseInt(_.get(preapprovalsData, "preapproval")) +
        parseInt(_.get(preapprovalsData, "preapproval_2")) +
        parseInt(_.get(preapprovalsData, "preapproval_3")) +
        parseInt(_.get(preapprovalsData, "preapproval_4"));
      return formatDollarAmount(fourYearEstimate);
    } else {
      //calculate actual total for accuracy
      const savingsTotal =
        parseInt(_.get(preapprovalsData, "funds_in_529") || 0) +
        parseInt(
          (_.get(preapprovalsData, "monthly_529_funds") || 0) *
            (_.get(preapprovalsData, "monthly_529_months") || 0)
        );
      const fourYearSavingsParent =
        parseInt(_.get(preapprovalsData, "parent_pledged_monthly") || 0) * 48;

      const fourYearSavingsStudent =
        parseInt(_.get(preapprovalsData, "student_pledged_monthly") || 0) * 48;
      const fourYearTotal =
        0 +
        savingsTotal +
        parseInt(_.get(preapprovalsData, "other_help") || 0) +
        parseInt(_.get(preapprovalsData, "parent_pledged_assets") || 0) +
        parseInt(_.get(preapprovalsData, "student_pledged_assets") || 0) +
        fourYearSavingsParent +
        fourYearSavingsStudent;
      return formatDollarAmount(fourYearTotal);
    }
  } else {
    return "$XXX,XXX";
  }
};

/**
 * TODO: refactor process functions to return a non formatted value so they can be reused here. Value will be formatted where they are used.
 * @param {*} yearsData
 * @param {*} preapprovalsData
 * @returns four year loan total (funding gap)
 */
const processLoanTotal = (yearsData, preapprovalsData) => {
  //calculate four year loan total
  if (yearsData && preapprovalsData) {
    const fourYearTotalPreApprovals =
      0 +
      _.get(preapprovalsData, "preapproval") +
      _.get(preapprovalsData, "preapproval_2") +
      _.get(preapprovalsData, "preapproval_3") +
      _.get(preapprovalsData, "preapproval_4");
    //calculate net cost according to the number of years projected to be attended at school
    let fourYearNetCost = 0;
    const net_cost = _.get(yearsData, "[0].net_cost");
    if (net_cost !== undefined) {
      for (let i = 0; i < 4; i++) {
        if (yearsData[i]) {
          fourYearNetCost += yearsData[i].net_cost;
        }
      }
    }
    let total = fourYearNetCost - fourYearTotalPreApprovals;
    if (total < 0) {
      total = 0;
    }
    return formatDollarAmount(total);
  } else {
    return "$XXX,XXX";
  }
};

/**
 *
 * @param {Number} dollarAmount - valid number
 * @param {boolean} withCents - format with cents
 * @returns formatted dollar amount or $XXX,XXX. Essentially the only difference from formatDollarAmount is that this returns $XXX,XXX instead of $0 for undefined
 */
const processUSD = (dollarAmount, withCents = false) => {
  if (dollarAmount !== undefined && dollarAmount !== null) {
    return formatDollarAmount(dollarAmount, withCents);
  } else {
    return "$XXX,XXX";
  }
};

/**
 *
 * @param {object} yearsData - an array of years, each year containing a cost_of_attendanc
 * @returns
 */
const processCostOfAttendance = (yearsData, year = 0) => {
  //calculate the sticker price, which is different from the net cost
  const cost_of_attendance = _.get(yearsData, `[${year}].cost_of_attendance`);
  if (cost_of_attendance !== undefined) {
    return formatDollarAmount(cost_of_attendance);
  } else {
    return "$XXX,XXX";
  }
};

/**
 *
 * @param {object} yearsData - an array of years, each year containing a need_based_grants
 * @returns
 */
const processNeedBasedGrants = (yearsData) => {
  const need_based_grants = _.get(yearsData, "[0].need_based_grants");
  if (need_based_grants !== undefined) {
    return formatDollarAmount(need_based_grants);
  } else {
    return "$XXX,XXX";
  }
};

/**
 * used for offers in compare & appeal to calculate remaining cost
 * @param {Object} tuition_budget - object with offer budget data will be deconstructed to get needed values.
 * @returns total cost after subtracting need_based_grants, merit_based_scholarships, subsidized_loans, and unsubsidized_loans from cost_of_attendance
 */
const processRemainingCost = (tuition_budget) => {
  const totalRemaining =
    parseInt(tuition_budget?.coa_1 || 0) +
    parseInt(tuition_budget?.room_and_board_1 || 0) +
    parseInt(tuition_budget?.books_and_supplies_1 || 0) +
    parseInt(tuition_budget?.travel_and_misc_1 || 0) +
    parseInt(tuition_budget?.other_fees_1 || 0) -
    parseInt(tuition_budget?.fed_state_grants_1 || 0) -
    parseInt(tuition_budget?.merit_scholarship_1 || 0);
  // - parseInt(tuition_budget?.federal_direct_student_loan_1 || 0) -
  // parseInt(tuition_budget?.unsubsidized_student_loan_1 || 0);
  return formatDollarAmount(totalRemaining);
};

/**
 *
 * @param {*} principal - funding gap
 * @param {*} interest - annual interest rate
 * @param {*} term - number of months
 * @returns
 */
const calcMonthlyRepayment = (principal, interest, term) => {
  //convert whole percentage number to decimal and divide by 12 months (1200)
  const rate = interest / 1200;
  //add the rate to 100% in decimal form eg. 1.005 instead of .005
  const rate1 = 1 + rate;
  //get the compound interest without multiplying it by the principal amount
  const compoundInterestMultiplier = Math.pow(rate1, term);
  // get the amortized payment
  const amortizedPayment =
    (principal * (rate * compoundInterestMultiplier)) /
    (compoundInterestMultiplier - 1);
  return Math.round(amortizedPayment * 100) / 100; // round to two decimal places
};

/**
 *
 * @param {*} payment - monthly payment
 * @param {*} term - number of months
 * @returns
 */
const calcTotalRepayment = (payment, term) => {
  return Math.round(payment * term * 100) / 100; // round to two decimal places
};

/**
 *
 * @param {fn} watchInt
 * @param {integer} interestRate - percentage
 * @param {integer} numYears
 * @returns
 */
const getMonthlyRepayment = (watchInt, interestRate, numYears) => {
  return calcMonthlyRepayment(
    getFundingGap(watchInt) < 0 ? 0 : getFundingGap(watchInt),
    interestRate,
    12 * numYears
  );
};

/**
 *
 * @param {fn} watchInt
 * @param {integer} interestRate - percentage
 * @param {integer} numYears
 * @returns
 */
const getTotalRepayment = (watchInt, interestRate, numYears) => {
  return calcTotalRepayment(
    getMonthlyRepayment(watchInt, interestRate, numYears),
    12 * numYears
  );
};

export {
  calcNetCost,
  processNetCost,
  processPreApprovals,
  processLoanTotal,
  processUSD,
  processCostOfAttendance,
  processNeedBasedGrants,
  processRemainingCost,
  getMonthlyRepayment,
  getTotalRepayment,
};
