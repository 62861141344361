import { useState } from "react";
import { registerWakeMeUser } from "@api/auth";
import { useRouter } from "next/router";
import Image from "next/image";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { size } from "lodash";

const IntakeForm = () => {
  const [studentInfo, setStudentInfo] = useState({
    student_first_name: "",
    student_last_name: "",
    student_birthdate: "",
    student_email: "",
    student_phone: "",
    student_address1: "",
    student_address2: "",
    student_city: "",
    student_state: "",
    student_zip: "",
    usnca_timezone_id: 8,
    high_school: "",
    graduation_year: "",
    gpa: "",
    weighted_gpa: "",
    sat: "",
    act: "",
    colleges: "",
    tax_return_filed: "",
    bank_account_balance: "",
    income: "",
    investments: "",
    parent1_first_name: "",
    parent1_last_name: "",
    parent1_self_employed: "",
    parent1_birthdate: "",
    parent1_occupation: "",
    parent1_years_employed: "",
    parent1_income: "",
    parent1_medical_expenses: "",
    parent1_retirement_amount: "",
    parent1_pretax_retirement: "",
    parent2_first_name: "",
    parent2_last_name: "",
    parent2_self_employed: "",
    parent2_birthdate: "",
    parent2_occupation: "",
    parent2_years_employed: "",
    parent2_income: "",
    parent2_medical_expenses: "",
    parent2_retirement_amount: "",
    parent2_pretax_retirement: "",
    household_size: "",
    marital_status: "",
    marital_status_date: "",
    parent_email: "",
    parent_phone: "",
    housing_status: "",
    housing_payment: "",
    housing_market_value: "",
    housing_amount_owed: "",
    housing_year_purchased: "",
    housing_purchase_price: "",
    sibling1_first_name: "",
    sibling1_last_name: "",
    sibling1_grade: "",
    sibling1_birthdate: "",
    sibling1_school: "",
    sibling1_tuition: "",
    sibling1_grants: "",
    sibling2_first_name: "",
    sibling2_last_name: "",
    sibling2_grade: "",
    sibling2_birthdate: "",
    sibling2_school: "",
    sibling2_tuition: "",
    sibling2_grants: "",
    acknowledgement1: 0,
    acknowledgement2: 0,
    acknowledgement3: 0,
    acknowledgement4: 0,
    signature: "",
  });

  const time_zones = [
    { label: "(GMT-10:00) Hawaii Time (USA)", value: 1 },
    { label: "(GMT-09:00) West-Alaska Time (USA)", value: 2 },
    { label: "(GMT-08:00) Alaska Time (USA)", value: 3 },
    { label: "(GMT-07:00) Pacific Time (USA & Canada)", value: 4 },
    { label: "(GMT-06:00) Mountain Time (USA & Canada)", value: 5 },
    { label: "(GMT-07:00) Arizona Time (USA)", value: 6 },
    { label: "(GMT-05:00) Central Time (USA & Canada)", value: 7 },
    { label: "(GMT-04:00) Eastern Time (USA & Canada)", value: 8 },
    { label: "(GMT-04:00) Atlantic Time (USA & Canada)", value: 9 },
  ];

  const states = [
    { label: "Choose a State", value: "null" },
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "American Samoa", value: "AS" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District of Columbia", value: "DC" },
    { label: "Federated States of Micronesia", value: "FSM" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Guam", value: "GU" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Palua", value: "PW" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virgin Islands", value: "VI" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];

  const router = useRouter();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    router.push("https://www.collegeaidpro.com");
  };

  const theme = createTheme({
    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: "2rem",
            fontWeight: "bold",
          },
        },
      },
    },
  });

  const ThankYouModal = () => {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Thank You for Registering!</DialogTitle>
            <DialogContent>
              <p>
                Thank you for submitting your intake form. We will be in touch
                on next steps shortly!
              </p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const results = await registerWakeMeUser(studentInfo);

    if (results.status === 200) {
      // Reset studentInfo values
      for (const key in studentInfo) {
        if (Object.hasOwnProperty.call(studentInfo, key)) {
          setStudentInfo((prevInfo) => ({
            ...prevInfo,
            [key]: "",
          }));
        }
      }

      // Show success modal
      setOpen(true);
    }
  };

  const handleChange = (e) => {
    let { id, value } = e.target;
    let type = "";
    type = e.target.attributes.type?.nodeValue;
    if (type === "checkbox") {
      const checked = e.target.checked;
      if (checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    setStudentInfo((prevInfo) => ({
      ...prevInfo,
      [id]: value,
    }));
  };

  const schoolStyle = {
    width: "100%",
    minWidth: "20rem",
    maxWidth: "66.5rem",
  };

  const handleCloseModal = () => {
    setOpen(false);
    router.push("https://www.collegeaidpro.com");
  };

  return (
    <form
      className="create-account-form w-full h-full flex flex-col items-center text-left"
      onSubmit={handleSubmit}
    >
      <ThankYouModal />
      <div className="IntakeFormContainer w-full h-full flex flex-col items-start justify-start">
        <div className="header flex flex-row items-center justify-start">
          <div className="flex flex-col justify-start w-1/2">
            <div className="desk-logo justify-start mb-4 mt-2">
              <div className="relative w-full h-full">
                <Image
                  src="/images/cap_main_logo.png"
                  alt="CollegeAidPro"
                  layout="fill"
                  width="100%"
                  height="100%"
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start w-2/3">
            <div className="title flex">Wake Me!!</div>
            <div className="flex">CAP's premiere service.</div>
          </div>
        </div>
        <div className="section-container flex flex flex-col items-center justify-center">
          <div className="section topSection flex flex-col justify-center w-4/5">
            <div className="section-title flex flex-row items-center justify-center">
              Student
            </div>
            <div className="section-content flex flex-col items-center justify-center">
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label htmlFor="student_first_name" className="textLabel small">
                  <span className="labelFocus">First Name</span>
                  <input
                    id="student_first_name"
                    type="text"
                    alt="Student First Name"
                    value={studentInfo.student_first_name}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label htmlFor="student_last_name" className="textLabel small">
                  <span className="labelFocus">Last Name</span>
                  <input
                    id="student_last_name"
                    type="text"
                    value={studentInfo.student_last_name}
                    onChange={handleChange}
                    alt="Student Last Name"
                    required
                  />
                </label>
                <label
                  htmlFor="student_birthdate"
                  className="textLabel dates small"
                >
                  <span className="labelFocus">Date of Birth</span>
                  <input
                    id="student_birthdate"
                    type="date"
                    value={studentInfo.student_birthdate}
                    onChange={handleChange}
                    placeholder="mm/dd/yyyy"
                    alt="Student Birthdate"
                    required
                  />
                </label>
              </div>
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label htmlFor="student_email" className="textLabel small">
                  <span className="labelFocus">Email</span>
                  <input
                    id="student_email"
                    type="email"
                    value={studentInfo.student_email}
                    onChange={handleChange}
                    placeholder="email@email.com"
                    required
                  />
                </label>
                <label htmlFor="student_phone" className="textLabel small">
                  <span className="labelFocus">Phone</span>
                  <input
                    id="student_phone"
                    type="tel"
                    value={studentInfo.student_phone}
                    onChange={handleChange}
                    alt="Student Phone"
                    placeholder="555-555-5555"
                    required
                  />
                </label>
              </div>
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label htmlFor="student_address1" className="textLabel small">
                  <span className="labelFocus">Address 1</span>
                  <input
                    id="student_address1"
                    type="text"
                    value={studentInfo.student_address1}
                    onChange={handleChange}
                    alt="Student Address 1"
                    required
                  />
                </label>
                <label htmlFor="student_address2" className="textLabel small">
                  <span className="labelFocus">Address 2</span>
                  <input
                    id="student_address2"
                    type="text"
                    value={studentInfo.student_address2}
                    onChange={handleChange}
                    alt="Student Address 2"
                  />
                </label>
              </div>
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label htmlFor="student_city " className="textLabel small">
                  <span className="labelFocus">City</span>
                  <input
                    id="student_city"
                    type="text"
                    value={studentInfo.student_city}
                    onChange={handleChange}
                    alt="Student City"
                  />
                </label>
                <label
                  htmlFor="student_state"
                  className="textLabel state small"
                >
                  <span className="labelFocus">State</span>
                  <select
                    id="student_state"
                    value={studentInfo.student_state}
                    onChange={handleChange}
                    alt="Student  State"
                    required
                  >
                    {states.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </label>
                <label
                  htmlFor="student_zip"
                  className="textLabel zipcode small"
                >
                  <span className="labelFocus">Zip Code</span>
                  <input
                    id="student_zip"
                    type="text"
                    value={studentInfo.student_zip}
                    onChange={handleChange}
                    alt="Student  Zip Code"
                    required
                  />
                </label>
                <label
                  htmlFor="usnca_timezone_id"
                  className="textLabel time_zone small"
                >
                  <span className="labelFocus">Prefered Time Zone</span>
                  <select
                    id="usnca_timezone_id"
                    value={studentInfo.prefered_tz}
                    onChange={handleChange}
                    alt="Prefered time zone"
                    required
                  >
                    {time_zones.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label htmlFor="high_school" className="textLabel small">
                  <span className="labelFocus">High School</span>
                  <input
                    id="high_school"
                    alt="High School"
                    type="text"
                    value={studentInfo.high_school}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label
                  htmlFor="graduation_year"
                  className="textLabel year small"
                >
                  <span className="labelFocus">Graduating Class</span>
                  <input
                    id="graduation_year"
                    type="number"
                    value={studentInfo.graduation_year}
                    onChange={handleChange}
                    size="4"
                    placeholder="YYYY"
                    alt="Graduatiion Year"
                    required
                  />
                </label>
                <label htmlFor="gpa" className="textLabel scores small">
                  <span className="labelFocus">GPA </span>
                  <input
                    id="gpa"
                    alt="GPA"
                    type="number"
                    value={studentInfo.gpa}
                    onChange={handleChange}
                    className="showSpinner"
                    min="0"
                    max="4"
                    step="0.1"
                    required
                  />
                </label>
                <label
                  htmlFor="weighted_gpa"
                  className="textLabel scores small"
                >
                  <span className="labelFocus">Weighted GPA</span>
                  <input
                    id="weighted_gpa"
                    alt="Weighted GPA"
                    type="number"
                    value={studentInfo.weighted_gpa}
                    onChange={handleChange}
                    className="showSpinner"
                    min="0"
                    max="5"
                    step="0.1"
                  />
                </label>
                <label htmlFor="sat" className="textLabel scores small">
                  <span className="labelFocus">SAT</span>
                  <input
                    id="sat"
                    alt="SAT"
                    type="number"
                    value={studentInfo.sat}
                    onChange={handleChange}
                    className="showSpinner"
                    min="400"
                    max="1600"
                    step="10"
                  />
                </label>
                <label htmlFor="sat" className="textLabel scores small">
                  <span className="labelFocus">ACT</span>
                  <input
                    id="act"
                    alt="ACT"
                    type="number"
                    value={studentInfo.act}
                    onChange={handleChange}
                    className="showSpinner"
                    min="0"
                    max="36"
                    step="1"
                  />
                </label>
              </div>
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label
                  htmlFor="colleges"
                  className="textLabel colleges small w-fulll"
                >
                  <span className="labelFocus">Potential Colleges</span>
                  <input
                    id="colleges"
                    alt="Potential Colleges"
                    type="text"
                    value={studentInfo.colleges}
                    onChange={handleChange}
                    style={schoolStyle}
                    required
                  />
                </label>
              </div>
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label htmlFor="tax_return_filed" className="textLabel  small">
                  <span className="labelFocus">Tax Return Filed</span>
                  <div className="flex flex-row items-center justify-start">
                    <label className="radioContainer radioLabel">
                      Yes
                      <input
                        id="tax_return_filed"
                        type="radio"
                        value="1"
                        onChange={handleChange}
                        checked={studentInfo.tax_return_filed === "1"}
                        required
                      />
                      <span className="radioCheckmark"></span>
                    </label>
                    <label className="radioContainer">
                      No
                      <input
                        id="tax_return_filed"
                        type="radio"
                        value="0"
                        onChange={handleChange}
                        checked={studentInfo.tax_return_filed === "0"}
                      />
                      <span className="radioCheckmark"></span>
                    </label>
                  </div>
                </label>
                <label
                  htmlFor="bank_account_balance"
                  className="textLabel small"
                >
                  <span className="labelFocus">Checking/Savings Balance</span>
                  <input
                    id="bank_account_balance"
                    type="number"
                    value={studentInfo.bank_account_balance}
                    onChange={handleChange}
                    alt="Student Checking/Savings balance"
                    required
                  />
                </label>
              </div>
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label htmlFor="income" className="textLabel small">
                  <span className="labelFocus">Student Yearly Income</span>
                  <input
                    id="income"
                    type="number"
                    value={studentInfo.income}
                    onChange={handleChange}
                    alt="Student Yearly Income"
                    required
                  />
                </label>
                <label htmlFor="investments" className="textLabel small">
                  <span className="labelFocus">
                    Value of Non-Retirement Investments
                  </span>
                  <input
                    id="investments"
                    type="number"
                    value={studentInfo.investments}
                    onChange={handleChange}
                    alt="Student Value of Non-Retirement Investments"
                    required
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="section-container flex flex flex-col items-center justify-center">
          <div className="section flex flex-col justify-center w-4/5">
            <div className="section-title flex flex-row items-center justify-center">
              Parent(s)
            </div>
            <div className="section-content flex flex-row items-center justify-evenly">
              <div className="subSection flex flex-col items-start justify-start w-1/2">
                <fieldset>
                  <legend>Parent 1</legend>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="parent1_first_name"
                      className="textLabel small"
                    >
                      <span className="labelFocus"> First Name</span>
                      <input
                        id="parent1_first_name"
                        type="text"
                        value={studentInfo.parent1_first_name}
                        onChange={handleChange}
                        alt="Parent 1 First Name"
                        required
                      />
                    </label>
                    <label
                      htmlFor="parent1_last_name"
                      className="textLabel small"
                    >
                      <span className="labelFocus"> Last Name</span>
                      <input
                        id="parent1_last_name"
                        type="text"
                        value={studentInfo.parent1_last_name}
                        onChange={handleChange}
                        alt="Parent 1 Last Name"
                        required
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="parent1_self_employed"
                      className="textLabel small"
                    >
                      <span className="labelFocus"> Self Employed</span>
                      <div className="flex flex-row items-center justify-start">
                        <label className="radioContainer radioLabel">
                          Yes
                          <input
                            id="parent1_self_employed"
                            type="radio"
                            value="1"
                            onChange={handleChange}
                            checked={studentInfo.parent1_self_employed === "1"}
                            required
                          />
                          <span className="radioCheckmark"></span>
                        </label>
                        <label className="radioContainer">
                          No
                          <input
                            id="parent1_self_employed"
                            type="radio"
                            value="0"
                            onChange={handleChange}
                            checked={studentInfo.parent1_self_employed === "0"}
                          />
                          <span className="radioCheckmark"></span>
                        </label>
                      </div>
                    </label>
                    <label
                      htmlFor="parent1_birthdate"
                      className="textLabel dates small"
                    >
                      <span className="labelFocus">Date of Birth</span>
                      <input
                        id="parent1_birthdate"
                        type="date"
                        value={studentInfo.parent1_birthdate}
                        onChange={handleChange}
                        placeholder="mm/dd/yyyy"
                        alt="Parent 1 Date of Birth"
                        required
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="parent1_occupation"
                      className="textLabel small"
                    >
                      <span className="labelFocus"> Occupation</span>
                      <input
                        id="parent1_occupation"
                        type="text"
                        value={studentInfo.parent1_occupation}
                        onChange={handleChange}
                        alt="Parent 1 Occupation"
                        required
                      />
                    </label>
                    <label
                      htmlFor="parent1_years_employed"
                      className="textLabel year small"
                    >
                      <span className="labelFocus">Years Employed</span>
                      <input
                        id="parent1_years_employed"
                        type="number"
                        value={studentInfo.parent1_years_employed}
                        onChange={handleChange}
                        alt="Parent 1 Years Employed"
                        required
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="parent1_birthdate"
                      className="textLabel small w-full"
                    >
                      <span className="labelFocus">Income</span>
                      <input
                        id="parent1_income"
                        type="number"
                        value={studentInfo.parent1_income}
                        onChange={handleChange}
                        alt="parent 1's Income"
                        required
                      />
                    </label>
                    <label
                      htmlFor="parent1_medical_expenses"
                      className="textLabel small"
                    >
                      <span className="labelFocus">
                        {" "}
                        Out of Pocket Medical Expenses
                      </span>
                      <input
                        id="parent1_medical_expenses"
                        type="number"
                        value={studentInfo.parent1_medical_expenses}
                        onChange={handleChange}
                        alt="Parent 1 Out of Pocket Medical Expenses"
                        required
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="parent1_retirement_amount"
                      className="textLabel small"
                    >
                      <span className="labelFocus"> Value of Retirement</span>
                      <input
                        id="parent1_retirement_amount"
                        type="number"
                        value={studentInfo.parent1_retirement_amount}
                        onChange={handleChange}
                        alt="Parent 1 Out of Pocket Medical Expenses"
                        required
                      />
                    </label>
                    <label
                      htmlFor="parent1_pretax_retirement"
                      className="textLabel small"
                    >
                      <span className="labelFocus">
                        {" "}
                        Pre-tax Retirement Contributions
                      </span>
                      <input
                        id="parent1_pretax_retirement"
                        type="number"
                        value={studentInfo.parent1_pretax_retirement}
                        onChange={handleChange}
                        alt="Parent 1 Pre-tax Retirement Contributions"
                        required
                      />
                    </label>
                  </div>
                </fieldset>
              </div>
              <div className="subSection flex flex-col items-start justify-start w-1/2">
                <fieldset>
                  <legend>Parent 2</legend>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="parent2_first_name"
                      className="textLabel small"
                    >
                      <span className="labelFocus"> First Name</span>
                      <input
                        id="parent2_first_name"
                        type="text"
                        value={studentInfo.parent2_first_name}
                        onChange={handleChange}
                        alt="Parent 2 First Name"
                        required
                      />
                    </label>
                    <label
                      htmlFor="parent2_last_name"
                      className="textLabel small"
                    >
                      <span className="labelFocus"> Last Name</span>
                      <input
                        id="parent2_last_name"
                        type="text"
                        value={studentInfo.parent2_last_name}
                        onChange={handleChange}
                        alt="Parent 2 Last Name"
                        required
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="parent2_self_employed"
                      className="textLabel small"
                    >
                      <span className="labelFocus"> Self Employed</span>
                      <div className="flex flex-row items-center justify-start">
                        <label className="radioContainer radioLabel">
                          Yes
                          <input
                            id="parent2_self_employed"
                            type="radio"
                            value="1"
                            onChange={handleChange}
                            checked={studentInfo.parent2_self_employed === "1"}
                            required
                          />
                          <span className="radioCheckmark"></span>
                        </label>
                        <label className="radioContainer">
                          No
                          <input
                            id="parent2_self_employed"
                            type="radio"
                            value="0"
                            onChange={handleChange}
                            checked={studentInfo.parent2_self_employed === "0"}
                          />
                          <span className="radioCheckmark"></span>
                        </label>
                      </div>
                    </label>
                    <label
                      htmlFor="parent2_birthdate"
                      className="textLabel dates small"
                    >
                      <span className="labelFocus">Date of Birth</span>
                      <input
                        id="parent2_birthdate"
                        type="date"
                        value={studentInfo.parent2_birthdate}
                        onChange={handleChange}
                        placeholder="mm/dd/yyyy"
                        alt="Parent 2 Date of Birth"
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="parent2_occupation"
                      className="textLabel small"
                    >
                      <span className="labelFocus"> Occupation</span>
                      <input
                        id="parent2_occupation"
                        type="text"
                        value={studentInfo.parent2_occupation}
                        onChange={handleChange}
                        alt="Parent 2 Occupation"
                      />
                    </label>
                    <label
                      htmlFor="parent2_years_employed"
                      className="textLabel year small"
                    >
                      <span className="labelFocus">Years Employed</span>
                      <input
                        id="parent2_years_employed"
                        type="number"
                        value={studentInfo.parent2_years_employed}
                        onChange={handleChange}
                        alt="Parent 2 Years Employed"
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="parent2_birthdate"
                      className="textLabel small w-full"
                    >
                      <span className="labelFocus">Income</span>
                      <input
                        id="parent2_income"
                        type="number"
                        value={studentInfo.parent2_income}
                        onChange={handleChange}
                        alt="'s Income"
                      />
                    </label>
                    <label
                      htmlFor="parent2_medical_expenses"
                      className="textLabel small"
                    >
                      <span className="labelFocus">
                        {" "}
                        Out of Pocket Medical Expenses
                      </span>
                      <input
                        id="parent2_medical_expenses"
                        type="number"
                        value={studentInfo.parent2_medical_expenses}
                        onChange={handleChange}
                        alt="Parent 2 Out of Pocket Medical Expenses"
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="parent2_retirement_amount"
                      className="textLabel small"
                    >
                      <span className="labelFocus"> Value of Retirement</span>
                      <input
                        id="parent2_retirement_amount"
                        type="number"
                        value={studentInfo.parent2_retirement_amount}
                        onChange={handleChange}
                        alt="Parent 2 Out of Pocket Medical Expenses"
                      />
                    </label>
                    <label
                      htmlFor="parent2_pretax_retirement"
                      className="textLabel small"
                    >
                      <span className="labelFocus">
                        {" "}
                        Pre-tax Retirement Contributions
                      </span>
                      <input
                        id="parent2_pretax_retirement"
                        type="number"
                        value={studentInfo.parent2_pretax_retirement}
                        onChange={handleChange}
                        alt="Parent 2 Pre-tax Retirement Contributions"
                      />
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <div className="section-container flex flex flex-col items-center justify-center">
          <div className="section flex flex-col justify-center w-4/5">
            <div className="section-title flex flex-row items-center justify-center">
              Household
            </div>
            <div className="section-content flex flex-col items-center justify-center">
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label
                  htmlFor="household_size"
                  className="textLabel scores small"
                >
                  <span className="labelFocus">Household Size</span>
                  <input
                    id="household_size"
                    type="number"
                    value={studentInfo.household_size}
                    onChange={handleChange}
                    className="showSpinner"
                    min="0"
                    max="15"
                    step="1"
                    alt="Household Size"
                  />
                </label>
                <label
                  htmlFor="marital_status"
                  className="textLabel marital_status small"
                >
                  <span className="labelFocus">Current Marital Status</span>
                  <select
                    id="marital_status"
                    value={studentInfo.marital_status}
                    onChange={handleChange}
                    alt="Current Marital Status"
                  >
                    <option value="Never married">Never married</option>
                    <option value="Unmarried and parents not living together">
                      Unmarried and parents not living together
                    </option>
                    <option value="Unmarried and parents living together">
                      Unmarried and parents living together
                    </option>
                    <option value="Married">Married</option>
                    <option value="Divorced or separated">
                      Divorced or separated
                    </option>
                    <option value="Remarried">Remarried</option>
                    <option value="Widowed">Widowed</option>
                  </select>
                </label>
                <label
                  htmlFor="marital_status_date"
                  className="textLabel dates small"
                >
                  <span className="labelFocus">
                    Date of Marriage, Divorce, etc.
                  </span>
                  <input
                    id="marital_status_date"
                    type="date"
                    value={studentInfo.marital_status_date}
                    onChange={handleChange}
                    placeholder="mm/dd/yyyy"
                    alt="Date of Marriage, Divorce, Separation, etc."
                  />
                </label>
              </div>
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label htmlFor="parent_email" className="textLabel small">
                  <span className="labelFocus">Parent Preferred Email</span>
                  <input
                    id="parent_email"
                    type="email"
                    value={studentInfo.parent_email}
                    onChange={handleChange}
                    alt="Parent Preferred Email"
                    placeholder="email@email.com"
                  />
                </label>
                <label htmlFor="parent_phone" className="textLabel small">
                  <span className="labelFocus">Parent Preferred Phone</span>
                  <input
                    id="parent_phone"
                    type="tel"
                    value={studentInfo.parent_phone}
                    onChange={handleChange}
                    alt="Parent Preferred Phone"
                    placeholder="555-555-5555"
                  />
                </label>
              </div>
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label
                  htmlFor="housing_status"
                  className="textLabel state small"
                >
                  <span className="labelFocus">Housing Status</span>
                  <select
                    id="housing_status"
                    value={studentInfo.housing_status}
                    onChange={handleChange}
                    alt="Current Housing Status"
                  >
                    'Own','Rent','Other'
                    <option value="Own">Own</option>
                    <option value="Rent">Rent</option>
                    <option value="Other">Other</option>
                  </select>
                </label>
                <label htmlFor="housing_payment" className="textLabel small">
                  <span className="labelFocus">
                    Monthly Mortgage/Rent Payment
                  </span>
                  <input
                    id="housing_payment"
                    type="number"
                    value={studentInfo.housing_payment}
                    onChange={handleChange}
                    alt="Monthly Mortgage/Rent Payment"
                  />
                </label>
              </div>
              <div className="section-content-text flex flex-row items-end justify-start w-4/5">
                <label
                  htmlFor="housing_market_value "
                  className="textLabel small"
                >
                  <span className="labelFocus">Housing Market Value</span>
                  <input
                    id="housing_market_value"
                    type="number"
                    value={studentInfo.housing_market_value}
                    onChange={handleChange}
                    alt="Housing Market Value"
                  />
                </label>
                <label
                  htmlFor="housing_amount_owed"
                  className="textLabel small"
                >
                  <span className="labelFocus">Housing Debt Balance</span>
                  <input
                    id="housing_amount_owed"
                    type="number"
                    value={studentInfo.housing_amount_owed}
                    onChange={handleChange}
                    alt="Housing Debt Balance"
                  />
                </label>
                <label
                  htmlFor="housing_year_purchased"
                  className="textLabel year small"
                >
                  <span className="labelFocus">Year Purchased</span>
                  <input
                    id="housing_year_purchased"
                    type="number"
                    value={studentInfo.housing_year_purchased}
                    onChange={handleChange}
                    placeholder="YYYY"
                    alt="Housing Year Purchased"
                  />
                </label>
                <label
                  htmlFor="housing_purchase_price"
                  className="textLabel small"
                >
                  <span className="labelFocus">Original Purchase Price</span>
                  <input
                    id="housing_purchase_price"
                    type="number"
                    value={studentInfo.housing_purchase_price}
                    onChange={handleChange}
                    alt="Original Purchase Price"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="section-container flex flex flex-col items-center justify-center">
          <div className="section flex flex-col justify-center w-4/5">
            <div className="section-title flex flex-row items-center justify-center">
              Sibling(s)
            </div>
            <div className="section-content flex flex-row items-center justify-evenly">
              <div className="subSection flex flex-col items-start justify-start w-1/2">
                <fieldset>
                  <legend>Sibling 1</legend>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="sibling1_first_name"
                      className="textLabel small"
                    >
                      <span className="labelFocus">First Name</span>
                      <input
                        id="sibling1_first_name"
                        type="text"
                        value={studentInfo.sibling1_first_name}
                        onChange={handleChange}
                        alt="Sibling 1 First Name"
                        required
                      />
                    </label>
                    <label
                      htmlFor="sibling1_last_name"
                      className="textLabel small"
                    >
                      <span className="labelFocus">Last Name</span>
                      <input
                        id="sibling1_last_name"
                        type="text"
                        value={studentInfo.sibling1_last_name}
                        onChange={handleChange}
                        alt="Sibling 1 Last Name"
                        required
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label htmlFor="sibling1_grade" className="textLabel small">
                      <span className="labelFocus">Grade</span>
                      <input
                        id="sibling1_grade"
                        type="number"
                        value={studentInfo.sibling1_grade}
                        onChange={handleChange}
                        alt="Sibling 1 Grade"
                      />
                    </label>
                    <label
                      htmlFor="sibling1_birthdate"
                      className="textLabel dates small"
                    >
                      <span className="labelFocus">Date of Birth</span>
                      <input
                        id="sibling1_birthdate"
                        type="date"
                        value={studentInfo.sibling1_birthdate}
                        onChange={handleChange}
                        placeholder="mm/dd/yyyy"
                        alt="Sibling 1 Date of Birth"
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="sibling1_birthdate"
                      className="textLabel small w-full"
                    >
                      <span className="labelFocus">Current School</span>
                      <input
                        id="sibling1_school"
                        type="text"
                        value={studentInfo.sibling1_school}
                        onChange={handleChange}
                        alt="Sibling 1's School"
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="sibling1_tuition"
                      className="textLabel small"
                    >
                      <span className="labelFocus">Tuition</span>
                      <input
                        id="sibling1_tuition"
                        type="number"
                        value={studentInfo.sibling1_tuition}
                        onChange={handleChange}
                        alt="Sibling 1 Tuition"
                      />
                    </label>
                    <label
                      htmlFor="sibling1_grants"
                      className="textLabel small"
                    >
                      <span className="labelFocus">Grants Received</span>
                      <input
                        id="sibling1_grants"
                        type="number"
                        value={studentInfo.sibling1_grants}
                        onChange={handleChange}
                        alt="Sibling 1 grants received"
                      />
                    </label>
                  </div>
                </fieldset>
              </div>
              <div className="subSection flex flex-col items-start justify-start w-1/2">
                <fieldset>
                  <legend>Sibling 2</legend>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="sibling2_first_name"
                      className="textLabel small"
                    >
                      <span className="labelFocus">First Name</span>
                      <input
                        id="sibling2_first_name"
                        type="text"
                        value={studentInfo.sibling2_first_name}
                        onChange={handleChange}
                        alt="Sibling 2 First Name"
                      />
                    </label>
                    <label
                      htmlFor="sibling2_last_name"
                      className="textLabel small"
                    >
                      <span className="labelFocus">Last Name</span>
                      <input
                        id="sibling2_last_name"
                        type="text"
                        value={studentInfo.sibling2_last_name}
                        onChange={handleChange}
                        alt="Sibling 2 Last Name"
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label htmlFor="sibling2_grade" className="textLabel small">
                      <span className="labelFocus">Grade</span>
                      <input
                        id="sibling2_grade"
                        type="number"
                        value={studentInfo.sibling2_grade}
                        onChange={handleChange}
                        alt="Sibling 2 Grade"
                      />
                    </label>
                    <label
                      htmlFor="sibling2_birthdate"
                      className="textLabel dates small"
                    >
                      <span className="labelFocus">Date of Birth</span>
                      <input
                        id="sibling2_birthdate"
                        type="date"
                        value={studentInfo.sibling2_birthdate}
                        onChange={handleChange}
                        placeholder="mm/dd/yyyy"
                        alt="Sibling 2 Date of Birth"
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="sibling2_school"
                      className="textLabel small w-full"
                    >
                      <span className="labelFocus">Current School</span>
                      <input
                        id="sibling2_school"
                        type="text"
                        value={studentInfo.sibling2_school}
                        onChange={handleChange}
                        alt="Sibling 2's School"
                      />
                    </label>
                  </div>
                  <div className="section-content-text multiSection flex flex-row items-end justify-start w-4/5">
                    <label
                      htmlFor="sibling2_tuition"
                      className="textLabel small"
                    >
                      <span className="labelFocus">Tuition</span>
                      <input
                        id="sibling2_tuition"
                        type="number"
                        value={studentInfo.sibling2_tuition}
                        onChange={handleChange}
                        alt="Sibling 2 Tuition"
                      />
                    </label>
                    <label
                      htmlFor="sibling2_grants"
                      className="textLabel small"
                    >
                      <span className="labelFocus">Grants Received</span>
                      <input
                        id="sibling2_grants"
                        type="number"
                        value={studentInfo.sibling2_grants}
                        onChange={handleChange}
                        alt="Sibling 2 grants received"
                      />
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <div className="section-container flex flex flex-col items-center justify-center">
          <div className="section flex flex-col justify-center w-4/5">
            <div className="section-title wide flex flex-row items-center justify-center">
              Acknowledgements & Signature
            </div>
            <div className="section-content flex flex-row items-center justify-evenly w-4/5">
              <div className="section-content-text  flex flex-col items-center justify-start w-1/4">
                <label htmlFor="acknowledgement1" className="chkbxContainer">
                  <input
                    id="acknowledgement1"
                    type="checkbox"
                    checked={
                      studentInfo.acknowledgement1 === 1 ? "checked" : ""
                    }
                    onChange={handleChange}
                    alt="Acknowledgements"
                    required
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="section-content-text  flex flex-col items-start justify-start w-3/4">
                <span className="acknowledgeText">
                  Parents will open and engage in all communication received
                  from CAP; emails, calls, videos, meetings, etc.
                </span>
              </div>
            </div>
            <div className="section-content flex flex-row items-center justify-evenly w-4/5">
              <div className="section-content-text  flex flex-col items-center justify-start w-1/4">
                <label htmlFor="acknowledgement2" className="chkbxContainer">
                  <input
                    id="acknowledgement2"
                    type="checkbox"
                    checked={
                      studentInfo.acknowledgement2 === 1 ? "checked" : ""
                    }
                    onChange={handleChange}
                    alt="Acknowledgements"
                    required
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="section-content-text  flex flex-col items-start justify-start w-3/4">
                <span className="acknowledgeText">
                  Parent AND student will open and engage in all communication
                  received from colleges. Many colleges will send important
                  notifications ONLY to students.
                </span>
              </div>
            </div>
            <div className="section-content flex flex-row items-center justify-evenly w-4/5">
              <div className="section-content-text  flex flex-col items-center justify-start w-1/4">
                <label htmlFor="acknowledgement3" className="chkbxContainer">
                  <input
                    id="acknowledgement3"
                    type="checkbox"
                    checked={
                      studentInfo.acknowledgement3 === 1 ? "checked" : ""
                    }
                    onChange={handleChange}
                    alt="Acknowledgements"
                    required
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="section-content-text  flex flex-col items-start justify-start w-3/4">
                <span className="acknowledgeText">
                  Parents will submit tax documentation directly to any entity
                  requesting this information; particular colleges and/or IDOC.
                  I acknowledge that the estimated cost projections do not
                  represent a final determination, or actual award, of financial
                  assistance, or a final net price.
                </span>
              </div>
            </div>
            <div className="section-content flex flex-row items-center justify-evenly w-4/5">
              <div className="section-content-text  flex flex-col items-center justify-start w-1/4">
                <label htmlFor="acknowledgement4" className="chkbxContainer">
                  <input
                    id="acknowledgement4"
                    type="checkbox"
                    checked={
                      studentInfo.acknowledgement4 === 1 ? "checked" : ""
                    }
                    onChange={handleChange}
                    alt="Acknowledge digital signature is binding"
                    required
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="section-content-text  flex flex-col items-start justify-start w-3/4">
                <span className="acknowledgeText">
                  By entering typing your name into the signature box below you
                  agree that this is a legal and binding signature.
                </span>
              </div>
            </div>
            <div className="section-content-text flex flex-row items-end justify-start w-4/5 pt-6">
              <label htmlFor="signature" className="textLabel signature small">
                <span className="labelFocus signatureLabel">
                  Type your name here to sign:
                </span>
                <input
                  id="signature"
                  type="text"
                  className="signature"
                  value={studentInfo.signature}
                  onChange={handleChange}
                  alt="Signature"
                  placeholder="Your Name"
                  required
                />
              </label>
            </div>
          </div>
          <div className="section subButton flex flex-row items-center justify-center w-full">
            <button type="submit">Submit</button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default IntakeForm;
