import { useFormContext } from "react-hook-form";
import NumberFormat from 'react-number-format';
import _ from "lodash";
import { useEffect } from "react";

const TextField = ({ input }) => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (input.initialValue !== undefined) {
      setValue(input.mapId, input.initialValue, { shouldDirty: false });
    }
  }, [input.initialValue]);

  let value;
  if (!input.dontRenderValue) {
    value = !isNaN(input.forcedValue)
      ? input.forcedValue
      : getValues(input.mapId);
  }

  return (
    <>
      {input.amount ? (
        <NumberFormat
          className={`${_.get(errors, input.mapId) ? "inputErrorState" : ""}`}
          value={value}
          {...register(input.mapId, {
            ...input.validation,
          })}
          placeholder={input.placeholder}
          onValueChange={({ value }) => {
            setValue(input.mapId, value, { shouldDirty: true });
          }}
          thousandSeparator={true}
          style={{ ...(input.forceWhite ? { background: "white" } : {}) }}
          disabled={input.disabled}
        />
      ) : (
        <input
          type={input.type}
          id={input.mapId}
          className={`${_.get(errors, input.mapId) ? "inputErrorState" : ""}`}
          {...register(input.mapId, {
            ...input.validation,
          })}
          style={{ ...(input.forceWhite ? { background: "white" } : {}) }}
          placeholder={input.placeholder}
          disabled={input.disabled}
        />
      )}
    </>
  );
};

export default TextField;
