const Profile = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 36 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Profile"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M18,34 C9.163444,34 2,26.836556 2,18 C2,9.163444 9.163444,2 18,2 C26.836556,2 34,9.163444 34,18 C34,26.836556 26.836556,34 18,34 Z M18,24.4217687 C13.679906,24.4217687 10.1777778,20.9375085 10.1777778,16.6394558 C10.1777778,12.341403 13.679906,8.85714286 18,8.85714286 C22.320094,8.85714286 25.8222222,12.341403 25.8222222,16.6394558 C25.8222222,20.9375085 22.320094,24.4217687 18,24.4217687 Z M8.0952381,28.6666667 C10.2301223,25.8367347 13.5340437,24.4217687 18.0070026,24.4217687 C22.470192,24.4217687 25.7694451,25.8305606 27.9047619,28.6481443"
            id="Icon"
            stroke="currentColor"
            strokeWidth="2"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Profile;
