import { useFormContext } from "react-hook-form";

/**
 * Compnent return a custom radio input field
 * with options that can be selected.
 * @param {object} input - Values for input field. For more reference
 * *@param {string} input.label - Label that describes what the value is about.
 * @param {string} input.mapId - Id against which we search student info in
 * an object. Also use this key value to register a field in react hook
 * form if we want to edit this value.
 * @param {string} input.radioOptions - Possible options that can be selected for
 * radio input field e.g Yes, No.
 */
const RadioField = ({ input }) => {
  const { register } = useFormContext();

  return (
    <div className="grid grid-cols-2 gap-x-2">
      {input.radioOptions.map((option) => (
        <div key={option.value}>
          <input
            type="radio"
            value={option.value}
            id={option.value}
            {...register(input.mapId, {
              required: {
                value: true,
                message: "Please specify status",
              },
            })}
          />
          <label htmlFor={option.value} className="radioOption">
            <span className="radioLabel">{option.label}</span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioField;
