import { processUSD } from "@utils/costEstimates";
import ClipLoader from "react-spinners/ClipLoader";

/**
 * @param {integer} awardNetCost - net cost according to award amount
 * @param {integer} estimatedNetCost - CAP projected net cost based on profile
 * @param {string} expectedNetCostRating
 * @returns details of an appeal
 */
const AppealDetails = ({
  awardNetCost,
  estimatedNetCost,
  expectedNetCostRating,
  hasApplicableCategories,
}) => {
  const renderStrengthText = () => {
    switch (expectedNetCostRating) {
      case "Lower":
        return (
          <>
            <small className="mb-2">
              Regretfully, this financial aid package is meaningfully less than
              what we would expect.
            </small>
            <small className="mb-2">
              There could be several factors at play here, but an appeal is
              definitely worth considering.
            </small>
          </>
        );
      case "Higher":
        return (
          <>
            <small className="mb-2">
              Congratulations, you received a better financial aid package than
              we anticipated!
            </small>
            <small className="mb-2">
              You can still appeal, but it does not look like the school is
              trying to short-change you.
            </small>
          </>
        );
      case "In line":
        return (
          <>
            <small className="mb-2">
              Good news: the financial aid package you received was in line with
              our expectations.
            </small>
            <small className="mb-2">
              There is still a chance to appeal for more aid, but you are
              starting from a reasonable place.
            </small>
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <small>Award Letter First Year Net Cost</small>
      <h2>{processUSD(awardNetCost)}</h2>
      <small>CAP Projected First Year Net Cost</small>
      <h5>
        {estimatedNetCost ? (
          processUSD(estimatedNetCost)
        ) : (
          <div className="loading-spinner w-full h-full flex justify-start items-center">
            <ClipLoader
              size={20}
              css={{
                borderColor: "inherit",
                borderBottomColor: "transparent",
              }}
            />
          </div>
        )}
      </h5>
      <div className="flex flex-row w-full">
        <div className="flex flex-col">
          <small>Difference</small>
          <h5 className="removeMargin">
            {estimatedNetCost ? (
              processUSD(awardNetCost - estimatedNetCost)
            ) : (
              <div className="loading-spinner w-full h-full flex justify-start items-center">
                <ClipLoader
                  size={20}
                  css={{
                    borderColor: "inherit",
                    borderBottomColor: "transparent",
                  }}
                />
              </div>
            )}
          </h5>
        </div>
        <div className="right-side flex flex-col">
          {estimatedNetCost && awardNetCost - estimatedNetCost !== 0 && (
            <small>
              The award amount is{" "}
              <span className="font-bold">
                {expectedNetCostRating?.toLowerCase()}{" "}
              </span>
              <span>
                {expectedNetCostRating === "In line" ? "with" : "than"}{" "}
              </span>
              what we would expect
            </small>
          )}
        </div>
      </div>
      <div className="divider" />
      {renderStrengthText()}
      {hasApplicableCategories && (
        <small>
          See below for some factors that could contribute to a successful
          appeal:
        </small>
      )}
    </>
  );
};

export default AppealDetails;
