import { useAppContext } from "@context/state.js";
import { useForm } from "react-hook-form";
import moment from "moment";
import { getDaysFromCount, getYears, months } from "@utils/dateFormatter";
import Image from "next/legacy/image";
import { setSchoolApplyingStatus, updateSchoolInfo } from "@api/schools";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import _ from "lodash";
import { DownArrow } from "@icons/index";

// TODO doesn't seem to be used
const SchoolCardEditModal = () => {
  const router = useRouter();

  const [days, setDays] = useState([]);

  const {
    scenario,
    populateUserSchools,
    formModalContent,
    setDisplayFormModal,
    handleApiError,
  } = useAppContext();

  const methods = useForm();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  /**
   * parse submitted data and update school data on backend
   * @param {object} values - Object contains the following:
   * @param {string} applying - application stage, eg. Early Action,
   * @param {Date} deadline - format YYYY-MM-DD
   * @param {string || integer} earlyActionDateDay - If touched and submitted from form, it's a string, if untouched it's an integer (value unchanged).
   * @param {string || integer} earlyActionDateMonth - If touched and submitted from form, it's a string, if untouched it's an integer (value unchanged).
   * @param {string || integer} earlyActionDateYear - If touched and submitted from form, it's a string, if untouched it's an integer (value unchanged).
   * @param {string} fafsa - status of fafsa
   * @param {boolean} removeApplication - used to remove application from user's scenario if submitted as true.
   */
  const filterAndUpdateSchoolData = async (values) => {
    let { earlyActionDateYear, earlyActionDateMonth, earlyActionDateDay } =
      values;

    /**
     * Add 0 prefix to numbers from 0 to 9.
     */
    earlyActionDateMonth = ("0" + earlyActionDateMonth).slice(-2);
    earlyActionDateDay = ("0" + earlyActionDateDay).slice(-2);

    const deadline = `${earlyActionDateYear}-${earlyActionDateMonth}-${earlyActionDateDay}`;
    if (earlyActionDateDay && earlyActionDateMonth && earlyActionDateYear) {
      values.deadline = deadline;
    }
    Object.keys(values).forEach((key) => {
      if (values[key] === "") {
        delete values[key];
      }
    });
    await updateSchoolInfo(
      scenario?.case_id,
      formModalContent.school_id,
      values
    );
  };

  const onSubmit = async (values) => {
    try {
      if (_.isEqual(values.removeApplication, true)) {
        await setSchoolApplyingStatus(
          scenario?.case_id,
          formModalContent.school_id,
          "No"
        );
      } else {
        await filterAndUpdateSchoolData(values);
      }
      populateUserSchools(scenario?.case_id);
      setDisplayFormModal(false);
    } catch (error) {
      handleApiError(error);
    }
  };

  const { name, logo, institutional, deadline, decision, fafsa, applying } =
    formModalContent;
  const deadlineMomentObject = moment(deadline, "YYYY-MM-DD");
  const deadlineYearMapId = "earlyActionDateYear";
  const deadlineMonthMapId = "earlyActionDateMonth";
  const deadlineDayhMapId = "earlyActionDateDay";
  const deadlineYearWatch =
    watch(deadlineYearMapId) || deadlineMomentObject.year();
  const deadlineMonthWatch =
    watch(deadlineMonthMapId) || deadlineMomentObject.month();
  const deadlineDayWatch =
    watch("earlyActionDateDay") || deadlineMomentObject.date();
  const selectOptions = ["", "In Progress", "Submitted"];

  const applyingActionOptions = [
    "",
    "Early Action",
    "Early Decision",
    "Regular Decision",
    "Rolling",
  ];

  const universityDecisionOptions = ["", "Accepted", "Waitlisted", "Declined"];

  /**
   * Recreate days array based on month selected
   */
  useEffect(() => {
    setDays(
      getDaysFromCount(
        moment(
          `${deadlineYearWatch}-${deadlineMonthWatch}`,
          "YYYY-MM"
        ).daysInMonth() || 31
      )
    );
  }, [deadlineYearWatch, deadlineMonthWatch]);

  /**
   * Update days field on every time days array is changed.
   */
  useEffect(() => {
    setValue(deadlineDayhMapId, deadlineDayWatch);
  }, [days]);

  const getOptions = (optionsArray = []) => {
    return optionsArray.map((value, index) => (
      <option value={value} key={index}>
        {value}
      </option>
    ));
  };

  const getDefaultValue = (value) => {
    if (value && value !== "N/A") {
      return value;
    } else {
      return "";
    }
  };

  const gridSelectFields = [
    {
      label: "FAFSA",
      options: selectOptions,
      map: "fafsa",
      defaultValue: getDefaultValue(fafsa),
    },
    {
      label: "Institutional",
      options: selectOptions,
      map: "institutional",
      defaultValue: getDefaultValue(institutional),
    },
  ];

  const earlyActionDateSelectFields = [
    {
      map: "earlyActionDateYear",
      defaultValue: deadlineMomentObject.year(),
      options: getYears(),
    },
    {
      map: "earlyActionDateMonth",
      defaultValue: deadlineMomentObject.month() + 1,
      options: months,
    },
    {
      map: "earlyActionDateDay",
      defaultValue: deadlineMomentObject.date(),
      options: days,
    },
  ];

  /**
   * Reset react hook form when component
   * unmounts
   */
  useEffect(() => {
    return () => {
      methods.reset();
    };
  }, []);

  return (
    <form
      className="w-full h-full flex flex-col items-center"
      action="#"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="modal-header">
        <button
          type="button"
          className="imitateLink border-0 text-left small"
          onClick={() => setDisplayFormModal(false)}
        >
          Cancel
        </button>
        <h6 className="mb-0 text-center capitalize">
          {router.asPath.replaceAll("_", " ").replaceAll("/", "")}
        </h6>
        {isSubmitting ? (
          <ClipLoader
            size={20}
            css={{
              borderColor: "var(--primary-color)",
              borderBottomColor: "transparent",
            }}
          />
        ) : (
          <button
            disabled={isSubmitting}
            type="submit"
            className="border-0 text-right small save"
          >
            Save
          </button>
        )}
      </div>
      <div className="modal-scroll flex justify-center">
        <div className="modal-content form-content">
          <div id="school-card-edit-modal">
            <div className="flex flex-row items-center content-section">
              <div className="schoolLogo flex justify-center items-center">
                <Image src={logo} layout="fill" objectFit="contain" />
              </div>
              <h4 className="university-name">{name}</h4>
            </div>

            <div className="flex flex-row content-section remove_application">
              <h6 className="mb-0">Remove Application</h6>
              <label className="toggle">
                <input
                  type="checkbox"
                  {...register("removeApplication", { value: false })}
                />
                <span className="slider">
                  <div className="yes">Yes</div>
                  <div className="no">No</div>
                </span>
              </label>
            </div>

            <div className="flex flex-1 mr-2 flex-col field">
              <label>Applying</label>
              <select
                {...register("applying")}
                className="w-full"
                defaultValue={applying}
              >
                {getOptions(applyingActionOptions)}
              </select>
              <div className="select-icon">
                <DownArrow />
              </div>
            </div>

            <div className="flex flex-row w-full field">
              <div className="flex flex-1 mr-2 flex-col">
                <label>Deadline</label>
                <div className="grid grid-cols-3 gap-5">
                  {earlyActionDateSelectFields.map((fieldData) => (
                    <select
                      key={fieldData.map}
                      {...register(fieldData.map, {})}
                      defaultValue={fieldData.defaultValue}
                    >
                      <option value=""></option>
                      {getOptions(fieldData.options)}
                    </select>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex flex-1 mr-2 flex-col field relative">
              <label>Decision</label>
              <select
                {...register("decision", {
                  value: getDefaultValue(decision),
                })}
              >
                {getOptions(universityDecisionOptions)}
              </select>
              <div className="select-icon">
                <DownArrow />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-5">
              {gridSelectFields.map((fieldData, index) => (
                <div
                  key={`select-${fieldData.label}-options-${index}`}
                  className="flex flex-1 mr-2 flex-col field"
                >
                  <label>{fieldData.label}</label>
                  <select
                    {...register(fieldData.map, {
                      value: fieldData.defaultValue,
                    })}
                  >
                    {getOptions(fieldData.options)}
                  </select>
                  <div className="select-icon">
                    <DownArrow />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SchoolCardEditModal;
