import Image from "next/legacy/image";

/**
 * Method for checking if the url references a logo
 * @param {string} logo - logo url
 * @returns: boolean
 */
const logoCheck = (logo) => {
  if (logo?.length > 0) {
    if (logo.includes("/logos/")) {
      return true;
    }
  }
  return false;
};

/**
 * Method for rendering school logo
 * @param {string} logo - logo url
 * @param {string} name - school name
 * @returns: xml render of logo
 */
const renderLogo = (logo, name) => {
  return (
    <div
      className={`${
        !logoCheck(logo) ? "noLogo " : ""
      }schoolLogo flex justify-center items-center`}
    >
      {logoCheck(logo) ? (
        <Image
          src={logo}
          alt={name || logo}
          layout="fill"
          objectFit="contain"
        />
      ) : (
        <div className="bg" />
      )}
    </div>
  );
};

// If need to call logo check separately from stuff
renderLogo.logoCheck = logoCheck;

export default renderLogo;
