import React, { useEffect } from "react";
import { useAppContext } from "@context/state";
import { getAccessTokenFromDocument } from "@utils/getAccessToken";
import { parseJwt } from "@utils/parseJwt";
import _ from "lodash";

/**
 * Handle when tab or window is returned to.
 * This helps show which user is actually logged in. An old session might allow a user to send a bad request if not refreshed.
 * @param {*} children
 * @returns
 */
const WindowFocusHandler = ({ children }) => {
  const { user, handleUserToken, populateUser } = useAppContext();

  // User has switched back to the tab.
  //NOTE: This will only trigger a refresh of the user if on an authorized path. /login, /, and /create_account will not trigger it.
  const onVisibilityChange = () => {
    // check the token when returning to tab
    const tokenIsValid = handleUserToken();
    //repopulate user if user is already present and it doesn't match the token (view is not a new tab)
    if (tokenIsValid && user?.email) {
      const userTokenObject = parseJwt(getAccessTokenFromDocument());
      if (user?.email !== _.get(userTokenObject, "uid")) {
        populateUser();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);
    // Calls onVisibilityChange when the window first loads
    onVisibilityChange();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  return <>{children}</>;
};

export default WindowFocusHandler;
