import { LongLeftArrow, WarningIcon, ErrorIcon } from "@icons/index";
import { useRouter } from "next/router";
import { useAppContext } from "@context/state";
import { PERSONAL_INFO_EDIT, HELP_VIEW } from "@utils/constants";
import { getbasicInfo, getStudentDetail } from "@utils/studentData";
import { getParentGuardianInfo } from "@utils/parentData";
import _ from "lodash";
import { getTotalPersonalInfoWeightPercentage } from "@utils/calcEnhancedProfile";
import { PillProgress } from "@components/index";
import { isFilingJointly } from "@utils/maritalStatusCheck";

//Read-only view of onboarding data in profile
const PersonalInfo = () => {
  const router = useRouter();
  const {
    scenario,
    setFormModalView,
    setDisplayFormModal,
    setDisplayModal,
    setModalView,
    setModalContent,
  } = useAppContext();
  const { onboarding } = scenario;
  //NOTE: intended_major_object rather than intended_major, since we wanted to return more info related to the chosen major without breaking things that previously relied on intended_major being an integer.
  const major = onboarding?.student?.intended_major_object;
  const religion = onboarding?.student?.religion;
  const ethnicity = onboarding?.student?.ethnicity;

  //For each item in the list of onboarding data, render the data to the DOM
  //In PersonalInfoEditModal, itemData is the same object as input or inputData
  //TODO: consolidate naming of matching objects
  const ListItem = ({ itemData }) => {
    //conditionally render based on marital status or other render conditions
    if (
      itemData.validateRenderCondition &&
      !itemData.validateRenderCondition(
        _.get(onboarding, itemData.dependentMapId)
      )
    ) {
      return null;
    }

    if (itemData.formOnly) {
      return null;
    }

    let content = "--";
    if (itemData.value !== null) {
      /* Not all items have a displayValue. displayValue is used to format
          different types of data while leaving value alone (since value will be used to submit changes to backend)
          Reference profileData.js to see mapping of the data
          check "&& itemData.value" is needed since an undefined value will still return a valid displayValue for
          items that have it. Thus we can show only an empty space with itemData.value for those undefined items*/
      content =
        itemData.displayValue !== undefined &&
          (itemData.value || itemData.value === 0)
          ? itemData.displayValue
          : itemData.value;
    }

    const openTooltip = (label, type) => {
      const modalContent =
        type === "warning"
          ? [
            {
              title: label,
              description:
                "Optional Input - please enter if available, but can be left blank.",
            },
          ]
          : [
            {
              title: label,
              description:
                "Important Input - do not leave blank and enter zero if applicable.",
            },
          ];
      setModalContent(modalContent);
      setModalView(HELP_VIEW);
      setDisplayModal(true);
    };
    // if (itemData.displayValue === "N/A") {
    //   content = itemData.displayValue;
    // }
    //render item to list of personal info
    const renderWarningOrErrorIcon = (value) => {
      if (value === null) {
        const isFilingJointlyResult = isFilingJointly(
          onboarding?.parent_guardian?.filing_status,
          onboarding?.parent_guardian?.marital_status
        );
        switch (itemData.mapId) {
          case "parent_guardian.parent_earnings":
            if (isFilingJointlyResult && onboarding?.parent_guardian.agi_2) {
              return;
            }
            if (!isFilingJointlyResult && onboarding?.parent_guardian.agi) {
              return;
            }
            break;
          case "parent_guardian.parent_earnings_2":
            if (onboarding?.parent_guardian.agi_2) {
              return;
            }
            break;
          case "parent_guardian.parent_earnings_3":
            if (onboarding?.parent_guardian.agi_3) {
              return;
            }
            break;
          case "student.student_earnings":
            if (onboarding?.student.student_agi) {
              return;
            }
            break;
          case "parent_guardian.agi":
            if (onboarding?.parent_guardian.parent_earnings) {
              return;
            }
            break;
          case "parent_guardian.agi_2":
            if (
              isFilingJointlyResult &&
              onboarding?.parent_guardian.parent_earnings &&
              onboarding?.parent_guardian.parent_earnings_2
            ) {
              return;
            }
            if (
              !isFilingJointlyResult &&
              onboarding?.parent_guardian.parent_earnings_2
            ) {
              return;
            }
            break;
          case "parent_guardian.agi_3":
            if (onboarding?.parent_guardian.parent_earnings_3) {
              return;
            }
            break;
          case "student.student_agi":
            if (onboarding?.student.student_earnings) {
              return;
            }
            break;
        }

        return (
          <>
            {" "}
            {/* TODO figure out how to make an attribute do this is done for optional fields, define something upstream for this file and calcEnhancedProfile ideally */}
            {[
              "student.scores.weighted_gpa",
              "student.scores.sat",
              "student.scores.act",
              "student.high_school_class_rank",
              "student.high_school_class_size",
              "student.ethnicity_id",
              "student.religion_id",
              "parent_guardian.agi",
              "parent_guardian.agi_2",
              "parent_guardian.agi_3",
              "parent_guardian.birth_date",
              "parent_guardian.birth_date_2",
              "parent_guardian.birth_date_3",
              "parent_guardian.income_tax_paid",
              "parent_guardian.income_tax_paid_2",
              "parent_guardian.income_tax_paid_3",
              "student.student_agi",
              "student.national_merit_award",
              "student.school_name",
              "student.school_id",
              "parent_guardian.child_support",
              "parent_guardian.child_support_2",
              "parent_guardian.child_support_3",
            ].includes(itemData.mapId) ? (
              <div
                className={`missing-field textWarningState`}
                onClick={() => openTooltip(itemData.label, "warning")}
              >
                <WarningIcon />
              </div>
            ) : (
              <div
                className={`missing-field textErrorState`}
                onClick={() => openTooltip(itemData.label, "error")}
              >
                <ErrorIcon />
              </div>
            )}
          </>
        );
      }
    };
    return (
      <div className={`contentItem`}>
        {renderWarningOrErrorIcon(itemData.value)}
        <small>{itemData.label}</small>
        <p>{content}</p>
      </div>
    );
  };

  //Personal Info page display.
  return (
    <div className="entryPage flex flex-col justify-between h-full w-full">
      <div className="profileContainer flex flex-col items-center justify-start h-full w-full">
        <div className="pseudo-nav constrained flex flex-row items-center justify-center w-full relative">
          <button
            className="simple w-7 h-7 p-2 -ml-2 flex items-center absolute left-0"
            onClick={() => {
              router.back();
            }}
          >
            <LongLeftArrow />
          </button>
          <div className="font-demi-bold text-center">Personal Info</div>
          <button
            className="small imitateLink p-2 -mr-2 absolute right-0"
            onClick={() => {
              setFormModalView(PERSONAL_INFO_EDIT);
              setDisplayFormModal(true);
            }}
          >
            Edit
          </button>
        </div>
        <div className="mb-4">
          <PillProgress
            progressPercent={
              getTotalPersonalInfoWeightPercentage(onboarding).percentComplete
            }
          />
        </div>
        <div className="profileDirectory flex flex-col items-center w-full">
          <div className="informationBlock mb-6">
            <div className="title">
              <h3>Basic Info</h3>
            </div>
            {getbasicInfo(onboarding).map((basicItem, index) => (
              <ListItem key={index} itemData={basicItem} />
            ))}
          </div>
        </div>
        <div className="profileDirectory flex flex-col items-center w-full">
          <div className="informationBlock mb-6">
            <div className="title">
              <h3>Student Info</h3>
            </div>
            {getStudentDetail({
              onboarding,
              major,
              useMajorEthnicityOrReligionId: false,
              religion,
              ethnicity,
            }).map((studentItem, index) =>
              studentItem.label === "siblings_data" ? (
                studentItem.value?.map((sibling, siblingIndex) => (
                  <ListItem key={siblingIndex} itemData={sibling} />
                ))
              ) : (
                <ListItem key={index} itemData={studentItem} />
              )
            )}
          </div>
        </div>
        <div className="profileDirectory flex flex-col  items-center w-full">
          <div className="informationBlock mb-6 md:mb-10">
            <div className="title">
              <h3>Parent/Guardian Info</h3>
            </div>
            {getParentGuardianInfo(onboarding).map((parentItem, index) => (
              <ListItem key={index} itemData={parentItem} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
