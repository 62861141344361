import { useEffect } from "react";
import { TextField, HelpButton } from "@components/index";
import { useFormContext } from "react-hook-form";

/**
 * Returns an input based on the type of type of data
 * @param {object} input - Values for input field. For more reference
 * look for info objects in personalData.js file.
 */
const CollegePreApprovalInput = ({ label, mapId, value, prefix, helpText }) => {
  const { setValue } = useFormContext();

  /**
   * After render initialize default values for every
   * input field. Values are received from user preapprovals object.
   */
  useEffect(() => {
    if (value !== undefined && value !== null) {
      setValue(mapId, value);
    }
  }, []);

  return (
    <div className={`inputItem ${prefix ? "currencyLabel" : ""}`}>
      <div className="labelWrapper flex items-end justify-between">
        <label htmlFor={mapId} className="small">
          {label}
        </label>
        {helpText && (
          <HelpButton
            helpContent={[
              {
                id: 0,
                title: label,
                description: helpText,
              },
            ]}
            className="small mb-0.5 absolute"
          />
        )}
      </div>
      <TextField
        input={{
          mapId,
          type: "text",
          amount: true,
        }}
      />
      <div className="currency resetFont">{prefix}</div>
    </div>
  );
};

export default CollegePreApprovalInput;
