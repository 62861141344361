import { useEffect, useState } from "react";
import { useAppContext } from "@context/state";
import { PENDING, READY, NOT_SUPPORTED } from "@utils/constants";
import { FormProvider, useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { SchoolHeader } from "@components/index";
import {
  SchoolSearchInput,
  FileUpload,
  HelpButton,
  OfferCostFormFields,
  OfferAwardFormFields,
} from "@components/index";
import TranslationPendingModal from "./TranslationPendingModal";
import TranslationReadyModal from "./TranslationReadyModal";
import TranslationFailureModal from "./TranslationFailureModal";
import { processRemainingCost } from "@utils/costEstimates";
import { getSchoolUserInfo } from "@api/schools";
import { addOffer, getOfferDocumentJob, updateOffer } from "@api/offers";
import _ from "lodash";
import { CapLogo, ResetIcon } from "@icons/index";

/**
 * Component to edit Offers information for a user that
 * opens in Translate & Compare page.
 * NOTE: This component is a work in progress.
 */
const OfferEditModal = () => {
  const methods = useForm();

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting },
  } = methods;

  /**
   * Using watch's default value is not sufficient to handle empty strings, so we use || 0
   * @param {string} inputId
   * @returns integer that updates when form values change
   */
  const watchInt = (inputId) => parseInt(watch(inputId) || 0);

  const {
    scenario,
    setDisplayFormModal,
    formModalContent,
    handleApiError,
    userOffers,
    setUserOffers,
    userSchools,
    setUserSchools,
    updateOffersState,
  } = useAppContext();

  //offer is set using formModalContent,
  //while user offers are set globally on login
  //and updated whenever offers are altered.
  const offer = formModalContent;

  //NOTE: In Progress. Offer object should look something like this.
  const {
    // file,
    document,
    school,
    tuition_budget, //manual choice
    decided_document_job, //auto choice (translated)
    cap_projections, //auto choice (cap projections)
    // review_status, //only used by admins?
  } = offer;

  const { filename, mime_type, size, created_at, url } = document
    ? document
    : {};

  const {
    school_id,
    logo,
    name,
    breakdowns,
    out_of_state_tuition,
    in_state_tuition,
    private_tuition,
    room_and_board,
    book_fees,
    travel_and_misc,
    fees,
  } = school ? school : {}; //TODO: With offer, also include raw breakdowns, out_of_state_tuition, in_state_tuition, private_tuition, room_and_board, book_fees, travel_and_misc, fees

  const [offerSchool, setOfferSchool] = useState(undefined);
  const [fileChanged, setFileChanged] = useState(false);
  const [fileExists, setFileExists] = useState(!!url);
  const [translationStatus, setTranslationStatus] = useState(null);

  /**
   * Prepare the form values for submitting as multipart/form-data
   * @param {*} offer
   * @param {*} tuitionBudget
   * @returns
   */
  const constructOfferFormData = (offer, tuitionBudget) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(tuitionBudget)) {
      if (value) {
        formData.append(key, value);
      }
    }
    //If the file exists and has been changed, submit it with the form data.
    //The reason this isn't simply checked against the existence of file[0] is that when file deletion is implemented,
    //we will use fileChanged to set the flag for backend to know that the file should be removed from s3
    if (fileExists && fileChanged) {
      // HTML file input, chosen by user
      formData.append("file", offer?.file[0]);
    }

    formData.append("school_id", offer?.school?.school_id);

    return formData;
  };

  /**
   * poll for job status and set state upon finishing
   * @param {*} scenarioId
   * @param {*} offerId
   * @param {*} jobId
   */
  const pollForJobStatus = async (scenarioId, offerId, jobId) => {
    try {
      const res = await getOfferDocumentJob(scenarioId, offerId, jobId);
      if (res.data) {
        if (res.data?.result?.job?.status === PENDING) {
          setTimeout(() => {
            pollForJobStatus(scenarioId, offerId, jobId);
          }, 5000);
        } else if (
          res.data?.result?.job?.status === READY ||
          res.data?.result?.job?.status === NOT_SUPPORTED
        ) {
          setTranslationStatus(res.data?.result?.job?.status);
          //set offer
          const parsedOffer = res.data.result?.offer;
          const offerSchoolId = parsedOffer?.school?.school_id;
          let existingOfferId;
          !!userOffers.forEach((offer) => {
            if (offer?.school?.school_id === offerSchoolId) {
              existingOfferId = offer?.id;
            }
          });
          if (existingOfferId) {
            updateOffersState(parsedOffer);
          } else {
            setUserOffers(
              _.orderBy(
                [...userOffers, parsedOffer],
                (offer) => offer.school.name,
                ["asc"]
              )
            );
          }
          // update form values
          setValue(
            "tuition_budget.coa_1",
            parsedOffer?.tuition_budget?.fillable_fields?.coa_1
          );
          setValue(
            "tuition_budget.room_and_board_1",
            parsedOffer?.tuition_budget?.fillable_fields?.room_and_board_1
          );
          setValue(
            "tuition_budget.books_and_supplies_1",
            parsedOffer?.tuition_budget?.fillable_fields?.books_and_supplies_1
          );
          setValue(
            "tuition_budget.travel_and_misc_1",
            parsedOffer?.tuition_budget?.fillable_fields?.travel_and_misc_1
          );
          setValue(
            "tuition_budget.other_fees_1",
            parsedOffer?.tuition_budget?.fillable_fields?.other_fees_1
          );
          setValue(
            "tuition_budget.fed_state_grants_1",
            parsedOffer?.tuition_budget?.fillable_fields?.fed_state_grants_1
          );
          setValue(
            "tuition_budget.merit_scholarship_1",
            parsedOffer?.tuition_budget?.fillable_fields?.merit_scholarship_1
          );
          setValue(
            "tuition_budget.federal_direct_student_loan_1",
            parsedOffer?.tuition_budget?.fillable_fields
              ?.federal_direct_student_loan_1
          );
          setValue(
            "tuition_budget.unsubsidized_student_loan_1",
            parsedOffer?.tuition_budget?.fillable_fields
              ?.unsubsidized_student_loan_1
          );
          setFileChanged(false);
          methods.reset({}, { keepValues: true });
        }
      }
    } catch (error) {
      setTranslationStatus(null);
      handleApiError(error);
    }
  };

  /**
   * Parse offer letter
   * @param {*} values
   */
  const translateOfferLetter = async (values) => {
    setTranslationStatus(PENDING);
    try {
      const offerSchoolId = values?.offer?.school?.school_id;
      let existingOfferId;
      !!userOffers.forEach((offer) => {
        if (`${offer?.school?.school_id}` === offerSchoolId) {
          existingOfferId = offer?.id;
        }
      });
      //Prepare form data
      const offerFormData = constructOfferFormData(
        values?.offer,
        values?.tuition_budget
      );
      const queryParams = { translate: true };
      //if offer exists for user already, update the offer, else add the offer
      if (existingOfferId) {
        const res = await updateOffer(
          scenario?.case_id,
          existingOfferId,
          offerFormData,
          queryParams
        );
        if (res.data) {
          const pendingOffer = res.data.result?.offer;
          const jobId = res.data.result?.job?.job_id;
          if (jobId) {
            // document.cookie = `document_parse_job_id=${jobId}; path=/`;
            pollForJobStatus(scenario?.case_id, pendingOffer.id, jobId);
          }
        }
      } else {
        await addNewOffer(offerFormData, queryParams);
      }
    } catch (error) {
      setTranslationStatus(null);
      handleApiError(error);
    }
  };

  /**
   * Form submit
   * @param {*} values
   */
  const onSubmit = async (values) => {
    if (isDirty) {
      try {
        const offerSchoolId = values?.offer?.school?.school_id;
        let existingOfferId;
        !!userOffers.forEach((offer) => {
          if (`${offer?.school?.school_id}` === offerSchoolId) {
            existingOfferId = offer?.id;
          }
        });
        //Prepare form data
        const offerFormData = constructOfferFormData(
          values?.offer,
          values?.tuition_budget
        );
        //if offer exists for user already, update the offer, else add the offer
        if (existingOfferId) {
          await updateExistingOffer(existingOfferId, offerFormData);
          setDisplayFormModal(false);
        } else {
          await addNewOffer(offerFormData);
          setDisplayFormModal(false);
        }
      } catch (error) {
        handleApiError(error);
      }
    } else {
      setDisplayFormModal(false);
    }
  };

  /**
   * Add a new offer
   * @param {*} offerFormData
   */
  const addNewOffer = async (offerFormData, queryParams = {}) => {
    const res = await addOffer(scenario?.case_id, offerFormData, queryParams);
    if (res.data) {
      const newOffer = res.data.result?.offer;
      setUserOffers(
        _.orderBy([...userOffers, newOffer], (offer) => offer.school.name, [
          "asc",
        ])
      );
      const newSchool = res.data.result?.school?.result;
      if (newSchool) {
        setUserSchools(
          _.orderBy([...userSchools, newSchool], (school) => school.name, [
            "asc",
          ])
        );
      }
      const jobId = res.data.result?.job?.job_id;
      if (jobId) {
        // document.cookie = `document_parse_job_id=${jobId}; path=/`;
        pollForJobStatus(scenario?.case_id, newOffer.id, jobId);
      }
    }
  };

  /**
   * update an existing offer
   * @param {*} offerId
   * @param {*} offerFormData
   */
  const updateExistingOffer = async (offerId, offerFormData) => {
    //File to be an optional field
    const res = await updateOffer(scenario?.case_id, offerId, offerFormData);
    if (res.data) {
      const updatedOffer = res.data.result?.offer;
      updateOffersState(updatedOffer);
    }
  };

  /**
   * When a school is clicked on, render the offer edit form
   * @param {*} school
   */
  const setChosenSchool = (school) => {
    //set offer school
    getOfferSchool(school);
  };

  /**
   * Set the school after the user chooses it
   * @param {*} school
   */
  const getOfferSchool = async (school) => {
    const { college_id } = school;
    try {
      const res = await getSchoolUserInfo(scenario?.case_id, college_id);
      const schoolData = res?.data?.result || undefined;
      setOfferSchool(schoolData);
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    //set offerSchool to render form if school exists already (for update rather than create).
    //NOTE: Trying to render everything right away using the data from formModalContent
    //causes the form to render without the data populating the form properly.
    //This may be due to the modal open state being triggered before the formModalContent state
    if (school_id) {
      setOfferSchool({
        school_id,
        name,
        logo,
        breakdowns,
        out_of_state_tuition,
        in_state_tuition,
        private_tuition,
        room_and_board,
        book_fees,
        travel_and_misc,
        fees,
      });
    }
  }, [school_id]);

  /**
   * Reset react hook form when component
   * unmounts
   */
  useEffect(() => {
    return () => {
      methods.reset();
    };
  }, []);

  const clickOutside = (event) => {
    if (translationStatus === READY || translationStatus === NOT_SUPPORTED) {
      if (event.target.classList.contains("translation-loading")) {
        setTranslationStatus(null);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="offer-form w-full h-full flex flex-col items-center text-left"
        action="#"
        method="post"
        encType="multipart/form-data"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="modal-header">
          <button
            type="button"
            className="imitateLink border-0 text-left small"
            onClick={() => setDisplayFormModal(false)}
          >
            Cancel
          </button>
          <h6 className="mb-0 text-center capitalize">Translate & Compare</h6>
          <button
            type="submit"
            disabled={isSubmitting || translationStatus}
            className={`${isSubmitting ? "pt-1" : ""}
          border-0 text-right small save`}
          >
            {isSubmitting ? (
              <ClipLoader
                size={20}
                css={{
                  borderColor: "var(--primary-color)",
                  borderBottomColor: "transparent",
                }}
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
        <div
          className={`modal-scroll flex justify-center ${
            !!translationStatus && "form-loading"
          }`}
        >
          <div className="modal-content form-content">
            <div className="flex flex-col items-center justify-start h-full w-full">
              <div className="flex flex-col items-center w-full h-full px-3 md:p-0">
                <div className="offer-info-edit-block">
                  {!offerSchool && !school_id && (
                    <div className="flex flex-col items-center h-full">
                      <div className="schoolSearchContainer flex justify-center w-full pt-4 md:pt-6 mb-8">
                        <SchoolSearchInput
                          primaryStyle={true}
                          inputId={"school_search"}
                          chooseSchool={setChosenSchool}
                          methods={methods}
                        />
                      </div>
                      <div className="flex items-center justify-center p-3">
                        <div className="h5 whitespace-nowrap">
                          Enter the name of the school{" "}
                          <br className="md:hidden" />
                          that extended an offer
                        </div>
                      </div>
                    </div>
                  )}
                  {offerSchool && (
                    <div>
                      <SchoolHeader
                        name={offerSchool?.name}
                        logo={offerSchool?.logo}
                      />
                      <input
                        id="offer.school.school_id"
                        value={offerSchool?.school_id}
                        {...register("offer.school.school_id", {
                          required: {
                            value: true,
                            message: "School id required",
                          },
                        })}
                        className="hidden"
                      ></input>
                      <input
                        id="offer.school.logo"
                        value={offerSchool?.logo}
                        {...register("offer.school.logo")}
                        className="hidden"
                      ></input>
                      <input
                        id="offer.school.name"
                        value={offerSchool?.name}
                        {...register("offer.school.name")}
                        className="hidden"
                      ></input>
                      <FileUpload
                        fileInputId="offer.file"
                        fileUrlInputId="offer.document.url"
                        fileUrl={url}
                        fileNameInputId="offer.document.filename"
                        fileName={filename}
                        fileTypeInputId="offer.document.mime_type"
                        fileType={mime_type}
                        fileSizeInputId="offer.document.size"
                        fileSize={size}
                        fileDateInputId={"offer.document.created_at"}
                        fileDate={created_at}
                        setFileChanged={setFileChanged}
                        fileExists={fileExists}
                        setFileExists={setFileExists}
                      />
                      {fileExists && (
                        <div className="w-full flex flex-col items-center">
                          <button
                            type="button"
                            disabled={
                              isSubmitting ||
                              // !getValues("offer.document.url") ||
                              // !fileChanged ||
                              translationStatus
                            }
                            onClick={handleSubmit(translateOfferLetter)}
                            className="primary upload-offer-btn mb-3"
                          >
                            Translate Offer Letter
                          </button>
                          <div className="micro flex flex-col text-center mb-4">
                            <span className="text-left mx-2">
                              {`*By clicking "Translate" above, you are allowing
                              our partner, DecidED (a 501(c)(3) nonprofit), to
                              auto-translate your award letter. If you would
                              prefer to manually input your values, you can skip
                              the auto-translation and fill in the amounts
                              yourself below.`}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col items-start mb-2 ml-2">
                        <div className="flex mb-2">
                          <div className="logo">
                            <CapLogo />
                          </div>
                          <small className="px-0.5">
                            {`= CAP's projected value`}
                          </small>
                        </div>
                        <div className="flex mb-2">
                          <div className="logo">
                            <ResetIcon />
                          </div>
                          <small className="px-0.5">= Reset to default</small>
                        </div>
                      </div>
                      <div className="w-full flex flex-col">
                        <OfferCostFormFields
                          capProjections={cap_projections}
                          tuitionBudget={tuition_budget}
                          decidedDocumentJob={
                            //JSON.parse needed because response field is stored as json in database
                            decided_document_job?.response
                              ? JSON.parse(decided_document_job?.response)
                              : null
                          }
                          offerSchool={offerSchool}
                          watchInt={watchInt}
                        />
                        <div className="divider mb-3" />
                        <OfferAwardFormFields
                          tuitionBudget={tuition_budget}
                          decidedDocumentJob={
                            decided_document_job?.response
                              ? JSON.parse(decided_document_job?.response)
                              : null
                          }
                          watchInt={watchInt}
                        />
                        <div className="divider mb-3" />
                        <div className="relative flex flex-col mb-10">
                          <div className="labelWrapper flex items-end justify-between">
                            <label
                              htmlFor="remaining_cost"
                              className="small mb-0.5"
                            >
                              Remaining Cost
                            </label>
                            <HelpButton
                              helpContent={[
                                {
                                  id: 0,
                                  title: "Remaining Cost",
                                  description: (
                                    <span>
                                      Remaining Cost is your estimated
                                      out-of-pocket costs after considering
                                      Grants, Scholarships and Federal Direct
                                      Student Loan. <br />
                                      <br />
                                      Remaining Cost = Cost of Attendance -
                                      Grants & Scholarships - Federal Direct
                                      Student Loan
                                    </span>
                                  ),
                                },
                              ]}
                              className="small mb-0.5 top-1.5 absolute"
                            />
                          </div>
                          <div className="fontRegular h2">
                            {processRemainingCost(watch("tuition_budget"))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!!translationStatus && (
          <div
            className="translation-loading modal-scroll w-full h-full absolute flex flex-col justify-start items-center px-3"
            onClick={clickOutside}
          >
            <div
              className={`card-container ${
                translationStatus === READY && "translation-success"
              } ${
                translationStatus === NOT_SUPPORTED && "translation-failure"
              }`}
            >
              {translationStatus === PENDING && <TranslationPendingModal />}
              {translationStatus === READY && (
                <TranslationReadyModal
                  setTranslationStatus={setTranslationStatus}
                />
              )}
              {translationStatus === NOT_SUPPORTED && (
                <TranslationFailureModal
                  setTranslationStatus={setTranslationStatus}
                />
              )}
            </div>
          </div>
        )}
      </form>
    </FormProvider>
  );
};
export default OfferEditModal;
