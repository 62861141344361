import Link from "next/link";
import { MyCap } from "@icons/index";
import { useAppContext } from "@context/state";
import PseudoNav from "./PseudoNav";
import Image from "next/legacy/image";
import { logo, settings } from "@whitelabel/whitelabel.preval.js";

/**
 * If a user has paid for an appointment, they will see this page that allows them to book an appointment.
 * @returns The book consultation landing page for users that have purchased a new appointment
 */
const BookConsultationSuccess = () => {
  const { user, consultationAppointment } = useAppContext();

  //Even if user has a free appointment available, the only important thing is if the appointment being scheduled is the free appointment.
  return (
    <div className="book-consultation flex flex-col items-center text-center px-3 mb-8">
      <PseudoNav />
      {process.env.NEXT_PUBLIC_WHITELABEL === "true" ? (
        <div className="whitelabel-mycap mt-10 mb-4 flex justify-center relative">
          <Image
            src={logo}
            alt={settings?.name}
            layout="fill"
            objectFit="contain"
          />
        </div>
      ) : (
        <div className="myCapSuccess mt-10 mb-2">
          <MyCap />
        </div>
      )}
      {consultationAppointment?.is_discounted ? (
        <div className="h6">Schedule your free appointment!</div>
      ) : (
        <div className="h6">Your purchase was successful!</div>
      )}
      <Link
        href={`/schedule_meeting/${encodeURIComponent(
          user?.email
        )}?&appointment_token=${consultationAppointment?.token}`}
        rel="noreferrer">
        
          Click here to book your 1-hour 1:1 session with a MyCAP Expert.
        
      </Link>
    </div>
  );
};

export default BookConsultationSuccess;
