import { useAppContext } from "@context/state.js";
import { useRouter } from "next/router";
import { Notice } from "@icons/index";
import { getSubscriptionEndDate } from "@utils/getSubscriptionRemainingTime";
import _ from "lodash";

const ExpirationModal = () => {
  const { setDisplayModal, scenario } = useAppContext();

  const router = useRouter();

  const premiumUnavailable = false;

  return (
    <div className="modal compact">
      <div className="modal-scroll">
        <div className="modal-content">
          <div className="notice mb-2 mt-4 md:mt-0">
            <div className="icon">
              <Notice />
            </div>
          </div>
          <div className="title mb-6 h3">Your subscription is ending soon!</div>
          <div className="infoText h5 mb-6 flex flex-col items-start">
            You will be downgraded to the free plan with reduced functionality
            starting on{" "}
            {getSubscriptionEndDate(
              _.get(scenario, "subscription_info.created_at")
            )}
            .
            <br />
            <br />
            Keep your college planning profile active at a discounted monthly
            rate of $4.99.
          </div>
          <button
            className="upgradeBtn primary"
            disabled={premiumUnavailable}
            onClick={() => {
              router.push("/renew_plan");
              setDisplayModal(false);
            }}
          >
            View renewal plans
          </button>
          <button
            type="button"
            className="noThanksBtn"
            onClick={() => setDisplayModal((cur) => !cur)}
          >
            I No Longer Need Premium Access
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpirationModal;
