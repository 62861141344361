const LongArrow = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 25 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Long-Arrow/Left/Blue"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M9.73279349,0.319548901 C10.0796884,0.693127981 10.0864047,1.26099671 9.76706948,1.64172965 L9.6804511,1.73279349 L4.546,6.49999572 L23.5,6.49999572 C24.0522847,6.49999572 24.5,6.94771525 24.5,7.49999572 C24.5,8.01283584 24.1139598,8.43550716 23.6166211,8.49327227 L23.5,8.49999572 L4.546,8.49999572 L9.6804511,13.2672065 C10.0540302,13.6141014 10.1027349,14.1799175 9.81243833,14.5832294 L9.73279349,14.6804511 C9.38589863,15.0540302 8.82008254,15.1027349 8.41677064,14.8124383 L8.3195489,14.7327935 L0.530386679,7.49999572 L8.3195489,0.267206508 C8.72425957,-0.108596257 9.35699073,-0.0851617696 9.73279349,0.319548901 Z"
            id="Arrow-2"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default LongArrow;
