import { useAppContext } from "@context/state";
import { useEffect, useState } from "react";
import _ from "lodash";
import { updateAppointment } from "@api/bookConsultation";
import ChooseExpert from "./ChooseExpert";
import PseudoNav from "./PseudoNav";

/**
 * @param {string} user_id
 * @param {object} appointment
 * @param {array} experts - used for choose expert stage of booking an appointment
 * @returns page for scheduling an appointment with a custom choose expert component and an embedded hubspot calendar
 */
const EmbeddedMeetings = ({ user_id, appointment, experts }) => {
  const {
    user,
    handleApiError,
    setConsultationAppointment,
    setHasPurchasedConsultation,
  } = useAppContext();

  const [expert, setExpert] = useState(null);

  useEffect(() => {
    // Append hubspot meeting script
    if (!_.isNull(user)) {
      if (document.getElementById("meetings-script-element")) {
        document.getElementById("meetings-script-element").remove();
      }
      const hubspotMeetingsScript = document.createElement("script");
      hubspotMeetingsScript.src =
        "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
      hubspotMeetingsScript.id = "meetings-script-element";
      document.head.appendChild(hubspotMeetingsScript);
    }
  }, [user, expert]);

  //Update the appointment with the is_booked flag set to true. Get user_id and appointment from props to ensure they exist
  const updateAppointmentAsBooked = async () => {
    const paidBookedAppointment = {
      is_paid: true,
      is_booked: true,
      expert_id: expert?.id,
    };
    try {
      const res = await updateAppointment(
        user_id,
        appointment?.id,
        paidBookedAppointment
      );
      if (res) {
        //reset appointment states
        setConsultationAppointment({});
        setHasPurchasedConsultation(false);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  //When a booking is being made, this function will trigger and when a user successfully books an appointment, we update our record of the appointment
  const handleBookingSuccess = (event) => {
    //handle meeting success
    if (event.data.meetingBookSucceeded) {
      updateAppointmentAsBooked();
    }
  };

  useEffect(() => {
    //Listen for hubspot meeting
    window.addEventListener("message", handleBookingSuccess);
    return () => {
      window.removeEventListener("message", handleBookingSuccess);
    };
  }, [expert]);

  //TODO: refactor css to use a common component for pseudonav with logo and back button.
  return (
    <div className="entryPage flex flex-col justify-between h-full w-full">
      <section className="book-consultation flex flex-col items-center">
        <PseudoNav />
        {expert ? (
          <div className="flex items-start w-full h-full lg:py-4">
            <div
              className="meetings-iframe-container w-full"
              data-src={`${expert?.meeting_link}?embed=true`} //Dynamic link
            ></div>
          </div>
        ) : (
          <ChooseExpert setExpert={setExpert} expertsList={experts} />
        )}
      </section>
    </div>
  );
};

export default EmbeddedMeetings;
