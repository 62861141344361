const MagnifyingGlass = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Mag-Glass/Search-Default"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M3.18198052,3.18198052 C5.72038841,0.643572619 9.83596077,0.643572619 12.3743687,3.18198052 C14.6733617,5.48097351 14.8901957,9.07365425 13.0248707,11.6166806 L13.0814755,11.6672619 L16.6170094,15.2027958 C17.0075336,15.5933201 17.0075336,16.2264851 16.6170094,16.6170094 C16.2543797,16.9796391 15.6825343,17.0055412 15.2900166,16.6947157 L15.2027958,16.6170094 L11.6672619,13.0814755 L11.6166806,13.0248707 C9.07365425,14.8901957 5.48097351,14.6733617 3.18198052,12.3743687 C0.643572619,9.83596077 0.643572619,5.72038841 3.18198052,3.18198052 Z M10.9601551,4.59619408 C9.2027958,2.83883476 6.35355339,2.83883476 4.59619408,4.59619408 C2.83883476,6.35355339 2.83883476,9.2027958 4.59619408,10.9601551 C6.35355339,12.7175144 9.2027958,12.7175144 10.9601551,10.9601551 C12.7175144,9.2027958 12.7175144,6.35355339 10.9601551,4.59619408 Z"
            id="Combined-Shape"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default MagnifyingGlass;
