import { useRef, useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useAppContext } from "@context/state";
import _ from "lodash";

/**
 * @returns The secondary nav component that appears horizontal on mobile and vertical on desktop
 */
const SecondaryNav = () => {
  const { myCapLogout, scenario } = useAppContext();

  const router = useRouter();

  const targetRef = useRef();

  const [selected, setSelected] = useState(
    document?.getElementById(router.pathname)
  );

  const moveUnderline = (item) => {
    //BUG: why are these two numbers wrong on refresh, but not while interacting?
    const targetWidth = item.offsetWidth;
    const elementLeft = item.parentNode.offsetLeft + 16;
    if (targetRef.current) {
      targetRef.current.style.width = `${targetWidth}px`;
      targetRef.current.style.left = `${elementLeft}px`;
      //make sure starting width matches when rerendering nav
      document?.body.style.setProperty("--underlineWidth", `${targetWidth}px`);
    }
  };

  //highlight selected item
  const selectItem = () => {
    if (selected) {
      selected.classList.add("selected");
      //Move underline
      moveUnderline(selected);
    }
  };

  useEffect(() => {
    const currentSelection = document?.getElementById(router.pathname);
    if (currentSelection && targetRef.current) {
      if (selected) {
        selected.classList.remove("selected");
      }
      setSelected(currentSelection);
    }
  }, [router.pathname]);

  useEffect(() => {
    //Preloaded fonts solves issue of misaligned underline
    selectItem();
  }, [selected]);

  //When moving from desktop to mobile layout, need to reselect item to place underline
  const handleResize = _.throttle(() => {
    selectItem();
  }, 200);

  useEffect(() => {
    if (targetRef.current) {
      window.addEventListener("resize", handleResize);
      // clean up function
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  // Set the search url to the school search page with the case id and default search params
  // state is xx because we aren't in the advanced search yet.
  const case_id = scenario?.case_id;
  const Url = `/school_search/${case_id}/state=xx&10&offset=0&order=net-cost`;

  return (
    <div className="secondaryNav">
      <div className="flex lg:flex-col relative">
        <div className="h1 hidden lg:block studentName">
          {scenario.onboarding?.student?.student_name || "Student"}
        </div>
        <Link href="/">

          <div className="h6 dashboardItem">
            <span id="/">Dashboard</span>
          </div>

        </Link>
        <Link href={Url}>

          <div className="h6 dashboardItem">
            <span id="/school_search">School Search</span>
          </div>

        </Link>
        <Link href="/affordability">

          <div className="h6 dashboardItem">
            <span id="/affordability">Affordability</span>
          </div>

        </Link>
        <Link href="/translate_compare">

          <div className="h6 dashboardItem">
            <span id="/translate_compare">Translate & Compare</span>
          </div>

        </Link>
        <Link href="/evaluate_appeal">

          <div className="h6 dashboardItem">
            <span id="/evaluate_appeal">Evaluate & Appeal</span>
          </div>

        </Link>
        <Link href="/how_to_pay">

          <div className="h6 dashboardItem">
            <span id="/how_to_pay">How to Pay</span>
          </div>

        </Link>
        <div
          className="h6 dashboardItem cursor-pointer underline hidden lg:block"
          onClick={() => {
            myCapLogout();
          }}
        >
          <span id="/logout-secondary">Log Out</span>
        </div>
      </div>
      <div className="target inline-block lg:hidden" ref={targetRef} />
    </div>
  );
};

export default SecondaryNav;
