import {
  MYCAP_PREMIUM_PLUS_PRICE_ID,
  MYCAP_PREMIUM_PRICE_ID_MONTHLY,
  MYCAP_PREMIUM_PRICE_ID_YEARLY,
  MYCAP_PREMIUM_RENEWAL_PRICE_ID,
  MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID,
} from "@utils/constants";
import { useAppContext } from "@context/state";

const PlanCard = ({
  active,
  plan,
  recommended,
  unavailable,
  freePlanFunction,
  freeButtonText,
  planFunction,
}) => {
  const { setFreePlanSelected } = useAppContext();
  const {
    planName,
    priceHeader,
    priceSubTag,
    features,
    lostFeatures,
    downgradeSubTag,
    planPrices,
  } = plan;

  const selectPlanBtn = (defaultText) => {
    return (
      <button
        type="button"
        disabled={active || unavailable}
        className="secondary"
      >
        {active ? "Current Plan" : unavailable ? "Unavailable" : defaultText}
      </button>
    );
  };

  const chargeBeeAnchorProps = {
    "data-cb-item-0-quantity": "1",
    "data-cb-type": "checkout",
    href: "javascript:void(0)",
  };

  const renderPlanButton = (pricingPlan) => {
    let selectPlanLabel;
    switch (pricingPlan.dataCbItem) {
      case MYCAP_PREMIUM_PRICE_ID_YEARLY:
      case MYCAP_PREMIUM_PRICE_ID_MONTHLY:
      case MYCAP_PREMIUM_PLUS_PRICE_ID:
        selectPlanLabel = "Select Plan";
        break;
      case MYCAP_PREMIUM_RENEWAL_PRICE_ID:
      case MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID:
        selectPlanLabel = "Renew";
        break;
    }
    if (
      [
        MYCAP_PREMIUM_PRICE_ID_YEARLY,
        MYCAP_PREMIUM_PRICE_ID_MONTHLY,
        MYCAP_PREMIUM_PLUS_PRICE_ID,
      ].includes(pricingPlan.dataCbItem)
    ) {
      return (
        <a {...chargeBeeAnchorProps} data-cb-item-0={pricingPlan.dataCbItem}>
          {selectPlanBtn(selectPlanLabel)}
        </a>
      );
    } else if (
      [
        MYCAP_PREMIUM_RENEWAL_PRICE_ID,
        MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID,
      ].includes(pricingPlan.dataCbItem)
    ) {
      return (
        <a
          onClick={() => {
            if (!unavailable) {
              planFunction();
            }
          }}
        >
          {selectPlanBtn(selectPlanLabel)}
        </a>
      );
    } else {
      return (
        <a
          // {...chargeBeeAnchorProps} data-cb-item-0={FREEMIUM_PRICE_ID}
          onClick={() => {
            if (!unavailable) {
              setFreePlanSelected(true);
              freePlanFunction();
            }
          }}
        >
          {selectPlanBtn(freeButtonText)}
        </a>
      );
    }
  };

  return (
    <section
      className={`planCard ${
        recommended ? "recommended " : ""
      }flex flex-col items-center justify-between shadow-md`}
    >
      <div className="w-full mb-3">
        {recommended && (
          <div className="micro recommendedTag">Recommended - Best Deal!</div>
        )}
        <p>{planName}</p>
        <h2>{priceHeader}</h2>
        <small>{priceSubTag}</small>
        <hr />
        <ul className="checklist">
          {features.map((feature, key) => (
            <li key={key} className="micro">
              {feature}
            </li>
          ))}
        </ul>
        {lostFeatures && (
          <>
            <small className="mt-2">{downgradeSubTag}</small>
            <hr />
            <ul className="checklist">
              {lostFeatures.map((feature, key) => (
                <li key={key} className="micro not">
                  {feature}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div>{renderPlanButton(planPrices[0])}</div>
    </section>
  );
};

export default PlanCard;
