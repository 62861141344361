const AffordabilityProfile = () => {
  return (
    <div className="affordability-profile flex flex-col">
      <h2>Affordability Profile</h2>
      <div className="rating-row">
        <b>No Loans</b>
        <small className="micro status ideal flex justify-center items-center">
          Ideal
        </small>
      </div>
      <div className="rating-row">
        <b>Loans under $27,000 (FDSL)</b>
        <small className="micro status great flex justify-center items-center">
          Great
        </small>
      </div>
      <div className="rating-row">
        <b>Total Loans Are Below Expected 1st Year Salary</b>
        <small className="micro status good flex justify-center items-center">
          Good
        </small>
      </div>
      <div className="rating-row">
        <b>Total Loans Above Expected 1st Year Salary</b>
        <small className="micro status caution flex justify-center items-center">
          Caution
        </small>
      </div>
    </div>
  );
};

export default AffordabilityProfile;
