import BreakdownRow from "./BreakdownRow";
import BreakdownDrawer from "./BreakdownDrawer";
import { getPreApprovalAmount } from "@utils/calcHowToPay";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ResetIcon } from "@icons/index";

const PreApprovalDrawer = ({ budgetData, preapprovalData, watchInt }) => {
  const {
    funds_in_529_1,
    parent_pledged_assets_1,
    parent_pledged_monthly_1,
    student_pledged_assets_1,
    student_pledged_monthly_1,
    other_help_1,
  } = budgetData;

  const methods = useFormContext();
  const { setValue } = methods;

  useEffect(() => {
    if (!budgetData) {
      //setvalue with dirty for cap projected data
      if (preapprovalData) {
        for (const [key, value] of Object.entries(preapprovalData)) {
          if (value) {
            setValue(`tuition_budget.${key}`, value, {
              shouldDirty: true,
            });
          }
        }
      }
    }
  }, [preapprovalData]);

  //Each value has a multiplier to coerce into displaying the default value for a year
  return (
    <BreakdownDrawer
      title={
        <span className="font-demi-bold w-min sm:w-auto">Budget Amount</span>
      }
      status={"In Progress"}
      helpText={
        <div className="text-center">
          The default budget amounts come directly from the{" "}
          <a href="/college_pre_approval">My College Budget</a> section in your
          Profile. When clicking {`"Reset"`} (
          <span className="inline-block w-2.5 relative top-0.5">
            <ResetIcon />
          </span>
          ) , the value will be set to the default value from your Profile.
        </div>
      }
      forcedValue={getPreApprovalAmount(watchInt)}
      inputId={"preapproval_amount_1"}
    >
      <BreakdownRow
        title="529 Savings Plan"
        defaultValue={funds_in_529_1}
        inputId={"tuition_budget.funds_in_529_1"}
        capProjectionValue={preapprovalData?.funds_in_529_1}
        type="Preapproval"
      />
      <BreakdownRow
        title="Parent Pledged Assets"
        defaultValue={parent_pledged_assets_1}
        inputId={"tuition_budget.parent_pledged_assets_1"}
        capProjectionValue={preapprovalData?.parent_pledged_assets_1}
        type="Preapproval"
      />
      <BreakdownRow
        title="Parent Pledged Monthly Cash Flow"
        defaultValue={parent_pledged_monthly_1}
        inputId={"tuition_budget.parent_pledged_monthly_1"}
        capProjectionValue={preapprovalData?.parent_pledged_monthly_1}
        type="Preapproval"
      />
      <BreakdownRow
        title="Student Pledged Assets"
        defaultValue={student_pledged_assets_1}
        inputId={"tuition_budget.student_pledged_assets_1"}
        capProjectionValue={preapprovalData?.student_pledged_assets_1}
        type="Preapproval"
      />
      <BreakdownRow
        title="Student Pledged Monthly Cash Flow"
        defaultValue={student_pledged_monthly_1}
        inputId={"tuition_budget.student_pledged_monthly_1"}
        capProjectionValue={preapprovalData?.student_pledged_monthly_1}
        type="Preapproval"
      />
      <BreakdownRow
        title="Grandparent and Other Financial Help"
        defaultValue={other_help_1}
        inputId={"tuition_budget.other_help_1"}
        capProjectionValue={preapprovalData?.other_help_1}
        type="Preapproval"
      />
    </BreakdownDrawer>
  );
};

export default PreApprovalDrawer;
