import {
  MYCAP_PREMIUM_PRICE_ID_MONTHLY,
  MYCAP_PREMIUM_PRICE_ID_YEARLY,
  MYCAP_PREMIUM_PLUS_PRICE_ID,
  MYCAP_PREMIUM_RENEWAL_PRICE_ID,
  MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID,
} from "./constants";

/**
 * Function for checking subscription status of user
 * @param {String} price_id - pass in the user's price_id
 * @returns {Boolean} - true if upgraded
 */
const checkIsUpgraded = (price_id) => {
  const upgradedArray = [
    MYCAP_PREMIUM_PRICE_ID_MONTHLY,
    MYCAP_PREMIUM_PRICE_ID_YEARLY,
    MYCAP_PREMIUM_PLUS_PRICE_ID,
    MYCAP_PREMIUM_RENEWAL_PRICE_ID,
    MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID,
  ];

  return upgradedArray.includes(price_id);
};

/**
 * Function for checking subscription status of user is a renewal plan
 * @param {String} price_id - pass in the user's price_id
 * @returns {Boolean} - true if upgraded
 */
const checkIsOnRenewalPlan = (price_id) => {
  const renewalArray = [
    MYCAP_PREMIUM_RENEWAL_PRICE_ID,
    MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID,
  ];

  return renewalArray.includes(price_id);
};

export { checkIsUpgraded, checkIsOnRenewalPlan };
