const Academy = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 36 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Academy"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M34,12.7318278 L34,22.1604888 L34,12.7318278 Z M34,12.5785162 L18,22.4285714 L2,12.5785162 L18,3 L34,12.5785162 Z M27.9047619,16.7142857 L27.9047619,27.9904762 C25.6190476,31.647619 22.3174603,33.4761905 18,33.4761905 C13.6825397,33.4761905 10.3809524,31.647619 8.0952381,27.9904762 L8.0952381,16.7142857"
            id="Icon"
            stroke="currentColor"
            strokeWidth="2"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Academy;
