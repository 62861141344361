import _ from "lodash";
import throttle from "lodash.throttle";
import { useState, useRef, useEffect } from "react";
import { useAppContext } from "@context/state";
import { LeftArrow, RightArrow } from "@icons/index";
import router from "next/router";
import delay from "@utils/delay";
import { animateScroll as scroll } from "react-scroll";

//Template container for a section of the page which holds a gallery of cards.
//required props: children, arrowsHidden (boolean for whether arrows for scrolling through cards should be hidden)
//optional props: title (for sections on the dashboard page), empty (boolean to trigger different layout of a section that is empty of cards)
/**
 *
 * @param {string} title - (optional) title of section
 * @param {string} galleryName - name of section, used for card gallery id
 * @param {boolean} empty - if gallery is empty
 * @param {boolean} fullView - if width of each card should be width of the viewable section. Default is 3 or 4 cards per view depending on media width
 * @param {Element} children - card elements
 * @returns
 */
const Section = ({ title, galleryName, empty, fullView, children }) => {
  //reference the card gallery
  const galleryRef = useRef();
  //Control disabled state of arrow buttons using refs.
  const leftBtnRef = useRef();
  const rightBtnRef = useRef();

  //For affordability sections, these are needed to handle default scrollLeft position of gallery. They need to be used in the same component that has the galleryRef.
  //Using document.getElementById you could refactor the logic to exist elsewhere, but using a ref is preferred.
  const { scrollToCard, setScrollToCard } = useAppContext();

  //Hide or show arrows
  const [arrowsHidden, setArrowsHidden] = useState("");

  //Throttle scroll to reduce calls. Listener is on element, not window. Use throttle instead of debounce to see a more responsive action.
  const handleScroll = throttle(() => {
    //If scrolled all the way left or right, the corresponding arrow will be disabled.
    if (galleryRef?.current && leftBtnRef?.current && rightBtnRef?.current) {
      const gallery = galleryRef.current;
      if (
        //Use a value greater than actual scrollLeft to account for spacing at end of gallery
        gallery.scrollLeft + 24 >=
        gallery.scrollWidth - gallery.offsetWidth
      ) {
        // setRightDisabled(true);
        rightBtnRef.current.disabled = true;
      } else if (rightBtnRef.current) {
        // setRightDisabled(false);
        rightBtnRef.current.disabled = false;
      }
      if (gallery.scrollLeft === 0) {
        // setLeftDisabled(true);
        leftBtnRef.current.disabled = true;
      } else if (leftBtnRef.current) {
        // setLeftDisabled(false);
        leftBtnRef.current.disabled = false;
      }
    }
  }, 200);

  useEffect(() => {
    if (leftBtnRef?.current && rightBtnRef?.current) {
      leftBtnRef.current.disabled = true;
      rightBtnRef.current.disabled = false;
    }
  }, [leftBtnRef.current, rightBtnRef.current]);

  useEffect(() => {
    async function scrollToCardPosition() {
      if (galleryRef?.current && scrollToCard !== 0) {
        await delay(10);
        //Currently scrollToCard is only used for affordability and translate_compare
        //After clicking more info on card in dashboard and being routed to /affordability, scroll to same card in affordability
        const scrollToPaths = [
          "/affordability",
          "/translate_compare", //TODO: abstract routes and titles to a constants file
          "/evaluate_appeal",
          "/how_to_pay",
        ];
        if (scrollToPaths.includes(router.pathname)) {
          //get position based on index instead of matching the offset to bypass need to match the layout and size of cards
          const scrollToPosition =
            galleryRef.current?.children[scrollToCard]?.offsetLeft;
          const offset = -12; //account for padding space between cards
          scroll.scrollMore(scrollToPosition + offset, {
            duration: 750,
            horizontal: true,
            smooth: "easeOutCubic",
            containerId: galleryName,
            ignoreCancelEvents: true,
          });
          //reset default scroll left for affordability page
          setScrollToCard(0);
        }
      }
    }
  }, [scrollToCard]);

  // Trigger arrow hiding logic when the children change
  // If less than 4 cards show, hide arrows, and for screen sizes where up to 4 cards can be displayed, hide arrows if there are less than 5 cards.
  useEffect(() => {
    const galleryLength = _.get(galleryRef, "current.children.length") || 0;
    if (!fullView) {
      const arrowsHiddenString = `${galleryLength <= 3 ? "invisible " : ""} ${galleryLength <= 4 ? "xxl:invisible" : ""
        }`;
      setArrowsHidden(arrowsHiddenString);
    } else {
      const arrowsHiddenString = `${galleryLength <= 1 ? "invisible " : ""}`;
      setArrowsHidden(arrowsHiddenString);
    }
  }, [children]);

  return (
    <div className="section">
      {title && <h2 className="sectionTitle">{title}</h2>}
      <div className="sectionContainer">
        {empty ? (
          //boolean prop empty is used to display this html since an empty layout is significantly different than a populated layout. children will be an EmptyCard
          <div className="flex flex-row flex-nowrap items-center w-full">
            {children}
          </div>
        ) : (
          <div className="flex flex-row flex-nowrap items-center w-full">
            <button
              ref={leftBtnRef}
              className={`${arrowsHidden} arrow left`}
              onClick={() => {
                //scroll in left direction to exact position of next card
                const offset = 24; //account for padding space between cards
                const cardWidth =
                  galleryRef.current?.children[0].offsetWidth + offset;
                const galleryOffset = galleryRef.current.scrollLeft % cardWidth;
                //allow scrolling again if current offset is between cards
                const scrollBy =
                  galleryOffset > 12
                    ? galleryOffset
                    : cardWidth + galleryOffset;
                scroll.scrollMore(-scrollBy, {
                  duration: 500,
                  horizontal: true,
                  smooth: "easeOutCubic",
                  containerId: galleryName,
                  ignoreCancelEvents: true,
                });
              }}
            >
              <LeftArrow />
            </button>
            <div
              ref={galleryRef}
              className={`cardGallery ${fullView ? "full-view" : ""
                } relative flex flex-row flex-nowrap w-full`}
              id={galleryName}
              onScroll={handleScroll}
            >
              {children}
            </div>
            <button
              ref={rightBtnRef}
              className={`${arrowsHidden} arrow right`}
              onClick={() => {
                //scroll in right direction to exact position of next card
                const offset = 24; //account for padding space between cards
                const cardWidth =
                  galleryRef.current?.children[0].offsetWidth + offset;
                const galleryOffset = galleryRef.current.scrollLeft % cardWidth;
                //allow scrolling again if current offset is between cards
                const scrollBy =
                  cardWidth - galleryOffset < 12
                    ? cardWidth + (cardWidth - galleryOffset)
                    : cardWidth - galleryOffset;
                scroll.scrollMore(scrollBy, {
                  duration: 500,
                  horizontal: true,
                  smooth: "easeOutCubic",
                  containerId: galleryName,
                  ignoreCancelEvents: true,
                });
              }}
            >
              <RightArrow />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Section;
