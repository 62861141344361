import { CapLogo, MyCap } from "@icons/index";
import Link from "next/link";

const Welcome = () => {
  return (
    <div className="entryPage flex flex-col justify-between h-full w-full">
      <div className="welcomeContainer flex flex-col items-center justify-start text-center h-full w-full">
        <div className="flex flex-col items-center w-full">
          <div className="bgLogo">
            <CapLogo />
          </div>
          <div className="welcomeText entry flex flex-col items-center w-full">
            <div className="myCap">
              <MyCap />
            </div>
            <h5 className="entryText self-center">
              Find your most affordable path to college
            </h5>
          </div>
          <b className="h6">~2 minutes to complete</b>
          <Link href="/create_account" className="startBtn">

            <button className="primary w-full h-full">
              Get Started for FREE
            </button>

          </Link>
          <b>Already have an account?</b>
          <Link href="/login" className="loginLink">

            <b>Log In</b>

          </Link>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
