import { useAppContext } from "@context/state.js";
import Graph from "@components/Common/Graph";
import {
  processNetCost,
  processPreApprovals,
  processLoanTotal,
} from "@utils/costEstimates";
import renderLogo from "@utils/renderLogo";

const SchoolMoreInfoModal = () => {
  const { scenario, formModalContent, setDisplayFormModal } = useAppContext();
  const { school_id, logo, name, breakdowns } = formModalContent;

  const schoolFinancialInformation = [
    { label: "Four Year Net Cost", value: `${processNetCost(4, breakdowns)}` },
    {
      label: "Four Year Budget",
      value: processPreApprovals(scenario?.preapprovals),
    },
    {
      label: "Loan Total",
      value: processLoanTotal(breakdowns, scenario?.preapprovals),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col items-center text-left">
      <div className="modal-header">
        <div className="flex-1"></div>
        <h6 className="mb-0 flex-1 text-center capitalize">Affordability</h6>
        <button
          className="imitateLink border-0 text-right small flex-1"
          onClick={() => setDisplayFormModal(false)}
        >
          <small>Close</small>
        </button>
      </div>
      <div className="modal-scroll flex justify-center">
        <div className="modal-content w-full">
          <div id="school_more_info">
            <div className="content-section">
              <div className="name-section flex flex-row items-center">
                {renderLogo(logo, name)}
                <h4 className="university-name">
                  {name || "University Name Goes Here"}
                </h4>
              </div>
              <ul className="lg:px-6">
                {schoolFinancialInformation.map((info, index) => (
                  <li
                    className="list-none px-3 flex items-center justify-between"
                    key={index}
                  >
                    <h6>{info.label}</h6>
                    <span>{info.value}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="content-section px-3 lg:px-6">
              <h4>
                Average Starting Salaries from <br /> {name}
              </h4>
              <p className="lg:w-1/2">
                Below is a list of majors ordered by average starting salary. We
                strongly recommend limiting loans to be no greater than the
                starting salary of the chosen major.
              </p>
            </div>
            <div className="graph-container lg:px-4">
              <Graph school_id={school_id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolMoreInfoModal;
