/**
 * Push custom events to google tag manager
 * @param {Object} eventObject
 */
export const gtmPushToDataLayer = (eventObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventObject);
};

/**
 * Push custom event account_creation to google tag manager
 * @param {String} method - (email | google) - identifies whether account was created through traditional registration or social login
 */
export const gtmPushAccountCreated = (method) => {
  gtmPushToDataLayer({
    event: "account_creation",
    account_type: "free",
    method,
    value: "0",
  });
};

/**
 * Push custom event login to google tag manager
 * @param {String} method - (email | google) - identifies whether login was through traditional login or social login
 */
export const gtmPushLogin = (method) => {
  gtmPushToDataLayer({
    event: "login",
    method,
  });
};

/**
 * Push custom event account_upgrade to google tag manager
 * @param {String} accountType
 * @param {String} value
 */
export const gtmPushAccountUpgrade = (accountType, value) => {
  gtmPushToDataLayer({
    event: "account_upgrade",
    account_type: accountType,
    value,
  });
};
