import { useAppContext } from "@context/state";
import { OFFER_EDIT } from "@utils/constants";
import { Card } from "@components/index";

const AddOfferCard = () => {
  const { setFormModalView, setFormModalContent, setDisplayFormModal } =
    useAppContext();

  return (
    <div className="cardContainer add-offer-card">
      <Card type="typeTall">
        <div className="w-full h-full flex flex-col justify-center items-center text-center">
          <h4>Add Your Offer Here</h4>
          <button
            className="tertiary addSchoolBtn"
            onClick={() => {
              setFormModalContent({}); //When offer data is present, set here
              setFormModalView(OFFER_EDIT);
              setDisplayFormModal(true);
            }}
          >
            Add Offer
          </button>
        </div>
      </Card>
    </div>
  );
};

export default AddOfferCard;
