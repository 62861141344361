import BreakdownRow from "./Breakdown/BreakdownRow";
import PreApprovalDrawer from "./Breakdown/PreApprovalDrawer";
import NetCostDrawer from "./Breakdown/NetCostDrawer";
import LoanDrawer from "./Breakdown/LoanDrawer";
import { getFundingGap, getRemainingFundingGap } from "@utils/calcHowToPay";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useAppContext } from "@context/state";

const HowToPayBreakdown = ({
  offerCardData,
  capProjectedData,
  budgetData,
  watchInt,
}) => {
  const methods = useFormContext();
  const { setValue } = methods;
  const { scenario } = useAppContext();
  const { preapprovals } = scenario;

  const {
    fillable_fields = {
      coa_1: 0,
      room_and_board_1: 0,
      books_and_supplies_1: 0,
      travel_and_misc_1: 0,
      other_fees_1: 0,
      merit_scholarship_1: 0,
      fed_state_grants_1: 0,
      private_scholarships_1: 0,
      //Pre-Approvals. NOTE: Temporarily pulled from scenario for consistency. Will not be fillale for now
      funds_in_529_1: 0,
      parent_pledged_assets_1: 0,
      parent_pledged_monthly_1: 0,
      aotc_1: 0,
      student_pledged_assets_1: 0,
      student_pledged_monthly_1: 0,
      other_help_1: 0,
      //Loan Total
      direct_student_loan_1: 0,
      unsubsidized_student_loan_1: 0,
      federal_direct_student_loan_1: 0,
      private_loan_1: 0,
      perkins_loan_1: 0,
      parent_plus_loan_1: 0,
      other_loan_1: 0,
    },
  } = budgetData ?? {};

  useEffect(() => {
    if (!budgetData) {
      //setvalue with dirty for cap projected data
      if (capProjectedData?.fillable_fields) {
        for (const [key, value] of Object.entries(
          capProjectedData?.fillable_fields
        )) {
          if (value) {
            setValue(`tuition_budget.${key}`, value, { shouldDirty: true });
          }
        }
      }
    }
  }, [capProjectedData?.fillable_fields]);

  return (
    <div className="how-to-pay-breakdown flex flex-col items-center">
      <div className="breakdown-header w-full flex flex-row justify-between p-1">
        <button
          type="button"
          className="primary import-offer-btn flex items-center md:mx-1"
          //TODO: disable button when all fields match award letter
          onClick={() => {
            for (const [key, value] of Object.entries(
              offerCardData?.fillable_fields
            )) {
              if (value) {
                setValue(`tuition_budget.${key}`, value, { shouldDirty: true });
              }
            }
          }}
        >
          <small className="px-2">Import Award Letter Data</small>
        </button>
        <div className="column-header flex justify-center items-center md:mx-1">
          Year 1
        </div>
      </div>
      <div className="breakdown-table flex flex-col items-center w-full">
        <NetCostDrawer
          capProjectedData={capProjectedData?.fillable_fields}
          budgetData={fillable_fields}
          watchInt={watchInt}
        />
        <PreApprovalDrawer
          budgetData={fillable_fields}
          preapprovalData={{
            funds_in_529_1: Math.round(
              (parseInt(preapprovals?.funds_in_529) +
                parseInt(preapprovals?.monthly_529_funds) *
                  parseInt(preapprovals?.monthly_529_months)) /
                4
            ),
            parent_pledged_assets_1: Math.round(
              parseInt(preapprovals?.parent_pledged_assets) / 4
            ),
            parent_pledged_monthly_1: Math.round(
              parseInt(preapprovals?.parent_pledged_monthly) * 12
            ),
            // aotc_1: 0,
            student_pledged_assets_1: Math.round(
              parseInt(preapprovals?.student_pledged_assets) / 4
            ),
            student_pledged_monthly_1: Math.round(
              parseInt(preapprovals?.student_pledged_monthly) * 12
            ),
            other_help_1: Math.round(parseInt(preapprovals?.other_help) / 4),
          }}
          watchInt={watchInt}
        />
        <BreakdownRow
          title={<span className="font-demi-bold">Funding Gap</span>}
          readOnly={true}
          forcedValue={getFundingGap(watchInt)}
          inputId={"funding_gap_1"}
        />
        <LoanDrawer budgetData={fillable_fields} watchInt={watchInt} />
        <BreakdownRow
          title={<span className="font-demi-bold">Remaining Funding Gap</span>}
          readOnly={true}
          forcedValue={getRemainingFundingGap(watchInt)}
          inputId={"remaining_funding_gap_1"}
        />
      </div>
    </div>
  );
};

export default HowToPayBreakdown;
