import _ from "lodash";
import { YES, NO } from "@utils/constants";

/**
 * College Pre Approval Information
 */
export const getPreApprovalInfo = (preapprovals) => {
  const savingsTotal =
    parseInt(_.get(preapprovals, "funds_in_529") || 0) +
    parseInt(
      (_.get(preapprovals, "monthly_529_funds") || 0) *
        (_.get(preapprovals, "monthly_529_months") || 0)
    );

  const fourYearSavingsParent =
    parseInt(_.get(preapprovals, "parent_pledged_monthly") || 0) * 48;

  const fourYearSavingsStudent =
    parseInt(_.get(preapprovals, "student_pledged_monthly") || 0) * 48;

  const preApprovalInfoArray = [
    {
      label: "Do you have a 529 Savings Plan?",
      mapId: "savings_exist",
      value: savingsTotal > 0 ? YES : NO,
      type: "radio",
      optionsType: "radio",
      radioOptions: [YES, NO].map((option) => {
        return { label: option, value: option };
      }),
      defaultValue: NO,
    },
    savingsTotal > 0 && {
      label: "Current 529 Savings Plan Balance",
      mapId: "funds_in_529",
      type: "text",
      amount: true,
      prefix: "$",
    },
    savingsTotal > 0 && {
      label: "Monthly Contributions to 529 Account",
      mapId: "monthly_529_funds",
      type: "text",
      amount: true,
      prefix: "$",
    },
    savingsTotal > 0 && {
      label: "Number of Months Until College Starts",
      mapId: "monthly_529_months",
      type: "text",
      amount: true,
    },
    savingsTotal > 0 && {
      label: "Total 529 Savings Plan",
      mapId: "total_529_plan",
      value: savingsTotal,
      type: "text",
      amount: true,
      prefix: "$",
    },
    {
      label: "Parent Pledged Assets",
      mapId: "parent_pledged_assets",
      type: "text",
      amount: true,
      prefix: "$",
    },
    {
      label: "Monthly Cash-Flow",
      mapId: "parent_pledged_monthly",
      type: "text",
      amount: true,
      prefix: "$",
    },
    {
      label: "4-Year Cash-Flow",
      mapId: "four_year_cash_flow",
      value: fourYearSavingsParent,
      basis: "Monthly Cash-Flow",
      type: "text",
      amount: true,
      prefix: "$",
    },
    {
      label: "Student Pledged Monthly Cash-Flow",
      mapId: "student_pledged_monthly",
      type: "text",
      amount: true,
      prefix: "$",
    },
    {
      label: "4-Year Student Pledged Cash-Flow",
      mapId: "four_year_student_cash_flow",
      value: fourYearSavingsStudent,
      basis: "Student Pledged Monthly Cash-Flow",
      type: "text",
      amount: true,
      prefix: "$",
    },
    {
      label: "Grandparent and Other Financial Help",
      mapId: "other_help",
      type: "text",
      amount: true,
      prefix: "$",
    },
  ];

  /**
   * Assign value to each object, extracted from preapproval information.
   */
  return preApprovalInfoArray.filter(Boolean).map((preApprovalInput) => {
    const currentValue = _.get(preapprovals, preApprovalInput.mapId);
    //Allow zero to be truthy
    // if (currentValue === 0) {
    //   currentValue = "0";
    // }
    if (!preApprovalInput.value) {
      return {
        ...preApprovalInput,
        value: currentValue || preApprovalInput.defaultValue,
      };
    } else {
      return preApprovalInput;
    }
  });
};
