import _ from "lodash";
import { useEffect, useState } from "react";
import ProgressBar from "../ProgressBar";
import { DownArrow } from "@icons/index";
import { MinusIcon, PlusIcon } from "@icons/index";
import { infoTips } from "@utils/infoTips";

const Step05 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
  reset,
  setValue,
  getValues,
}) => {
  if (formStep !== 7) {
    // Don't render unless step matches
    return null;
  }

  const years = [
    "-",
    2006,
    2007,
    2008,
    2009,
    2010,
    2011,
    2012,
    2013,
    2014,
    2015,
    2016,
    2017,
    2018,
    2019,
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
  ];

  const [quantity, setQuantity] = useState(
    parseInt(_.get(formData, "student.num_siblings")) || 0
  );
  const [siblings, setSiblings] = useState(
    _.get(formData, "student.siblings") || []
  );

  useEffect(() => {
    trigger("student.num_siblings");
    setWatchFields(["student"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "",
        description: _.get(
          infoTips,
          "scenario.onboarding.student.num_siblings"
        ),
      },
    ]);
  }, []);

  useEffect(() => {
    setValue("student.num_siblings", quantity); //necessary due to hidden nature of input
  }, [quantity]);

  const decrement = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      const remainingSiblings = [...siblings]; //create new array to avoid affecting saved data directly
      remainingSiblings.pop();
      reset({
        student: { ...getValues("student"), siblings: remainingSiblings },
      }); //use reset for nested values instead of setValue
      setSiblings(remainingSiblings);
    }
  };

  const increment = () => {
    if (quantity < 5) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      setSiblings([
        ...siblings,
        { id: newQuantity - 1, starting_college_year: "-" },
      ]);
    }
  };

  return (
    <div className="studentInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep} />
      <div className="studentInfoText">
        <h5>
          How many siblings does{" "}
          {_.get(formData, "student.student_name") || "Student"} have?
        </h5>
      </div>
      <div className="inputs flex justify-center">
        <label htmlFor="num_siblings" className="numberLabel small">
          <button type="button" className="incButton" onClick={decrement}>
            <div className="icon">
              <MinusIcon />
            </div>
          </button>
          <input
            id="num_siblings"
            type="number"
            className="hidden"
            {...register("student.num_siblings", {
              required: {
                value: true,
                message: "Please enter a quantity of at least 1",
              },
            })}
          />
          <div className="numDisplay h2">{quantity}</div>
          <button type="button" className="incButton" onClick={increment}>
            <div className="icon">
              <PlusIcon />
            </div>
          </button>
        </label>
      </div>
      {quantity > 0 && (
        <div>
          <hr className="divider" />
          <div className="studentInfoText">
            <h5>
              In what year will{" "}
              {_.get(formData, "student.student_name") || "Student"}&apos;s
              siblings graduate high school?
            </h5>
          </div>
          <div className="inputs flex flex-row flex-wrap justify-between text-left">
            {siblings.map((sibling, index) => {
              return (
                <label
                  htmlFor={`siblings[${index}].starting_college_year`}
                  className="selectLabel sibling small"
                  key={index}
                >
                  <span className="labelFocus">
                    Sibling {parseInt(index) + 1}
                  </span>
                  <select
                    id={`siblings[${index}].starting_college_year`}
                    {...register(
                      `student.siblings[${index}].starting_college_year`,
                      {
                        required: {
                          value: true,
                          message:
                            "Please select the sibling's graduation year",
                        },
                        validate: {
                          notDefault: (value) => value !== "-",
                        },
                      }
                    )}
                    defaultValue={sibling.starting_college_year}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <div className="select-icon">
                    <DownArrow />
                  </div>
                </label>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Step05;
