import { useAppContext } from "@context/state";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { changeEmail } from "@api/auth";
import ClipLoader from "react-spinners/ClipLoader";
import {
  getAdminAccessTokenFromCookies,
  setAccessToken,
} from "@utils/getAccessToken";

const ChangeEmailModal = () => {
  const {
    user,
    populateUser,
    handleApiError,
    setDisplayFormModal,
    errorResponse,
    setErrorResponse,
    handleAdminToken,
  } = useAppContext();

  const [showEmailErrors, setShowEmailErrors] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async () => {
    const { current_email, new_email, password } = getValues();
    try {
      if (process.env.NEXT_PUBLIC_WHITELABEL === "true") {
        throw {
          status: 405,
          data: {
            status: 405,
            message: "Changing email not allowed for this user",
          },
        };
      }
      const isImpersonating = handleAdminToken(
        getAdminAccessTokenFromCookies(document.cookie)
      );
      const res = await changeEmail(
        current_email,
        new_email,
        password,
        isImpersonating
      );
      if (res) {
        //After submitting successfully, proceed to change email success page
        //Route provides new token
        localStorage.setItem("refresh_token", res.data.jwt?.refresh_token);
        setAccessToken(res.data.jwt?.access_token);
        reset({
          current_email: new_email,
          new_email: "",
          password: "",
        });
        populateUser();
      }
    } catch (error) {
      //Only use error modal for unexpected errors (handleApiError)
      if (error?.response?.status === 403) {
        //Unauthorized
        setErrorResponse(error.response.data);
      } else {
        handleApiError(error);
      }
    }
  };

  return (
    <form
      className="w-full h-full flex flex-col items-center"
      action="#"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="modal-header">
        <button
          type="button"
          className="imitateLink border-0 text-left small"
          onClick={() => setDisplayFormModal(false)}
        >
          Cancel
        </button>
        <h6 className="mb-0 text-center capitalize">Account Info</h6>
        <button
          type="submit"
          disabled={isSubmitting}
          className={`${isSubmitting ? "pl-1 pt-1" : ""}
          border-0 text-right small save`}
        >
          {isSubmitting ? (
            <ClipLoader
              size={20}
              css={{
                borderColor: "var(--primary-color)",
                borderBottomColor: "transparent",
              }}
            />
          ) : (
            "Save"
          )}
        </button>
      </div>
      <div className="modal-scroll flex justify-center">
        <div className="modal-content form-content flex justify-center">
          <div id="account-edit-modal">
            <div className="header mb-4 text-left">
              <h3>Change Email</h3>
              <div className="divider"></div>
              <div>Use the form below to change your email.</div>
            </div>
            <div className="inputs flex flex-col text-left pb-10">
              <label htmlFor="current_email" className="textLabel small">
                <span className="labelFocus">Current Email</span>
                <input
                  id="current_email"
                  type="email"
                  value={user?.email}
                  {...register("current_email", {
                    required: {
                      value: true,
                      message: "Invalid email address",
                    },
                    validate: {
                      correctFormat: (value) => /\S+@\S+/g.test(value),
                    },
                  })}
                />
              </label>
              <label
                htmlFor="email"
                className="textLabel small"
                onChange={() => {
                  if (errorResponse) {
                    //reset server errors when changing input
                    //putting it directly on the input messes with react hook form behavior
                    setErrorResponse({});
                  }
                }}
              >
                <span className="labelFocus">New Email</span>
                <input
                  id="new_email"
                  type="email"
                  autoComplete="email"
                  className={`${
                    ((showEmailErrors && errors.email) ||
                      errorResponse?.email) &&
                    "inputErrorState"
                  }`}
                  {...register("new_email", {
                    required: {
                      value: true,
                      message: "Invalid email address",
                    },
                    validate: {
                      correctFormat: (value) => /\S+@\S+/g.test(value),
                    },
                  })}
                  onBlur={() => setShowEmailErrors(true)}
                />
                {showEmailErrors && errors.email && (
                  <small className="errorMessage">Invalid email address</small>
                )}
                {errorResponse?.email && (
                  <small className="errorMessage">
                    {errorResponse.email[0]}
                  </small>
                )}
              </label>
              <label htmlFor="password" className="textLabel small">
                <span className="labelFocus">Password</span>
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  className={`${
                    errorResponse?.result ===
                    "The provided password is incorrect."
                      ? "inputErrorState"
                      : ""
                  }`}
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Please enter a password",
                    },
                  })}
                />
                {/* TODO: error should return an object with password as a key and message as a value, not just a string */}
                {errorResponse?.result ===
                  "The provided password is incorrect." && (
                  <small className="errorMessage">{errorResponse.result}</small>
                )}
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChangeEmailModal;
