import { useAppContext } from "@context/state";
import { Section, EmptyOfferSection } from "@components/index";
import HowToPayBreakdownCard from "./HowToPayBreakdownCard";

/**
 * @returns section with gallery of detailed breakdowns for each tuition budget
 */
const HowToPayBreakdownView = () => {
  const { userOffers } = useAppContext();

  const renderBreakdownCards = () => {
    //map breakdownCards to cards
    const breakdownCards = userOffers.map((offer) => (
      <HowToPayBreakdownCard
        key={offer.id}
        offerData={offer}
        showMoreDetails={true}
      />
    ));
    return breakdownCards;
  };

  return (
    <div className="how-to-pay-view">
      <Section
        galleryName={"how-to-pay-gallery"}
        empty={userOffers.length === 0}
        fullView={true}
      >
        {userOffers.length === 0 ? (
          <EmptyOfferSection
            descriptionText={"Add an offer to calculate expected costs"}
          />
        ) : (
          <>{renderBreakdownCards()}</>
        )}
      </Section>
    </div>
  );
};

export default HowToPayBreakdownView;
