const DrawerRow = ({ title, value }) => {
  return (
    <div className="drawerRow">
      <b>{title}</b>
      <span>{value ? value : "Not Reported"}</span>
    </div>
  );
};

export default DrawerRow;
