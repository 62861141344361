const Hamburger = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 20 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Hamburger-Menu"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M19.047619,0 C19.5736045,0 20,0.44771525 20,1 C20,1.51283584 19.6323427,1.93550716 19.1586868,1.99327227 L19.047619,2 L0.952380952,2 C0.426395476,2 0,1.55228475 0,1 C0,0.487164161 0.367657324,0.0644928393 0.841313214,0.00672773133 L0.952380952,0 L19.047619,0 Z"
            id="Line-3"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
          <path
            d="M19.047619,8 C19.5736045,8 20,8.44771525 20,9 C20,9.51283584 19.6323427,9.93550716 19.1586868,9.99327227 L19.047619,10 L0.952380952,10 C0.426395476,10 0,9.55228475 0,9 C0,8.48716416 0.367657324,8.06449284 0.841313214,8.00672773 L0.952380952,8 L19.047619,8 Z"
            id="Line-3"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
          <path
            d="M19.047619,16 C19.5736045,16 20,16.4477153 20,17 C20,17.5128358 19.6323427,17.9355072 19.1586868,17.9932723 L19.047619,18 L0.952380952,18 C0.426395476,18 0,17.5522847 0,17 C0,16.4871642 0.367657324,16.0644928 0.841313214,16.0067277 L0.952380952,16 L19.047619,16 Z"
            id="Line-3"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Hamburger;
