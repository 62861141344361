import { useAppContext } from "@context/state";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { scheduleRenewal } from "@api/user";
import { getSubscriptionEndDate } from "@utils/getSubscriptionRemainingTime";
import _ from "lodash";
import {
  MYCAP_PREMIUM_PLUS_PRICE_ID,
  MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID,
} from "@utils/constants";
import { plans } from "@utils/plans";
import { Cross } from "@icons/index";

const SubscriptionRenewalModal = () => {
  const router = useRouter();

  const { renew } = plans;

  const {
    user,
    scenario,
    setScenario,
    handleApiError,
    setDisplayModal,
    setEligibleForRenewal,
  } = useAppContext();

  const [showThankYou, setShowThankYou] = useState(false);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  /**
   * TODO: add feature for applying and validating a chargebee coupon code
   */
  const onSubmit = async () => {
    // const { coupon_code } = getValues();
    try {
      const res = await scheduleRenewal(user.email);
      if (res.data) {
        setScenario({
          ...scenario,
          subscription_info: res.data,
        });
        // setNewPriceId(res.data.price_id);
      }
      setShowThankYou(true);
      setEligibleForRenewal(false);
      router.push("/account_info");
    } catch (error) {
      handleApiError(error);
    }
  };

  const { subscription_info } = scenario;

  const getSubscription = () => {
    if (subscription_info) {
      switch (subscription_info.price_id) {
        case MYCAP_PREMIUM_PLUS_PRICE_ID:
        case MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID:
          return "Valedictorian";
        default:
          return "Scholar";
      }
    }
  };

  return (
    <div className="modal compact">
      <form
        className="w-full h-full flex flex-col items-center"
        action="#"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="modal-scroll flex justify-center">
          <div className="modal-content form-content flex justify-center">
            <div className="title mb-4 md:mb-2">
              <h3>Confirm Renewal</h3>
            </div>
            <div className="infoText h5 mb-4 md:mb-6">
              Your subscription will be changed to the {getSubscription()}{" "}
              Renewal Plan for ${_.get(renew, "planPrices[0].value")} per month
              starting{" "}
              {getSubscriptionEndDate(
                _.get(scenario, "subscription_info.created_at")
              )}
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="upgradeBtn primary"
            >
              {isSubmitting ? (
                <ClipLoader
                  size={20}
                  css={{
                    borderColor: "var(--primary-color)",
                    borderBottomColor: "transparent",
                  }}
                />
              ) : (
                "Renew My Subscription"
              )}
            </button>
            <button
              type="button"
              className="noThanksBtn"
              onClick={() => setDisplayModal((cur) => !cur)}
            >
              No Thanks
            </button>
            {showThankYou && (
              <div className="thank-you absolute h-full w-full flex flex-col justify-center items-center">
                <h2>Thank you for renewing your subscription!</h2>
                <button
                  className="close-modal tertiary rounded-full flex items-center justify-center"
                  onClick={() => setDisplayModal((cur) => !cur)}
                >
                  <Cross />
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SubscriptionRenewalModal;
