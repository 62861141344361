/* eslint-disable no-console */
import { LongLeftArrow } from "@icons/index";
import { useRouter } from "next/router";
import { useAppContext } from "@context/state";
import Link from "next/link";
import {
  MYCAP_PREMIUM_PLUS_PRICE_ID,
  MYCAP_PREMIUM_PRICE_ID_MONTHLY,
  MYCAP_PREMIUM_PRICE_ID_YEARLY,
  MYCAP_PREMIUM_RENEWAL_PRICE_ID,
  MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CANCEL_SUBSCRIPTION,
} from "@utils/constants";
import { checkIsUpgraded } from "@utils/checkIsUpgraded";
import { useEffect } from "react";
import { createChargebeePortalSession } from "@api/user";
import _ from "lodash";

const AccountInfo = () => {
  const router = useRouter();

  const {
    scenario,
    user,
    setFormModalView,
    setDisplayFormModal,
    handleApiError,
    eligibleForRenewal,
  } = useAppContext();

  const { subscription_info } = scenario;

  const getSubscription = () => {
    if (subscription_info) {
      switch (subscription_info.price_id) {
        case MYCAP_PREMIUM_PLUS_PRICE_ID:
        case MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID:
          return "Valedictorian";
        case MYCAP_PREMIUM_PRICE_ID_MONTHLY:
        case MYCAP_PREMIUM_PRICE_ID_YEARLY:
        case MYCAP_PREMIUM_RENEWAL_PRICE_ID:
          return "Scholar";
        default:
          return "Student";
      }
    }
  };

  /**
   *
   */
  const initializePortalSession = async () => {
    try {
      const res = await createChargebeePortalSession(user.email);
      const portalSessionObject = res.data;
      const cbInstance = window.Chargebee.getInstance();
      if (cbInstance) {
        cbInstance.setPortalSession(() => {
          return new Promise(function (resolve) {
            resolve(portalSessionObject);
          });
        });
        const chargebeePortalInstance = cbInstance.createChargebeePortal();
        const callbacks = {
          loaded: () => {
            console.warn("chargebee portal loaded");
          },
          close: () => {
            console.warn("chargebee portal closed");
          },
        };
        //More elegant solution to go to subscription details
        // chargebeePortalInstance.openSection(
        //   {
        //     sectionType:
        //       window.Chargebee.getPortalSections().SUBSCRIPTION_DETAILS,
        //     params: {
        //       subscriptionId: subscription_info?.chargebee_subscription_id,
        //     },
        //   },
        //   callbacks
        // );
        chargebeePortalInstance.open(callbacks);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    // Chargebee instance will be created when DOMContentLoaded
    // event is triggered.Since, you are loading it asynchronously,
    // instance is not getting created. So we can create the instance
    // using Chargebee.init().
    // In Nextjs, it does not simply work if we add a script tag to Head.
    // Requires to load a script after the app loads in order to work.
    if (!_.isNull(user)) {
      if (document.getElementById("chargebee-script-element")) {
        document.getElementById("chargebee-script-element").remove();
      }
      //Standard chargebee script
      const chargeBeeScript = document.createElement("script");
      chargeBeeScript.onload = () => {
        window.Chargebee.init({
          site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
        });
        window.Chargebee.registerAgain();
      };

      chargeBeeScript.src = "https://js.chargebee.com/v2/chargebee.js";
      chargeBeeScript.id = "chargebee-script-element";
      document.head.appendChild(chargeBeeScript);
    }
  }, [user]);

  //Edit Account Info
  return (
    <div className="entryPage flex flex-col justify-between h-full w-full">
      <div className="profileContainer flex flex-col items-center justify-start text-center h-full w-full">
        <div className="pseudo-nav flex flex-row items-center justify-center w-full">
          <button
            className="simple back-btn flex items-center"
            onClick={() => {
              router.push("/profile");
            }}
          >
            <LongLeftArrow />
          </button>
          <div className="font-demi-bold">Account Info</div>
        </div>
        <ul className="accountInfoContainer flex flex-col items-center w-full">
          <li>
            <div
              className={`accountInfoOption flex flex-col md:flex-row md:justify-between
              ${process.env.NEXT_PUBLIC_WHITELABEL === "true"
                  ? "w-full"
                  : "w-3/4 max-w-3/4"
                }`}
            >
              <small>Email</small>
              <div className="email">{user?.email || "Your email here"}</div>
            </div>
            {process.env.NEXT_PUBLIC_WHITELABEL !== "true" && (
              <a
                onClick={() => {
                  setFormModalView(CHANGE_EMAIL);
                  setDisplayFormModal(true);
                }}
              >
                <small>Edit</small>
              </a>
            )}
          </li>
          <li>
            <div
              className={`accountInfoOption flex flex-col md:flex-row md:justify-between
              ${process.env.NEXT_PUBLIC_WHITELABEL === "true"
                  ? "w-full"
                  : "w-3/4 max-w-3/4"
                }`}
            >
              <small>Password</small>
              <div>{user?.password || "**********"}</div>
            </div>
            {process.env.NEXT_PUBLIC_WHITELABEL !== "true" && (
              <a
                onClick={() => {
                  setFormModalView(CHANGE_PASSWORD);
                  setDisplayFormModal(true);
                }}
              >
                <small>Edit</small>
              </a>
            )}
          </li>
          <li>
            <div
              className={`accountInfoOption flex flex-col md:flex-row md:justify-between
              ${process.env.NEXT_PUBLIC_WHITELABEL === "true"
                  ? "w-full"
                  : "w-2/4 max-w-3/4"
                }`}
            >
              <small>Subscription</small>
              <div>{getSubscription() || "Your subscription tier"}</div>
              <a
                onClick={() => {
                  setFormModalView(CANCEL_SUBSCRIPTION);
                  setDisplayFormModal(true);
                }}
              >
                <small className="ml-[140px]">Cancel</small>
              </a>
            </div>
            {process.env.NEXT_PUBLIC_WHITELABEL !== "true" &&
              !checkIsUpgraded(subscription_info?.price_id) ? (
              (<Link href="/upgrade_plan">

                <small>Upgrade</small>

              </Link>)
            ) : (
              <>
                {subscription_info?.chargebee_customer_id && (
                  <a
                    onClick={() => {
                      initializePortalSession();
                    }}
                  >
                    <small>Billing Portal</small>
                  </a>
                )}
              </>
            )}
          </li>
        </ul>
        {eligibleForRenewal && (
          <div className="my-4 flex justify-center">
            <Link href="/renew_plan">

              <button className="p-2 primary">Renew Subscription</button>

            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountInfo;
