import { useEffect } from "react";

const Entry2 = ({
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
}) => {
  if (formStep !== 2) {
    // Don't render unless step matches
    return null;
  }

  useEffect(() => {
    trigger(["parent_guardian.parent_name", "student.student_name"]);
    setWatchFields(["parent_guardian", "student"]);
    //Set content for help modal
    setModalContent([]);
  }, []);

  //TODO: The field should be restricted to alphabetical values and hyphen (-)
  //If the user enters a numerical value or special character they will see an error that says “Name cannot have numerical or special characters”

  return (
    <div className="welcomeContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <div className="welcomeText welcome flex flex-col w-full">
        <h1>Great!</h1>
        <h5>Now, fill in the names of the parent and the student.</h5>
      </div>
      <div className="inputs flex flex-col text-left">
        <label
          htmlFor="parent_guardian.parent_name"
          className="textLabel small"
        >
          <span className="labelFocus">Parent/Guardian First Name</span>
          <input
            id="parent_guardian.parent_name"
            type="text"
            {...register("parent_guardian.parent_name", {
              required: {
                value: true,
                message: "Please enter the parent or guardian's name",
              },
              // minLength: {
              //   value: 3,
              //   message: "Please enter at least 1 character"
              // }
            })}
          />
        </label>
        <label htmlFor="student.student_name" className="textLabel small">
          <span className="labelFocus">Student First Name</span>
          <input
            id="student.student_name"
            type="text"
            {...register("student.student_name", {
              required: {
                value: true,
                message: "Please enter the student's name",
              },
              // minLength: {
              //   value: 1,
              //   message: "Please enter at least 1 character"
              // }
            })}
          />
        </label>
      </div>
    </div>
  );
};

export default Entry2;
