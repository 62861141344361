import { MyCap } from "@icons/index";
import CAPSelect from "../SchoolSearch/CAPSelect"; //TODO: refactor and move to common components folder
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import Image from "next/legacy/image";
import { logo, settings } from "@whitelabel/whitelabel.preval.js";

/**
 * Before choosing a time, a user will see this component allowing them to select an expert to schedule with.
 * @param {function} setExpert - set state of expert in preparation for making an update request to the api
 * @param {array} expertsList - array of experts obtained from the api
 * @returns page for choosing an expert before proceeding to schedule an appointment
 */
const ChooseExpert = ({ setExpert, expertsList }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: "all",
    criteriaMode: "all", //allows multiple errors for one field
  });

  const onSubmit = async (formValues) => {
    //parse value before setting state
    const chosenExpert = JSON.parse(formValues.expert);
    await setExpert(chosenExpert);
  };

  const renderExperts = () => {
    //map experts to dropdown
    const roundRobinOption = {
      id: 0,
      name: "Next Available",
      meeting_link:
        "https://meetings.hubspot.com/college-aid-pro/book-a-consultation",
    };
    const experts = [roundRobinOption, ...expertsList].map((expert) => {
      return { value: JSON.stringify(expert), label: expert.name };
    });
    return <CAPSelect register={register} field="expert" options={experts} />;
  };

  return (
    <form
      className="flex flex-col items-center text-center px-3 mb-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      {process.env.NEXT_PUBLIC_WHITELABEL === "true" ? (
        <div className="whitelabel-mycap mt-10 mb-4 flex justify-center relative">
          <Image
            src={logo}
            alt={settings?.name}
            layout="fill"
            objectFit="contain"
          />
        </div>
      ) : (
        <div className="myCap mt-10 mb-2">
          <MyCap />
        </div>
      )}
      <div className="h3">Choose a MyCAP Expert to meet</div>
      <div className="w-full text-center mb-2">Who would you like to meet?</div>
      <div className="experts-dropdown w-full mb-2">{renderExperts()}</div>
      <button
        type="submit"
        disabled={isSubmitting}
        className={`${isSubmitting ? "pt-1" : ""}
          primary h-7 px-4`}
      >
        {isSubmitting ? (
          <ClipLoader
            size={20}
            css={{
              borderColor: "var(--primary-color)",
              borderBottomColor: "transparent",
            }}
          />
        ) : (
          "Next"
        )}
      </button>
    </form>
  );
};

export default ChooseExpert;
