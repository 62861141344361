const PlusIcon = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 13 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Plus"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <polygon
            id="PlusIcon"
            fill="currentColor"
            fillRule="nonzero"
            points="7.265 13 7.265 7.265 13 7.265 13 5.735 7.265 5.735 7.265 0 5.735 0 5.735 5.735 0 5.735 0 7.265 5.735 7.265 5.735 13"
          ></polygon>
        </g>
      </svg>
    </div>
  );
};

export default PlusIcon;
