import _ from "lodash";
import { useEffect } from "react";
import ProgressBar from "../ProgressBar.js";
import NumberFormat from "react-number-format";
import { hasExSpouse } from "@utils/maritalStatusCheck";
import { infoTips } from "@utils/infoTips";

const Step12 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
  setValue,
  getValues,
}) => {
  if (formStep !== 14) {
    // Don't render unless step matches
    return null;
  }

  const maritalStatus = getValues("parent_guardian.marital_status");

  useEffect(() => {
    trigger("parent_guardian.investments");
    setWatchFields(["parent_guardian"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "Parent/Guardian’s Non-Retirement Savings/Investments",
        description: _.get(
          infoTips,
          "scenario.onboarding.parent_guardian.investments"
        ),
      },
    ]);
  }, []);

  return (
    <div className="guardianInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep - 1} />
      <div className="guardianInfoText">
        <h5>
          How much does{" "}
          {_.get(formData, "parent_guardian.parent_name") || "Parent"} have in
          Non&#8288;-&#8288;Retirement Savings/&#8203;Investments?
        </h5>
      </div>
      <div className="inputs flex flex-col text-left">
        <label
          htmlFor="parent_guardian.investments"
          className="textLabel currencyLabel small"
        >
          <span className="labelFocus">Total amount</span>
          <NumberFormat
            value={getValues("parent_guardian.investments")}
            {...register("parent_guardian.investments", {
              required: {
                value: true,
                message: "Please enter nonretirement savings/investments total",
              },
            })}
            onValueChange={({ value }) => {
              setValue("parent_guardian.investments", value);
              trigger("parent_guardian.investments");
            }}
            thousandSeparator={true}
          />
          <div className="currency resetFont">$</div>
        </label>
        {/* TODO: Find out if spouse non-retirement savings is needed */}
        {hasExSpouse(maritalStatus) && (
          <label
            htmlFor="parent_guardian.investments_3"
            className="textLabel currencyLabel small"
          >
            <span className="labelFocus">
              How much does your Ex-Spouse have in Non&#8288;-&#8288;Retirement
              Savings/&#8203;Investments?
            </span>
            <NumberFormat
              value={getValues("parent_guardian.investments_3")}
              {...register("parent_guardian.investments_3", {
                required: {
                  value: true,
                  message:
                    "Please enter nonretirement savings/investments total",
                },
              })}
              onValueChange={({ value }) => {
                setValue("parent_guardian.investments_3", value);
                trigger("parent_guardian.investments_3");
              }}
              thousandSeparator={true}
            />
            <div className="currency resetFont">$</div>
          </label>
        )}
      </div>
    </div>
  );
};

export default Step12;
