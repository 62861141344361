import { processUSD } from "@utils/costEstimates";
import OfferFormField from "./OfferFormField";
import { getCostOfAttendance } from "@utils/calcHowToPay";

/**
 * @param {object} capProjections
 * @param {object} tuitionBudget
 * @param {object} decidedDocumentJob
 * @returns Award fields on offer card
 */
const OfferCostFormFields = ({
  capProjections,
  tuitionBudget,
  decidedDocumentJob,
  offerSchool,
  watchInt,
}) => {
  /**
   * calculate value from decided document job
   * @param {array} costAmounts
   * @param {function} filterFn
   * @returns {Number}
   */
  const calculateTotalCost = (costAmounts, filterFn) => {
    const filteredArray = costAmounts?.filter(filterFn);
    if (filteredArray?.length > 0) {
      return filteredArray.reduce((total, cost) => total + cost.value, 0);
    } else {
      return null;
    }
  };

  //TODO: cost of attendance will likely need to be summed on FE due to potential for differing sources of values
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full mb-1">
        <h4 className="mb-0.5">Cost of Attendance</h4>
        <h5 className="removeMargin">
          {processUSD(getCostOfAttendance(watchInt))}
        </h5>
      </div>
      <OfferFormField
        label="Tuition"
        mapId="tuition_budget.coa_1"
        tuitionBudgetValue={tuitionBudget?.fillable_fields?.coa_1}
        capProjectionValue={
          capProjections?.fillable_fields?.coa_1 ||
          offerSchool?.breakdowns[0]?.cost_of_attendance -
            offerSchool?.room_and_board -
            offerSchool?.book_fees -
            offerSchool?.travel_and_misc -
            offerSchool?.fees //TODO: need projection multiplier based on grad year
        }
        decidedDocumentJobValue={calculateTotalCost(
          decidedDocumentJob?.cost_amounts,
          (cost) => cost.categories.includes("TUITION")
        )}
        placeholder="0"
        prefix="$"
      />
      <OfferFormField
        label="Room & Board"
        mapId="tuition_budget.room_and_board_1"
        tuitionBudgetValue={tuitionBudget?.fillable_fields?.room_and_board_1}
        capProjectionValue={
          capProjections?.fillable_fields?.room_and_board_1 ||
          offerSchool?.room_and_board //TODO: need projection multiplier based on grad year
        }
        decidedDocumentJobValue={calculateTotalCost(
          decidedDocumentJob?.cost_amounts,
          (cost) =>
            cost.categories.includes("HOUSING") ||
            cost.categories.includes("MEALS")
        )}
        placeholder="0"
        prefix="$"
      />
      <OfferFormField
        label="Books & Supplies"
        mapId="tuition_budget.books_and_supplies_1"
        tuitionBudgetValue={
          tuitionBudget?.fillable_fields?.books_and_supplies_1
        }
        capProjectionValue={
          capProjections?.fillable_fields?.books_and_supplies_1 ||
          offerSchool?.book_fees //TODO: need projection multiplier based on grad year
        }
        decidedDocumentJobValue={calculateTotalCost(
          decidedDocumentJob?.cost_amounts,
          (cost) => cost.categories.includes("BOOKS_AND_SUPPLIES")
        )}
        placeholder="0"
        prefix="$"
      />
      <OfferFormField
        label="Travel & Misc."
        mapId="tuition_budget.travel_and_misc_1"
        tuitionBudgetValue={tuitionBudget?.fillable_fields?.travel_and_misc_1}
        capProjectionValue={
          capProjections?.fillable_fields?.travel_and_misc_1 ||
          offerSchool?.travel_and_misc //TODO: need projection multiplier based on grad year
        }
        decidedDocumentJobValue={calculateTotalCost(
          decidedDocumentJob?.cost_amounts,
          (cost) =>
            !cost.categories.includes("BOOKS_AND_SUPPLIES") &&
            (cost.categories.includes("TRANSPORT") ||
              cost.categories.includes("PERSONAL"))
        )}
        placeholder="0"
        prefix="$"
      />
      <OfferFormField
        label="Other Fees"
        mapId="tuition_budget.other_fees_1"
        tuitionBudgetValue={tuitionBudget?.fillable_fields?.other_fees_1}
        capProjectionValue={
          capProjections?.fillable_fields?.other_fees_1 || offerSchool?.fees //TODO: need projection multiplier based on grad year
        }
        decidedDocumentJobValue={calculateTotalCost(
          decidedDocumentJob?.cost_amounts,
          (cost) =>
            cost.categories.length === 1 &&
            cost.categories.every((category) => category === "FEES")
        )}
        placeholder="0"
        prefix="$"
      />
    </div>
  );
};

export default OfferCostFormFields;
