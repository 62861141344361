import axiosInstance from "@api/axiosInstance";

//******************************************//
//********** --- MISCELLANEOUS --- *********//
//******************************************//

/**
 * End point to get list of all k-12 schools in a 140 mile radius of a given zip code.
 * Filter out all non high schools here.
 * The search for a specific school will happen on the UI side.
 * @param {string} zip - Zip code to search around
 *
 */
export const getHighSchools = async (zipCode) => {
  const data = {
    zip_code: zipCode,
  };
  const res = await axiosInstance.post(`/highschool/${zipCode}`, data);
  const resData = res.data.result;
  const highSchools = resData[0].filter(
    (school) => school.high_school === true
  );
  console.log(highSchools);
  return highSchools;
};

/**
 * Endpoint to get list of all majors
 * Must be public
 * No params
 */
export const getMajors = async () => {
  const res = await axiosInstance.get(`/majors`);
  return res;
};

/**
 * Endpoint to get list of all religions
 * Must be public
 * No params
 */
export const getReligions = async () => {
  const res = await axiosInstance.get(`/religions`);
  return res;
};

/**
 * Endpoint to get list of all ethnicities
 * Must be public
 * No params
 */
export const getEthnicities = async () => {
  const res = await axiosInstance.get(`/ethnicities`);
  return res;
};

/**
 * Endpoint to get projected starting salaries for major groups specific to school
 * Major groups represent several different majors which are in similar
 * fields, and combined for the sake of reporting expected salaries.
 * @param {number} school_id - Id of a specific school
 */
export const getMajorsSalaries = async (school_id) => {
  const res = await axiosInstance.get(`/schools/${school_id}/salary`);
  return res;
};
