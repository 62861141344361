import axiosInstance from "@api/axiosInstance";

//******************************************//
//********** ----- SCHOOLS ----- ***********//
//******************************************//

/**
 * Endpoint to get school info specific to a user. Used for school detail page
 * @param {number} case_id - Id of a user's scenario
 * @param {number} school_id - Id of a school
 * @param {string} access_token - cookie value provided from serverSideProps
 */
export const getSchoolUserInfo = async (case_id, school_id, access_token) => {
  const res = await axiosInstance.get(`/case/${case_id}/school/${school_id}`, {
    headers: access_token ? { Authorization: `Bearer ${access_token}` } : {},
  });
  return res;
};

/**
 * Endpoint for getting list of all schools that have been added by the user
 * @param {number} case_id - Id of a user's scenario
 */
export const getUserSchools = async (case_id) => {
  const res = await axiosInstance.get(`/case/${case_id}/schools`);
  return res;
};

/**
 * Endpoint for adding a school to a user.
 * @param {number} case_id - Id of a user's scenario
 * @param {number} school_id - Id of a school
 */
export const addSchoolToUser = async (case_id, school_id) => {
  const res = await axiosInstance.post(`/case/${case_id}/school/${school_id}`);
  return res;
};

/**
 * Endpoint for deleting a school from a user.
 * @param {number} case_id - Id of a user's scenario
 * @param {number} school_id - Id of a school
 */
export const removeSchoolFromUser = async (case_id, school_id) => {
  const res = await axiosInstance.delete(
    `/case/${case_id}/school/${school_id}`
  );
  return res;
};

/**
 * Endpoint to set applying status for a
 * school that belongs to a user.
 * @param {number} case_id - Id of a user's scenario
 * @param {number} school_id - Id of a school
 * @param {string} status - status of applying to school (Yes, No)
 */
export const setSchoolApplyingStatus = async (case_id, school_id, status) => {
  const res = await axiosInstance.patch(
    `/case/${case_id}/school/${school_id}/applying`,
    { applying: status }
  );
  return res;
};

/**
 * Endpoint for updating a school data for a user
 * @param {number} case_id - Id of a user's scenario
 * @param {number} school_id - Id of a school
 * @param {object} school_data - object of school data with school values to update
 */
export const updateSchoolInfo = async (case_id, school_id, school_data) => {
  const res = await axiosInstance.put(
    `/case/${case_id}/school/${school_id}/apply-for-aid`,
    school_data
  );
  return res;
};

//******************************************//
//*********** ----- SEARCH ----- ***********//
//******************************************//

/**
 * CAP College Search
 * Endpoint for searching list of schools by school name
 * Minimum 3 characters required
 * @param {string} asyncSearchTerm - Term against which to search, currently is school_name
 * returns: list of schools with limited amount of data (enough for the basic search)
 */
export const getSearchList = async (asyncSearchTerm) => {
  const res = await axiosInstance.get(`/schools?name_like=${asyncSearchTerm}`);
  return res;
};

/**
 * CAP High School Search
 * Endpoint for searching list of schools by school name in a 140 mi radius of passed zip code
 * @param {integer} zip code - Term against which to search, currently is school_name
 * returns: list of schools with limited amount of data (enough for the basic search)
 */
export const getHSSearchList = async (zipcode) => {
  const res = await axiosInstance.get(
    `/highschools?name_like=${asyncSearchTerm}`
  );
  return res;
};

/**
 * CAP Advanced Search
 * Endpoint for searching/getting list of schools based on filter values applied (premium feature)
 * @param {number} case_id - Id of a user's scenario
 * @param {object} filters - Object of filter values like location, distance, major, enrollment e.t.c.
 * @param {string} access_token - cookie value provided from serverSideProps
 * To sort, append &order= to filters and pass in a valid sorting string
 * returns: list of schools with detailed data (used to populate more detailed school cards)
 */
export const CAPAdvancedSchoolSearch = async (
  case_id,
  filters,
  access_token
) => {
  const res = await axiosInstance.get(
    `/case/${case_id}/schools-advanced-search?${filters}`,
    {
      headers: access_token ? { Authorization: `Bearer ${access_token}` } : {},
    }
  );
  return res;
};

//******************************************//
//******** ----- SCHOLARSHIPS ----- ********//
//******************************************//

/**
 * Endpoint for getting scholarships for a specific school for user
 * Response will also incllude default selected state for each scholarship
 * @param {number} case_id - Id of a user's scenario
 * @param {number} school_id - Id of a school
 */
export const getSchoolScholarships = async (case_id, school_id) => {
  // Get scholarships for a school
  const res = await axiosInstance.get(
    `/case/${case_id}/scholarship/${school_id}`
  );
  return res;
};

/**
 * Endpoint for resetting added scholarships.
 * The backend will set all the scholarships 'selected' status back to default, which is not necessarily "No". Some scholarships are automatic.
 * @param {number} case_id - Id of a user's scenario
 * @param {number} school_id - Id of a school
 */
export const resetSchoolScholarships = async (case_id, school_id) => {
  // Reset scholarships for scenario
  const res = await axiosInstance.delete(
    `/case/${case_id}/scholarship/${school_id}`
  );
  return res;
};

/**
 * Endpoint for adding or removing scholarships requires entire array of the ids of added schools.
 * This is why there's a useState for addedScholarshipIds.
 * @param {number} case_id - Id of a user's scenario
 * @param {number} school_id - Id of a school
 * @param {array} scholarshipIdArray - An array of scholarship ids, representing which scholarships are "added".
 * Backend will take the array and set the scholarships 'selected' status to be "Yes" for the ids passed in, and "No" for any scholarship ids not passed in.
 */
export const updateSchoolScholarships = async (
  case_id,
  school_id,
  scholarshipIdArray
) => {
  const res = await axiosInstance.post(
    `/case/${case_id}/scholarship/${school_id}`,
    { scholarships: scholarshipIdArray }
  );
  return res;
};
