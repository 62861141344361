import {
  LongLeftArrow,
  YoutubeIcon,
  FacebookIcon,
  Awards,
  CapAdvisors,
  Profile,
} from "@icons/index";
import SidebarOption from "./SidebarOption";
import { useAppContext } from "@context/state";
import { ssoRedirect } from "@api/ssoApi";
import {
  YOUTUBE_CHANNEL,
  FB_GROUP,
  SSO_SCHOLARSHIPS_PATH,
  EXPERT_BUTTON_TEXT,
} from "@utils/constants";
import { useRouter } from "next/router";
import { openExternalLink } from "@utils/externalLink";
import { settings } from "@whitelabel/whitelabel.preval.js";

const Sidebar = () => {
  const router = useRouter();

  const {
    myCapLogout,
    displaySidebar,
    setDisplaySidebar,
    isIecAndImpersonating,
    routeToExpertMeeting,
  } = useAppContext();

  return (
    <div
      className={`${
        displaySidebar ? "showSidebarContainer" : ""
      } sidebarContainer`}
    >
      <div
        className={`${displaySidebar ? "showSidebarBG" : ""} sidebarBG`}
        onClick={() => {
          setDisplaySidebar(false);
        }}
      ></div>
      <div
        className={`${
          displaySidebar ? "showSidebar" : ""
        } sidebar flex flex-col justify-between`}
      >
        <div className="sidebarScroll flex flex-col justify-between">
          <div>
            <button
              className="closeBtn flex items-center"
              onClick={() => {
                setDisplaySidebar(false);
              }}
            >
              <LongLeftArrow />
            </button>
            <div onClick={() => openExternalLink(YOUTUBE_CHANNEL)}>
              <SidebarOption Icon={YoutubeIcon} title="CAP Academy" />
            </div>
            <div onClick={() => openExternalLink(FB_GROUP)}>
              <SidebarOption
                Icon={FacebookIcon}
                title="Ask CAP Community Group"
              />
            </div>
            <div
              onClick={() => {
                ssoRedirect(SSO_SCHOLARSHIPS_PATH);
              }}
            >
              <SidebarOption Icon={Awards} title="Private Scholarships" />
            </div>
            <div onClick={routeToExpertMeeting}>
              <SidebarOption
                Icon={CapAdvisors}
                title={settings?.expertButtonText || EXPERT_BUTTON_TEXT}
              />
            </div>
            {!isIecAndImpersonating && (
              <div onClick={() => router.push("/profile")}>
                <SidebarOption Icon={Profile} title="Profile" />
              </div>
            )}
          </div>
          <div className="logoutContainer">
            <button
              className="tertiary inherit logoutBtn flex justify-center items-center"
              onClick={() => {
                setDisplaySidebar(false);
                myCapLogout();
              }}
            >
              <small>Log Out</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
