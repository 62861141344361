import { useAppContext } from "@context/state";
import AddOfferCard from "../TranslateCompare/AddOfferCard";
import { Section, OfferCard, EmptyOfferSection } from "@components/index";

/**
 * Show Offer Cards in gallery
 * @returns Offers section on dashboard (Translate & Compare Offers)
 */
const TranslateCompareSection = () => {
  const { userOffers } = useAppContext();

  const renderOffers = () => {
    //map offers to cards
    const offers = userOffers.map((offer) => (
      <OfferCard key={offer.id} offerData={offer} />
    ));
    offers.push(<AddOfferCard key="add_offer" />);
    return offers;
  };

  return (
    <div className="offers-container">
      <Section
        title={"Translate & Compare"}
        galleryName={"offer-gallery"}
        empty={userOffers.length === 0}
      >
        {userOffers.length === 0 ? (
          <EmptyOfferSection
            descriptionText={"Add an offer to better understand your options"}
          />
        ) : (
          <>{renderOffers()}</>
        )}
      </Section>
    </div>
  );
};

export default TranslateCompareSection;
