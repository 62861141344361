import axiosInstance from "@api/axiosInstance";
import axiosInstanceMultipart from "@api/axiosInstanceMultipart";

/**
 * Endpoint for adding an offer to a scenario.
 * @param {number} case_id - Id of a user's scenario
 * @param {object} offerFormData - form values to upload offer
 * @param {object} queryParams - query param to trigger translation of document (?translate)
 */
export const addOffer = async (case_id, offerFormData, queryParams = {}) => {
  const res = await axiosInstanceMultipart.post(
    `/case/${case_id}/offers`,
    offerFormData,
    { params: queryParams }
  );
  return res;
};

/**
 * Endpoint for updating an offer. put/patch does not work with form data so we must use a post request
 * @param {number} case_id - Id of a user's scenario
 * @param {number} offer_id - Id of an offer
 * @param {object} offerFormData - form values to upload offer
 * @param {object} queryParams - query param to trigger translation of document (&translate)
 */
export const updateOffer = async (
  case_id,
  offer_id,
  offerFormData,
  queryParams = {}
) => {
  const res = await axiosInstanceMultipart.post(
    `/case/${case_id}/offers/${offer_id}?_method=put`,
    offerFormData,
    { params: queryParams }
  );
  return res;
};

/**
 * Endpoint for getting all offers associated with a scenario.
 * @param {number} case_id - Id of a user's scenario
 */
export const getScenarioOffers = async (case_id) => {
  const res = await axiosInstance.get(`/case/${case_id}/offers`);
  return res;
};

/**
 * Endpoint for getting all offers associated with a scenario.
 * @param {number} case_id - Id of a user's scenario
 * @param {number} offer_id - Id of an offer
 */
export const getScenarioOffer = async (case_id, offer_id) => {
  const res = await axiosInstance.get(`/case/${case_id}/offers/${offer_id}`);
  return res;
};

/**
 * Endpoint for getting all tuition budgets associated with a scenario.
 * @param {number} case_id - Id of a user's scenario
 * @param {object} tuitionBudgetData - form values to upload offer
 */
export const createTuitionBudget = async (case_id, tuitionBudgetData) => {
  const res = await axiosInstance.post(
    `/case/${case_id}/tuition_budgets`,
    tuitionBudgetData
  );
  return res;
};

/**
 * Endpoint for getting all tuition budgets associated with a scenario.
 * @param {number} case_id - Id of a user's scenario
 * @param {number} tuition_budget_id - Id of a tuition budget
 * @param {object} tuitionBudgetData - form values to upload offer
 */
export const updateTuitionBudget = async (
  case_id,
  tuition_budget_id,
  tuitionBudgetData
) => {
  const res = await axiosInstance.put(
    `/case/${case_id}/tuition_budgets/${tuition_budget_id}`,
    tuitionBudgetData
  );
  return res;
};

/**
 * Endpoint for getting all tuition budgets associated with a scenario.
 * @param {number} case_id - Id of a user's scenario
 */
export const getScenarioTuitionBudgets = async (case_id) => {
  const res = await axiosInstance.get(`/case/${case_id}/tuition_budgets`);
  return res;
};

/**
 * Endpoint for polling an offer document parsing job. put/patch does not work with form data so we must use a post request
 * @param {number} case_id - Id of a user's scenario
 * @param {number} offer_id - Id of an offer
 * @param {object} job_id - Id of job
 */
export const getOfferDocumentJob = async (case_id, offer_id, job_id) => {
  const res = await axiosInstance.get(
    `/case/${case_id}/offers/${offer_id}/decided_document_jobs/${job_id}`
  );
  return res;
};
