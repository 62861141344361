import ShopForSchools from "./ShopForSchools/ShopForSchools";
import Scholarships from "./Scholarships";
import FafsaCss from "./FafsaCss/FafsaCss";
import TranslateCompareSection from "./TranslateCompareSection";
import EvaluateAppealSection from "./EvaluateAppealSection";
import Affordability from "./Affordability";
import HowToPay from "./HowToPay";
import HowToBorrow from "./HowToBorrow";
import { settings } from "@whitelabel/whitelabel.preval.js";

const Home = () => {
  return (
    <div className="sectionsContainer flex flex-col w-full">
      <ShopForSchools />
      <Affordability />
      <Scholarships />
      <FafsaCss />
      <TranslateCompareSection />
      <EvaluateAppealSection />
      <HowToPay />
      {!settings?.suppressHowToBorrow && <HowToBorrow />}
    </div>
  );
};

export default Home;
