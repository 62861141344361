/**
 *
 * @param {Number} num - valid number
 */
export const isNegative = (num) => {
  return num < 0 ? true : false;
};

/**
 * check if input is a number then use the absolute value to format the number with the appropriate prefixes
 * @param {Number} num - valid number
 * @param {boolean} withCents - format with cents
 */
export const formatDollarAmount = (num, withCents = false) => {
  //This line will result in strings showing as 0. NOTE: If a field may be something other than a number, do not use this helper method.
  if (Number.isNaN(num)) {
    return "$0";
  }
  const prefix = isNegative(num) ? "-" : "";
  if (withCents) {
    return (
      prefix +
      "$" +
      Math.abs(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  } else {
    return (
      prefix +
      "$" +
      Math.abs(num).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    );
  }
};
