import { getMajorsSalaries } from "@api/misc";
import { useAppContext } from "@context/state";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Graph = ({ school_id }) => {
  // TODO: consider using recharts
  // Default values for graph when no data received
  const defaultXAxisValues = [
    { label: "Computer Science", value: "0%" },
    { label: "Engineering", value: "0%" },
    { label: "Business", value: "0%" },
    { label: "Health Sciences", value: "0%" },
    { label: "Math, Biology & Physical Sciences", value: "0%" },
    { label: "Communications", value: "0%" },
    { label: "Humanities", value: "0%" },
  ];

  const [xAxisValues, setXAxisValues] = useState(defaultXAxisValues);
  const [yAxisValues, setYAxisValues] = useState([]);

  const { scenario, handleApiError } = useAppContext();

  //NOTE: intended_major_object rather than intended_major, since we wanted to return more info related to the chosen major without breaking things that previously relied on intended_major being an integer.
  const major = scenario?.onboarding?.student?.intended_major_object;

  const parseMajorsSalaries = async () => {
    try {
      const res = await getMajorsSalaries(school_id);
      if (res.data) {
        const yAxisIncrement = 20;
        const yAxisLabels = [];
        const topSalary = res.data[0] ? res.data[0].first_year_salary : 100000;
        const yAxisScope = Math.ceil(topSalary / 1000 / yAxisIncrement);
        for (let i = yAxisScope; i > 0; i--) {
          yAxisLabels.push(i * yAxisIncrement + "K");
        }
        yAxisLabels.push("0");
        setYAxisValues(yAxisLabels);
        /**
         * Move student's major to be included in sorted order. If outside the scope, move to last place within scope
         * @param {Array} majorsArray
         * @param {integer} scopeIndex - index of last major to be shown by default
         * @returns
         */
        const includeStudentMajor = (majorsArray, scopeIndex) => {
          for (let i = 0; i < majorsArray.length; i++) {
            if (res.data[i].name === major?.name) {
              if (i > scopeIndex) {
                majorsArray.splice(scopeIndex, 0, majorsArray.splice(i, 1)[0]);
              }
              return majorsArray;
            }
          }
          return majorsArray;
        };
        //scope is the number of majors to be graphed
        const scope = 7;
        const majorsArray = includeStudentMajor(res.data, scope - 1);
        const mappedSalaryData = majorsArray
          .slice(0, scope)
          .map((majorSalary) => {
            return {
              label: majorSalary.name,
              value: `${
                Math.round(majorSalary.first_year_salary / 1000) /
                ((yAxisIncrement / 100) * yAxisScope)
              }%`,
            };
          });
        setXAxisValues(mappedSalaryData);
      }
    } catch (error) {
      //This is a public route, so 401 or 403 errors are not expected.
      handleApiError(error);
    }
  };

  useEffect(() => {
    parseMajorsSalaries();
  }, []);

  return (
    <div className="graph relative w-full h-auto overflow-x-scroll overflow-y-visible">
      <div
        className={`relative w-full ${yAxisValues.length === 0 && "opacity-0"}`}
      >
        {yAxisValues.map((data, index) => (
          <div
            key={index}
            className="graph-line flex items-center justify-center"
          >
            <small className="micro text-right w-7 px-2">{data}</small>
            <div className="w-full m-0" />
          </div>
        ))}
        <div className="major-graph h-full rounded-t-md">
          <div className="relative w-full">
            {yAxisValues.map((yAxis, index) => (
              <div
                key={index}
                className="graph-line flex items-center justify-center"
              >
                <hr className="w-full m-0" />
              </div>
            ))}
            <div className=" px-1 absolute bottom-0 w-full h-full flex items-end">
              {xAxisValues.map((xAxis, index) => (
                <div
                  key={index}
                  style={{
                    height: xAxis.value,
                  }}
                  className="bar-outer relative bottom-0 flex flex-col items-center justify-end"
                >
                  <div
                    className={`${
                      xAxis.label === major?.name ? "highlight " : ""
                    }bar-inner rounded-t-md h-full`}
                  ></div>
                  <div className="relative w-full">
                    <div className="absolute w-full x-axis-label">
                      <p className="text-center w-full mb-0 micro">
                        {xAxis.label}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {yAxisValues.length === 0 && (
        <div className="loading-spinner w-full h-full flex justify-center items-center">
          <ClipLoader
            size={100}
            css={{
              borderColor: "inherit",
              borderBottomColor: "transparent",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Graph;
