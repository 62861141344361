import { useRouter } from "next/router";
import { Card } from "@components/index";

const AppealLimitCard = ({ remainingAppeals }) => {
  const router = useRouter();

  return (
    <div className="cardContainer add-offer-card">
      <Card type="typeTall">
        <div className="appeal-limit-card w-full h-full flex flex-col justify-center items-center text-center">
          <h4>Remaining Appeals: {remainingAppeals}</h4>
          <span className="mb-2">
            As a free account, you will see results for your three selected
            schools.
          </span>
          <span className="mb-2">
            Upgrade to view unlimited appeals evaluations!
          </span>
          <button
            className="tertiary addSchoolBtn "
            onClick={() => {
              router.push("/upgrade_plan");
            }}
          >
            Upgrade
          </button>
        </div>
      </Card>
    </div>
  );
};

export default AppealLimitCard;
