import BreakdownRow from "./BreakdownRow";
import BreakdownDrawer from "./BreakdownDrawer";
import { getCostOfAttendance, getNetCost } from "@utils/calcHowToPay";

const NetCostDrawer = ({ capProjectedData, budgetData, watchInt }) => {
  return (
    <BreakdownDrawer
      title={<span className="font-demi-bold">Net Cost</span>}
      status={"Get Started"}
      forcedValue={getNetCost(watchInt)}
      inputId={"net_cost_1"}
    >
      <BreakdownRow
        title={<span>Cost of Attendance</span>}
        forcedValue={getCostOfAttendance(watchInt)}
        readOnly={true}
        inputId={"cost_of_attendance_1"}
        type="Cost"
      />
      <BreakdownRow
        title={<i className="ml-1">Tuition</i>}
        defaultValue={budgetData?.coa_1}
        inputId={"tuition_budget.coa_1"}
        capProjectionValue={capProjectedData?.coa_1}
        type="Cost"
      />
      <BreakdownRow
        title={<i className="ml-1">Room and Board</i>}
        defaultValue={budgetData?.room_and_board_1}
        inputId={"tuition_budget.room_and_board_1"}
        capProjectionValue={capProjectedData?.room_and_board_1}
        type="Cost"
      />
      <BreakdownRow
        title={<i className="ml-1">Books and Supplies</i>}
        defaultValue={budgetData?.books_and_supplies_1}
        inputId={"tuition_budget.books_and_supplies_1"}
        capProjectionValue={capProjectedData?.books_and_supplies_1}
        type="Cost"
      />
      <BreakdownRow
        title={<i className="ml-1">Travel and Misc.</i>}
        defaultValue={budgetData?.travel_and_misc_1}
        inputId={"tuition_budget.travel_and_misc_1"}
        capProjectionValue={capProjectedData?.travel_and_misc_1}
        type="Cost"
      />
      <BreakdownRow
        title={<i className="ml-1">Other Fees</i>}
        defaultValue={budgetData?.other_fees_1}
        inputId={"tuition_budget.other_fees_1"}
        capProjectionValue={capProjectedData?.other_fees_1}
        type="Cost"
      />
      <BreakdownRow
        title="Need-Based Grants"
        defaultValue={budgetData?.fed_state_grants_1}
        inputId={"tuition_budget.fed_state_grants_1"}
        type="Award"
      />
      <BreakdownRow
        title="Merit Scholarships"
        defaultValue={budgetData?.merit_scholarship_1}
        inputId={"tuition_budget.merit_scholarship_1"}
        type="Award"
      />
      <BreakdownRow
        title="Private Scholarships"
        defaultValue={budgetData?.private_scholarships_1}
        inputId={"tuition_budget.private_scholarships_1"}
        type="Award"
      />
    </BreakdownDrawer>
  );
};

export default NetCostDrawer;
