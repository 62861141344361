import { convertMsToDays } from "./dateFormatter";
import ordinalNumber from "./ordinalNumber";
/**
 * Calculate the remaining number of days left on a plan using a year from the date the subscription was created.
 * @param {string} planCreatedAt - timestamp from database
 */
export const getSubscriptionRemainingTime = (planCreatedAt) => {
  const planCreatedDate = new Date(planCreatedAt);
  const currentTime = Date.now();
  //Extend the deadline for early adopters
  let planEndingTime;
  if (planCreatedDate.getFullYear() === 2021) {
    planEndingTime = new Date("2023-01-02T00:00:00.000000Z").getTime();
  } else {
    planEndingTime = planCreatedDate.setFullYear(
      planCreatedDate.getFullYear() + 1
    );
  }
  const planRemainingDays = convertMsToDays(planEndingTime - currentTime);

  return planRemainingDays;
};

/**
 * Calculate the remaining number of days left on a plan using a year from the date the subscription was created.
 * @param {string} planCreatedAt - timestamp from database
 */
export const getSubscriptionEndDate = (planCreatedAt) => {
  const planCreatedDate = new Date(planCreatedAt);
  let planEndingTime;
  if (planCreatedDate.getFullYear() === 2021) {
    planEndingTime = new Date("2023-01-02T00:00:00.000000Z").getTime();
  } else {
    planEndingTime = planCreatedDate.setFullYear(
      planCreatedDate.getFullYear() + 1
    );
  }
  const date = new Date(planEndingTime);
  const year = date.getFullYear();
  const month = date.toLocaleString("default", { month: "long" });
  const day = ordinalNumber(date.toLocaleString("default", { day: "numeric" }));

  return month + " " + day + ", " + year;
};
