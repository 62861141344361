import { useAppContext } from "@context/state.js";

const Modal = ({ children }) => {
  const { displayModal, setDisplayModal } = useAppContext();

  const clickOutside = (event) => {
    if (event.target.classList.contains("modal-container")) {
      setDisplayModal((cur) => !cur);
    }
  };

  //All hooks need to come before early termination
  if (!displayModal) {
    return null;
  }

  return (
    <div
      className="modal-container fixed top-0 w-full h-full"
      onClick={clickOutside}
    >
      {children}
    </div>
  );
};

export default Modal;
