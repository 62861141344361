import { useEffect } from "react";
import { RadioField, RangeInputField, CheckboxField } from "..";
import { TextField } from "@components/index";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

import { HighSchoolSearchInput } from "@components/index";
import { HelpButton } from "@components/index";
import { DownArrow } from "@icons/index";

/**
 * Returns an input based on the type of type of data
 * @param {object} input - Values for input field. For more reference
 * look for info objects in personalData.js file.
 */
const PersonalInfoInput = ({ input, majors, ethnicities, religions }) => {
  const {
    register,
    unregister,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const renderSelectOptions = (optionsArray = []) => {
    if (input.mapId === "student.major_id") {
      optionsArray = majors;
    }
    if (input.mapId === "student.ethnicity_id") {
      //TODO consider doing this for majors
      optionsArray = [
        { value: null, label: "Please choose an ethnicity" },
        ...ethnicities,
      ];
    }
    if (input.mapId === "student.religion_id") {
      optionsArray = [
        { value: null, label: "Please choose a religion" },
        ...religions,
      ];
    }
    return optionsArray.map((option, index) => {
      const disabledHidden = option.value === null ? { disabled: true } : {};
      return (
        <option value={option.value} key={index} {...disabledHidden}>
          {option.label}
        </option>
      );
    });
  };

  /**
   * After render initialize default values for every
   * input field. Values are received from user onboarding object.
   */
  useEffect(() => {
    if (input.label !== "siblings_data") {
      setValue(input.mapId, input.value);
    }
  }, [majors, ethnicities, religions]);

  useEffect(() => {
    // Unregister a field from react hook form if
    // not rendered in the form.
    return () => {
      unregister(input.mapId);
    };
  }, []);

  const getInputField = () => {
    switch (input.type) {
      case "dropdown":
        return (
          <>
            <select
              id={input.mapId}
              {...register(input.mapId, { ...input.validation })}
            >
              {renderSelectOptions(input.selectOptions)}
            </select>
            <div className="select-icon">
              <DownArrow />
            </div>
          </>
        );
      case "range":
        return <RangeInputField input={input} />;
      case "radio":
        return <RadioField input={input} />;
      case "checkbox":
        return <CheckboxField input={input} />;
      case "text":
      case "number":
        return <TextField input={input} />;
      case "hsLookup":
        return <HighSchoolSearchInput input={input} />;
      default:
        return (
          <>
            <input type="hidden" {...register(input.mapId)} />
          </>
        );
    }
  };

  if (
    input.validateRenderCondition &&
    !input.validateRenderCondition(watch(input.dependentMapId))
  ) {
    return null;
  }

  return input.label === "siblings_data" ? (
    input
      .getSiblingsDataFeilds(watch(input.dependentMapId))
      .map((sibling, index) => (
        <PersonalInfoInput key={index} input={sibling} />
      ))
  ) : (
    <div
      className={`inputItem relative ${watch(input.mapId) === null || watch(input.mapId) === ""
        ? "incomplete"
        : ""
        }`}
    >
      {input.type !== "range" && (
        <div className="labelWrapper flex items-end justify-between">
          <label htmlFor={input.mapId} className="small">
            {input.editLabel || input.label}
          </label>
          {input.tipsData && (
            <HelpButton
              helpContent={[
                {
                  id: 0,
                  title: input.label,
                  description: input.tipsData,
                },
              ]}
              className="small mb-0.5"
            />
          )}
        </div>
      )}
      {getInputField()}
      {_.get(errors, input.mapId) && (
        <small className="errorMessage">
          {_.get(errors, input.mapId).message}
        </small>
      )}
    </div>
  );
};

export default PersonalInfoInput;
