import BreakdownInput from "./BreakdownInput";
import HelpButton from "@components/Common/HelpButton";

/**
 * @param {*} title
 * @param {number} forcedValue
 * @param {number} defaultalue
 * @param {string} inputId
 * @param {*} children
 * @param {boolean} readOnly
 * @param {*} progressButton
 * @param {*} helpText
 * @returns
 */
const BreakdownRow = ({
  title,
  forcedValue,
  defaultValue,
  inputId,
  children,
  readOnly,
  // progressButton,
  helpText,
  onClick,
  textClassName,
  capProjectionValue,
  type,
}) => {
  return (
    <div className="breakdown-row relative w-full flex flex-row justify-between py-0.5 px-1 sm:py-1 sm:px-2">
      <div
        className={`relative flex justify-start items-center w-full mr-2 ${textClassName}`}
        onClick={onClick}
      >
        <div className="flex flex-row justify-start items-center">
          {title}
          {children}
        </div>
      </div>
      <div className="breakdown-inputs flex flex-row justify-end items-center">
        <div className="flex flex-row items-center">
          {helpText && (
            <HelpButton
              helpContent={[
                {
                  id: 0,
                  title,
                  description: helpText,
                },
              ]}
              className="small"
            />
          )}
        </div>
        <BreakdownInput
          forcedValue={forcedValue}
          defaultValue={defaultValue}
          readOnly={readOnly}
          mapId={inputId}
          capProjectionValue={capProjectionValue}
          type={type}
        />
      </div>
    </div>
  );
};

export default BreakdownRow;
