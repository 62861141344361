import { DefaultSeo } from "next-seo";
import config from "@config/seo.json";
import { icon, theme } from "@whitelabel/whitelabel.preval.js";

const CustomHead = () => {
  return (
    <>
      {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ? (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
          }}
        />
      ) : null}
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID ? (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
        ></script>
      ) : null}
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID ? (
        <script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');`,
          }}
        />
      ) : null}
      {process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID ? (
        <script
          type="text/javascript"
          async
          defer
          dangerouslySetInnerHTML={{
            __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("${process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID}");`,
          }}
        />
      ) : null}
      {process.env.NEXT_PUBLIC_FULLSTORY_ANALYTICS_ID ? (
        <script
          type="text/javascript"
          async
          defer
          dangerouslySetInnerHTML={{
            __html: `window['_fs_debug'] = false;
window['_fs_host'] = 'fullstory.com';
window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
window['_fs_org'] = '${process.env.NEXT_PUBLIC_FULLSTORY_ANALYTICS_ID}';
window['_fs_namespace'] = 'FS';
(function(m,n,e,t,l,o,g,y){
    if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
    g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
    o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
    y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
    g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
    g.anonymize=function(){g.identify(!!0)};
    g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
    g.log = function(a,b){g("log",[a,b])};
    g.consent=function(a){g("consent",!arguments.length||a)};
    g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
    g.clearUserCookie=function(){};
    g.setVars=function(n, p){g('setVars',[n,p]);};
    g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
    if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
    g._v="1.3.0";
})(window,document,window['_fs_namespace'],'script','user');`,
          }}
        />
      ) : null}
      {process.env.NEXT_PUBLIC_WHITELABEL === "true" && (
        <link href={theme} rel="stylesheet" key="whitelabel" />
      )}
      <DefaultSeo {...config} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {process.env.NEXT_PUBLIC_WHITELABEL === "true" ? (
        <link rel="icon" href={icon} />
      ) : (
        <link rel="icon" href="/logo.svg" />
      )}
      <link
        rel="preload"
        href="/fonts/AvenirNext-Regular.ttf"
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/AvenirNext-Medium.otf"
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/AvenirNext-DemiBold.ttf"
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/AvenirNext-Bold.ttf"
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      {/*  Script to Load the HubSpot tracking code  */}
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/9206925.js"
      ></script>
      {/* Chargebee Tracking Script */}
      <script
        type="text/javascript"
        async
        defer
        dangerouslySetInnerHTML={{
          __html: `(function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
fpr("init", {cid:"7sc5t8d5"});
fpr("click");`,
        }}
      />
      <script src="https://cdn.firstpromoter.com/fpr.js" async></script>
      {/* Adobe PDF Embed Script.
      NOTE: This script for whatever reason is incompatible with having scroll snap on the site,
      as Element.scrollBy actions will not have smooth behavior.
      Mobile still works with scroll snap.
      UPDATE 11/28/2022: new script may or may not affect scroll snap. Needs testing if scroll snap is desired. */}
      <script
        defer
        src="https://documentservices.adobe.com/view-sdk/viewer.js"
      ></script>
    </>
  );
};

export default CustomHead;
