import { LongLeftArrow, RightArrow } from "@icons/index";
import { useRouter } from "next/router";
import Link from "next/link";
import { useAppContext } from "@context/state";
import { getTotalPersonalInfoWeightPercentage } from "@utils/calcEnhancedProfile";
import { PillProgress } from "@components/index";

//Root directory for profile page.
const Profile = () => {
  const router = useRouter();
  const { scenario } = useAppContext();
  const { onboarding } = scenario;

  return (
    <div className="entryPage flex flex-col justify-between h-full w-full">
      <div className="profileContainer flex flex-col items-center justify-start text-center h-full w-full">
        <div className="pseudo-nav constrained flex flex-row items-center justify-center w-full">
          <button
            className="simple w-7 h-7 p-2 -ml-2 flex items-center absolute left-0"
            onClick={() => {
              router.push("/");
            }}
          >
            <LongLeftArrow />
          </button>
          <div className="font-demi-bold">Profile</div>
        </div>
        <div className="profileDirectory flex flex-col items-center w-full">
          <Link href="/account_info" className="profileOption">

            <span>Account Info</span>
            <button className="arrow">
              <RightArrow />
            </button>

          </Link>
          <Link href="/personal_info" className="profileOption">

            <span>Personal Info</span>
            <div className="flex items-center">
              <PillProgress
                progressPercent={
                  getTotalPersonalInfoWeightPercentage(onboarding)
                    .percentComplete
                }
              />
              <button className="arrow">
                <RightArrow />
              </button>
            </div>

          </Link>
          <Link href="/college_pre_approval" className="profileOption">

            <span>My College Budget</span>
            <button className="arrow">
              <RightArrow />
            </button>

          </Link>
        </div>
      </div>
    </div>
  );
};

export default Profile;
