import Link from "next/link";

const CheckEmail = ({ setSentEmail }) => {
  //Call to action to check user's email
  //setSentEmail(false) will trigger the render of this confirmation page to disappear and render the default state of /reset_password, which is the reset password input.
  return (
    <div className="flex flex-col items-center w-full">
      <div className="checkEmailText flex flex-col w-full">
        <h2>Check Your Email</h2>
        <span className="self-center">
          We have sent password recovery instructions to your email.
        </span>
      </div>
      <Link href="/login" legacyBehavior>
        {" "}
        <button
          type="button"
          name="checkEmail"
          className="checkEmailBtn primary"
        >
          Return to Log in
        </button>
      </Link>
      <div className="tryAgain">
        Did not receive the email? Check your spam filter, or{" "}
        <a onClick={() => setSentEmail(false)}>Try another email address.</a>
      </div>
    </div>
  );
};

export default CheckEmail;
