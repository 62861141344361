import Link from "next/link";

import { Card } from "@components/index";
import { useAppContext } from "@context/state";

import renderLogo from "@utils/renderLogo";
import { SCHOOL_CARD_EDIT, YES, NO } from "@utils/constants";
import moment from "moment";

// TODO is this page being used at all?
const SchoolCard = ({ schoolData, showMoreDetails }) => {
  const {
    scenario,
    setDisplayFormModal,
    setFormModalView,
    setFormModalContent,
  } = useAppContext();

  const {
    school_id,
    name,
    logo,
    deadline,
    decision,
    institutional,
    fafsa,
    applying,
  } = schoolData;

  const date = moment(deadline, "YYYY-MM-DD").format("MM/DD/YYYY");

  const getDefaultValue = (value) => {
    if (value && value !== "N/A") {
      return value;
    } else {
      return "";
    }
  };
  return (
    <div className="cardContainer">
      <Card type="typeTall">
        <div className="topHeading">
          <div className="flex flex-row justify-between items-start">
            {renderLogo(logo, name)}
            {showMoreDetails && (
              <button
                onClick={() => {
                  setFormModalContent(schoolData);
                  setFormModalView(SCHOOL_CARD_EDIT);
                  setDisplayFormModal(true);
                }}
                className="imitateLink flex items-start"
              >
                <small>
                  <b>Edit</b>
                </small>
              </button>
            )}
          </div>
          <div className="h4">
            <Link
              href={`/case/${scenario.case_id}/school/${[school_id]}`}
              className="underline inherit">
              {name}
            </Link>
          </div>
        </div>
        <div className="early-action">
          <small>Applying</small>
          <h2 className="font-regular">
            {/**
             * Only show status for applying like
             * early decision, early action, rolling e.t.c.
             */}
            {applying && applying !== YES && applying !== NO ? applying : ""}
          </h2>
          <div className="progress-bar rounded-full w-full">
            <div
              className="progress rounded-full"
              style={{ width: `${0.3 * 100}%` }}
            />
          </div>
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-1/2">
              <small>Deadline</small>
              <div>{deadline === "0000-00-00" ? "Not Reported" : date}</div>
            </div>
            <div className="right-side flex flex-col w-1/2">
              <small>Decision</small>
              <div>{getDefaultValue(decision) || ""}</div>
            </div>
          </div>
        </div>
        {showMoreDetails && (
          <>
            <div className="divider" />
            <div className="application-required flex flex-col">
              <small className="title font-demi-bold">
                Applications Required
              </small>
              <div className="flex flex-row w-full">
                <div className="flex flex-col w-1/2">
                  <small>FASFA</small>
                  <div>{getDefaultValue(fafsa)}</div>
                </div>
                <div className="flex flex-col w-1/2">
                  <small>Institutional</small>
                  <div>{getDefaultValue(institutional)}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default SchoolCard;
