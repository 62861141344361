import { useEffect } from "react";
import { useAppContext } from "@context/state";
import { useAuth0 } from "@auth0/auth0-react";
import { settings } from "@whitelabel/whitelabel.preval.js";
import { useRouter } from "next/router";

/**
 * Handle protected pages
 * @param {*} children - Layout component
 * @returns
 */
const WhitelabelProvider = ({ children }) => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  const router = useRouter();

  const { getAuth0AccessToken, handleUserToken } = useAppContext();

  // If whitelabel & done loading & not authenticated, login with redirect.
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_WHITELABEL === "true" &&
      process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID &&
      !isLoading &&
      !isAuthenticated
    ) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    async function getSettings() {
      if (
        process.env.NEXT_PUBLIC_WHITELABEL === "true" &&
        process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
      ) {
        if (isAuthenticated) {
          getAuth0AccessToken();
        }
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_WHITELABEL === "true" &&
      settings?.redirectUrl
    ) {
      const tokenIsValid = handleUserToken();
      if (!tokenIsValid) {
        router.push(settings?.redirectUrl);
      }
    }
  }, []);

  return children;
};

export default WhitelabelProvider;
