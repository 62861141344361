/**
 * Married status data objects used in
 * onboarding flow and profile settings flow.
 */
export const maritalStatuses = [
  "-",
  "Never married",
  "Unmarried and parents not living together",
  "Unmarried and parents living together",
  "Married",
  "Divorced or separated",
  "Remarried",
  "Widowed",
];

/**
 * Filing statuses for married persons
 */
export const marriedOptions = [
  "-",
  "Married filing jointly",
  "Married filing separately",
  "Not required to file Federal taxes",
];

/**
 * Filing statuses for unmarried persons
 */
export const unmarriedOptions = [
  "-",
  "Single",
  "Head of household",
  "Qualifying widow(er) with dependent child",
  "Not required to file Federal taxes",
];

/**
 * @param {string} maritalStatus
 * @returns {boolean}
 */
export const hasSpouse = (maritalStatus) => {
  return (
    maritalStatus === "Married" ||
    maritalStatus === "Remarried" ||
    maritalStatus === "Unmarried and parents living together"
  );
};

/**
 * @param {string} maritalStatus
 * @returns {boolean}
 */
export const hasExSpouse = (maritalStatus) => {
  return (
    maritalStatus === "Unmarried and parents not living together" ||
    maritalStatus === "Divorced or separated" ||
    maritalStatus === "Remarried"
  );
};

/**
 * @param {string} filingStatus
 * @returns {boolean}
 */
export const isFilingJointly = (filingStatus, maritalStatus) => {
  return (
    hasSpouse(maritalStatus) &&
    ["Married filing jointly", "Not required to file Federal taxes"].includes(
      filingStatus
    )
  );
};

/**
 * @param {string} filingStatus
 * @returns {boolean}
 */
export const isFilingSeparately = (filingStatus) => {
  return filingStatus === "Married filing separately";
};
