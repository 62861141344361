import { useEffect, useRef } from "react";

const Collapsible = ({ children, expanded }) => {
  //This component is used for all collapsible pieces of cards, drawers, etc.
  //To use, the component that contains this component should have a useState for "expanded" and some method to change expanded to true or false
  const collapsibleRef = useRef();
  const collapsibleContainerRef = useRef();

  //Use inner div to calculate height parent should be when expanded.
  //This allows children of any height. Otherwise transitions need a hardcoded height value.
  const expand = () => {
    if (collapsibleContainerRef.current) {
      collapsibleContainerRef.current.style.display = "block";
      collapsibleContainerRef.current.style.height = `${collapsibleRef.current.offsetHeight}px`;
    }
  };

  const collapse = () => {
    if (collapsibleContainerRef.current) {
      collapsibleContainerRef.current.style.height = "0";
      collapsibleContainerRef.current.style.display = "none";
    }
  };

  useEffect(() => {
    !expanded ? collapse() : expand();
  }, [expanded, collapsibleRef.current]);

  return (
    <div className="collapsible" ref={collapsibleContainerRef}>
      <div
        ref={collapsibleRef}
        className={`flex flex-col ${
          //Hide component until ref is ready
          !collapsibleContainerRef?.current ? "hidden" : "block"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
