import { useAppContext } from "@context/state";
import AddOfferCard from "./AddOfferCard";
import OfferCard from "./OfferCard";
import { Section, TalkToAdvisor } from "@components/index";

/**
 * Show Offer Cards in gallery
 * @returns Translate & Compare page components (Translate & Compare Offers)
 */
const TranslateCompare = () => {
  const { userOffers } = useAppContext();

  const renderOffers = () => {
    //map offers to cards
    const offers = userOffers.map((offer) => {
      return (
        <OfferCard key={offer.id} offerData={offer} showMoreDetails={true} />
      );
    });
    offers.push(<AddOfferCard key="add_offer" />);
    return offers;
  };

  return (
    <div className="offers-container sectionsContainer flex flex-col w-full">
      <div className="background" />
      <h2 className="desktop-header hidden lg:block">Translate & Compare</h2>
      <div className="header-text text-center">
        Don&apos;t take the first offer! It&apos;s often
        <br /> possible to appeal for more $$.
      </div>
      <Section galleryName={"offer-gallery"} empty={false}>
        {userOffers.length === 0 ? <AddOfferCard /> : <>{renderOffers()}</>}
      </Section>
      <TalkToAdvisor
        title="Help Me Appeal for More $$"
        description1="We are experts in negotiating with colleges for more money."
      />
    </div>
  );
};

export default TranslateCompare;
