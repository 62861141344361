import Link from "next/link";
import { useRef } from "react";
import { Card } from "@components/index";
import { useAppContext } from "@context/state";
import { processUSD } from "@utils/costEstimates";
import renderLogo from "@utils/renderLogo";
import { useRouter } from "next/router";
import { RadialProgress } from "@components/index";

/**
 * @param {object} offerData
 * @returns Basic version of how to pay card for dashboard and simplified view of How To Pay page
 */
const SchoolCard = ({ offerData }) => {
  if (!offerData?.tuition_budget) {
    return null;
  }
  const cardRef = useRef();
  const { school, tuition_budget_sandbox } = offerData;
  const { school_id, name, logo, average_monthly_take_home } = school;
  const {
    percent_funded = 0,
    loan_repayment_details = {
      year_1_loans: 0,
      total_10: 0,
      payment_10: 0,
      total_25: 0,
      payment_25: 0,
    },
  } = tuition_budget_sandbox ?? {};
  const { year_1_loans, total_10, payment_10, total_25, payment_25 } =
    loan_repayment_details;

  const { scenario, setScrollToCard, setHowToPaySchool } = useAppContext();
  //NOTE: intended_major_object rather than intended_major, since we wanted to return more info related to the chosen major without breaking things that previously relied on intended_major being an integer.
  const major = scenario?.onboarding?.student?.intended_major_object;

  const router = useRouter();

  const handleEditButton = () => {
    //Set scroll left default for how_to_pay section
    setHowToPaySchool(school);
    //set index of card to scroll to
    const cardGalleryIndex = [...cardRef.current.parentNode.children].indexOf(
      cardRef.current
    );
    setScrollToCard(cardGalleryIndex);
    //if not on how to pay page, navigate there
    router.push("/how_to_pay");
  };

  return (
    <div className="cardContainer" ref={cardRef}>
      <Card type="typeTall">
        <div className={`flex flex-col justify-between h-full`}>
          <div className="topHeading">
            <div className="flex flex-row justify-between items-start">
              {renderLogo(logo, name)}
              <button
                onClick={handleEditButton}
                className="imitateLink flex items-start"
              >
                <small>
                  <b>Edit</b>
                </small>
              </button>
            </div>
            <div className="h4">
              <Link
                href={`/case/${scenario.case_id}/school/${[school_id]}`}
                className="underline inherit">
                {name}
              </Link>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="estimates mt-2">
              <div className="flex flex-row items-center w-full">
                <div className="flex flex-col w-1/2">
                  <RadialProgress
                    progressPercent={percent_funded}
                    textContent="Funded"
                  />
                </div>
                <div className="right-side flex flex-col w-1/2">
                  <small className="year-one-loans font-demi-bold">
                    Year 1 Loans
                  </small>
                  <div>{processUSD(year_1_loans || 0)}</div>
                </div>
              </div>
            </div>
            <div className="divider" />
            <div className="estimates flex flex-col">
              <small className="title font-demi-bold">
                Total Repayment for 10-year plan
              </small>
              <div className="flex flex-row w-full">
                <div className="flex flex-col w-1/2">
                  <small>Full Amount</small>
                  <div>{processUSD(total_10 || 0)}</div>
                </div>
                <div className="right-side flex flex-col w-1/2">
                  <small>Per Month</small>
                  <div>{processUSD(payment_10 || 0, true)}</div>
                </div>
              </div>
              <small className="title font-demi-bold">
                Total Repayment for 25-year plan
              </small>
              <div className="flex flex-row w-full">
                <div className="flex flex-col w-1/2">
                  <small>Full Amount</small>
                  <div>{processUSD(total_25 || 0)}</div>
                </div>
                <div className="right-side flex flex-col w-1/2">
                  <small>Per Month</small>
                  <div>{processUSD(payment_25 || 0, true)}</div>
                </div>
              </div>
              <small className="title font-demi-bold">
                Estimated Take-Home Pay
              </small>
              <div className="flex flex-row w-full">
                <div className="flex flex-col w-1/2">
                  <small>Major</small>
                  <div>{major?.name}</div>
                </div>
                <div className="right-side flex flex-col w-1/2">
                  <small>Per Month</small>
                  <div>{processUSD(average_monthly_take_home || 0)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SchoolCard;
