const UpArrow = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Arrow/Down/Blue"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M3.29289322,0.292893219 C3.65337718,-0.0675907428 4.22060824,-0.0953202783 4.61289944,0.209704612 L4.70710678,0.292893219 L11.4142136,7 L4.70710678,13.7071068 C4.31658249,14.0976311 3.68341751,14.0976311 3.29289322,13.7071068 C2.93240926,13.3466228 2.90467972,12.7793918 3.20970461,12.3871006 L3.29289322,12.2928932 L8.585,7 L3.29289322,1.70710678 C2.93240926,1.34662282 2.90467972,0.779391764 3.20970461,0.387100557 L3.29289322,0.292893219 Z"
            id="Arrow"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(7.207107, 7.000000) rotate(-90.000000) translate(-7.207107, -7.000000) "
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default UpArrow;
