import { useAppContext } from "@context/state";

import { EmptyCard, Section } from "@components/index";
import SchoolCard from "./SchoolCard";
import AddSchoolCard from "./AddSchoolCard";

const ShopForSchools = ( ) => {
  const { userSchools, scenario } = useAppContext();

  const renderSchools = () => {
    //map schools to cards and include add school cards
    const schools = userSchools.map((school) => (
      <SchoolCard key={school.school_id} schoolData={school} />
    ));
    schools.push(<AddSchoolCard key="add1" />);
    if (schools.length < 3) {
      schools.push(<AddSchoolCard key="add2" />);
    }
    return schools;
  };

  // Set the search url to the school search page with the case id and default search params
  // state is xx because we aren't in the advanced search yet.
  const case_id = scenario?.case_id;
  const Url = `/school_search/${case_id}/state=xx&limit=10&offset=0&order=net-cost`;

  return (
    <Section
      title={"My Schools"}
      galleryName={"schools-gallery"}
      empty={!userSchools.some((school) => school.visible === true)}
    >
      {!userSchools.some((school) => school.visible === true) ? (
        <EmptyCard route={Url} />
      ) : (
        <>{renderSchools()}</>
      )}
    </Section>
  );
};

export default ShopForSchools;
