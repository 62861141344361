/**
 * get tid from cookies for chargebee tracking script
 * @param - none
 */
const getTidCookie = () => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("_fprom_tid="));

  if (cookieValue) {
    return cookieValue.split("=")[1];
  } else {
    return undefined;
  }
};

export default getTidCookie;
