import BreakdownRow from "./BreakdownRow";
import BreakdownDrawer from "./BreakdownDrawer";
import { getLoanTotal } from "@utils/calcHowToPay";

const LoanDrawer = ({ budgetData, watchInt }) => {
  const {
    // direct_student_loan_1,
    unsubsidized_student_loan_1,
    federal_direct_student_loan_1,
    private_loan_1,
    // perkins_loan_1,
    parent_plus_loan_1,
    other_loan_1,
  } = budgetData;

  return (
    <BreakdownDrawer
      title={<span className="font-demi-bold">Loan Total</span>}
      status={"Done"}
      forcedValue={getLoanTotal(watchInt)}
      inputId={"loan_total_1"}
    >
      <BreakdownRow
        title="Federal Direct Subsidized Loans"
        defaultValue={federal_direct_student_loan_1}
        inputId={"tuition_budget.federal_direct_student_loan_1"}
        type="Award"
      />
      <BreakdownRow
        title="Federal Direct Unsubsidized Loans"
        defaultValue={unsubsidized_student_loan_1}
        inputId={"tuition_budget.unsubsidized_student_loan_1"}
        type="Award"
      />
      <BreakdownRow
        title="Private Student Loan"
        defaultValue={private_loan_1}
        inputId={"tuition_budget.private_loan_1"}
        type="Loan"
      />
      <BreakdownRow
        title="Federal Direct Parent Plus Loan"
        defaultValue={parent_plus_loan_1}
        inputId={"tuition_budget.parent_plus_loan_1"}
        type="Loan"
      />
      <BreakdownRow
        title="HELOC / Other Loans"
        defaultValue={other_loan_1}
        inputId={"tuition_budget.other_loan_1"}
        type="Loan"
      />
    </BreakdownDrawer>
  );
};

export default LoanDrawer;
