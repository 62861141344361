import { useEffect } from "react";
import { useRouter } from "next/router";
import {
  UNPROTECTED_PATHS,
  AUTH_NAV_PATHS,
  IEC_RESTRICTED_PATHS,
} from "@utils/constants";
import { useAppContext } from "@context/state";
import ClipLoader from "react-spinners/ClipLoader";
import { settings } from "@whitelabel/whitelabel.preval.js";

/**
 * Handle protected pages
 * @param {*} children - Layout component
 * @returns
 */
const PrivateRoute = ({ children }) => {
  const router = useRouter();
  const {
    siteLoading,
    setSiteLoading,
    loggedIn,
    setLoggedIn,
    isIecAndImpersonating,
    handleUserToken,
  } = useAppContext();

  const pathIsProtected = !UNPROTECTED_PATHS.includes(router.pathname);
  const pathIsIecRestricted = IEC_RESTRICTED_PATHS.includes(router.pathname);
  const pathIsWhitelabelRestricted =
    process.env.NEXT_PUBLIC_WHITELABEL === "true"
      ? settings?.disabledRoutes.includes(router.pathname)
      : false;

  useEffect(() => {
    const tokenIsValid = handleUserToken();
    // Allows refreshing, and prevents visiting pages without authentication
    if (!AUTH_NAV_PATHS.includes(router.pathname)) {
      //Run check to see if token needs to be refreshed.
      if (!loggedIn && tokenIsValid) {
        setLoggedIn(true);
      }
      if (siteLoading) {
        setSiteLoading(false);
      }
    }
    //Redirect if visiting auth paths while logged in
    if (tokenIsValid && AUTH_NAV_PATHS.includes(router.pathname)) {
      router.push("/");
    }
  }, [router.pathname]);

  useEffect(() => {
    //whitelabeled site must have separate logic for "redirect from restricted paths" due to additional auth verifiers for checking logged in state.
    if (process.env.NEXT_PUBLIC_WHITELABEL === "true") {
      if (pathIsWhitelabelRestricted) {
        //TODO: If IEC impersonation feature ever included on whitelabel, maybe add "||(isIecAndImpersonating && pathIsIecRestricted)" to check
        //redirect to base path if on restricted page
        router.push("/");
      }
    } else {
      if (
        (!siteLoading && !loggedIn && pathIsProtected) ||
        (isIecAndImpersonating && pathIsIecRestricted)
      ) {
        //redirect to base path if on restricted page
        router.push("/");
      }
    }
  }, [
    siteLoading,
    loggedIn,
    isIecAndImpersonating,
    pathIsProtected,
    pathIsIecRestricted,
    pathIsWhitelabelRestricted,
  ]);

  //Do not show page if restricted
  if (
    (!loggedIn && pathIsProtected) ||
    (isIecAndImpersonating && pathIsIecRestricted) ||
    (process.env.NEXT_PUBLIC_WHITELABEL === "true" &&
      pathIsWhitelabelRestricted)
  ) {
    return (
      <div className="page-loader">
        <ClipLoader
          size={50}
          css={{
            borderColor: "var(--primary-color)",
            borderBottomColor: "transparent",
          }}
        />
      </div>
    );
  }

  return children;
};

export default PrivateRoute;
