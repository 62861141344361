import { processUSD } from "@utils/costEstimates";
import OfferCardField from "./OfferCardField";

/**
 * @param {object} capProjections
 * @param {object} tuitionBudget
 * @param {object} decidedDocumentJob
 * @returns Award fields on offer card
 */
const OfferCardCosts = ({
  capProjections,
  tuitionBudget,
  decidedDocumentJob,
}) => {
  /**
   * calculate value from decided document job
   * @param {array} costAmounts
   * @param {function} filterFn
   * @returns {Number}
   */
  const calculateTotalCost = (costAmounts, filterFn) =>
    costAmounts
      ?.filter(filterFn)
      .reduce((total, cost) => total + cost.value, 0);

  //cost of attendance will likely need to be summed on FE due to potential for differing sources of values
  return (
    <div className="flex flex-col w-full mb-2">
      <div className="flex flex-col w-full mb-1">
        <h6 className="mb-0.5">Cost of Attendance</h6>
        <h5 className="removeMargin">
          {processUSD(
            tuitionBudget?.fillable_fields?.coa_1 +
              tuitionBudget?.fillable_fields?.room_and_board_1 +
              tuitionBudget?.fillable_fields?.books_and_supplies_1 +
              tuitionBudget?.fillable_fields?.travel_and_misc_1 +
              tuitionBudget?.fillable_fields?.other_fees_1
          )}
        </h5>
      </div>
      <OfferCardField
        title={"Tuition"}
        tuitionBudgetValue={tuitionBudget?.fillable_fields?.coa_1}
        capProjectionValue={capProjections?.fillable_fields?.coa_1}
        decidedDocumentJobValue={calculateTotalCost(
          decidedDocumentJob?.cost_amounts,
          (cost) => cost.categories.includes("TUITION")
        )}
      />
      <OfferCardField
        title={"Room & Board"}
        tuitionBudgetValue={tuitionBudget?.fillable_fields?.room_and_board_1}
        capProjectionValue={capProjections?.fillable_fields?.room_and_board_1}
        decidedDocumentJobValue={calculateTotalCost(
          decidedDocumentJob?.cost_amounts,
          (cost) =>
            cost.categories.includes("HOUSING") ||
            cost.categories.includes("MEALS")
        )}
      />
      <OfferCardField
        title={"Books & Supplies"}
        tuitionBudgetValue={
          tuitionBudget?.fillable_fields?.books_and_supplies_1
        }
        capProjectionValue={
          capProjections?.fillable_fields?.books_and_supplies_1
        }
        decidedDocumentJobValue={calculateTotalCost(
          decidedDocumentJob?.cost_amounts,
          (cost) => cost.categories.includes("BOOKS_AND_SUPPLIES")
        )}
      />
      <OfferCardField
        title={"Travel & Misc."}
        tuitionBudgetValue={tuitionBudget?.fillable_fields?.travel_and_misc_1}
        capProjectionValue={capProjections?.fillable_fields?.travel_and_misc_1}
        decidedDocumentJobValue={calculateTotalCost(
          decidedDocumentJob?.cost_amounts,
          (cost) =>
            !cost.categories.includes("BOOKS_AND_SUPPLIES") &&
            (cost.categories.includes("TRANSPORT") ||
              cost.categories.includes("PERSONAL"))
        )}
      />
      <OfferCardField
        title={"Other Fees"}
        tuitionBudgetValue={tuitionBudget?.fillable_fields?.other_fees_1}
        capProjectionValue={capProjections?.fillable_fields?.other_fees_1}
        decidedDocumentJobValue={calculateTotalCost(
          decidedDocumentJob?.cost_amounts,
          (cost) =>
            cost.categories.length === 1 &&
            cost.categories.every((category) => category === "FEES")
        )}
      />
    </div>
  );
};

export default OfferCardCosts;
