import _ from "lodash";
import { useAppContext } from "@context/state";
import { useFormContext } from "react-hook-form";
import { HelpButton } from "@components/index";

/**
 * Compnent return a range custom range input field
 * For values like student gpa score.
 * @param {object} input - Values for input field. For more reference
 */
const RangeInputField = ({ input }) => {
  const { register, watch, setValue } = useFormContext();
  const { scenario } = useAppContext();
  const barWatch = watch(input.mapId) || input.value || input.min;
  const studentName =
    _.get(scenario, "onboarding.student.student_name") || "Student";

  const displayInput = () => {
    if (`${parseFloat(barWatch)}` === `${input.min}`) {
      return "N/A";
    } else {
      if (
        input.mapId === "student.scores.gpa" ||
        input.mapId === "student.scores.weighted_gpa"
      ) {
        return parseFloat(barWatch).toFixed(1);
      } else {
        return barWatch;
      }
    }
  };

  return (
    <div className="inputItem">
      <div className="sliderText flex flex-row">
        <label className="w-full flex justify-between">
          <h5>{`${studentName}'s ${input.label}`}</h5>
          <div className="h2">{displayInput()}</div>
        </label>
        {input.tipsData && (
          <div className="flex items-center ml-1">
            <HelpButton
              helpContent={[
                {
                  id: 0,
                  title: input.label,
                  description: input.tipsData,
                },
              ]}
              className="small mb-0.5"
            />
          </div>
        )}
      </div>
      <div className="sliderProgressContainer">
        <div className="sliderProgressBar">
          <div
            className="sliderProgress"
            style={{
              width: input.calculateBarWidth(barWatch),
            }}
          />
        </div>
      </div>
      <input
        id={input.mapId}
        type={input.type}
        min={input.min}
        max={input.max}
        step={input.step}
        value={barWatch || input.min}
        {...register(input.mapId, {
          ...(input.validation || null),
        })}
        // Override register onchange to
        // keep range min value as empty for sat and act scores
        // and 0.00 for gpa score.
        onChange={(event) => {
          event.target.value === input.min
            ? setValue(input.mapId, input.minRequiredValue, {
                shouldDirty: true,
              })
            : setValue(input.mapId, event.target.value, { shouldDirty: true });
        }}
      />
      <div className="minMax flex justify-between">
        <span>N/A</span>
        <span>{input.max}</span>
      </div>
    </div>
  );
};

export default RangeInputField;
