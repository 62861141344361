import _ from "lodash";
import { Checkmark } from "@icons/index";
import { Card, Collapsible, DropdownBtn } from "@components/index";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "@context/state";
import renderLogo from "@utils/renderLogo";
import { processUSD } from "@utils/costEstimates";

const AdvancedSchoolCard = (props) => {
  const { schoolData } = props;

  const {
    college_id,
    name,
    logo,
    city,
    undergraduate_enrollment,
    forbes_ranking,
    cost_of_attendance, //Only in advanced search
    net_cost, //Only in advanced search
    need_met_percentage,
    merit_scholarship,
    four_year_estimated_cost,
    funding_gap,
    affordability,
  } = schoolData;

  const {
    scenario,
    userSchools,
    advancedSearchListChecked,
    setAdvancedSearchListChecked,
  } = useAppContext();

  const showUpgradeModal = () => {
    if (upgraded) {
      return;
    } else {
      setModalView(PREMIUM_VIEW);
      setDisplayModal(true);
    }
  };


  //Checkbox
  const checkboxRef = useRef();

  const [expanded, setExpanded] = useState(false);
  const { upgraded, setDisplayModal, setModalView } = useAppContext();

  const addSchool = () => {
    setAdvancedSearchListChecked((cur) => [...cur, `${college_id}`]);
  };

  const removeSchool = () => {
    const newSearchList = advancedSearchListChecked;
    const index = advancedSearchListChecked.indexOf(`${college_id}`);
    if (index > -1) {
      newSearchList.splice(index, 1);
      setAdvancedSearchListChecked(newSearchList);
    }
  };

  const toggleCheckbox = (event) => {
    //post or delete school from user's scenario
    if (event.target.checked) {
      addSchool();
    } else {
      removeSchool();
    }
  };

  useEffect(() => {
    //Initialize checkbox state
    if (checkboxRef.current) {
      // In advanced search response we get college_id instead of school_id which is
      // identical, but is an integer value, so convert to string to match in userSchools
      //  if exist, for settig up the initial state of checkbox.
      const id = `${college_id}`;
      if (_.find(userSchools, { school_id: id })) {
        checkboxRef.current.checked = true;
      }
    }
  }, [checkboxRef]);

  const schoolMoreInformation = [
    { label: "Location", value: city },
    {
      label: "Enrollment",
      value: `${undergraduate_enrollment.toLocaleString()}`,
    },
    {
      label: "Forbes Ranking",
      value: forbes_ranking === 0 ? "Not Reported" : `#${forbes_ranking}`,
    },
    { label: "COA", value: processUSD(cost_of_attendance) },
    { label: "Net Cost", value: processUSD(net_cost) },
    {
      label: "Need Met",
      value:
        need_met_percentage !== undefined
          ? `${need_met_percentage}%`
          : "Not Reported",
    },
    {
      label: "Merit Scholarship",
      value: processUSD(merit_scholarship),
    },
    {
      label: "4 Year Est Cost",
      value: processUSD(four_year_estimated_cost),
    },
  ];

  return (
    <div className="cardContainer">
      <Card type="typeShort">
        <div className="flex flex-col">
          <div className="advancedUpperInfo flex flex-row justify-between">
            <div className="flex flex-col relative md:flex-row md:items-center md:w-10/12">
              <div className="topHeading md:w-2/5">
                <div className="flex flex-row items-center">
                  {renderLogo(logo, name)}
                  <div className="h6">
                    <form>
                      <input type="hidden" name="college_id" value={college_id} />
                      <input type="hidden" name="scenario_id" value={scenario.id} />
                      <button onClick={showUpgradeModal}>{name}</button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="affordability flex items-center md:justify-start">
                <div className="flex items-center">
                  <span className="micro">Affordability</span>
                  <span
                    className={`micro status ${affordability.toLowerCase()} flex items-center justify-center`}
                  >
                    {affordability}
                  </span>
                </div>
              </div>
              <div className="quote flex md:justify-start">
                <small>
                  <b>
                    Funding Gap:{" "}
                    {funding_gap !== undefined
                      ? processUSD(funding_gap)
                      : "Not Reported"}
                  </b>
                </small>
              </div>
            </div>
            <div className="flex flex-col h-full justify-between items-end md:flex-row md:items-center md:justify-start md:w-2/12">
              <label className="checkbox md:mx-3">
                <input
                  type="checkbox"
                  ref={checkboxRef}
                  onChange={toggleCheckbox}
                />
                <span className="checkmark">
                  <Checkmark />
                </span>
              </label>
              <DropdownBtn
                className="p-1 -m-1 md:ml-4"
                onClick={() => setExpanded((cur) => !cur)}
                expanded={expanded}
              />
            </div>
          </div>
          <Collapsible expanded={expanded}>
            <div className="flex flex-wrap pt-4">
              {schoolMoreInformation.map((data, index) => (
                <div className="moreInfoCell" key={index}>
                  <small>{data.label}</small>
                  <p>{data.value}</p>
                </div>
              ))}
            </div>
          </Collapsible>
        </div>
      </Card >
    </div >
  );
};

export default AdvancedSchoolCard;
