import { useAppContext } from "@context/state.js";
import { useRouter } from "next/router";

const PremiumModal = () => {
  const { setDisplayModal } = useAppContext();

  const router = useRouter();

  const premiumUnavailable = false;

  return (
    <div className="modal">
      <div className="modal-scroll">
        <div className="modal-content">
          <div className="title mb-4 md:mb-2">
            <h3>Premium Feature</h3>
          </div>
          <div className="infoText h5 mb-4 md:mb-6">
            This is a Premium Feature. Consider upgrading to access the full
            potential of the College Aid Pro Platform.
          </div>
          <button
            className="upgradeBtn primary"
            disabled={premiumUnavailable}
            onClick={() => {
              router.push("/upgrade_plan");
              setDisplayModal(false);
            }}
          >
            {premiumUnavailable ? "Coming Soon!" : "Yes, I Want To Upgrade!"}
          </button>
          <button
            type="button"
            className="noThanksBtn"
            onClick={() => setDisplayModal((cur) => !cur)}
          >
            No Thanks
          </button>
        </div>
      </div>
    </div>
  );
};

export default PremiumModal;
