import _ from "lodash";
import { useEffect } from "react";
import ProgressBar from "../ProgressBar.js";
import NumberFormat from "react-number-format";
import { hasSpouse, hasExSpouse } from "@utils/maritalStatusCheck";
import { infoTips } from "@utils/infoTips";

const Step11 = ({
  formData,
  formStep,
  setModalContent,
  setWatchFields,
  register,
  trigger,
  getValues,
  setValue,
}) => {
  if (formStep !== 13) {
    // Don't render unless step matches
    return null;
  }

  const maritalStatus = getValues("parent_guardian.marital_status");

  useEffect(() => {
    trigger([
      "parent_guardian.parent_earnings",
      "parent_guardian.parent_earnings_2",
    ]);
    setWatchFields(["parent_guardian"]);
    //Set content for help modal
    setModalContent([
      {
        id: 0,
        title: "Parent/Guardian Gross Earnings",
        description: _.get(
          infoTips,
          "scenario.onboarding.parent_guardian.parent_earnings"
        ),
      },
    ]);
  }, []);

  return (
    <div className="guardianInfoContainer onboardingScreen flex flex-col items-center justify-start text-center">
      <ProgressBar formStep={formStep - 1} />
      <div className="guardianInfoText">
        <h5>
          What are {_.get(formData, "parent_guardian.parent_name") || "Parent"}
          &apos;s Individual Earnings?
        </h5>
      </div>
      <div className="inputs flex flex-col text-left">
        <label
          htmlFor="parent_guardian.parent_earnings"
          className="textLabel currencyLabel small"
        >
          <span className="labelFocus">Your Gross Earnings</span>
          <NumberFormat
            value={getValues("parent_guardian.parent_earnings")}
            {...register("parent_guardian.parent_earnings", {
              required: {
                value: true,
                message: "Please enter individual's gross earnings",
              },
            })}
            onValueChange={({ value }) => {
              setValue("parent_guardian.parent_earnings", value);
              trigger("parent_guardian.parent_earnings");
            }}
            thousandSeparator={true}
          />
          <div className="currency resetFont">$</div>
        </label>
        {hasSpouse(maritalStatus) && (
          <label
            htmlFor="parent_guardian.parent_earnings_2"
            className="textLabel currencyLabel small"
          >
            <span className="labelFocus">Your Spouse&apos;s Earnings</span>
            <NumberFormat
              value={getValues("parent_guardian.parent_earnings_2")}
              {...register("parent_guardian.parent_earnings_2", {
                required: {
                  value: true,
                  message: "Please enter individual's gross earnings",
                },
              })}
              onValueChange={({ value }) => {
                setValue("parent_guardian.parent_earnings_2", value);
                trigger("parent_guardian.parent_earnings_2");
              }}
              thousandSeparator={true}
            />
            <div className="currency resetFont">$</div>
          </label>
        )}
        {hasExSpouse(maritalStatus) && (
          <label
            htmlFor="parent_guardian.parent_earnings_3"
            className="textLabel currencyLabel small"
          >
            <span className="labelFocus">Your Ex-Spouse&apos;s Earnings</span>
            <NumberFormat
              value={getValues("parent_guardian.parent_earnings_3")}
              {...register("parent_guardian.parent_earnings_3", {
                required: {
                  value: true,
                  message: "Please enter individual's gross earnings",
                },
              })}
              onValueChange={({ value }) => {
                setValue("parent_guardian.parent_earnings_3", value);
                trigger("parent_guardian.parent_earnings_3");
              }}
              thousandSeparator={true}
            />
            <div className="currency resetFont">$</div>
          </label>
        )}
      </div>
    </div>
  );
};

export default Step11;
