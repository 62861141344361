import FactorListItem from "./FactorListItem";

/**
 * @param {object} category - Appeal category evaluations
 * @param {boolean} showMoreDetails
 * @returns Unordered list of applicable factor categories
 */
const AppealFactorsList = ({ category, showMoreDetails }) => {
  return (
    <>
      <ul className="my-2 font-demi-bold">
        <FactorListItem
          category={category.amount}
          title="Amount Awarded"
          showMoreDetails={showMoreDetails}
        />
        <FactorListItem
          category={category.school}
          title="Institution's Methodology"
          showMoreDetails={showMoreDetails}
        />
        <FactorListItem
          category={category.family}
          title="Family Considerations"
          showMoreDetails={showMoreDetails}
        />
        <FactorListItem
          category={category.unique}
          title="Unique Circumstances"
          showMoreDetails={showMoreDetails}
        />
      </ul>
    </>
  );
};

export default AppealFactorsList;
