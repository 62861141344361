import _ from "lodash";
import { AppWrapper } from "@context/state";
import Layout from "@components/Layout/Layout";
import WindowFocusHandler from "@components/Layout/WindowFocusHandler";
import "../styles/global.scss";
import PrivateRoute from "@components/Auth/PrivateRoute";
import WhitelabelProvider from "@components/Auth/WhitelabelProvider";
import { Auth0Provider } from "@auth0/auth0-react";

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
        redirectUri={`${process.env.NEXT_PUBLIC_URL_PROTOCOL}//${process.env.NEXT_PUBLIC_VERCEL_URL}`}
        audience={`${process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL}/api/v2/`}
        scope="openid"
        useRefreshTokens={true}
      >
        <AppWrapper>
          <WhitelabelProvider>
            <WindowFocusHandler>
              <PrivateRoute>
                <Layout
                  data={_.get(pageProps, "contentfulData") || ""}
                  {...pageProps}
                >
                  <Component {...pageProps} />
                </Layout>
              </PrivateRoute>
            </WindowFocusHandler>
          </WhitelabelProvider>
        </AppWrapper>
      </Auth0Provider>
    </>
  );
}

export default MyApp;
