import { PlanCard } from "@components/index";
import { useEffect, useRef } from "react";
import { useAppContext } from "@context/state";
import _ from "lodash";
import {
  MYCAP_PREMIUM_PLUS_PRICE_ID,
  MYCAP_PREMIUM_RENEWAL_PRICE_ID,
  MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID,
  SUBSCRIPTION_RENEWAL,
} from "@utils/constants";
import { useRouter } from "next/router";
import { plans } from "@utils/plans";
import { animateScroll as scroll } from "react-scroll";

const RenewalPlan = () => {
  const router = useRouter();
  const { scenario, upgraded, setModalView, setDisplayModal } = useAppContext();
  const optionsRef = useRef();

  const { downgrade, renew } = plans;
  const { subscription_info } = scenario;

  /**
   * Alter renewal plan details if user is already a valedictorian
   * NOTE: since neither portal nor checkout is opened, this useEffect may be pointless
   */
  useEffect(() => {
    if (
      _.get(scenario, "subscription_info.price_id") ===
      MYCAP_PREMIUM_PLUS_PRICE_ID
    ) {
      renew.planPrices[0].type = "Valedictorian";
      renew.planPrices[0].dataCbItem = MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID;
    }
  }, [scenario]);

  const freePlanFunction = () => {
    router.push("/");
  };

  /**
   * Open confirmation modal
   */
  const openRenewalModal = () => {
    // setModalContent();
    setModalView(SUBSCRIPTION_RENEWAL);
    setDisplayModal(true);
  };

  const isPriceId = (toComparePriceId) => {
    return subscription_info?.price_id === toComparePriceId;
  };

  // Set disabled state for the button on each plan card.
  const renewalActive =
    isPriceId(MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID) ||
    isPriceId(MYCAP_PREMIUM_RENEWAL_PRICE_ID);

  useEffect(() => {
    if (optionsRef.current) {
      //get position based on index instead of matching the offset to bypass need to match the layout and size of cards
      const scrollToPosition = optionsRef.current?.children[0]?.offsetWidth;
      const offset = -12; //account for padding space between cards
      scroll.scrollMore(scrollToPosition + offset, {
        duration: 750,
        horizontal: true,
        smooth: "easeOutCubic",
        containerId: "renewalOptions",
        ignoreCancelEvents: true,
      });
    }
  }, [optionsRef.current]);

  if (!upgraded) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col items-center text-center mb-1 lg:mb-2">
        <h1>Renew your plan</h1>
        <h5 className="hidden lg:block">
          Keep your college planning profile active
        </h5>
      </div>
      {!_.isEmpty(scenario) && (
        <div className="gridContainer">
          <div className="cardsContainer" id="renewalOptions" ref={optionsRef}>
            <PlanCard
              active={renewalActive}
              plan={downgrade}
              unavailable={renewalActive}
              freePlanFunction={freePlanFunction}
              freeButtonText="Don't Renew"
            />
            <PlanCard
              plan={renew}
              active={renewalActive}
              unavailable={renewalActive}
              recommended
              planFunction={openRenewalModal}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RenewalPlan;
