import axiosInstance from "@api/axiosInstance";

//******************************************//
//************ ----- PROFILE ----- ************//
//******************************************//

/**
 *  Update profile progress in the database.  Only the profile progress
 * is being updated in the database for v1.  The other fields are for
 * future iterations.
 */
export const updateProfileProgress = async (scenarioId, progress) => {
  const data = {
    scenario_id: scenarioId,
    profile_percent_complete: progress,
    school_selection: "0",
    affordability: "0",
    financial_aid_forms: "0",
    compare_awards: "0",
    appeals_evaluation: "0",
    how_to_pay_the_bill: "0",
    how_to_borrow: "0",
    now_what: "0",
    last_page: "0",
  };
  const res = await axiosInstance.post(
    `/scenario_progress/${scenarioId}`,
    data
  );
  return res;
};
