import { Awards } from "@icons/index";
import { Card, Section } from "@components/index";
import { ssoRedirect } from "@api/ssoApi";
import { SSO_SCHOLARSHIPS_PATH } from "@utils/constants";
import { useAppContext } from "@context/state";
import { PREMIUM_VIEW } from "@utils/constants";
import { useRouter } from "next/router";

const Scholarships = () => {
  const { upgraded, setModalView, setDisplayModal } = useAppContext();
  const router = useRouter();

  const openScholarshipSearch = () => {
    if (!upgraded) {
      setModalView(PREMIUM_VIEW);
      setDisplayModal(true);
    } else {
      router.push(SSO_SCHOLARSHIPS_PATH);
    }
  };

  return (
    <Section galleryName={"scholarship-gallery"} empty={true}>
      <Card type="typeDark typeEmpty w-full">
        <div className="emptyCard flex flex-col items-center text-center md:flex-row md:justify-between">
          <div className="flex flex-col items-center md:flex-row">
            <div className="icon">
              <Awards />
            </div>
            <div className="flex flex-col items-center md:items-start md:text-left md:mx-6">
              <h3 className="header">Private Scholarships</h3>
              <span className="description">
                Unlock 5.1 Billion Dollars in Private Scholarships
              </span>
            </div>
          </div>
          <button
            className="tertiary-dark highlight small flex justify-center"
            onClick={openScholarshipSearch}
          >
            Search for Scholarships
          </button>
        </div>
      </Card>
    </Section>
  );
};

export default Scholarships;
