import { Drawer, DrawerRow } from "@components/index";
import ordinalNumber from "@utils/ordinalNumber";
import { processCostOfAttendance, processUSD } from "@utils/costEstimates";
import _ from "lodash";
import { EFC_LABEL } from "@utils/constants";

const FinancialAidDrawer = ({ financialAidData }) => {
  const {
    need_met_percentage,
    percent_receiving_non_need_merit,
    average_non_need_merit,
    breakdowns,
    merit_aid_transparency,
    methodology,
  } = financialAidData;

  return (
    <Drawer title="Financial Aid">
      <DrawerRow
        title={`Cost of Attendance (${_.get(
          breakdowns,
          "[0].year"
        )} projected at 3%)`}
        value={processCostOfAttendance(breakdowns, 0) + "*"}
      />
      <DrawerRow title={`${EFC_LABEL} Basis`} value={methodology} />
      <DrawerRow
        title="Total Need Met %"
        value={need_met_percentage ? `${need_met_percentage}%` : undefined}
      />
      <DrawerRow
        title="% Receiving Non-Need Merit"
        value={
          percent_receiving_non_need_merit
            ? `${percent_receiving_non_need_merit}%`
            : undefined
        }
      />
      <DrawerRow
        title="Merit Aid Transparency"
        value={
          merit_aid_transparency ? (
            <a
              href="https://collegeaidpro.com/merit-scholarship-transparency-methodology/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {"Grade: " + _.get(merit_aid_transparency, "letter_grade")}
            </a>
          ) : undefined
        }
      />
      <DrawerRow
        title="Avg Non-Need Merit"
        value={processUSD(average_non_need_merit)}
      />
    </Drawer>
  );
};

export default FinancialAidDrawer;
