import { useAppContext } from "@context/state";

import { EmptyCard, Section, AffordabilitySchoolCard } from "@components/index";

const Affordability = () => {
  const { userSchools, scenario } = useAppContext();

  const renderSchools = () => {
    //map schools to cards
    const schools = userSchools.map((school) => (
      <AffordabilitySchoolCard
        key={school.school_id}
        schoolData={school}
        showMoreDetails={false}
      />
    ));
    return schools;
  };

  // Set the search url to the school search page with the case id and default search params
  // state is xx because we aren't in the advanced search yet.
  const case_id = scenario?.case_id;
  const Url = `/school_search/${case_id}/state=xx&limit=10&offset=0&order=net-cost`;

  return (
    <div className="affordability-container">
      <Section
        title={"Affordability"}
        galleryName={"affordability-gallery"}
        empty={!userSchools.some((school) => school.visible === true)}
      >
        {!userSchools.some((school) => school.visible === true) ? (
          <EmptyCard
            description={
              "Add a school to learn affordability & financial outcome"
            }
            route={Url}
          />
        ) : (
          <>{renderSchools()}</>
        )}
      </Section>
    </div>
  );
};

export default Affordability;
