import { useAppContext } from "@context/state";

import {
  EmptyCard,
  Section,
  AffordabilitySchoolCard,
  TalkToAdvisor,
} from "@components/index";

import AffordabilityProfile from "./AffordabilityProfile";

const Affordability = () => {
  const { userSchools } = useAppContext();

  const renderSchools = () => {
    //map schools to cards
    const schools = userSchools.map((school) => (
      <AffordabilitySchoolCard
        key={school.school_id}
        schoolData={school}
        showMoreDetails={true}
      />
    ));
    return schools;
  };

  return (
    <div className="affordability-container sectionsContainer flex flex-col w-full">
      <div className="background" />
      <h2 className="desktop-header hidden lg:block">Affordability</h2>
      <div className="header-text text-center">
        Can you attend this college without taking on too much debt?
      </div>
      <Section
        galleryName={"affordability-gallery"}
        empty={!userSchools.some((school) => school.visible === true)}
      >
        {!userSchools.some((school) => school.visible === true) ? (
          <EmptyCard />
        ) : (
          <>{renderSchools()}</>
        )}
      </Section>
      <TalkToAdvisor
        title="Help Me Build a Great List of Affordable Schools"
        description1="Financial Fit is just as important as Academic and Cultural Fit."
        description2="Learn more!"
      />
      <AffordabilityProfile />
    </div>
  );
};

export default Affordability;
