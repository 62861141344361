import { useEffect } from "react";
import { TextField } from "@components/index";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import { requiredValidation } from "@utils/formValidations";
import { HelpButton } from "@components/index";

/**
 * Returns an input based on the type of type of data
 * @param {object} input - Values for input field. For more reference
 * @param {string} label
 * @param {string} mapId
 * @param {number} value
 * @param {string} placeholder
 * @param {string} prefix
 * @param {*} helpText - Can be a string or Element
 * look for info objects in personalData.js file.
 */
const OfferEditInput = ({
  label,
  mapId,
  value,
  placeholder,
  prefix,
  helpText,
}) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  /**
   * After render initialize default values for every
   * input field. Values are received from user preapprovals object.
   */
  useEffect(() => {
    if (value !== undefined) {
      setValue(mapId, value);
    }
  }, []);

  return (
    <div className="mb-3">
      <div
        className={`inputItem ${prefix ? "currencyLabel" : ""} flex flex-col`}
      >
        <div className="labelWrapper flex items-end justify-between">
          <label htmlFor={mapId} className="small">
            {label}
          </label>
          {helpText && (
            <HelpButton
              helpContent={[
                {
                  id: 0,
                  title: label,
                  description: helpText,
                },
              ]}
              className="small mb-0.5 absolute"
            />
          )}
        </div>
        <TextField
          input={{
            mapId,
            type: "text",
            amount: true,
            placeholder,
            validation: requiredValidation,
          }}
        />
        <div className="currency resetFont">{prefix}</div>
      </div>
      {_.get(errors, mapId) && (
        <small className="errorMessage text-left">
          {_.get(errors, mapId).message}
        </small>
      )}
    </div>
  );
};

export default OfferEditInput;
