import { processUSD } from "@utils/costEstimates";

/**
 * If a tuition budget value is present, display that, otherwise display decided document job value and if that is not present, display the cap projected value
 * NOTE: What about zero value fields in tuition budget?
 * @param {string} title
 * @param capProjectionValue
 * @param tuitionBudgetValue
 * @param decidedDocumentJobValue
 * @returns
 */
const OfferCardField = ({
  title,
  capProjectionValue,
  tuitionBudgetValue,
  decidedDocumentJobValue,
}) => {
  return (
    <div className="flex flex-col w-full mb-1">
      <small>{title}</small>
      <div>
        {processUSD(
          typeof tuitionBudgetValue === "number"
            ? tuitionBudgetValue
            : typeof decidedDocumentJobValue === "number"
            ? decidedDocumentJobValue
            : typeof capProjectionValue === "number"
            ? capProjectionValue
            : "0"
        )}
      </div>
    </div>
  );
};

export default OfferCardField;
