import { useAppContext } from "@context/state.js";

const HelpModal = () => {
  const { setDisplayModal, modalContent } = useAppContext();

  const renderContent = () => {
    return [...modalContent].map((content, index) => (
      <div key={content.id} className="flex flex-col items-center">
        {content.title ? (
          <h3 className={modalContent.length <= 1 ? "extraMargin" : ""}>
            {content.title}
          </h3>
        ) : (
          <br />
        )}
        {content.description ? (
          <div
            className={`infoText h5 text-left ${
              modalContent.length - 1 !== index ? "mb-4" : ""
            }`}
          >
            {content.description}
          </div>
        ) : (
          <br />
        )}
      </div>
    ));
  };

  return (
    <div className="modal">
      <div className="modal-scroll">
        <div className="modal-content">
          {modalContent.length > 0 ? renderContent() : ""}
          <button
            type="button"
            className="tertiary hideInfoBtn"
            onClick={() => setDisplayModal((cur) => !cur)}
          >
            Got it
          </button>
        </div>
      </div>
    </div>
  );
};

export default HelpModal;
