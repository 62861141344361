import { useEffect } from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import {
  getFirstPromoterReferralCodeFromUrl,
  getReferralCodeFromUrl,
  setReferralCode,
} from "@utils/getReferralCode";
import { useAuth0 } from "@auth0/auth0-react";
import ClipLoader from "react-spinners/ClipLoader";
import { logo, settings } from "@whitelabel/whitelabel.preval.js";

const SiteEntry = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const referralCode =
      getFirstPromoterReferralCodeFromUrl() || getReferralCodeFromUrl();
    if (referralCode) {
      setReferralCode(referralCode);
    }
  }, []);

  // If whitelabel, skip intiial screens
  // TODO: Do we render something else here?
  if (process.env.NEXT_PUBLIC_WHITELABEL === "true") {
    return (
      <div className="page-loader">
        <ClipLoader
          size={50}
          css={{
            borderColor: "var(--primary-color)",
            borderBottomColor: "transparent",
          }}
        />
      </div>
    );
  }

  return (
    <div className="entryPage flex flex-row h-full w-full">
      <div className="entryPortal flex flex-col items-center text-center w-full md:w-auto">
        <div className="desktop-logo justify-start mb-4 mt-2">
          <div className="relative w-full h-full">
            {process.env.NEXT_PUBLIC_WHITELABEL === "true" ? (
              <div className="whitelabel-logo">
                <Image
                  src={logo}
                  alt={settings?.name}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            ) : (
              <Image
                src="/images/cap_main_logo_white.png"
                alt="CollegeAidPro"
                layout="fill"
                objectFit="contain"
              />
            )}
          </div>
        </div>
        <div className="entryFontBig">THREE</div>
        <div className="entryFontMedium mb-1.5">STEPS</div>
        <div className="entryFontBig">TWO</div>
        <div className="entryFontMedium mb-1">MINUTES</div>
        <div className="divider my-2" />
        <ol className="mt-4">
          <li className="mb-2">CREATE ACCOUNT</li>
          <li className="mb-2">SELECT PLAN</li>
          <li>SAVE ON COLLEGE</li>
        </ol>
        <Link href="/create_account" className="startBtn">

          <button className="primary w-full h-full">
            Get Started for FREE
          </button>

        </Link>
        <span className="font-demi-bold">Already have an account?</span>
        {process.env.NEXT_PUBLIC_WHITELABEL === "true" ? (
          <button onClick={loginWithRedirect} className="loginLink link h6">
            Log In
          </button>
        ) : (
          <Link href="/login" className="loginLink">
            Log In
          </Link>
        )}
      </div>
      <div className="entryImage hidden md:flex flex-col items-center w-full h-full">
        <div className="title mt-4">YOUR AFFORDABLE PATH TO COLLEGE</div>
        <div className="imageFile relative flex flex-col w-full h-full mb-4">
          <Image
            src="/images/site-welcome-medium.png"
            alt="CollegeAidPro"
            layout="fill"
            objectFit="contain"
          />
        </div>
      </div>
    </div>
  );
};

export default SiteEntry;
