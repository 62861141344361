const Cross = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 13 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Icons/Plus/Dark"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <polygon
            id="Plus-Icon"
            fill="currentColor"
            fillRule="nonzero"
            points="7.26470588 13 7.26470588 7.26470588 13 7.26470588 13 5.73529412 7.26470588 5.73529412 7.26470588 0 5.73529412 0 5.73529412 5.73529412 0 5.73529412 0 7.26470588 5.73529412 7.26470588 5.73529412 13"
            transform="translate(6.5, 7.2) rotate(45.000000) translate(-7, -7.000000)"
          ></polygon>
        </g>
      </svg>
    </div>
  );
};

export default Cross;
