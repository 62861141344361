import { CapLogo, LongLeftArrow } from "@icons/index";
import ClipLoader from "react-spinners/ClipLoader";

const OnboardingNav = ({
  formStep,
  setFormStep,
  clearErrors,
  reset,
  saveAndExit,
  loading,
}) => {
  return (
    <div className="navContainer onboardingNav">
      <div className="flex justify-between items-center w-full">
        {/* On first step, back button goes to "/" but after step 1, back button goes to previous step */}
        {formStep === 0 ? (
          <div />
        ) : (
          <button
            type="button"
            className="simple leftBtn"
            onClick={() => {
              reset(
                {
                  // formData
                },
                {
                  keepDefaultValues: true, //Most important flag, sets back to default values which use formData
                }
              );

              clearErrors();
              setFormStep((cur) => cur - 1);
            }}
          >
            <LongLeftArrow />
          </button>
        )}
        {formStep < 17 && formStep > 0 && (
          <>
            {loading ? (
              <div className="save">
                <ClipLoader
                  size={25}
                  css={{
                    borderColor: "var(--primary-color)",
                    borderBottomColor: "transparent",
                  }}
                />
              </div>
            ) : (
              <button
                type="button"
                className="imitateLink save small"
                onClick={saveAndExit}
              >
                Save & Exit
              </button>
            )}
          </>
        )}
      </div>
      {/* <Link  href="/"> */}
      <a className="logo pointer-events-none">
        <button type="button">
          <CapLogo />
        </button>
      </a>
      {/* </Link> */}
    </div>
  );
};

export default OnboardingNav;
