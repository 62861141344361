import _ from "lodash";
import { getYears } from "./dateFormatter";
import { formatDollarAmount } from "./costFormatter";
import { codes } from "iso-country-codes";
import { getKeyValueFromObject } from "@utils/getValueForDisplay";
import { infoTips } from "./infoTips";
import {
  adjustedGrossIncomeField,
  initializeGetDirtyOrOriginalField,
} from "./parentData";

export const zipCodeRegex = /^\d{4,5}(?:[-\s]\d{4})?$/;

const requiredValidation = {
  required: { value: true, message: "Field is required." },
};

/**
 * Get sibling data for a student from onboarding data object
 * @param {object} student - student object that contains siblings data (if found)
 */
const getSiblingData = (student) => {
  if (student?.num_siblings) {
    const siblingsArray = student?.siblings || [];
    return siblingsArray.map((sibling, index) => {
      return {
        label: `Sibling ${index + 1} graduation year`,
        mapId: `student.siblings[${index}].starting_college_year`,
        value: sibling.starting_college_year,
        type: "dropdown",
        selectOptions: getYears().map((year) => {
          return { label: `${year}`, value: year };
        }),
      };
    });
  }
  return [];
};

/**
 * Create sibling graduation field for each sibling which are
 * dependent on student.sibings field value.
 * @param {number} numSiblings - Count of how many siblings exist.
 */
const createSiblingData = (student, numSiblings) => {
  if (numSiblings > 0) {
    const siblingsArray = _.times(numSiblings > 5 ? 5 : numSiblings, null);
    return siblingsArray.map((sibling, index) => {
      return {
        label: `Sibling ${index + 1} graduation year`,
        mapId: `student.siblings[${index}].starting_college_year`,
        value: _.get(student, `siblings[${index}].starting_college_year`),
        type: "dropdown",
        selectOptions: getYears().map((year) => {
          return { label: `${year}`, value: year };
        }),
        validation: requiredValidation,
      };
    });
  }
  return [];
};

/**
 * Method that returns data object with basic information for a user
 * such as student_name and user_type.
 * Generic object is created so can be leveraged both in personal_info
 * view and edit screens.
 * Onboarding flow can also be converted to use this object so we can
 * can manage all the user information from one file.
 * @param {object} onboarding - object received from user scenario that provides
 * all the onboarding information for a user (student object and parent/guardian object).
 */
export const getbasicInfo = (onboarding = {}) => {
  return [
    {
      label: "Current Situation",
      mapId: "user_type",
      value: getKeyValueFromObject(onboarding, "user_type"),
      displayValue: `${getKeyValueFromObject(onboarding, "user_type") === "independent student"
          ? "I am an"
          : "I am a"
        } ${getKeyValueFromObject(onboarding, "user_type")}`,
      type: "dropdown",
      selectOptions: [
        { label: "I am a parent/guardian", value: "parent/guardian" },
        { label: "I am a dependent student", value: "dependent student" },
        { label: "I am an independent student", value: "independent student" },
      ],
      validation: requiredValidation,
      tipsData: _.get(infoTips, "scenario.onboarding.user_type"),
    },
    {
      label: "Parent/Guardian First Name",
      mapId: "parent_guardian.parent_name",
      value: getKeyValueFromObject(onboarding, "parent_guardian.parent_name"),
      type: "text",
      tipsData: _.get(
        infoTips,
        "scenario.onboarding.parent_guardian.parent_name"
      ),
    },
    {
      label: "Student First Name ",
      mapId: "student.student_name",
      value: getKeyValueFromObject(onboarding, "student.student_name"),
      type: "text",
      tipsData: _.get(infoTips, "scenario.onboarding.student.student_name"),
    },
  ];
};

/**
 * Method that returns student information from the onboarding object.
 * Student information contains all student related information like
 * student_name, siblings, major_id, student scores (gpa, sat, act).
 * @param {object} onboarding - object received from user scenario that provides
 * all the onboarding information for a user (student object and parent/guardian object).
 */
/**
 * Returned array contains student information that can be used
 * to display or edit student related data.
 * @param {string} label - Label that describes what the value is about.
 * @param {string} mapId - Id against which we search student info in
 * an object. Also use this key value to register a field in react hook
 * form if we want to edit this value.
 * @param {string} type - Defines which type of input field to render.
 * @param {object} validation - Defines a validation, if we need to pass it to
 * react hook form for that field.
 * @param {array} selectOptions - If input is a type of dropdown than this key
 * returns all the options that can be selected for that dropdown.
 * @param {string} dependentMapId - MapId for a field to which this field is
 * dependent upon.
 * @param {function} getSiblingsDataFeilds - Validates if siblings exists and
 * return fields for each sibling
 * @param {function} calculateBarWidth - Calculate width of the custom bar used in
 * inputs that are range type.
 * @param {boolean} validateRenderCondition - Condition that tells if a field should
 * be rendered or not in UI.
 */
export const getStudentDetail = ({
  onboarding = {},
  major,
  useMajorEthnicityOrReligionId,
  ethnicity,
  religion,
  dirtyFieldValues,
} = {}) => {
  const getDirtyOrOriginalField = initializeGetDirtyOrOriginalField({
    dirtyFieldValues,
    onboarding,
  });
  const { student } = onboarding || {};
  const actScorePresent = !!getKeyValueFromObject(
    onboarding,
    "student.scores.act"
  );
  const satScorePresent = !!getKeyValueFromObject(
    onboarding,
    "student.scores.sat"
  );
  const gpaScorePresent = !!getKeyValueFromObject(
    onboarding,
    "student.scores.gpa"
  );
  const weightedGpaPresent = !!getKeyValueFromObject(
    onboarding,
    "student.scores.weighted_gpa"
  );
  const studentInformationArray = [
    {
      label: "Student's Zip Code",
      mapId: "student.zip",
      type: "text",
      validation: {
        pattern: {
          value: zipCodeRegex,
          message: "Invalid zipcode",
        },
      },
    },
    {
      label: "Student's Country",
      mapId: "student.country",
      type: "dropdown",
      selectOptions: codes.map((country) => {
        return { label: `${country.name}`, value: country.alpha2 };
      }),
      valuePlaceholder: "US",
    },
    {
      label: "Number of People in Student's Household",
      mapId: "student.household_size",
      type: "number",
    },
    {
      label: "High School Name",
      mapId: "student.school_name",
      type: "hsLookup",
    },
    {
      label: "Graduation Year",
      mapId: "student.starting_college_year",
      type: "dropdown",
      selectOptions: getYears().map((year) => {
        return { label: `${year}`, value: year };
      }),
    },
    {
      label: "High School Class Rank",
      mapId: "student.high_school_class_rank",
      type: "number",
    },
    {
      label: "High School Class Size",
      mapId: "student.high_school_class_size",
      type: "number",
    },
    {
      label: "Intended Major",
      mapId: "student.major_id",
      displayValue: major?.name,
      valuePlaceholder:
        useMajorEthnicityOrReligionId === true ? major?.id : major?.name, //necessary temporary measure to display undecided as value. Will not need when major_id can have 0 as value
      type: "dropdown",
      selectOptions: [],
    },
    {
      label: "Ethnicity",
      mapId: "student.ethnicity_id",
      displayValue: ethnicity?.name,
      valuePlaceholder:
        useMajorEthnicityOrReligionId === true
          ? ethnicity?.id
          : ethnicity?.name, //necessary temporary measure to display undecided as value. Will not need when major_id can have 0 as value
      type: "dropdown",
      selectOptions: [],
    },
    {
      label: "Religion",
      mapId: "student.religion_id",
      displayValue: religion?.name,
      valuePlaceholder:
        useMajorEthnicityOrReligionId === true ? religion?.id : religion?.name, //necessary temporary measure to display undecided as value. Will not need when major_id can have 0 as value
      type: "dropdown",
      selectOptions: [],
    },
    {
      label: "Number of Student's Siblings",
      mapId: "student.num_siblings",
      type: "dropdown",
      // validation: {
      //   pattern: {
      //     value: /[0-5]/,
      //     message: "Allowed values only from 0 anto 5",
      //   },
      // },
      selectOptions: [0, 1, 2, 3, 4, 5].map((number) => ({
        label: number,
        value: number,
      })),
    },
    {
      label: "siblings_data",
      type: "text",
      dependentMapId: "student.num_siblings",
      getSiblingsDataFeilds: (numSiblings, unregister) =>
        createSiblingData(student, numSiblings, unregister),
    },
    {
      label: "GPA",
      mapId: "student.scores.gpa",
      type: "range",
      min: "0",
      max: "4.0",
      step: "0.1",
      minRequiredValue: "0",
      displayValue:
        !gpaScorePresent ||
          getKeyValueFromObject(onboarding, "student.scores.gpa") === "0.000"
          ? "N/A"
          : parseFloat(
            getKeyValueFromObject(onboarding, "student.scores.gpa")
          ).toFixed(1),
      validation: {
        min: (value) => value > parseFloat("0"),
      },
      calculateBarWidth: (scoreValue) => {
        return scoreValue
          ? `${((parseFloat(scoreValue) + 0.1) / 4.1) * 100}%`
          : "0%";
      },
    },
    {
      label: "Weighted GPA",
      mapId: "student.scores.weighted_gpa",
      type: "range",
      min: "0",
      max: "5.0",
      step: "0.1",
      minRequiredValue: "0",
      displayValue:
        !weightedGpaPresent ||
          getKeyValueFromObject(onboarding, "student.scores.weighted_gpa") ===
          "0.000"
          ? "N/A"
          : parseFloat(
            getKeyValueFromObject(onboarding, "student.scores.weighted_gpa")
          ).toFixed(1),
      validation: {
        min: (value) => value > parseFloat("0"),
      },
      calculateBarWidth: (scoreValue) => {
        return scoreValue
          ? `${((parseFloat(scoreValue) + 0.1) / 5.1) * 100}%`
          : "0%";
      },
    },
    {
      label: "SAT Score",
      mapId: "student.scores.sat",
      type: "range",
      min: "390",
      max: "1600",
      step: "10",
      minRequiredValue: "390",
      displayValue:
        !satScorePresent ||
          getKeyValueFromObject(onboarding, "student.scores.sat") === 390
          ? "N/A"
          : getKeyValueFromObject(onboarding, "student.scores.sat"),
      valuePlaceholder: satScorePresent
        ? "390"
        : getKeyValueFromObject(onboarding, "student.scores.sat"),
      calculateBarWidth: (scoreValue) => {
        return scoreValue
          ? `${((parseInt(scoreValue) - 390) / 1210) * 100}%`
          : "0%";
      },
    },
    {
      label: "ACT Score",
      mapId: "student.scores.act",
      type: "range",
      min: "0",
      max: "36",
      step: "1",
      minRequiredValue: "0",
      displayValue:
        !actScorePresent ||
          getKeyValueFromObject(onboarding, "student.scores.act") === 0
          ? "N/A"
          : getKeyValueFromObject(onboarding, "student.scores.act"),
      valuePlaceholder: actScorePresent
        ? "0"
        : getKeyValueFromObject(onboarding, "student.scores.act"),
      calculateBarWidth: (scoreValue) => {
        return scoreValue
          ? `${((parseInt(scoreValue) + 1) / 37) * 100}%`
          : "0%";
      },
    },
    {
      label: "Student’s Non-Retirement Savings/Investments",
      mapId: "student.student_investments",
      type: "text",
      displayValue: formatDollarAmount(
        Number(getKeyValueFromObject(onboarding, "student.student_investments"))
      ),
      amount: true,
    },
    {
      label: "Student’s Gross Earnings",
      mapId: "student.student_earnings",
      type: "text",
      displayValue: formatDollarAmount(
        Number(getKeyValueFromObject(onboarding, "student.student_earnings"))
      ),
      amount: true,
    },
    adjustedGrossIncomeField({
      onboarding,
      agi_user_provided: onboarding?.student?.student_agi_user_provided,
      labelPrefix: "Student",
      onboarding_agi_path: "student.student_agi",
    }),
    {
      label: "Received National Merit Award",
      mapId: "student.national_merit_award",
      type: "dropdown",
      selectOptions: [
        "No",
        "National Recognition",
        "Commended",
        "Semi-Finalist",
        "Finalist",
        "Scholar",
      ].map((receivedAward) => {
        return { label: receivedAward, value: receivedAward };
      }),
    },
  ];

  /**
   * Assign value to each object, extracted from onboarding information.
   */
  return studentInformationArray.map((studentInput) => {
    return {
      ...studentInput,
      value:
        studentInput.label === "siblings_data"
          ? getSiblingData(student)
          : getKeyValueFromObject(
            onboarding,
            studentInput.mapId,
            _.isUndefined(studentInput.valuePlaceholder)
              ? ""
              : studentInput.valuePlaceholder
          ),
      tipsData: _.get(infoTips, `scenario.onboarding.${studentInput.mapId}`),
    };
  });
};
