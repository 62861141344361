import Link from "next/link";
import { useRef } from "react";
import { Card } from "@components/index";
import { useAppContext } from "@context/state";
import { OFFER_EDIT } from "@utils/constants";
import { processRemainingCost } from "@utils/costEstimates";
import renderLogo from "@utils/renderLogo";
import { useRouter } from "next/router";
import { Checkmark } from "@icons/index";
import OfferCardCosts from "./OfferCardCosts";
import OfferCardAwards from "./OfferCardAwards";

/**
 * @param {object} offerData
 * @param {boolean} showMoreDetails
 * @returns Card with details about offer for dashboard and Translate & Compare page
 */
const OfferCard = ({ offerData, showMoreDetails }) => {
  if (!offerData) {
    return null;
  }
  const cardRef = useRef();

  const {
    review_status,
    school,
    tuition_budget, //manual choice
    decided_document_job, //auto choice (translated)
    cap_projections, //auto choice (cap projections)
  } = offerData;

  const { school_id, logo, name } = school ? school : {};

  const {
    scenario,
    setFormModalView,
    setFormModalContent,
    setDisplayFormModal,
    setScrollToCard,
  } = useAppContext();

  const router = useRouter();

  const handleEditButton = () => {
    if (router.pathname === "/translate_compare") {
      setFormModalContent(offerData); //When offer data is present, set here
      setFormModalView(OFFER_EDIT);
      setDisplayFormModal(true);
    } else {
      //Set scroll left default for compare & appeal section and navigate to /translate_compare
      //set index of card to scroll to
      const cardGalleryIndex = [...cardRef.current.parentNode.children].indexOf(
        cardRef.current
      );
      setScrollToCard(cardGalleryIndex);
      router.push("/translate_compare");
    }
  };

  return (
    <div className="cardContainer" ref={cardRef}>
      <Card type="typeTall">
        <div className="flex flex-col justify-between h-full">
          <div className="topHeading">
            <div className="flex flex-row justify-between items-start">
              {renderLogo(logo, name)}
              <div className="flex flex-col items-end">
                <button
                  onClick={handleEditButton}
                  className="imitateLink flex items-start"
                >
                  <small>
                    <b>Edit</b>
                  </small>
                </button>
                {review_status === "Approved" && (
                  <span className="checkmark mt-1">
                    <Checkmark />
                  </span>
                )}
              </div>
            </div>
            <div className="h4">
              <Link
                href={`/case/${scenario.case_id}/school/${[school_id]}`}
                className="underline inherit">
                {name}
              </Link>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="divider" />
            <div className="affordability-costs flex flex-col">
              {showMoreDetails ? (
                <>
                  <h6 className="mb-0.5">Remaining Cost</h6>
                  <h2>
                    {processRemainingCost(
                      offerData?.tuition_budget?.fillable_fields
                    )}
                  </h2>
                  <div className="flex flex-col justify-between">
                    <OfferCardCosts
                      capProjections={cap_projections}
                      tuitionBudget={tuition_budget}
                      decidedDocumentJob={
                        //JSON.parse needed because response field is stored as json in database
                        decided_document_job?.response
                          ? JSON.parse(decided_document_job?.response)
                          : null
                      }
                    />
                    <OfferCardAwards
                      tuitionBudget={tuition_budget}
                      decidedDocumentJob={
                        decided_document_job?.response
                          ? JSON.parse(decided_document_job?.response)
                          : null
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <h2 className="removeMargin">Remaining Cost</h2>
                  <small className="pb-1">Before loans and work study</small>
                  <h2 className="removeMargin">
                    {processRemainingCost(
                      offerData?.tuition_budget?.fillable_fields
                    )}
                  </h2>
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OfferCard;
