import ClipLoader from "react-spinners/ClipLoader";

const TranslationPendingModal = () => {
  return (
    <div className="card flex flex-col text-center">
      <div className="loading-spinner flex justify-center items-center mb-4 mt-2 md:mt-0">
        <ClipLoader
          size={100}
          css={{
            borderColor: "inherit",
            borderBottomColor: "transparent",
          }}
        />
      </div>
      <div className="mb-4 h3">
        It may take a minute for your award letter to be translated.
      </div>
      <div className="mb-0 h5">
        While you wait, please note that we value your data privacy and our
        partner DecidED does <b>not</b> store your award letter as we require
        that they immediately delete it following this translation process.
      </div>
    </div>
  );
};

export default TranslationPendingModal;
