import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { TextField } from "@components/index";
import { requiredValidation } from "@utils/formValidations";
import { useAppContext } from "@context/state";
import { HELP_VIEW } from "@utils/constants";
import { renderCapOrResetIcon } from "@utils/renderHelpers";

/**
 * @param {number} forcedValue
 * @param {number} defaultValue
 * @param {boolean} readOnly - whether or not input should be disabled
 * @param {string} mapId
 * @returns input component for how to pay breakdowns
 */
const BreakdownInput = ({
  forcedValue,
  defaultValue,
  readOnly,
  mapId,
  capProjectionValue,
  type,
}) => {
  const methods = useFormContext();
  const { setValue, watch } = methods;

  const [valueType, setValueType] = useState("Default");
  const { setDisplayModal, setModalView, setModalContent } = useAppContext();

  /**
   * Reset input to an automatic value, preferring translated values. TODO: Reset to CAP Value
   */
  const resetToDefaultValue = () => {
    if (valueType === "Reset") {
      if (capProjectionValue >= 0) {
        setValue(mapId, capProjectionValue, { shouldDirty: true });
        setValueType("CAP Value");
      } else {
        //set to default otherwise
        setValue(mapId, defaultValue, { shouldDirty: true });
        setValueType("Default");
      }
    } else if (valueType === "CAP Value") {
      setModalContent([
        {
          id: 0,
          title: "CAP Value",
          description: (
            <span>
              These are the expected or default results produced by the CAP
              platform based on your profile and selections.
            </span>
          ),
        },
      ]);
      setModalView(HELP_VIEW);
      setDisplayModal(true);
    }
  };

  /**
   * After render initialize default values for every
   * input field. Values are received from user preapprovals object.
   */
  useEffect(() => {
    if (!readOnly) {
      //if input value is the same as the CAP Value, show value type as CAP Value. Otherwise show "Reset"
      if (defaultValue !== undefined) {
        setValue(mapId, defaultValue);
        //set value type to blank if cap projection is undefined and value is 0
      } else if (Number.isInteger(capProjectionValue)) {
        setValueType("CAP Value");
        setValue(mapId, capProjectionValue);
      }
    }
  }, [readOnly]);

  /**
   * Dynamically change valueType when input is changed
   */
  useEffect(() => {
    if (!readOnly) {
      const inputValue = parseInt(watch(mapId));
      //if input value is the same as the CAP Value, show value type as CAP Value. Otherwise show "Reset"
      if (inputValue !== undefined) {
        if (inputValue === capProjectionValue && type === "Cost") {
          //CAP Value
          setValueType("CAP Value");
        } else {
          //if cap projection exists or input value is different from the default value while cap projection does not exist, show "Reset", otherwise show "Default" (blank)
          if (
            Number.isInteger(capProjectionValue) ||
            (inputValue !== defaultValue &&
              !Number.isInteger(capProjectionValue))
          ) {
            setValueType("Reset");
          } else if (inputValue === defaultValue) {
            setValueType("Default");
          }
        }
      }
    }
  }, [watch(mapId), readOnly]);

  return (
    <div className="breakdown-value inputItem currencyLabel relative flex flex-col justify-center items-center">
      <TextField
        input={{
          mapId,
          type: "text",
          amount: true,
          placeholder: "0",
          validation: requiredValidation,
          disabled: readOnly,
          forcedValue,
        }}
      />
      {!readOnly &&
        valueType !== "Default" &&
        (type === "Cost" ||
          (type === "Preapproval" &&
            parseInt(watch(mapId)) !== capProjectionValue)) && (
          <button
            className="value-type simple flex items-center"
            type="button"
            onClick={resetToDefaultValue}
          >
            <small>{renderCapOrResetIcon(valueType)}</small>
          </button>
        )}
      <div className="currency resetFont">$</div>
    </div>
  );
};

export default BreakdownInput;
